import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class KpiService {

  constructor(private firestore: AngularFirestore) { }

  async getDetailGraph(idgraph){
    const markers = [];
    return await this.firestore
    .collection("kpi")
    .ref.where('idgraph', '==', idgraph)
    .get()
    .then(data => {
      data.forEach(function (doc) {
        markers.push(doc.data());
      });
      return markers;
    })
  }

  async getKpis(user, cant = 10){
    const markers = [];
    return await this.firestore
    .collection("kpi")
    .ref.where('user', '==', user)
    .where("visibility", "==", 'visible')
    .limit(cant).orderBy("date", "desc")
    .get()
    .then(data => {
      data.forEach(function (doc) {
        markers.push(doc.data());
      });
      return markers;
    })
  }
}
