import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-configuration',
  templateUrl: './icon-configuration.component.svg',
  styleUrls: ['./icon-configuration.component.scss'],
})
export class IconConfigurationComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
