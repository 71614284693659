import { Pipe, PipeTransform } from '@angular/core';
import { LanguagesService } from '../services/languagesService/languages.service';

@Pipe({
  name: 'parseStates'
})
export class ParseStatesPipe implements PipeTransform {

  constructor(private languageService:LanguagesService){}

  get getTextIUOrdersStatus() {
    return this.languageService.textsIU["statusOrder"];
  }

  transform(value: unknown, ...args: unknown[]): unknown {
    let state = '';
    let colorState = '';
    switch (value) {
      case 'confirm_received':
      state = this.getTextIUOrdersStatus.confirm_received;
      colorState = 'light';
      break;
    case 'pending':
      state = this.getTextIUOrdersStatus.pending;
      colorState = 'warning';
      break;
    case 'processing':
      state = this.getTextIUOrdersStatus.processing;
      colorState = 'warning';
      break;
    case 'central_xfer':
      state = this.getTextIUOrdersStatus.central_xfer;
      colorState = 'warning';
      break;
    case 'acepted':
      state = this.getTextIUOrdersStatus.acepted;
      colorState = 'light';
      break;
    case 'cancelled':
      state = this.getTextIUOrdersStatus.cancelled;
      colorState = 'danger';
      break;
    case 'delivered':
      state = this.getTextIUOrdersStatus.delivered;
      colorState = 'success';
      break;
    case 'created':
      state = this.getTextIUOrdersStatus.created;
      colorState = 'warning';
      break;
    case 'retry_central_xfer':
      state = this.getTextIUOrdersStatus.retry_central_xfer;
      colorState = 'danger';
      break;
     }
     if(args && args.length > 0 && args[0] == "color"){
        return colorState;
      }
    return state;
  }

}
