import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'imgIcons'
})
export class ImgIconsPipe implements PipeTransform {

  transform(icon: string, folder?: string): string {
    const URL = "../../../../assets/icons"
    return `${URL}/${folder}/${icon}.svg`;
  }

}
