import { Component, Input } from "@angular/core";
import { LanguagesService } from "src/app/services/languagesService/languages.service";

@Component({
  selector: "app-checkout-status",
  templateUrl: "./checkout-status.component.html",
  styleUrls: ["./checkout-status.component.scss"],
})
export class CheckoutStatusComponent {
  constructor(private languageService: LanguagesService) { }
  @Input() orderStatus: CustomEvent;

  get getTextIUComponentCheckoutStatus() {
    return this.languageService.textsIU.component_checkout_status;
  }
}
