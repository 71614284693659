import { Injectable, ɵConsole } from "@angular/core";
import {
  HttpEvent,
  HttpInterceptor,
  HttpHandler,
  HttpRequest,
} from "@angular/common/http";
import { AuthService } from "../auth/auth.service";

import { switchMap } from "rxjs/operators";
import { from } from "rxjs";

import { environment } from "../../../environments/environment";
import { RouterService } from "../router/router.service";

@Injectable({
  providedIn: "root",
})
export class ServerHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): any {
    /* When user get new refresh token with X-Skip-Interceptor
    this code prevent infinite loop in this interceptor
    */
    if (request.headers.get("X-Skip-Interceptor") == "") {
      return next.handle(request);
    }

    return from(this.authService.refreshToken()).pipe(
      switchMap((token) => {
        let headers = {};
        /* IF TOKEN EXIST USE ON REQUEST */
        if (token) {
          headers = {
            "Content-Type": "application/json",
            Authorization: `Bearer ${token}`,
          };
        }
        /*REQUEST INSIDE TABS (INSIDE APLICACION WITH USER AUTHENTICATED*/
        if (request.headers.get("X-Skip-Interceptor") !== "") {
          request = request.clone({
            setParams: {
              key: this.authService.apiKey,
            },
          });
        }
        /* ADD HEADERS */
        request = request.clone({
          setHeaders: headers,
        });
        return next.handle(request);
      })
    );
  }
}
