import { Component, Input, OnInit } from "@angular/core";
//Services
import { UserService } from "src/app/services/user/user.service";
import { RouterService } from "src/app/services/router/router.service";
import firebase from "firebase/app";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { AnalyticsService } from "src/app/services/analytics/analytics.service";
import { Platform } from "@ionic/angular";
import { AlertService } from "src/app/services/alert/alert.service";
import { GamificationStatusPage } from "src/app/pages/private/gamification-status/gamification-status.page";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
@Component({
  selector: "app-profile-component",
  templateUrl: "./profile.component.html",
  styleUrls: ["./profile.component.scss"],
})
export class ProfileComponent implements OnInit {
  @Input() shape: string = "false";
  @Input() points: string = "0";

  public name: string;
  hideGamification = false;
  loader: boolean = false;
  data: any = {};
  db: any;
  pointsVariable: string;
  program: any;
  programs: any;
  color: string;
  constructor(
    private userService: UserService,
    private routerService: RouterService,
    private languageService: LanguagesService,
    public authService: AuthService,
    private analitycsService: AnalyticsService,
    private platform: Platform,
    public alertService: AlertService,
    public remoteConfig: RemoteConfigService
  ) {
    this.db = firebase;
    const sizeWidth = document.body.clientWidth;
    if (sizeWidth > 576) {
      this.color = "white";
    }
  }

  ngOnInit() {
    this.loader = true;
    this.getDataFromUser();
    this.getHideGamification();
    if (this.authService.programId && this.authService.programId[0]) {
      this.programs = this.authService.programId[0].split("/");
      this.program = this.programs.slice(-1).pop();
    }
    this.getInfoUser();
  }

  async getInfoUser() {
    this.userService.getUserInfo().subscribe((res: any) => {
      if (res) {
        this.name = res.name + " " + res.lastnames;
      }
    });
  }

  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }

  async getDataFromUser() {
    this.userService.getUserInfo().subscribe((user: any) => {
      if (user) {
        this.checkIfUserOldAddress(user);
        this.loader = false;
        this.analitycsService.setUser(this.authService.userGnxId);
        return;
      }
    });
  }

  // this is de old keys for adress, temporal for old programs
  async checkIfUserOldAddress(user) {
    if (user.adress) {
      await this.userService.removeFields("adress");
    }
    if (user.activeAdress) {
      await this.userService.removeFields("activeAdress");
    }
  }

  levelResumeSelected(value) {
    this.routerService.redirect(
      `gamification-status`,
      GamificationStatusPage,
      null,
      "gnx-modal-radius"
    );
  }
  colorText() {
    const sizeWidth = document.body.clientWidth;

    if (sizeWidth > 576) return "white";

    return "";
  }

  async getHideGamification() {
    return this.remoteConfig.getHideGamification().subscribe((res) => {
      this.hideGamification = res;
    });
  }
}
