import { Injectable } from '@angular/core';
import { Platform } from "@ionic/angular";
import { RemoteConfigService } from '../remote-config/remote-config.service';
import { map } from 'rxjs/operators';
import { AppVersion } from '@ionic-native/app-version/ngx';
import { AlertService } from '../alert/alert.service';
import { EMPTY } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ValidateVersionService {


  constructor(
    private platform: Platform,
    private remoteConfig: RemoteConfigService,
    private appVersion: AppVersion,
    private alertService: AlertService
  ) { }

  validate() {
    this.getVersionApp().subscribe((version) => {
      if (!version) {
        return;
      }
      this.appVersion.getVersionNumber().then((current_version) => {
        if (version > current_version) {
          this.alertService.presentAlertUpdateApp(version);
        }
      });
    });
  }
  private getVersionApp() {
    if (this.platform.is("mobileweb")) {
      return EMPTY;
    }
    else if (this.platform.is("ios")) {
      return this.remoteConfig.getVersionApp().pipe(
        map((versiones) => versiones['ios'])
      )
    }
    else if (this.platform.is('android')) {
      return this.remoteConfig.getVersionApp().pipe(
        map((versiones) => versiones['android'])
      )
    }
    else {
      return EMPTY;
    }
  }

}
