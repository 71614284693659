import { Component, OnInit } from '@angular/core';
import { Platform } from '@ionic/angular';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

@Component({
  selector: 'app-slide-exhibitions',
  templateUrl: './slide-exhibitions.component.html',
  styleUrls: ['./slide-exhibitions.component.scss'],
})
export class SlideExhibitionsComponent implements OnInit {
  categories_app_active: any = [];
  slidesFiltersPerView: number = 3.1;
  slidesContentPerView: number = 1.5;
  slideOpts = {};
  categories_app: any;

  constructor(
    public platform: Platform,
    public languageService: LanguagesService,
  ) { }

  ngOnInit() {
    if (this.platform.is("desktop")) {
      this.slidesFiltersPerView = 5;
      this.slidesContentPerView = 4;
    }
    this.slideOpts = {
      pagination: false,
      initialSlide: 0,
      slidesPerView: this.slidesContentPerView,
      speed: 400,
    };


    this.categories_app = this.languageService.textsIU.category_content;

    this.categories_app.forEach((element: any) => {
      if (element.active) {
        this.categories_app_active.push(element);
      }
    });
  }
  get getTextsIUContentTitle() {
    return this.languageService.textsIU.page_contact_title_ex.title;
  }
  get getTextsIUContentSubTitle() {
    return this.languageService.textsIU.page_contact_subtitle_ex.title;
  }
  get getTextsIUContent() {
    return this.languageService.textsIU.page_content;
  }
}
