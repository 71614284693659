import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";

import { MapPage } from "./map.page";


const routes: Routes = [
  {
    path: "no-address/:id",
    component: MapPage,
  },
  {
    path: "list",
    loadChildren: () =>
      import("./list-address/list-address.module").then(
        (m) => m.ListAddressPageModule
      ),
  },
  {
    path: 'add-address',
    loadChildren: () => import('./add-address/add-address.module').then(
       m => m.AddAddressPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class MapPageRoutingModule {}
