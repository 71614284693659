import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'srcImage'
})
export class SrcImagePipe implements PipeTransform {

  transform(folder:string, language:string): string {
    const url = '../../../../assets/img';
    return `${url}/${folder}/${language}.png`;
  }

}
