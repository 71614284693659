import { Component, OnInit, Input } from '@angular/core';
import firebase from "firebase/app";
import { LanguagesService } from 'src/app/services/languagesService/languages.service';
import { PointsService } from "../../../services/points/points.service";
import { UtilsService } from "../../../services/utils/utils.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { AlertService } from 'src/app/services/alert/alert.service';

@Component({
  selector: 'app-gamification-details',
  templateUrl: './gamification-details.page.html',
  styleUrls: ['./gamification-details.page.scss'],
})
export class GamificationDetailsPage implements OnInit {
  db: any;
  @Input() points: string = "1";
  program: any;
  programs: any;
  constructor(
    private pointsService: PointsService,
    public languageService: LanguagesService,
    private utilsService: UtilsService,
    public authService: AuthService,
    public alertService: AlertService,
  ) {
    this.db = firebase;
  }

  ngOnInit() {
    this.utilsService.getUserPoints().then((result: any ) => {
      this.points = result;
    });
    if(this.authService.programId && this.authService.programId[0]){
      this.programs = this.authService.programId[0].split('/');
      this.program = this.programs.slice(-1).pop();
    }
  }
  
  get getTextIUPageGamificationDetail() {
    return this.languageService.textsIU.page_gamification_detail;
  }
}
