import { Component, OnInit, Input } from "@angular/core";
import firebase from "firebase/app";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RouterService } from "src/app/services/router/router.service";
import { PointsService } from "../../../services/points/points.service";
import { UtilsService } from "../../../services/utils/utils.service";
import { AuthService } from "src/app/services/auth/auth.service";
import { Platform } from "@ionic/angular";
import { AlertService } from "src/app/services/alert/alert.service";
import { GamificationDetailsPage } from "../gamification-details/gamification-details.page";

@Component({
  selector: "app-gamification-status",
  templateUrl: "./gamification-status.page.html",
  styleUrls: ["./gamification-status.page.scss"],
})
export class GamificationStatusPage implements OnInit {
  db: any;
  @Input() points: string = "100";
  program: any;
  programs: any;
  constructor(
    private routerService: RouterService,
    private pointsService: PointsService,
    public languageService: LanguagesService,
    public authService: AuthService,
    private utilsService: UtilsService,
    private platform: Platform,
    public alertService: AlertService
  ) {
    this.db = firebase;
  }

  ngOnInit() {
    this.utilsService.getUserPoints().then((result: any) => {
      this.points = result;
    });
    if (this.authService.programId && this.authService.programId[0]) {
      this.programs = this.authService.programId[0].split("/");
      this.program = this.programs.slice(-1).pop();
    }
  }

  levelBenefitSelected(value) {
    this.alertService.dismissPopup();
    this.routerService.redirect(`gamification-details`, GamificationDetailsPage, null, "gnx-modal-radius gnx-modal-xs", GamificationStatusPage );
  }

  get getTextIUPageGamificationStatus() {
    return this.languageService.textsIU.page_gamification_status;
  }
}
