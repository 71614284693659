import { Component, OnInit, Input, Output, EventEmitter,} from '@angular/core';
import { Router } from '@angular/router';
import { NavController, Platform } from '@ionic/angular';

@Component({
  selector: 'app-card-content',
  templateUrl: './card-content.component.html',
  styleUrls: ['./card-content.component.scss']  
})
export class CardContentComponent implements OnInit {
  @Input() title: string;
  @Input() description: string;
  @Input() image: string;
  @Input() link: string;
  @Input() link_text: string;  
  @Input() diffPage: boolean = false;
  

    constructor(private router: Router, private navCtrl: NavController, private platform: Platform) { }

  ngOnInit() {}

  goTo(route) { 
    // @TODO agrega el app en la ruta cuando sea wab
    if( this.platform.is("desktop") &&  (route == "challenges" || route == "exhibitions" || route == "invoices")){
      route = "app/"+route;
    }
    this.router.navigate([route], {replaceUrl: true})
  }

}
