import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-card-content-skeleton',
  templateUrl: './card-content-skeleton.component.html',
  styleUrls: ['./card-content-skeleton.component.scss'],
})
export class CardContentSkeletonComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
