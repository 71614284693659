import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { KpiService } from 'src/app/services/kpi/kpi.service';
import { LanguagesService } from 'src/app/services/languagesService/languages.service';

@Component({
  selector: 'app-kpi-list',
  templateUrl: './kpi-list.component.html',
  styleUrls: ['./kpi-list.component.scss'],
})
export class KpiListComponent implements OnInit {
  kpis: any[];
  @Input() user;
  @Input() cant = 10;
  @Output() kpiSelected: EventEmitter<any> = new EventEmitter();

  constructor(private kpiService: KpiService,
    private languageService: LanguagesService) { }

  ngOnInit() {
    this.getKpis();
  }

  async getKpis() {
    this.kpis = await this.kpiService.getKpis(this.user,this.cant);
  }

  emitKpi(data){
    this.kpiSelected.emit(data);
  }

  get getTextsIUPageKpi() {
    return this.languageService.textsIU.page_kpi;
  }

}
