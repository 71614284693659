import { AfterViewInit, Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-profile',
  templateUrl: './icon-profile.component.svg',
  styleUrls: ['./icon-profile.component.scss'],
})
export class IconProfileComponent {

  constructor() { }
}
