import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-page-not-found',
  templateUrl: './page-not-found.component.html',
  styleUrls: ['./page-not-found.component.scss'],
})
export class PageNotFoundComponent {

  @Input()mode: 'page-not-found' | 'page-not-found-data';
  @Input()language:'es' | 'en' | 'fr'= 'es';
  constructor() { }
}
