/**
 * Returns the Colombian states list to populate the select item.
 */
export function gluky_addressfield_co_states_list() {
  let temporalStates = [];
  const states = {
    91: "Amazonas",
    "05": "Antioquia",
    81: "Arauca",
    88: "Archipiélago De San Andrés, Providencia Y",
    "08": "Atlántico",
    11: "Bogotá, D. C.",
    13: "Bolívar",
    15: "Boyacá",
    17: "Caldas",
    18: "Caquetá",
    85: "Casanare",
    19: "Cauca",
    20: "Cesar",
    27: "Chocó",
    23: "Córdoba",
    25: "Cundinamarca",
    94: "Guainía",
    95: "Guaviare",
    41: "Huila",
    44: "La Guajira",
    47: "Magdalena",
    50: "Meta",
    52: "Nariño",
    54: "Norte De Santander",
    86: "Putumayo",
    63: "Quindío",
    66: "Risaralda",
    68: "Santander",
    70: "Sucre",
    73: "Tolima",
    76: "Valle Del Cauca",
    97: "Vaupés",
    99: "Vichada",
  };
  Object.keys(states).map((res) => {
    temporalStates = [
      ...temporalStates,
      {
        name: states[res],
        code: res,
      },
    ];
  });
  return temporalStates;
}

/**
 * Returns the Colombian cities list by state code to populate the select item.
 *
 * @param $state_code
 *   The DANE state code
 * @return
 */
export function gluky_addressfield_co_cities_list($state_code) {
  let temporalCities = [];
  const $data = {
    "05": {
      "05002": "Abejorral",
      "05004": "Abriaqui",
      "05021": "Alejandria",
      "05030": "Amaga",
      "05031": "Amalfi",
      "05034": "Andes",
      "05036": "Angelopolis",
      "05038": "Angostura",
      "05040": "Anori",
      "05044": "Anza",
      "05045": "Apartado",
      "05051": "Arboletes",
      "05055": "Argelia",
      "05059": "Armenia",
      "05079": "Barbosa",
      "05088": "Bello",
      "05086": "Belmira",
      "05091": "Betania",
      "05093": "Betulia",
      "05107": "Briceño",
      "05113": "Buritica",
      "05120": "Caceres",
      "05125": "Caicedo",
      "05129": "Caldas",
      "05134": "Campamento",
      "05138": "Cañasgordas",
      "05142": "Caracoli",
      "05145": "Caramanta",
      "05147": "Carepa",
      "05148": "Carmen De Viboral",
      "05150": "Carolina",
      "05154": "Caucasia",
      "05172": "Chigorodo",
      "05190": "Cisneros",
      "05101": "Ciudad Bolivar",
      "05197": "Cocorna",
      "05206": "Concepcion",
      "05209": "Concordia",
      "05212": "Copacabana",
      "05234": "Dabeiba",
      "05237": "Don Matias",
      "05240": "Ebejico",
      "05250": "El Bagre",
      "05264": "Entrerrios",
      "05266": "Envigado",
      "05282": "Fredonia",
      "05284": "Frontino",
      "05306": "Giraldo",
      "05308": "Girardota",
      "05310": "Gomez Plata",
      "05313": "Granada",
      "05315": "Guadalupe",
      "05318": "Guarne",
      "05321": "Guatape",
      "05347": "Heliconia",
      "05353": "Hispania",
      "05360": "Itagui",
      "05361": "Ituango",
      "05364": "Jardin",
      "05368": "Jerico",
      "05376": "La Ceja",
      "05380": "La Estrella",
      "05390": "La Pintada",
      "05400": "La Union",
      "05411": "Liborina",
      "05425": "Maceo",
      "05440": "Marinilla",
      "05001": "Medellin",
      "05467": "Montebello",
      "05475": "Murindo",
      "05480": "Mutata",
      "05483": "Nariño",
      "05495": "Nechi",
      "05490": "Necocli",
      "05501": "Olaya",
      "05541": "Peñol",
      "05543": "Peque",
      "05576": "Pueblorrico",
      "05579": "Puerto Berrio",
      "05585": "Puerto Nare",
      "05591": "Puerto Triunfo",
      "05604": "Remedios",
      "05607": "Retiro",
      "05615": "Rionegro",
      "05628": "Sabanalarga",
      "05631": "Sabaneta",
      "05642": "Salgar",
      "05647": "San Andres",
      "05649": "San Carlos",
      "05652": "San Francisco",
      "05656": "San Jeronimo",
      "05658": "San Jose De La Montaña",
      "05659": "San Juan De Uraba",
      "05660": "San Luis",
      "05664": "San Pedro",
      "05665": "San Pedro De Uraba",
      "05667": "San Rafael",
      "05670": "San Roque",
      "05679": "Santa Barbara",
      "05042": "Santafe De Antioquia",
      "05686": "Santa Rosa De Osos",
      "05690": "Santo Domingo",
      "05697": "Santuario",
      "05674": "San Vicente",
      "05736": "Segovia",
      "05756": "Sonson",
      "05761": "Sopetran",
      "05789": "Tamesis",
      "05790": "Taraza",
      "05792": "Tarso",
      "05809": "Titiribi",
      "05819": "Toledo",
      "05837": "Turbo",
      "05842": "Uramita",
      "05847": "Urrao",
      "05854": "Valdivia",
      "05856": "Valparaiso",
      "05858": "Vegachi",
      "05861": "Venecia",
      "05873": "Vigia Del Fuerte",
      "05885": "Yali",
      "05887": "Yarumal",
      "05890": "Yolombo",
      "05893": "Yondo",
      "05895": "Zaragoza",
    },
    "08": {
      "08078": "Baranoa",
      "08001": "Barranquilla",
      "08137": "Campo De La Cruz",
      "08141": "Candelaria",
      "08296": "Galapa",
      "08372": "Juan De Acosta",
      "08421": "Luruaco",
      "08433": "Malambo",
      "08436": "Manati",
      "08520": "Palmar De Varela",
      "08549": "Piojo",
      "08558": "Polonuevo",
      "08560": "Ponedera",
      "08573": "Puerto Colombia",
      "08606": "Repelon",
      "08634": "Sabanagrande",
      "08638": "Sabanalarga",
      "08675": "Santa Lucia",
      "08685": "Santo Tomas",
      "08758": "Soledad",
      "08770": "Suan",
      "08832": "Tubara",
      "08849": "Usiacuri",
    },
    11: {
      11001: "Bogota D.c.",
      11092: "Bosa",
      11030: "Fontibon",
      11040: "Suba",
    },
    13: {
      13006: "Achi",
      13030: "Altos Del Rosario",
      13042: "Arenal",
      13052: "Arjona",
      13062: "Arroyohondo",
      13074: "Barranco De Loba",
      13140: "Calamar",
      13160: "Cantagallo",
      13001: "Cartagena",
      13188: "Cicuco",
      13222: "Clemencia",
      13212: "Cordoba",
      13244: "El Carmen De Bolivar",
      13248: "El Guamo",
      13268: "El Peñon",
      13300: "Hatillo De Loba",
      13430: "Magangue",
      13433: "Mahates",
      13440: "Margarita",
      13442: "Maria La Baja",
      13468: "Mompos",
      13458: "Montecristo",
      13473: "Morales",
      13490: "Norosi",
      13549: "Pinillos",
      13580: "Regidor",
      13600: "Rio Viejo",
      13620: "San Cristobal",
      13647: "San Estanislao",
      13650: "San Fernando",
      13654: "San Jacinto",
      13655: "San Jacinto Del Cauca",
      13657: "San Juan Nepomuceno",
      13667: "San Martin De Loba",
      13670: "San Pablo",
      13673: "Santa Catalina",
      13683: "Santa Rosa",
      13688: "Santa Rosa Del Sur",
      13744: "Simiti",
      13760: "Soplaviento",
      13780: "Talaigua Nuevo",
      13810: "Tiquisio",
      13836: "Turbaco",
      13838: "Turbana",
      13873: "Villanueva",
      13894: "Zambrano",
    },
    15: {
      15022: "Almeida",
      15047: "Aquitania",
      15051: "Arcabuco",
      15087: "Belen",
      15090: "Berbeo",
      15092: "Beteitiva",
      15097: "Boavita",
      15104: "Boyaca",
      15106: "Briceño",
      15109: "Buenavista",
      15114: "Busbanza",
      15131: "Caldas",
      15135: "Campohermoso",
      15162: "Cerinza",
      15172: "Chinavita",
      15176: "Chiquinquira",
      15232: "Chiquiza",
      15180: "Chiscas",
      15183: "Chita",
      15185: "Chitaraque",
      15187: "Chivata",
      15236: "Chivor",
      15189: "Cienega",
      15204: "Combita",
      15212: "Coper",
      15215: "Corrales",
      15218: "Covarachia",
      15223: "Cubara",
      15224: "Cucaita",
      15226: "Cuitiva",
      15238: "Duitama",
      15244: "El Cocuy",
      15248: "El Espino",
      15272: "Firavitoba",
      15276: "Floresta",
      15293: "Gachantiva",
      15296: "Gameza",
      15299: "Garagoa",
      15317: "Guacamayas",
      15322: "Guateque",
      15325: "Guayata",
      15332: "Guican",
      15362: "Iza",
      15367: "Jenesano",
      15368: "Jerico",
      15377: "Labranzagrande",
      15380: "La Capilla",
      15403: "La Uvita",
      15401: "La Victoria",
      15425: "Macanal",
      15442: "Maripi",
      15455: "Miraflores",
      15464: "Mongua",
      15466: "Mongui",
      15469: "Moniquira",
      15476: "Motavita",
      15480: "Muzo",
      15491: "Nobsa",
      15494: "Nuevo Colon",
      15500: "Oicata",
      15507: "Otanche",
      15511: "Pachavita",
      15514: "Paez",
      15516: "Paipa",
      15518: "Pajarito",
      15522: "Panqueba",
      15531: "Pauna",
      15533: "Paya",
      15537: "Paz De Rio",
      15542: "Pesca",
      15550: "Pisba",
      15572: "Puerto Boyaca",
      15580: "Quipama",
      15599: "Ramiriqui",
      15600: "Raquira",
      15621: "Rondon",
      15632: "Saboya",
      15638: "Sachica",
      15646: "Samaca",
      15660: "San Eduardo",
      15664: "San Jose De Pare",
      15667: "San Luis De Gaceno",
      15673: "San Mateo",
      15676: "San Miguel De Sema",
      15681: "San Pablo Borbur",
      15693: "San Rosa Viterbo",
      15690: "Santa Maria",
      15686: "Santana",
      15696: "Santa Sofia",
      15720: "Sativanorte",
      15723: "Sativasur",
      15740: "Siachoque",
      15753: "Soata",
      15757: "Socha",
      15755: "Socota",
      15759: "Sogamoso",
      15761: "Somondoco",
      15762: "Sora",
      15764: "Soraca",
      15763: "Sotaquira",
      15774: "Susacon",
      15776: "Sutamarchan",
      15778: "Sutatenza",
      15790: "Tasco",
      15798: "Tenza",
      15804: "Tibana",
      15806: "Tibasosa",
      15808: "Tinjaca",
      15810: "Tipacoque",
      15814: "Toca",
      15816: "Togui",
      15820: "Topaga",
      15822: "Tota",
      15001: "Tunja",
      15832: "Tunungua",
      15835: "Turmeque",
      15837: "Tuta",
      15839: "Tutaza",
      15842: "Umbita",
      15861: "Ventaquemada",
      15407: "Villa De Leyva",
      15879: "Viracacha",
      15897: "Zetaquira",
    },
    17: {
      17013: "Aguadas",
      17042: "Anserma",
      17050: "Aranzazu",
      17088: "Belalcazar",
      17174: "Chinchina",
      17272: "Filadelfia",
      17380: "La Dorada",
      17388: "La Merced",
      17001: "Manizales",
      17433: "Manzanares",
      17442: "Marmato",
      17444: "Marquetalia",
      17446: "Marulanda",
      17486: "Neira",
      17495: "Norcasia",
      17513: "Pacora",
      17524: "Palestina",
      17541: "Pensilvania",
      17614: "Riosucio",
      17616: "Risaralda",
      17653: "Salamina",
      17662: "Samana",
      17665: "San Jose",
      17777: "Supia",
      17867: "Victoria",
      17873: "Villamaria",
      17877: "Viterbo",
    },
    18: {
      18029: "Albania",
      18094: "Belen De Los Andaquies",
      18150: "Cartagena Del Chaira",
      18205: "Currillo",
      18247: "El Doncello",
      18256: "El Paujil",
      18001: "Florencia",
      18410: "La Montañita",
      18460: "Milan",
      18479: "Morelia",
      18592: "Puerto Rico",
      18610: "San Jose Del Fragua",
      18753: "San Vicente Del Caguan",
      18756: "Solano",
      18785: "Solita",
      18860: "Valparaiso",
    },
    19: {
      19022: "Almaguer",
      19050: "Argelia",
      19075: "Balboa",
      19100: "Bolivar",
      19110: "Buenos Aires",
      19130: "Cajibio",
      19137: "Caldono",
      19142: "Caloto",
      19212: "Corinto",
      19256: "El Tambo",
      19290: "Florencia",
      19300: "Guachene",
      19318: "Guapi",
      19355: "Inza",
      19364: "Jambalo",
      19392: "La Sierra",
      19397: "La Vega",
      19418: "Lopez",
      19450: "Mercaderes",
      19455: "Miranda",
      19473: "Morales",
      19513: "Padilla",
      19517: "Paez",
      19532: "Patia",
      19533: "Piamonte",
      19548: "Piendamo",
      19001: "Popayan",
      19573: "Puerto Tejada",
      19585: "Purace",
      19622: "Rosas",
      19693: "San Sebastian",
      19698: "Santander De Quilichao",
      19701: "Santa Rosa",
      19743: "Silvia",
      19760: "Sotara",
      19780: "Suarez",
      19785: "Sucre",
      19807: "Timbio",
      19809: "Timbiqui",
      19821: "Toribio",
      19824: "Totoro",
      19845: "Villa Rica",
    },
    20: {
      20011: "Aguachica",
      20013: "Agustin Codazzi",
      20032: "Astrea",
      20045: "Becerril",
      20060: "Bosconia",
      20175: "Chimichagua",
      20178: "Chiriguana",
      20228: "Curumani",
      20238: "El Copey",
      20250: "El Paso",
      20295: "Gamarra",
      20310: "Gonzalez",
      20383: "La Gloria",
      20400: "La Jagua De Ibirico",
      20621: "La Paz",
      20443: "Manaure",
      20517: "Pailitas",
      20550: "Pelaya",
      20570: "Pueblo Bello",
      20614: "Rio De Oro",
      20710: "San Alberto",
      20750: "San Diego",
      20770: "San Martin",
      20787: "Tamalameque",
      20001: "Valledupar",
    },
    23: {
      23068: "Ayapel",
      23079: "Buenavista",
      23090: "Canalete",
      23162: "Cerete",
      23168: "Chima",
      23182: "Chinu",
      23189: "Cienaga De Oro",
      23300: "Cotorra",
      23350: "La Apartada",
      23417: "Lorica",
      23419: "Los Cordobas",
      23464: "Momil",
      23466: "Montelibano",
      23001: "Monteria",
      23500: "Moñitos",
      23555: "Planeta Rica",
      23570: "Pueblo Nuevo",
      23574: "Puerto Escondido",
      23580: "Puerto Libertador",
      23586: "Purisima",
      23660: "Sahagun",
      23670: "San Andres Sotavento",
      23672: "San Antero",
      23675: "San Bernardo Del Viento",
      23678: "San Carlos",
      23682: "San Jose De Ure",
      23686: "San Pelayo",
      23807: "Tierralta",
      23815: "Tuchin",
      23855: "Valencia",
    },
    25: {
      25001: "Agua De Dios",
      25019: "Alban",
      25035: "Anapoima",
      25040: "Anolaima",
      25599: "Apulo",
      25053: "Arbelaez",
      25086: "Beltran",
      25095: "Bituima",
      25099: "Bojaca",
      25120: "Cabrera",
      25123: "Cachipay",
      25126: "Cajica",
      25148: "Caparrapi",
      25151: "Caqueza",
      25154: "Carmen De Carupa",
      25168: "Chaguani",
      25175: "Chia",
      25178: "Chipaque",
      25181: "Choachi",
      25183: "Choconta",
      25200: "Cogua",
      25214: "Cota",
      25224: "Cucunuba",
      25245: "El Colegio",
      25258: "El Peñon",
      25260: "El Rosal",
      25269: "Facatativa",
      25279: "Fomeque",
      25281: "Fosca",
      25286: "Funza",
      25288: "Fuquene",
      25290: "Fusagasuga",
      25293: "Gachala",
      25295: "Gachancipa",
      25297: "Gacheta",
      25299: "Gama",
      25307: "Girardot",
      25312: "Granada",
      25317: "Guacheta",
      25320: "Guaduas",
      25322: "Guasca",
      25324: "Guataqui",
      25326: "Guatavita",
      25328: "Guayabal De Siquima",
      25335: "Guayabetal",
      25339: "Gutierrez",
      25368: "Jerusalen",
      25372: "Junin",
      25377: "La Calera",
      25386: "La Mesa",
      25394: "La Palma",
      25398: "La Peña",
      25402: "La Vega",
      25407: "Lenguazaque",
      25426: "Macheta",
      25430: "Madrid",
      25436: "Manta",
      25438: "Medina",
      25473: "Mosquera",
      25483: "Nariño",
      25486: "Nemocon",
      25488: "Nilo",
      25489: "Nimaima",
      25491: "Nocaima",
      25513: "Pacho",
      25518: "Paime",
      25524: "Pandi",
      25530: "Paratebueno",
      25535: "Pasca",
      25572: "Puerto Salgar",
      25580: "Puli",
      25592: "Quebradanegra",
      25594: "Quetame",
      25596: "Quipile",
      25612: "Ricaurte",
      25645: "San Antonio De Tequendama",
      25649: "San Bernardo",
      25653: "San Cayetano",
      25658: "San Francisco",
      25662: "San Juan De Rio Seco",
      25718: "Sasaima",
      25736: "Sesquile",
      25740: "Sibate",
      25743: "Silvania",
      25745: "Simijaca",
      25754: "Soacha",
      25758: "Sopo",
      25769: "Subachoque",
      25772: "Suesca",
      25777: "Supata",
      25779: "Susa",
      25781: "Sutatausa",
      25785: "Tabio",
      25793: "Tausa",
      25797: "Tena",
      25799: "Tenjo",
      25805: "Tibacuy",
      25807: "Tibirita",
      25815: "Tocaima",
      25817: "Tocancipa",
      25823: "Topaipi",
      25839: "Ubala",
      25841: "Ubaque",
      25843: "Ubate",
      25845: "Une",
      25851: "Utica",
      25506: "Venecia",
      25862: "Vergara",
      25867: "Viani",
      25871: "Villagomez",
      25873: "Villapinzon",
      25875: "Villeta",
      25878: "Viota",
      25885: "Yacopi",
      25898: "Zipacon",
      25899: "Zipaquira",
    },
    27: {
      27006: "Acandi",
      27025: "Alto Baudo",
      27050: "Atrato",
      27073: "Bagado",
      27075: "Bahia Solano",
      27077: "Bajo Baudo",
      27086: "Belen De Bajira",
      27099: "Bojaya",
      27135: "Canton De San Pablo",
      27150: "Carmen Del Darien",
      27160: "Certegui",
      27205: "Condoto",
      27245: "El Carmen De Atrato",
      27250: "El Litoral Del San Juan",
      27361: "Itsmina",
      27372: "Jurado",
      27413: "Lloro",
      27425: "Medio Atrato",
      27430: "Medio Baudó",
      27450: "Medio San Juan",
      27491: "Novita",
      27495: "Nuqui",
      27001: "Quibdo",
      27580: "Rio Iro",
      27600: "Rio Quito",
      27615: "Riosucio",
      27660: "San Jose Del Palmar",
      27745: "Sipi",
      27787: "Tado",
      27800: "Unguia",
      27810: "Union Panamericana",
    },
    41: {
      41006: "Acevedo",
      41013: "Agrado",
      41016: "Aipe",
      41020: "Algeciras",
      41026: "Altamira",
      41078: "Baraya",
      41132: "Campoalegre",
      41206: "Colombia",
      41244: "Elias",
      41298: "Garzon",
      41306: "Gigante",
      41319: "Guadalupe",
      41349: "Hobo",
      41357: "Iquira",
      41359: "Isnos",
      41378: "La Argentina",
      41396: "La Plata",
      41483: "Nataga",
      41001: "Neiva",
      41503: "Oporapa",
      41518: "Paicol",
      41524: "Palermo",
      41530: "Palestina",
      41548: "Pital",
      41551: "Pitalito",
      41615: "Rivera",
      41660: "Saladoblanco",
      41668: "San Agustin",
      41676: "Santa Maria",
      41770: "Suaza",
      41791: "Tarqui",
      41799: "Tello",
      41801: "Teruel",
      41797: "Tesalia",
      41807: "Timana",
      41872: "Villavieja",
      41885: "Yaguara",
    },
    44: {
      44035: "Albania",
      44078: "Barrancas",
      44090: "Dibulla",
      44098: "Distraccion",
      44110: "El Molino",
      44279: "Fonseca",
      44378: "Hatonuevo",
      44420: "La Jagua Del Pilar",
      44430: "Maicao",
      44560: "Manaure",
      44001: "Riohacha",
      44650: "San Juan Del Cesar",
      44847: "Uribia",
      44855: "Urumita",
      44874: "Villanueva",
    },
    47: {
      47030: "Algarrobo",
      47053: "Aracataca",
      47058: "Ariguani",
      47161: "Cerro San Antonio",
      47170: "Chivolo",
      47189: "Cienaga",
      47205: "Concordia",
      47245: "El Banco",
      47258: "El Piñon",
      47268: "El Reten",
      47288: "Fundacion",
      47318: "Guamal",
      47460: "Nueva Granada",
      47541: "Pedraza",
      47545: "Pijiño Del Carmen",
      47551: "Pivijay",
      47555: "Plato",
      47570: "Puebloviejo",
      47605: "Remolino",
      47660: "Sabanas De San Angel",
      47675: "Salamina",
      47692: "San Sebastian De Buenavista",
      47707: "Santa Ana",
      47720: "Santa Barbara De Pinto",
      47001: "Santa Marta",
      47703: "San Zenon",
      47745: "Sitionuevo",
      47798: "Tenerife",
      47960: "Zapayan",
      47980: "Zona Bananera",
    },
    50: {
      50006: "Acacias",
      50110: "Barranca De Upia",
      50124: "Cabuyaro",
      50150: "Castilla La Nueva",
      50223: "Cubarral",
      50226: "Cumaral",
      50245: "El Calvario",
      50251: "El Castillo",
      50270: "El Dorado",
      50287: "Fuente De Oro",
      50313: "Granada",
      50318: "Guamal",
      50350: "La Macarena",
      50370: "La Uribe",
      50400: "Lejanias",
      50325: "Mapiripan",
      50330: "Mesetas",
      50450: "Puerto Concordia",
      50568: "Puerto Gaitan",
      50577: "Puerto Lleras",
      50573: "Puerto Lopez",
      50590: "Puerto Rico",
      50606: "Restrepo",
      50680: "San Carlos Guaroa",
      50683: "San Juan De Arama",
      50686: "San Juanito",
      50689: "San Martin",
      50001: "Villavicencio",
      50711: "Vista Hermosa",
    },
    52: {
      52019: "Alban",
      52022: "Aldana",
      52036: "Ancuya",
      52051: "Arboleda",
      52079: "Barbacoas",
      52083: "Belen",
      52110: "Buesaco",
      52240: "Chachagui",
      52203: "Colon",
      52207: "Consaca",
      52210: "Contadero",
      52215: "Cordoba",
      52224: "Cuaspud",
      52227: "Cumbal",
      52233: "Cumbitara",
      52250: "El Charco",
      52254: "El Peñol",
      52256: "El Rosario",
      52258: "El Tablon De Gomez",
      52260: "El Tambo",
      52520: "Francisco Pizarro",
      52287: "Funes",
      52317: "Guachucal",
      52320: "Guaitarilla",
      52323: "Gualmatan",
      52352: "Iles",
      52354: "Imues",
      52356: "Ipiales",
      52378: "La Cruz",
      52381: "La Florida",
      52385: "La Llanada",
      52390: "La Tola",
      52399: "La Union",
      52405: "Leiva",
      52411: "Linares",
      52418: "Los Andes",
      52427: "Magui",
      52435: "Mallama",
      52473: "Mosquera",
      52480: "Nariño",
      52490: "Olaya Herrera",
      52506: "Ospina",
      52001: "Pasto",
      52540: "Policarpa",
      52560: "Potosi",
      52565: "Providencia",
      52573: "Puerres",
      52585: "Pupiales",
      52612: "Ricaurte",
      52621: "Roberto Payan",
      52678: "Samaniego",
      52685: "San Bernardo",
      52683: "Sandona",
      52687: "San Lorenzo",
      52693: "San Pablo",
      52694: "San Pedro De Cartago",
      52696: "Santa Barbara",
      52699: "Santacruz",
      52720: "Sapuyes",
      52786: "Taminango",
      52788: "Tangua",
      52835: "Tumaco",
      52838: "Tuquerres",
      52885: "Yacuanquer",
    },
    54: {
      54003: "Abrego",
      54051: "Arboledas",
      54099: "Bochalema",
      54109: "Bucarasica",
      54128: "Cachira",
      54125: "Cacota",
      54172: "Chinacota",
      54174: "Chitaga",
      54206: "Convencion",
      54001: "Cucuta",
      54223: "Cucutilla",
      54239: "Durania",
      54245: "El Carmen",
      54250: "El Tarra",
      54261: "El Zulia",
      54313: "Gramalote",
      54344: "Hacari",
      54347: "Herran",
      54377: "Labateca",
      54385: "La Esperanza",
      54398: "La Playa",
      54405: "Los Patios",
      54418: "Lourdes",
      54480: "Mutiscua",
      54498: "Ocaña",
      54518: "Pamplona",
      54520: "Pamplonita",
      54553: "Puerto Santander",
      54599: "Ragonvalia",
      54660: "Salazar",
      54670: "San Calixto",
      54673: "San Cayetano",
      54680: "Santiago",
      54720: "Sardinata",
      54743: "Silos",
      54800: "Teorama",
      54810: "Tibu",
      54820: "Toledo",
      54871: "Villa Caro",
      54874: "Villa Del Rosario",
    },
    63: {
      63001: "Armenia",
      63111: "Buenavista",
      63130: "Calarca",
      63190: "Circasia",
      63212: "Cordoba",
      63272: "Filandia",
      63302: "Genova",
      63401: "La Tebaida",
      63470: "Montenegro",
      63548: "Pijao",
      63594: "Quimbaya",
      63690: "Salento",
    },
    66: {
      66045: "Apia",
      66075: "Balboa",
      66088: "Belen De Umbria",
      66170: "Dos Quebradas",
      66318: "Guatica",
      66383: "La Celia",
      66400: "La Virginia",
      66440: "Marsella",
      66456: "Mistrato",
      66001: "Pereira",
      66572: "Pueblo Rico",
      66594: "Quinchia",
      66682: "Santa Rosa De Cabal",
      66687: "Santuario",
    },
    68: {
      68013: "Aguada",
      68020: "Albania",
      68051: "Aratoca",
      68077: "Barbosa",
      68079: "Barichara",
      68081: "Barrancabermeja",
      68092: "Betulia",
      68101: "Bolivar",
      68001: "Bucaramanga",
      68121: "Cabrera",
      68132: "California",
      68147: "Capitanejo",
      68152: "Carcasi",
      68160: "Cepita",
      68162: "Cerrito",
      68167: "Charala",
      68169: "Charta",
      68176: "Chima",
      68179: "Chipata",
      68190: "Cimitarra",
      68207: "Concepcion",
      68209: "Confines",
      68211: "Contratacion",
      68217: "Coromoro",
      68229: "Curiti",
      68235: "El Carmen De Chucuri",
      68245: "El Guacamayo",
      68250: "El Peñon",
      68255: "El Playon",
      68264: "Encino",
      68266: "Enciso",
      68271: "Florian",
      68276: "Floridablanca",
      68296: "Galan",
      68298: "Gambita",
      68307: "Giron",
      68318: "Guaca",
      68320: "Guadalupe",
      68322: "Guapota",
      68324: "Guavata",
      68327: "Guepsa",
      68344: "Hato",
      68368: "Jesus Maria",
      68370: "Jordan",
      68377: "La Belleza",
      68385: "Landazuri",
      68397: "La Paz",
      68406: "Lebrija",
      68418: "Los Santos",
      68425: "Macaravita",
      68432: "Malaga",
      68444: "Matanza",
      68464: "Mogotes",
      68468: "Molagavita",
      68498: "Ocamonte",
      68500: "Oiba",
      68502: "Onzaga",
      68522: "Palmar",
      68524: "Palmas Del Socorro",
      68533: "Paramo",
      68547: "Piedecuesta",
      68549: "Pinchote",
      68572: "Puente Nacional",
      68573: "Puerto Parra",
      68575: "Puerto Wilches",
      68615: "Rionegro",
      68655: "Sabana De Torres",
      68669: "San Andres",
      68673: "San Benito",
      68679: "San Gil",
      68682: "San Joaquin",
      68684: "San Jose De Miranda",
      68686: "San Miguel",
      68705: "Santa Barbara",
      68720: "Santa Helena Del Opon",
      68689: "San Vicente De Chucuri",
      68745: "Simacota",
      68755: "Socorro",
      68770: "Suaita",
      68773: "Sucre",
      68780: "Surata",
      68820: "Tona",
      68855: "Valle De San Jose",
      68861: "Velez",
      68867: "Vetas",
      68872: "Villanueva",
      68895: "Zapatoca",
    },
    70: {
      70110: "Buenavista",
      70124: "Caimito",
      70230: "Chalan",
      70204: "Coloso",
      70215: "Corozal",
      70221: "Coveñas",
      70233: "El Roble",
      70235: "Galeras",
      70265: "Guaranda",
      70400: "La Union",
      70418: "Los Palmitos",
      70429: "Majagual",
      70473: "Morroa",
      70508: "Ovejas",
      70523: "Palmito",
      70670: "Sampues",
      70678: "San Benito Abad",
      70702: "San Juan Betulia",
      70708: "San Marcos",
      70713: "San Onofre",
      70717: "San Pedro",
      70820: "Santiago De Tolu",
      70742: "Since",
      70001: "Sincelejo",
      70771: "Sucre",
      70823: "Tolu Viejo",
    },
    73: {
      73024: "Alpujarra",
      73026: "Alvarado",
      73030: "Ambalema",
      73043: "Anzoategui",
      73055: "Armero Guayabal",
      73067: "Ataco",
      73124: "Cajamarca",
      73148: "Carmen De Apicala",
      73152: "Casabianca",
      73168: "Chaparral",
      73200: "Coello",
      73217: "Coyaima",
      73226: "Cunday",
      73236: "Dolores",
      73268: "Espinal",
      73270: "Falan",
      73275: "Flandes",
      73283: "Fresno",
      73319: "Guamo",
      73347: "Herveo",
      73349: "Honda",
      73001: "Ibague",
      73352: "Icononzo",
      73408: "Lerida",
      73411: "Libano",
      73443: "Mariquita",
      73449: "Melgar",
      73461: "Murillo",
      73483: "Natagaima",
      73504: "Ortega",
      73520: "Palocabildo",
      73547: "Piedras",
      73555: "Planadas",
      73563: "Prado",
      73585: "Purificacion",
      73616: "Rioblanco",
      73622: "Roncesvalles",
      73624: "Rovira",
      73671: "Saldaña",
      73675: "San Antonio",
      73678: "San Luis",
      73686: "Santa Isabel",
      73770: "Suarez",
      73854: "Valle De San Juan",
      73861: "Venadillo",
      73870: "Villahermosa",
      73873: "Villarrica",
    },
    76: {
      76020: "Alcala",
      76036: "Andalucia",
      76041: "Ansermanuevo",
      76054: "Argelia",
      76100: "Bolivar",
      76109: "Buenaventura",
      76111: "Buga",
      76113: "Bugalagrande",
      76122: "Caicedonia",
      76001: "Cali",
      76126: "Calima",
      76130: "Candelaria",
      76147: "Cartago",
      76233: "Dagua",
      76243: "El Aguila",
      76246: "El Cairo",
      76248: "El Cerrito",
      76250: "El Dovio",
      76275: "Florida",
      76306: "Ginebra",
      76318: "Guacari",
      76364: "Jamundi",
      76377: "La Cumbre",
      76400: "La Union",
      76403: "La Victoria",
      76497: "Obando",
      76520: "Palmira",
      76563: "Pradera",
      76606: "Restrepo",
      76616: "Riofrio",
      76622: "Roldanillo",
      76670: "San Pedro",
      76736: "Sevilla",
      76823: "Toro",
      76828: "Trujillo",
      76834: "Tulua",
      76845: "Ulloa",
      76863: "Versalles",
      76869: "Vijes",
      76890: "Yotoco",
      76892: "Yumbo",
      76895: "Zarzal",
    },
    81: {
      81001: "Arauca",
      81065: "Arauquita",
      81220: "Cravo Norte",
      81300: "Fortul",
      81591: "Puerto Rondon",
      81736: "Saravena",
      81794: "Tame",
    },
    85: {
      85010: "Aguazul",
      85015: "Chameza",
      85125: "Hato Corozal",
      85136: "La Salina",
      85139: "Mani",
      85162: "Monterrey",
      85225: "Nunchia",
      85230: "Orocue",
      85250: "Paz De Ariporo",
      85263: "Pore",
      85279: "Recetor",
      85300: "Sabanalarga",
      85315: "Sacama",
      85325: "San Luis De Palenque",
      85400: "Tamara",
      85410: "Tauramena",
      85430: "Trinidad",
      85440: "Villanueva",
      85001: "Yopal",
    },
    86: {
      86219: "Colon",
      86573: "Leguizamo",
      86001: "Mocoa",
      86320: "Orito",
      86568: "Puerto Asis",
      86569: "Puerto Caicedo",
      86571: "Puerto Guzman",
      86755: "San Francisco",
      86757: "San Miguel",
      86760: "Santiago",
      86749: "Sibundoy",
      86865: "Valle Del Guamuez",
      86885: "Villagarzon",
    },
    88: {
      88564: "Providencia",
      88001: "San Andres",
    },
    91: {
      91263: "El Encanto",
      91405: "La Chorrera",
      91407: "La Pedrera",
      91430: "La Victoria",
      91001: "Leticia",
      91460: "Miriti - Parana",
      91530: "Puerto Alegria",
      91536: "Puerto Arica",
      91540: "Puerto Nariño",
      91669: "Puerto Santander",
      91798: "Tarapaca",
    },
    94: {
      94343: "Barranco Mina",
      94886: "Cacahual",
      94001: "Inirida",
      94885: "La Guadalupe",
      94663: "Mapiripana",
      94888: "Morichal",
      94887: "Pana Pana",
      94884: "Puerto Colombia",
      94883: "San Felipe",
    },
    95: {
      95015: "Calamar",
      95025: "El Retorno",
      95200: "Miraflores",
      95001: "San Jose Del Guaviare",
    },
    97: {
      97161: "Caruru",
      97001: "Mitu",
      97511: "Pacoa",
      97777: "Papunahua",
      97666: "Taraira",
      97889: "Yavarate",
    },
    99: {
      99773: "Cumaribo",
      99524: "La Primavera",
      99001: "Puerto Carreño",
      99624: "Santa Rosalia",
    },
  };
  const cities = $data[$state_code] ? $data[$state_code] : null;
  Object.keys(cities).map((citie) => {
    temporalCities = [
      ...temporalCities,
      {
        name: cities[citie],
        code: citie,
      },
    ];
  });
  return temporalCities;
}

export function gluky_addressfield_co_localities_list($state_code, $city_code) {
  let temporalLocalities = [];
  const $data = {
    "05": {
      "05001": {
        "05001010": "Aguas Frías",
        "05001009": "Altavista",
        "05001034": "Barrios De Jesús",
        "05001014": "Barro Blanco",
        "05001035": "Boquerón",
        "05001036": "Chacaltaya",
        "05001015": "El Cerro",
        "05001037": "El Corazón",
        "05001038": "El Jardín",
        "05001040": "El Llano 2",
        "05001017": "El Patio",
        "05001018": "El Placer",
        "05001019": "El Plan",
        "05001020": "El Tesoro",
        "05001041": "El Uvito",
        "05001022": "La Aldea",
        "05001042": "La Avanzada",
        "05001043": "La Cruz",
        "05001023": "La Cuchilla",
        "05001044": "La Esperanza",
        "05001024": "La Florida",
        "05001045": "La Frisola",
        "05001046": "La Gabriela",
        "05001047": "La Ladera",
        "05001012": "La Loma",
        "05001025": "La Palma",
        "05001027": "Las Playas",
        "05001048": "La Verde",
        "05001049": "La Volcana",
        "05001051": "Llanaditas",
        "05001039": "Llano 1",
        "05001050": "Los Naranjos",
        "05001052": "Matasano",
        "05001053": "Matasano 1",
        "05001054": "Matasano 2",
        "05001055": "Mazo",
        "05001056": "Mazo 1",
        "05001000": "Medellín",
        "05001057": "Media Luna",
        "05001058": "Median Luna 1",
        "05001059": "Mirador Del Poblado",
        "05001060": "Ocho De Marzo",
        "05001061": "Oriente",
        "05001001": "Palmitas",
        "05001005": "Pedregal Alto",
        "05001029": "Piedra Gorda",
        "05001062": "Potrera Miserenga",
        "05001031": "Potrerito",
        "05001002": "San Antonio De Prado",
        "05001063": "San José De La Montaña",
        "05001013": "San José Del Manzanillo",
        "05001004": "Santa Elena",
        "05001032": "Travesias",
        "05001033": "Urquita",
        "05001064": "Versalles",
        "05001065": "Yolombo",
      },
      "05002": {
        "05002000": "Abejorral",
        "05002013": "Canteras",
        "05002001": "Chagualal",
        "05002010": "El Cairo",
        "05002015": "El Guaico",
        "05002016": "Las Canoas",
        "05002002": "Pantanillo",
        "05002007": "Portugal",
        "05002006": "Purima",
      },
      "05004": {
        "05004000": "Abriaquí",
        "05004002": "La Antigua",
      },
      "05021": {
        "05021000": "Alejandría",
      },
      "05030": {
        "05030000": "Amagá",
        "05030001": "Camilo C",
        "05030002": "El Cedro",
        "05030003": "La Clarita",
        "05030011": "Minas",
      },
      "05031": {
        "05031000": "Amalfi",
        "05031005": "Arenas Blancas",
        "05031006": "El Zacatin",
        "05031007": "La Guayana",
        "05031004": "Portachuelo",
      },
      "05034": {
        "05034000": "Andes",
        "05034001": "Buenos Aires",
        "05034015": "La Chaparrala",
        "05034014": "San Bartolo",
        "05034003": "San José",
        "05034012": "Santa Inés",
        "05034006": "Santa Rita",
        "05034007": "Tapartó",
      },
      "05036": {
        "05036000": "Angelópolis",
        "05036011": "Cienaguita",
        "05036007": "La Clara",
        "05036001": "La Estacion",
        "05036004": "San Isidro",
        "05036010": "Santa Rita",
      },
      "05038": {
        "05038000": "Angostura",
        "05038008": "Llanos De Cuiba",
      },
      "05040": {
        "05040000": "Anorí",
        "05040005": "Cristalinas",
        "05040006": "Las Nieves",
        "05040002": "Liberia",
        "05040007": "Madre Seca",
        "05040003": "Montefroo",
        "05040004": "Santo Domingo",
      },
      "05042": {
        "05042001": "Cativo",
        "05042006": "El Pescado",
        "05042002": "Guasabra",
        "05042003": "Las Azules",
        "05042007": "Sabanas",
        "05042000": "Santa Fé De Antioquia",
        "05042004": "Tonusco Arriba",
      },
      "05044": {
        "05044000": "Anzá",
        "05044001": "Guintar",
        "05044005": "La Cejita",
        "05044002": "La Cholina",
        "05044006": "La Higuina",
        "05044003": "Los Llanos",
      },
      "05045": {
        "05045000": "Apartadó",
        "05045002": "Churidó",
        "05045009": "El Reposo",
        "05045011": "El Salvador",
        "05045007": "Los Naranjales",
        "05045014": "Puerto Giron",
        "05045001": "San José De Apartadó",
        "05045008": "Vijagual",
        "05045003": "Zungo Carretera",
      },
      "05051": {
        "05051000": "Arboletes",
        "05051015": "Arenosa Carmelo",
        "05051016": "Barrancuda",
        "05051001": "Buenos Aires",
        "05051003": "El Carmelo",
        "05051014": "El Guadual",
        "05051008": "El Yeso",
        "05051017": "La Atoyosa",
        "05051011": "La Candelaria",
        "05051005": "Las Naranjitas",
        "05051010": "Las Platas (Santafé)",
        "05051009": "La Trinidad",
        "05051018": "Pueblo Chino",
        "05051019": "San Jose Del Carmelo",
      },
      "05055": {
        "05055000": "Argelia",
      },
      "05059": {
        "05059000": "Armenia Mantequilla",
        "05059005": "Cartagueño",
        "05059003": "El Socorro",
        "05059006": "Filo Seco",
        "05059001": "La Herradura",
        "05059002": "La Loma",
        "05059007": "La Pescadora",
        "05059008": "La Quiebra",
        "05059004": "Mojones",
        "05059009": "Palmichal",
        "05059010": "Palo Blanco",
      },
      "05079": {
        "05079013": "Aguas Claras",
        "05079000": "Barbosa",
        "05079010": "El Guayabo",
        "05079006": "El Tablazo",
        "05079007": "El Verde",
        "05079015": "Filoverde",
        "05079001": "Hatillo",
        "05079011": "Las Victorias",
        "05079014": "Popalito",
        "05079017": "Tablazo Hatillo",
        "05079016": "Yarumito",
      },
      "05086": {
        "05086000": "Belmira",
        "05086001": "Labores",
        "05086004": "Playas",
        "05086002": "Quebraditas",
      },
      "05088": {
        "05088000": "Bello",
        "05088020": "El Albergue",
        "05088018": "El Pinar",
        "05088021": "Hato Viejo",
        "05088022": "La China",
        "05088023": "La Union",
        "05088013": "San Félix",
      },
      "05091": {
        "05091000": "Betania",
        "05091003": "San Luis",
      },
      "05093": {
        "05093001": "Altamira",
        "05093000": "Betulia",
      },
      "05101": {
        "05101000": "Ciudad Bolívar",
        "05101007": "La Linda",
        "05101002": "San Bernardo De Los Farallones",
        "05101006": "San Gregorio",
        "05101008": "Villa Alegría",
      },
      "05107": {
        "05107001": "Berlon (Pueblo Nuevo)",
        "05107000": "Briceño",
        "05107002": "El Roblal",
        "05107003": "Las Auras",
      },
      "05113": {
        "05113000": "Buriticá",
        "05113001": "El Naranjo",
        "05113002": "Guarco",
        "05113005": "La Angelina",
        "05113003": "Tabacal",
        "05113004": "Urarco",
      },
      "05120": {
        "05120000": "Cáceres",
        "05120002": "El Jardín Caceres",
        "05120003": "Guarumo",
        "05120004": "Manizales",
        "05120011": "Nueva Esperanza",
        "05120010": "Piamonte",
        "05120006": "Puerto Bélgica",
        "05120012": "Río Man",
      },
      "05125": {
        "05125001": "Aseso",
        "05125000": "Caicedo",
        "05125002": "La Salazar",
      },
      "05129": {
        "05129000": "Caldas",
        "05129001": "El Caño",
        "05129007": "El Raizal",
        "05129008": "La Clara",
        "05129005": "La Corrala",
        "05129004": "La Miel",
        "05129009": "La Quiebra",
        "05129002": "La Raya",
        "05129010": "La Salada Parte Baja",
        "05129011": "La Tolva",
        "05129012": "La Valeria",
        "05129006": "Primavera",
      },
      "05134": {
        "05134000": "Campamento",
        "05134001": "La Chiquita",
        "05134002": "La Solita",
        "05134003": "Llanadas",
        "05134008": "Los Chorros",
        "05134005": "Morropelon",
      },
      "05138": {
        "05138001": "Buenos Aires",
        "05138000": "Cañasgordas",
        "05138002": "Cestillal",
        "05138008": "El Madero",
        "05138003": "Juntas De Uramita",
        "05138007": "La Balsa",
        "05138005": "San Pascual",
        "05138006": "Versalles",
      },
      "05142": {
        "05142000": "Caracolí",
        "05142002": "Cascaron",
        "05142003": "El Bagre",
        "05142004": "Las Aguilas",
        "05142001": "Sardinas",
      },
      "05145": {
        "05145001": "Alegrías",
        "05145003": "Barro Blanco",
        "05145000": "Caramanta",
        "05145004": "Chirapoto",
        "05145002": "Sucre",
      },
      "05147": {
        "05147000": "Carepa",
        "05147006": "Casa Verde",
        "05147007": "El Encanto",
        "05147003": "Piedras Blancas",
        "05147005": "Zungo Embarcadero - 11 De Noviembre",
        "05147008": "Zungo Embarcadero - 28 De Octubre",
        "05147004": "Zungo Embarcadero - Pueblo Nuevo",
      },
      "05148": {
        "05148003": "Aguas Claras",
        "05148000": "El Carmen De Viboral",
        "05148008": "La Aurora - Las Brisas",
        "05148005": "La Chapa",
      },
      "05150": {
        "05150000": "Carolina del Principe",
      },
      "05154": {
        "05154023": "Cacerí",
        "05154025": "Campo Alegre",
        "05154013": "Caracolo",
        "05154000": "Caucasia",
        "05154003": "Cuturú",
        "05154026": "El Chino",
        "05154024": "El Pando",
        "05154027": "La Esmeralda",
        "05154022": "La Ilusión",
        "05154028": "La Union",
        "05154006": "Margento",
        "05154008": "Palanca",
        "05154009": "Palomar",
        "05154007": "Puerto Colombia",
        "05154030": "Puerto España",
        "05154020": "Puerto Triana",
        "05154012": "Santa Rosita",
        "05154031": "Villa Del Socorro",
      },
      "05172": {
        "05172003": "Barranquillita",
        "05172000": "Chigorodó",
        "05172007": "Guapa Carreteras",
        "05172013": "Jurado",
      },
      "05190": {
        "05190000": "Cisneros",
        "05190004": "El Limon",
        "05190002": "Fatima",
      },
      "05197": {
        "05197001": "Agua Linda",
        "05197000": "Cocorná",
        "05197010": "La Florida",
        "05197011": "La Granja",
        "05197005": "La Piñuela",
        "05197009": "La Primavera",
        "05197007": "Las Cruces",
        "05197012": "Pailania",
      },
      "05206": {
        "05206000": "Concepción",
      },
      "05209": {
        "05209005": "Animas",
        "05209000": "Concordia",
        "05209006": "El Golpe",
        "05209001": "El Socorro",
        "05209007": "Salazar",
      },
      "05212": {
        "05212014": "Alvarado",
        "05212008": "Cabuyal",
        "05212000": "Copacabana",
        "05212018": "El Convento",
        "05212019": "El Noral",
        "05212005": "Salado",
        "05212011": "Zarzal (La Luz)",
      },
      "05234": {
        "05234011": "Arenera",
        "05234008": "Armenia - Camparrusia",
        "05234015": "Camparrusia",
        "05234007": "Carra",
        "05234012": "Chimiando",
        "05234000": "Dabeiba",
        "05234014": "El Botón",
        "05234016": "El Encierro",
        "05234003": "Galilea",
        "05234010": "Las Cruces De Urama",
        "05234009": "Llano Grande",
        "05234004": "San José De Urama (Uramagrande)",
      },
      "05237": {
        "05237001": "Bellavista",
        "05237000": "Donmatías",
        "05237002": "Riogrande",
      },
      "05240": {
        "05240001": "Brasil",
        "05240000": "Ebéjico",
        "05240003": "Sevilla",
      },
      "05250": {
        "05250000": "El Bagre",
        "05250005": "El Real",
        "05250006": "La Corona",
        "05250007": "Las Negritas",
        "05250010": "Muqui",
        "05250002": "Puerto Claver",
        "05250004": "Puerto López",
        "05250009": "Santa Bárbara",
      },
      "05264": {
        "05264000": "Entrerríos",
      },
      "05266": {
        "05266000": "Envigado",
        "05266001": "Las Palmas",
      },
      "05282": {
        "05282006": "Combia Grande",
        "05282010": "El Plan",
        "05282008": "El Zancudo",
        "05282000": "Fredonia",
        "05282009": "La Loma Del Plan",
        "05282002": "Los Palomos",
        "05282005": "Marsella",
        "05282003": "Minas",
        "05282014": "Piedra Verde",
        "05282004": "Puente Iglesias",
        "05282012": "Uvital",
        "05282007": "Zabaletas",
      },
      "05284": {
        "05284001": "Carauta",
        "05284003": "Chontaduro",
        "05284002": "El Cerro",
        "05284013": "El Madero",
        "05284000": "Frontino",
        "05284008": "Fuemoa",
        "05284012": "Jengamecoda",
        "05284015": "La Clarita - Las Minas",
        "05284004": "Murrí",
        "05284005": "Musinga",
        "05284011": "Noboga",
        "05284006": "Nutibara",
        "05284014": "Pantanos",
        "05284007": "Ponton",
        "05284010": "San Lazaro",
      },
      "05306": {
        "05306000": "Giraldo",
        "05306001": "Manglar",
        "05306002": "Pinguro",
        "05306005": "Sierrita",
      },
      "05308": {
        "05308009": "Cabildo",
        "05308000": "Girardota",
        "05308011": "Jamundo",
        "05308013": "La Calle",
        "05308010": "Las Cuchichas",
        "05308014": "San Esteban",
      },
      "05310": {
        "05310005": "Cañaveral",
        "05310006": "El Brasil",
        "05310007": "El Cerro",
        "05310001": "El Salto",
        "05310000": "Gómez Plata",
        "05310003": "La Estrella",
        "05310002": "San Matías",
        "05310004": "Vega De Botero",
      },
      "05313": {
        "05313000": "Granada",
        "05313004": "Los Medios",
        "05313001": "Santa Ana",
      },
      "05315": {
        "05315008": "Barrio Nuevo",
        "05315009": "El Machete",
        "05315000": "Guadalupe",
        "05315010": "La Bramadora",
        "05315011": "Puente Acacias",
      },
      "05318": {
        "05318000": "Guarne",
      },
      "05321": {
        "05321000": "Guatapé",
      },
      "05347": {
        "05347001": "Alto Del Corral",
        "05347007": "El Hatillo",
        "05347000": "Heliconia",
        "05347006": "La Chorrera",
        "05347008": "La Pava",
        "05347003": "Llanos De San Jose",
        "05347002": "Pueblito",
      },
      "05353": {
        "05353000": "Hispania",
      },
      "05360": {
        "05360003": "El Ajizal",
        "05360006": "El Pedregal",
        "05360012": "El Porvenir",
        "05360013": "El Progreso",
        "05360000": "Itaguí",
        "05360005": "La Loma De Los Zuleta",
        "05360014": "La María",
        "05360001": "Los Gómez",
        "05360015": "Los Olivares",
      },
      "05361": {
        "05361001": "Badillo",
        "05361002": "El Aro - Builopolis",
        "05361000": "Ituango",
        "05361003": "La Granja",
        "05361004": "Pascuita",
        "05361018": "Poo X La Fondita",
        "05361005": "Santa Ana",
        "05361006": "Santa Lucía",
        "05361007": "Santa Rita",
      },
      "05364": {
        "05364001": "Cristianoa",
        "05364000": "Jardín Suroeste",
        "05364004": "La Cascada",
        "05364005": "La Casiana",
      },
      "05368": {
        "05368008": "El Sacaton",
        "05368005": "Guacamayal",
        "05368000": "Jericó",
        "05368003": "La Cascada",
        "05368009": "La Fe",
      },
      "05376": {
        "05376000": "La Ceja",
        "05376003": "San José",
        "05376006": "San Judas",
        "05376007": "Santo Domingo Sabio",
        "05376008": "Toledo",
        "05376005": "Vueltecitas",
      },
      "05380": {
        "05380005": "Calle Vieja",
        "05380019": "La Bermejala",
        "05380000": "La Estrella",
        "05380010": "La Raya",
        "05380007": "La Tablaza",
        "05380001": "La Tablaza",
        "05380022": "La Virgen Parte Alta",
        "05380020": "Pan De Azucar",
        "05380021": "Peñas Blancas",
        "05380008": "Sagrada Familia",
        "05380011": "San Isidro",
        "05380009": "San José - Meleguindo",
        "05380013": "San Miguel",
        "05380012": "Tarapacá",
      },
      "05390": {
        "05390000": "La Pintada",
      },
      "05400": {
        "05400003": "Chuscalito",
        "05400004": "La Concha",
        "05400000": "La Unión",
        "05400005": "Maroa Auxiliadora",
        "05400001": "Mesopotamia",
      },
      "05411": {
        "05411008": "Belen",
        "05411005": "Curito",
        "05411001": "El Carmen - La Venta",
        "05411010": "El Guamal",
        "05411006": "El Potrero",
        "05411002": "La Honda",
        "05411003": "La Merced (Playón)",
        "05411000": "Liborina",
        "05411011": "Los Peñoles",
        "05411007": "San Cristobal",
        "05411004": "San Diego (Placita)",
        "05411009": "Sobresabana",
      },
      "05425": {
        "05425003": "La Floresta",
        "05425001": "La Susana",
        "05425000": "Maceo",
        "05425005": "San José Del Nus (San José De Nuestra Señora)",
      },
      "05440": {
        "05440000": "Marinilla",
      },
      "05467": {
        "05467004": "El Carmelo",
        "05467002": "El Tablazo",
        "05467005": "Getsemaní",
        "05467006": "La Granja",
        "05467007": "La Peña",
        "05467003": "Loma Del Venado",
        "05467000": "Montebello",
        "05467008": "Piedra Galana",
        "05467001": "Sabaletas",
        "05467009": "Zarcitos",
      },
      "05475": {
        "05475004": "Guadualito",
        "05475005": "Jedega",
        "05475000": "Murindó",
        "05475001": "Opogado",
        "05475003": "San Alejandro",
        "05475006": "Tadía",
      },
      "05480": {
        "05480005": "Belen de Bajira",
        "05480001": "Bejuquillo",
        "05480006": "Caucheras",
        "05480000": "Mutatá",
        "05480002": "Pavarandocito",
        "05480004": "Pavarando Grande",
        "05480003": "Villa Arteaga",
      },
      "05483": {
        "05483012": "El Faro",
        "05483000": "Nariño",
        "05483001": "Puerto Venus (Samaná)",
      },
      "05490": {
        "05490005": "Caribia",
        "05490009": "El Mellito",
        "05490001": "El Totumo",
        "05490008": "Las Changas",
        "05490002": "Mulatos",
        "05490000": "Necoclí",
        "05490003": "Pueblo Nuevo",
        "05490006": "Vereda Casa Blanca",
        "05490007": "Vereda El Bobal",
        "05490004": "Zapata",
      },
      "05495": {
        "05495001": "Bijagual",
        "05495005": "Cargueros",
        "05495002": "Colorado",
        "05495003": "La Concha",
        "05495004": "Las Flores",
        "05495000": "Nechí",
      },
      "05501": {
        "05501001": "Llanadas",
        "05501000": "Olaya",
        "05501002": "Sucre",
      },
      "05541": {
        "05541001": "El Peñol",
        "05541000": "Peñol",
      },
      "05543": {
        "05543001": "Barbacoas",
        "05543009": "El Agrio",
        "05543010": "Jerigua",
        "05543004": "La Vega Del Ingles",
        "05543002": "Lomitas",
        "05543003": "Los Llanos",
        "05543000": "Peque",
      },
      "05576": {
        "05576000": "Pueblorrico",
      },
      "05579": {
        "05579011": "Bodegas",
        "05579003": "Cabañas",
        "05579010": "Calera",
        "05579005": "Cristalina",
        "05579012": "Dorado-Calamar",
        "05579004": "El Brasil",
        "05579013": "La Carlota",
        "05579009": "Malena",
        "05579014": "Minas Del Vapor",
        "05579000": "Puerto Berrío",
        "05579001": "Puerto Murillo",
        "05579015": "Santa Martina",
        "05579002": "Virginias",
      },
      "05585": {
        "05585001": "Arabia",
        "05585007": "El Delirio",
        "05585006": "La Pesca",
        "05585008": "Las Angelitas",
        "05585003": "La Sierra",
        "05585004": "La Unión",
        "05585002": "Los Delirios",
        "05585000": "Puerto Nare",
      },
      "05591": {
        "05591004": "Doradal",
        "05591003": "Estación Cocorná",
        "05591007": "Estación Pita",
        "05591008": "La Florida Tres Ranchos",
        "05591005": "La Mercedes",
        "05591002": "Puerto Perales Nuevo",
        "05591000": "Puerto Triunfo",
        "05591009": "Santiago Berrio",
      },
      "05604": {
        "05604007": "Cañaveral",
        "05604001": "La Cruzada",
        "05604008": "Martana",
        "05604005": "Otu",
        "05604000": "Remedios",
        "05604009": "Roo Bagre",
        "05604003": "Santa Isabel",
      },
      "05607": {
        "05607003": "Alto De Carrizales",
        "05607005": "El Chuscal",
        "05607009": "El Portento",
        "05607011": "La Lorena",
        "05607006": "Lejos Del Nido",
        "05607007": "Los Salados",
        "05607012": "Marton Pescador",
        "05607000": "Retiro",
        "05607004": "San Diego",
        "05607008": "Santa Elena",
      },
      "05615": {
        "05615010": "Aeropuerto",
        "05615017": "La Playa",
        "05615018": "Los Peñoles",
        "05615000": "Rionegro",
      },
      "05628": {
        "05628001": "El Junco",
        "05628002": "El Oro",
        "05628004": "El Socorro",
        "05628007": "Membrillal",
        "05628008": "Pendiente",
        "05628009": "Remarton",
        "05628000": "Sabanalarga",
      },
      "05631": {
        "05631002": "Cañaveralejo",
        "05631009": "La Doctora",
        "05631007": "La Inmaculada",
        "05631011": "Las Brisas",
        "05631010": "Las Lomitas",
        "05631012": "Loma Del Taburete Parte Baja",
        "05631013": "Los Henao",
        "05631001": "María Auxiliadora",
        "05631008": "Pan De Azúcar",
        "05631014": "Playas Placer",
        "05631000": "Sabaneta",
        "05631006": "San Isidro",
      },
      "05642": {
        "05642001": "El Concilio",
        "05642007": "El Junco",
        "05642002": "La Cámara",
        "05642011": "La Gulunga",
        "05642003": "La Margarita",
        "05642004": "La Siberia",
        "05642005": "La Taborda",
        "05642010": "Peñalisa",
        "05642000": "Salgar",
      },
      "05647": {
        "05647000": "San Andrés De Cuerquía",
      },
      "05649": {
        "05649012": "Dos Quebradas",
        "05649001": "El Jordán",
        "05649015": "El Porvenir",
        "05649013": "La Hondita",
        "05649005": "Puerto Garza",
        "05649002": "Samaná",
        "05649000": "San Carlos",
        "05649014": "Vallejuelo",
      },
      "05652": {
        "05652001": "Aquitania",
        "05652000": "San Francisco",
      },
      "05656": {
        "05656009": "Buenos Aires Parte Alta",
        "05656010": "El Calvario",
        "05656011": "El Pomar",
        "05656012": "La Cienaga",
        "05656013": "La Palma",
        "05656004": "Llanos De Aguirre",
        "05656002": "Llanos De San Juan",
        "05656001": "Los Cedros",
        "05656006": "Mestizal",
        "05656007": "Poleal",
        "05656014": "Quimbayo",
        "05656000": "San Jerónimo",
        "05656015": "Veliguaron",
      },
      "05658": {
        "05658000": "San José De La Montaña",
      },
      "05659": {
        "05659001": "Damaquiel",
        "05659002": "San Juancito",
        "05659000": "San Juan De Urabá",
        "05659005": "San Nicolás Del Río",
        "05659004": "Siete Vueltas",
        "05659003": "Uveros",
      },
      "05660": {
        "05660008": "Alta Vista",
        "05660007": "Buenos Aires",
        "05660006": "El Prodigio",
        "05660001": "El Silencio",
        "05660012": "La Tebaida",
        "05660011": "Monteloro (La Josefina)",
        "05660010": "Salambrina",
        "05660000": "San Luis",
        "05660013": "Sopetran",
      },
      "05664": {
        "05664004": "El Espinal",
        "05664001": "La Cuchilla",
        "05664006": "La Lana",
        "05664002": "La Palma",
        "05664005": "Ovejas",
        "05664007": "Pantanillo",
        "05664008": "Roo Chico",
        "05664000": "San Pedro De Los Milagros",
      },
      "05665": {
        "05665003": "Arenas Monas",
        "05665008": "El Tomate",
        "05665000": "San Pedro De Urabá",
        "05665002": "Santa Catalina",
        "05665004": "Zapindonga",
      },
      "05667": {
        "05667002": "El Bizcocho",
        "05667000": "San Rafael",
      },
      "05670": {
        "05670001": "Cristales",
        "05670002": "Providencia",
        "05670003": "San José Del Nus",
        "05670000": "San Roque",
      },
      "05674": {
        "05674001": "Corrientes",
        "05674000": "San Vicente",
      },
      "05679": {
        "05679001": "Damasco",
        "05679000": "Santa Bárbara",
        "05679004": "Versalles",
        "05679009": "Yarumalito",
      },
      "05686": {
        "05686001": "Aragón",
        "05686010": "El Caney",
        "05686012": "El Chaquiro",
        "05686003": "Hoyo Rico",
        "05686013": "La Cejita",
        "05686014": "Malambo",
        "05686008": "Roo Grande",
        "05686004": "San Isidro",
        "05686006": "San Pablo",
        "05686000": "Santa Rosa De Osos",
      },
      "05690": {
        "05690001": "Botero",
        "05690002": "El Limon",
        "05690008": "La Quiebra",
        "05690003": "Porcecito",
        "05690004": "Santiago",
        "05690000": "Santo Domingo",
        "05690005": "Versalles",
      },
      "05697": {
        "05697000": "El Santuario",
      },
      "05736": {
        "05736003": "Campo Alegre",
        "05736004": "El Cenizo",
        "05736006": "El Chispero",
        "05736005": "El Cristo",
        "05736001": "Fraguas",
        "05736007": "La Caliente",
        "05736002": "Puerto Calavera",
        "05736000": "Segovia",
      },
      "05756": {
        "05756001": "Alto De Sabanas",
        "05756033": "Jerusalén",
        "05756030": "La Danta",
        "05756005": "San Miguel",
        "05756000": "Sonsón",
      },
      "05761": {
        "05761001": "Córdoba",
        "05761009": "El Rodeo",
        "05761003": "Horizontes",
        "05761005": "San Nicolás",
        "05761000": "Sopetrán",
      },
      "05789": {
        "05789001": "Palermo",
        "05789002": "San Pablo",
        "05789000": "Támesis",
      },
      "05790": {
        "05790001": "Barro Blanco",
        "05790002": "El Doce",
        "05790005": "El Guaimaro",
        "05790004": "La Caucana",
        "05790006": "Piedras",
        "05790003": "Puerto Antioquia",
        "05790000": "Tarazá",
      },
      "05792": {
        "05792000": "Tarso",
      },
      "05809": {
        "05809002": "Albania",
        "05809006": "Balsal",
        "05809007": "Bosque",
        "05809008": "Caracol",
        "05809005": "Corcovado",
        "05809009": "Falda Del Cauca",
        "05809001": "La Meseta",
        "05809013": "La Peña",
        "05809010": "Loma Del Guamo",
        "05809011": "Micos",
        "05809012": "Morro",
        "05809003": "Otramina",
        "05809014": "Porvenir",
        "05809015": "Pueblito De Los Bolovares",
        "05809016": "Puerto Escondido",
        "05809017": "Sinifana",
        "05809004": "Sitio Viejo",
        "05809000": "Titiribí",
        "05809018": "Volcan",
        "05809019": "Zancudo",
      },
      "05819": {
        "05819001": "Buenavista",
        "05819002": "El Valle",
        "05819000": "Toledo",
      },
      "05837": {
        "05837020": "Alto De Mulatos",
        "05837021": "Blanquicet",
        "05837009": "Bocas Del Atrato",
        "05837023": "Casanova",
        "05837026": "Coldesa-Barrio Las Palmas",
        "05837001": "Currulao",
        "05837010": "El Dos",
        "05837027": "El Porvenir",
        "05837003": "El Tres",
        "05837024": "Las Garzas",
        "05837007": "Lomas Aisladas",
        "05837019": "Macondo",
        "05837002": "Nueva Colonia",
        "05837028": "Nueva Granada",
        "05837018": "Nuevo Antioquia",
        "05837004": "Nuevo Oriente",
        "05837012": "Pueblo Bello",
        "05837014": "Puerto Rico",
        "05837029": "Punta De Piedra",
        "05837008": "Riogrande",
        "05837013": "San José De Mulatos",
        "05837005": "San Vicente Del Congo",
        "05837006": "Tie",
        "05837000": "Turbo",
        "05837025": "Villa Maroa Arriba",
      },
      "05842": {
        "05842002": "Ambalema",
        "05842003": "El Pital",
        "05842000": "Uramita",
      },
      "05847": {
        "05847003": "La Encarnación",
        "05847004": "Mande",
        "05847019": "Punta De Ocaido",
        "05847006": "Santa Isabel",
        "05847000": "Urrao",
      },
      "05854": {
        "05854002": "Puerto Valdivia",
        "05854003": "Raudal Viejo",
        "05854000": "Valdivia",
      },
      "05856": {
        "05856006": "Itima",
        "05856003": "La Herradura",
        "05856007": "Murrapal",
        "05856008": "Pueblo Nuevo",
        "05856004": "Tartarin Moreira",
        "05856000": "Valparaíso",
      },
      "05858": {
        "05858001": "El Tigre",
        "05858000": "Vegachí",
      },
      "05861": {
        "05861002": "Bolombolo",
        "05861011": "La Mina",
        "05861000": "Venecia",
      },
      "05873": {
        "05873010": "Bajo Murro",
        "05873007": "Buchado",
        "05873008": "Nendo",
        "05873003": "Neudo",
        "05873009": "Palo Blanco",
        "05873006": "Puerto Antioquia",
        "05873004": "San Alejandro",
        "05873001": "San Antonio De Padua",
        "05873005": "San Miguel",
        "05873002": "Vegaez",
        "05873000": "Vigía Del Fuerte",
      },
      "05885": {
        "05885000": "Yalí",
      },
      "05887": {
        "05887003": "Cedeño",
        "05887004": "El Cedro",
        "05887009": "El Pueblito",
        "05887021": "La Loma",
        "05887007": "Llanos De Cuiva",
        "05887022": "Mina Vieja",
        "05887006": "Ochalí",
        "05887000": "Yarumal",
      },
      "05890": {
        "05890006": "Abisinia",
        "05890008": "Bareño",
        "05890007": "Bengala",
        "05890004": "El Rubo",
        "05890005": "La Argentina",
        "05890003": "La Cancana",
        "05890001": "La Floresta",
        "05890002": "La Leona",
        "05890000": "Yolombó",
      },
      "05893": {
        "05893007": "Americas",
        "05893008": "Bagre",
        "05893009": "Bocas De Barbacoas",
        "05893010": "Bodegas",
        "05893011": "Campo Vijao",
        "05893000": "Yondo Casabe",
        "05893012": "Congoja",
        "05893004": "Cuatro Bocas",
        "05893013": "Poza",
        "05893014": "Puerto Mango",
        "05893015": "Puerto Matilde",
        "05893016": "Puerto Nuevo",
        "05893017": "Puerto Tamos",
        "05893005": "San Francisco",
        "05893002": "San Luis Beltran",
        "05893003": "San Miguel Del Tigre",
      },
      "05895": {
        "05895003": "Buenos Aires",
        "05895004": "Pato",
        "05895008": "Vegas De Segovia",
        "05895000": "Zaragoza",
      },
    },
    "08": {
      "08001": {
        "08001000": "Barranquilla",
        "08001001": "Juan Mina",
        "08001005": "Pinar Del Rio",
      },
      "08078": {
        "08078000": "Baranoa",
        "08078001": "Campeche",
        "08078002": "Pital",
        "08078003": "Sibarco",
      },
      "08137": {
        "08137001": "Bohórquez",
        "08137000": "Campo De La Cruz",
      },
      "08141": {
        "08141002": "Buenaventura De Leña",
        "08141000": "Candelaria",
        "08141001": "San José Del Carretal",
      },
      "08296": {
        "08296000": "Galapa",
        "08296001": "Paluato",
      },
      "08372": {
        "08372001": "Bocatocino",
        "08372002": "Chorrera",
        "08372000": "Juan De Acosta",
        "08372003": "San José De Saco",
        "08372004": "Santa Verónica",
        "08372007": "Urbanización Punta Cangrejo",
      },
      "08421": {
        "08421001": "Arroyo De Piedra",
        "08421012": "Barrigon",
        "08421007": "La Puntica",
        "08421006": "Los Límites",
        "08421003": "Los Pendales",
        "08421000": "Luruaco",
        "08421002": "Palmar De Candelaria",
        "08421004": "San Juan De Tocagua",
        "08421005": "Santa Cruz",
        "08421013": "Socavon",
      },
      "08433": {
        "08433001": "Caracolí",
        "08433004": "La Aguada",
        "08433000": "Malambo",
        "08433005": "Pitalito",
      },
      "08436": {
        "08436001": "El Porvenir (Las Compuertas)",
        "08436000": "Manatí",
      },
      "08520": {
        "08520001": "Burruscos",
        "08520000": "Palmar De Varela",
      },
      "08549": {
        "08549001": "Aguas Vivas",
        "08549003": "Hibacharo",
        "08549000": "Piojó",
      },
      "08558": {
        "08558001": "Pital Del Carlín (Pitalito)",
        "08558000": "Polonuevo",
      },
      "08560": {
        "08560007": "Cascajal",
        "08560001": "La Retirada",
        "08560002": "Martillo",
        "08560000": "Ponedera",
        "08560003": "Puerto Giraldo",
        "08560004": "Santa Rita",
      },
      "08573": {
        "08573005": "Country Club Villas",
        "08573006": "Country Mar",
        "08573007": "Lagos De Caujaral",
        "08573000": "Puerto Colombia",
        "08573003": "Sabanilla (Monte Carmelo)",
        "08573002": "Salgar",
        "08573004": "Urbanización Barranquilla Sport Club",
        "08573008": "Villa Campestre",
      },
      "08606": {
        "08606001": "Arroyo Negro",
        "08606002": "Cien Pesos",
        "08606006": "El Porvenir (Las Compuertas)",
        "08606003": "Las Tablas",
        "08606000": "Repelón",
        "08606004": "Rotinet",
        "08606005": "Villa Rosa",
      },
      "08634": {
        "08634000": "Sabanagrande",
      },
      "08638": {
        "08638001": "Aguada De Pablo",
        "08638002": "Cascajal",
        "08638003": "Colombia",
        "08638008": "Gallego",
        "08638004": "Isabel López",
        "08638005": "La Peña",
        "08638007": "Mirador",
        "08638006": "Molineros",
        "08638010": "Patilla",
        "08638000": "Sabanalarga",
      },
      "08675": {
        "08675001": "Algodonal",
        "08675000": "Santa Lucía",
      },
      "08685": {
        "08685001": "El Uvito",
        "08685000": "Santo Tomás",
      },
      "08758": {
        "08758000": "Soledad",
      },
      "08770": {
        "08770000": "Suan",
      },
      "08832": {
        "08832001": "Cuatro Bocas",
        "08832002": "El Morro",
        "08832003": "Guaimaral",
        "08832004": "Juaruco",
        "08832010": "Playa Mendoza",
        "08832011": "Playas De Edrimán",
        "08832000": "Tubará",
        "08832012": "Villas De Palmarito",
      },
      "08849": {
        "08849000": "Usiacurí",
      },
    },
    11: {
      11001: {
        11001009: "Betania",
        11001000: "Bogotá, D.c.",
        11001012: "Chorrillos",
        11001013: "El Destino",
        11001010: "La Union",
        11001011: "Mochuelo",
        11001002: "Nazareth",
        11001014: "Nueva Granada",
        11001007: "Pasquilla",
        11001015: "Quiba Bajo",
        11001008: "San Juan",
      },
      11030: {
        11030000: "Fontibon",
      },
      11040: {
        11040000: "Suba",
      },
      11092: {
        11092000: "Bosa",
      },
    },
    13: {
      13001: {
        13001015: "Ararca",
        13001017: "Archipielago De San Bernardo",
        13001026: "Arroyo De Las Canoas",
        13001001: "Arroyo De Piedra",
        13001002: "Arroyo Grande",
        13001003: "Barú",
        13001004: "Bayunca",
        13001005: "Bocachica",
        13001032: "Boquillita",
        13001006: "Caño Del Oro",
        13001000: "Cartagena De Indias",
        13001027: "El Pueblito",
        13001018: "El Recreo",
        13001007: "Isla Fuerte",
        13001021: "Islas Del Rosario",
        13001008: "La Boquilla",
        13001028: "Las Europas",
        13001016: "Leticia",
        13001029: "Manzanillo Del Mar",
        13001033: "Membrillal",
        13001022: "Palenquillo",
        13001009: "Pasacaballos",
        13001023: "Piedrecitas",
        13001024: "Playa Dorada",
        13001020: "Pontezuela",
        13001019: "Puerto Rey",
        13001014: "Punta Arena",
        13001010: "Punta Canoa",
        13001012: "Santa Ana",
        13001030: "Tierra Baja",
        13001013: "Tierra Bomba",
      },
      13006: {
        13006000: "Achí",
        13006005: "Algarrobo",
        13006002: "Boyacá",
        13006003: "Buenavista",
        13006021: "Buenos Aires",
        13006030: "Centro Alegre",
        13006038: "Gallego Arriba",
        13006007: "Guacamayo",
        13006008: "Guamo",
        13006039: "Los Nosperos",
        13006040: "Nueva Esperanza",
        13006029: "Palenquillo",
        13006041: "Paraoso Sinceron",
        13006019: "Payandé",
        13006011: "Playalta",
        13006042: "Providencia",
        13006022: "Puerto Isabel",
        13006033: "Puerto Venecia",
        13006020: "Río Nuevo",
        13006013: "Roo Negro",
        13006035: "Santa Lucoa",
        13006015: "Tacuyalta",
        13006017: "Tres Cruces",
        13006043: "Vida Tranquila",
      },
      13030: {
        13030000: "Altos Del Rosario",
        13030001: "El Rubio",
        13030002: "La Pacha",
        13030003: "San Isidro",
        13030004: "Santa Lucoa",
      },
      13042: {
        13042000: "Arenal",
        13042001: "Buenavista",
        13042002: "Carnizala",
        13042003: "San Rafael",
        13042007: "Santo Domingo",
      },
      13052: {
        13052000: "Arjona",
        13052006: "Cruz Del Vizo",
        13052002: "Gambote",
        13052001: "Puerto Badel (Caño Salado)",
        13052003: "Rocha",
        13052005: "San Rafael De La Cruz",
        13052004: "Sincerín",
      },
      13062: {
        13062000: "Arroyohondo",
        13062001: "Machado",
        13062002: "Monroy",
        13062003: "Pilón",
        13062006: "San Francisco (Solabanda)",
        13062004: "Sato",
      },
      13074: {
        13074000: "Barranco De Loba",
        13074006: "Las Delicias",
        13074005: "Los Cerritos",
        13074003: "Rionuevo",
        13074004: "San Antonio",
      },
      13140: {
        13140002: "Barranca Nueva",
        13140003: "Barranca Vieja",
        13140000: "Calamar",
        13140004: "Hato Viejo",
        13140009: "Yucal",
      },
      13160: {
        13160002: "Brisas De Bolovar",
        13160003: "Cagui",
        13160000: "Cantagallo",
        13160004: "El Bagre",
        13160005: "El Caraño",
        13160006: "El Cedro",
        13160007: "El Firme",
        13160008: "La Esperanza",
        13160009: "La Nutria",
        13160010: "La Poza",
        13160012: "Las Pavas",
        13160011: "La Victoria (Sepultura)",
        13160013: "Lejanoa",
        13160014: "Los Coroncoros",
        13160015: "Los Paticos",
        13160016: "Muriba",
        13160017: "No Hay Como Dios",
        13160001: "San Lorenzo",
        13160019: "Sinzona",
        13160020: "Yanacue",
      },
      13188: {
        13188001: "Campo Sereno",
        13188000: "Cicuco",
        13188002: "La Peña",
        13188005: "Pueblo Nuevo",
        13188003: "San Francisco De Loba",
        13188004: "San Javier",
      },
      13212: {
        13212000: "Córdoba",
        13212001: "Guaimaral",
        13212002: "Isla De Cordoba",
        13212003: "La Montaña De Alonso (Martín Alonso)",
        13212010: "La Sierra",
        13212011: "Las Maroas",
        13212012: "Pueblo Nuevo",
        13212013: "Sanahuare",
        13212005: "San Andrés",
        13212009: "Santa Lucía",
        13212006: "Sincelejito",
        13212014: "Socorro 1",
        13212007: "Tacamochito",
        13212008: "Tacamocho",
      },
      13222: {
        13222000: "Clemencia",
        13222002: "El Peñique",
        13222003: "El Socorro",
        13222001: "Las Caras",
      },
      13244: {
        13244001: "Bajo Grande",
        13244002: "Caracolí Grande",
        13244000: "El Carmen De Bolívar",
        13244018: "El Hobo",
        13244011: "El Raizal",
        13244003: "El Salado",
        13244008: "Hato Nuevo",
        13244004: "Jesús Del Monte",
        13244005: "Macayepos",
        13244006: "San Carlos",
        13244007: "San Isidro",
        13244014: "Santa Lucía",
        13244017: "Santo Domingo De Meza",
      },
      13248: {
        13248000: "El Guamo",
        13248001: "La Enea",
        13248003: "Nervití",
        13248004: "Robles",
        13248002: "San José De Lata",
        13248005: "Tasajera",
      },
      13268: {
        13268003: "Buenos Aires",
        13268004: "Castañal",
        13268000: "El Peñon",
        13268008: "Japon",
        13268006: "La Chapetona",
        13268013: "La Humareda",
        13268017: "Peñoncito",
      },
      13300: {
        13300007: "Cerro De Las Aguadas",
        13300001: "El Pozón",
        13300009: "Guacalo",
        13300000: "Hatillo De Loba",
        13300002: "Juana Sánchez",
        13300003: "La Ribona",
        13300008: "Las Brisas",
        13300004: "La Victoria",
        13300005: "Pueblo Nuevo",
        13300006: "San Miguel",
      },
      13430: {
        13430001: "Barbosa",
        13430002: "Barranca De Yuca",
        13430003: "Betania",
        13430039: "Boca De Guamal",
        13430004: "Boca De San Antonio",
        13430006: "Cascajal",
        13430007: "Ceibal",
        13430008: "Coyongal",
        13430038: "El Cuatro",
        13430009: "El Retiro",
        13430041: "Emaus",
        13430053: "Florencia",
        13430010: "Guazo",
        13430011: "Henequén",
        13430042: "Isla De Perico",
        13430012: "Isla Grande",
        13430013: "Juan Arias",
        13430014: "La Pascuala",
        13430016: "Las Brisas",
        13430015: "La Ventura",
        13430017: "Madrid",
        13430000: "Magangué",
        13430018: "Palmarito",
        13430019: "Pansegüita",
        13430020: "Piñalito",
        13430036: "Playa De Las Flores",
        13430029: "Puerto Kennedy",
        13430047: "Puerto Nariño",
        13430048: "Punta De Cartagena",
        13430037: "San Antoñito",
        13430051: "San Antoñito",
        13430022: "San José De Las Martas",
        13430021: "San Rafael De Cortina",
        13430023: "San Sebastián De Buenavista",
        13430052: "Santa Coita",
        13430024: "Santa Fe",
        13430025: "Santa Lucía",
        13430026: "Santa Mónica",
        13430027: "Santa Pabla",
        13430028: "Sitio Nuevo",
        13430030: "Tacaloa",
        13430031: "Tacasaluma",
        13430032: "Tolú",
        13430040: "Tres Puntas",
      },
      13433: {
        13433010: "Cruz Del Vizo",
        13433001: "Evitar",
        13433002: "Gamero",
        13433000: "Mahates",
        13433003: "Malagana",
        13433009: "Mandinga",
        13433004: "San Basilio De Palenque",
        13433005: "San Joaquín",
      },
      13440: {
        13440001: "Botón De Leiva",
        13440013: "Caimital",
        13440002: "Cantera",
        13440014: "Caño Mono",
        13440003: "Causado",
        13440004: "Chilloa",
        13440011: "Corocito",
        13440005: "Doña Juana",
        13440015: "El Barranco",
        13440016: "El Zafiro",
        13440006: "Guataca Sur",
        13440012: "Guataquita",
        13440017: "La Montaña",
        13440018: "Los Mangos",
        13440007: "Mamoncito",
        13440000: "Margarita",
        13440008: "Sandoval",
        13440019: "Sandovalito",
        13440020: "San Ignacio",
        13440010: "San José De Los Trapiches",
        13440021: "San Marton",
      },
      13442: {
        13442017: "Arroyo Grande",
        13442028: "Cacao",
        13442029: "Cañas",
        13442030: "Cedrito",
        13442014: "Colú",
        13442001: "Correa",
        13442031: "El Guamo",
        13442032: "El Limon",
        13442009: "El Majagua",
        13442002: "El Níspero",
        13442023: "El Sena",
        13442003: "Flamenco",
        13442033: "Florido Viejo",
        13442034: "Guarismo",
        13442024: "La Curva",
        13442025: "La Pista",
        13442035: "La Suprema",
        13442011: "Los Bellos",
        13442036: "Los Ochenta",
        13442037: "Majaguas",
        13442004: "Manpuján",
        13442000: "María La Baja",
        13442026: "Marquez",
        13442012: "Matuya",
        13442038: "Mundo Nuevo",
        13442027: "Munguia",
        13442021: "Nueva Florida",
        13442039: "Nueva Jerusalen",
        13442040: "Nuevo Porvenir",
        13442005: "Ñanguma",
        13442018: "Palo Altico",
        13442022: "Primero De Julio",
        13442020: "Pueblo Nuevo",
        13442041: "Puerto Santander",
        13442006: "Retiro Nuevo",
        13442007: "San José Del Playón",
        13442008: "San Pablo",
        13442042: "Santafe De Icotea",
        13442043: "Sucesion",
        13442044: "Toma Razon",
      },
      13458: {
        13458001: "Betania",
        13458002: "El Dorado",
        13458000: "Montecristo",
        13458003: "Paraoso",
        13458007: "Platanal",
        13458004: "Pueblo Lindo",
        13458005: "Pueblo Nuevo-Regencia",
        13458006: "Puerto España",
        13458013: "Rangel",
        13458008: "San Aguston",
        13458009: "San Mateo",
        13458011: "Taburetera",
        13458012: "Villa Uribe",
      },
      13468: {
        13468028: "Ancón",
        13468033: "Bomba",
        13468001: "Caldera",
        13468002: "Candelaria",
        13468003: "Carmen Del Cicuco",
        13468041: "El Carmen",
        13468035: "El Guamo",
        13468036: "El Rosario",
        13468008: "Guaimaral",
        13468037: "Guaimaro",
        13468009: "Guataca",
        13468010: "La Jagua",
        13468011: "La Lobata",
        13468013: "La Rinconada",
        13468014: "Las Boquillas",
        13468030: "La Travesía",
        13468015: "Loma De Simón",
        13468016: "Los Piñones",
        13468000: "Mompós",
        13468031: "Pueblonuevo",
        13468020: "San Ignacio",
        13468039: "San Luis",
        13468032: "San Marton",
        13468022: "San Nicolás",
        13468023: "Santa Cruz",
        13468038: "Santa Elena",
        13468024: "Santa Rosa",
        13468025: "Santa Teresita",
        13468040: "Villanueva",
      },
      13473: {
        13473012: "Boca De La Honda",
        13473002: "Bodega Central",
        13473015: "Corcovado",
        13473003: "El Dique",
        13473016: "La Esmerada",
        13473017: "La Palma",
        13473004: "Las Pailas",
        13473013: "Micoahumado",
        13473018: "Mina Gallo",
        13473000: "Morales",
        13473014: "Paredes De Ororia",
      },
      13490: {
        13490001: "Buena Seña",
        13490002: "Casa De Barro",
        13490003: "Las Nieves",
        13490004: "Mina Brisa",
        13490005: "Mina Estrella",
        13490000: "Norosí",
        13490006: "Olivares",
        13490007: "Santa Elena",
      },
      13549: {
        13549001: "Armenia",
        13549021: "Buenos Aires",
        13549022: "El Libano",
        13549004: "La Rufina",
        13549006: "Las Conchitas",
        13549007: "Las Flores",
        13549005: "La Unión",
        13549023: "La Union (Tiquisio)",
        13549024: "La Victoria",
        13549025: "Los Limones",
        13549009: "Mantequera",
        13549026: "Montecelio",
        13549027: "Nicaragua",
        13549010: "Palenquito",
        13549011: "Palomino",
        13549000: "Pinillos",
        13549028: "Puerto Bello",
        13549012: "Puerto López",
        13549018: "Rufina Nueva",
        13549029: "Rufina Puerto Nuevo",
        13549030: "San Francisco",
        13549014: "Santa Coa",
        13549031: "Santa Elena",
        13549015: "Santa Rosa",
        13549032: "Tapoa",
        13549033: "Vida Tranquila",
      },
      13580: {
        13580001: "Piñal",
        13580000: "Regidor",
        13580004: "San Antonio",
        13580005: "San Cayetano",
        13580007: "Santa Teresa",
      },
      13600: {
        13600007: "Caimital",
        13600009: "Cobadillo",
        13600010: "Hatillo",
        13600011: "Macedonia",
        13600000: "Río Viejo",
      },
      13620: {
        13620001: "Higueretal",
        13620002: "Las Cruces",
        13620000: "San Cristóbal",
      },
      13647: {
        13647001: "Bayano",
        13647002: "Las Piedras",
        13647000: "San Estanislao De Kostka",
      },
      13650: {
        13650009: "Cuatro Bocas",
        13650010: "El Contadero",
        13650011: "El Gato",
        13650012: "El Jolon",
        13650006: "El Palmar",
        13650008: "El Porvenir",
        13650001: "Guasimal",
        13650013: "La Guadua",
        13650014: "Las Cuevas",
        13650002: "Menchiquejo",
        13650015: "Pampanillo",
        13650004: "Punta De Hornos",
        13650000: "San Fernando",
        13650005: "Santa Rosa",
      },
      13654: {
        13654001: "Arenas",
        13654002: "Bajo Grande",
        13654006: "Las Charquitas",
        13654008: "Las Mercedes",
        13654003: "Las Palmas",
        13654007: "Paraíso",
        13654005: "San Cristóbal",
        13654000: "San Jacinto",
        13654009: "Villa Alegría",
      },
      13655: {
        13655007: "Astilleros",
        13655002: "Bermudez",
        13655003: "Caimital",
        13655005: "Galindo",
        13655004: "La Raya",
        13655006: "Méjico",
        13655000: "San Jacinto Del Cauca",
        13655001: "Tenche",
      },
      13657: {
        13657001: "Corralito",
        13657002: "La Haya",
        13657004: "San Agustín",
        13657005: "San Cayetano",
        13657003: "San José Del Peñón (Las Porqueras)",
        13657000: "San Juan Nepomuceno",
        13657006: "San Pedro Consolado",
      },
      13667: {
        13667002: "Chimi",
        13667015: "El Jobo",
        13667016: "El Varal",
        13667010: "Las Playitas",
        13667009: "Papayal",
        13667014: "Pueblo Nuevo Cerro De Julio",
        13667000: "San Martín De Loba",
      },
      13670: {
        13670010: "Aguasucia",
        13670002: "Canaletal",
        13670009: "Cañabraval",
        13670015: "Cañabraval Bajo",
        13670011: "Cerro Azul",
        13670016: "Cucu",
        13670004: "El Carmen",
        13670017: "El Rosario",
        13670018: "La Froa",
        13670019: "La Union",
        13670014: "La Virgencita",
        13670020: "Los Caguises",
        13670021: "Patio Bonito",
        13670007: "Pozo Azul",
        13670008: "San Juan Alto",
        13670000: "San Pablo",
        13670003: "Santo Domingo",
        13670012: "Vallecito",
        13670013: "Villanueva",
      },
      13673: {
        13673007: "Colorado",
        13673003: "Galerazamba",
        13673005: "Loma De Arena",
        13673006: "Pueblo Nuevo",
        13673000: "Santa Catalina",
      },
      13683: {
        13683000: "Santa Rosa De Lima",
      },
      13688: {
        13688014: "Arrayanes",
        13688002: "Buenavista",
        13688007: "Canelos",
        13688005: "Fatima",
        13688015: "San Francisco",
        13688016: "San Isidro",
        13688009: "San Jose",
        13688018: "San Juan",
        13688011: "San Lucas",
        13688019: "Santa Isabel",
        13688000: "Santa Rosa Del Sur",
        13688013: "Villa Flor",
      },
      13744: {
        13744013: "Animas Altas",
        13744014: "Animas Bajas",
        13744001: "Campo Pallares",
        13744015: "Diamante",
        13744016: "Garzal",
        13744010: "Las Brisas",
        13744011: "Monterrey",
        13744017: "Paraoso",
        13744018: "Paredes De Ororia",
        13744007: "San Blas",
        13744019: "San Joaquon",
        13744008: "San Luis",
        13744020: "Santa Lucoa",
        13744000: "Simití",
        13744006: "Veracruz",
      },
      13760: {
        13760000: "Soplaviento",
      },
      13780: {
        13780005: "Cañohondo",
        13780024: "Ladera De San Martín",
        13780015: "Las Maroas",
        13780026: "Los Mangos",
        13780017: "Patico",
        13780025: "Peñón De Durán",
        13780009: "Porvenir",
        13780000: "Talaigua Nuevo",
        13780023: "Talaigua Viejo",
        13780027: "Tupe",
        13780011: "Vesubio",
      },
      13810: {
        13810001: "Aguas Negras",
        13810002: "Bocas De Solis",
        13810003: "Colorado",
        13810004: "Dos Bocas",
        13810005: "El Sudán",
        13810006: "La Ventura",
        13810007: "Palma Esteral",
        13810008: "Puerto Coca",
        13810000: "Puerto Rico - Tiquisio",
        13810009: "Quebrada Del Medio",
        13810010: "Sabanas Del Firme",
        13810011: "Tiquisio Nuevo",
      },
      13836: {
        13836001: "Cañaveral",
        13836002: "San José De Chiquito",
        13836000: "Turbaco",
      },
      13838: {
        13838001: "Ballestas",
        13838002: "El Cobado (El Pueblito)",
        13838000: "Turbaná",
      },
      13873: {
        13873000: "Villanueva",
        13873001: "Zipacoa",
      },
      13894: {
        13894000: "Zambrano",
      },
    },
    15: {
      15001: {
        15001000: "Tunja",
      },
      15022: {
        15022000: "Almeida",
      },
      15047: {
        15047000: "Aquitania",
        15047010: "Daito",
        15047002: "Diganome",
        15047011: "Hato Laguna",
        15047014: "Perez",
        15047012: "Primavera",
        15047015: "Quebradas",
        15047013: "Soriano",
        15047009: "Susaca",
        15047006: "Suse",
      },
      15051: {
        15051000: "Arcabuco",
      },
      15087: {
        15087000: "Belén",
      },
      15090: {
        15090000: "Berbeo",
      },
      15092: {
        15092000: "Betéitiva",
        15092001: "Otenga",
      },
      15097: {
        15097000: "Boavita",
        15097001: "El Espigon",
        15097002: "San Francisco",
      },
      15104: {
        15104000: "Boyacá",
      },
      15106: {
        15106000: "Briceño",
      },
      15109: {
        15109000: "Buenavista",
      },
      15114: {
        15114000: "Busbanzá",
      },
      15131: {
        15131000: "Caldas",
        15131003: "El Cubo",
        15131001: "Nariño",
        15131002: "Quipe Chungaguta",
      },
      15135: {
        15135000: "Campohermoso",
        15135003: "Los Cedros",
        15135002: "Vistahermosa",
      },
      15162: {
        15162000: "Cerinza",
      },
      15172: {
        15172000: "Chinavita",
      },
      15176: {
        15176000: "Chiquinquirá",
        15176001: "Moyavita (Sasa)",
      },
      15180: {
        15180000: "Chiscas",
        15180005: "Mercedes",
      },
      15183: {
        15183006: "Canoas",
        15183005: "Chipa Centro",
        15183001: "Chipaviejo",
        15183000: "Chita",
        15183008: "El Arenal",
        15183002: "El Moral",
        15183007: "La Playa",
        15183004: "Minas",
        15183003: "Monserrate",
      },
      15185: {
        15185000: "Chitaraque",
        15185001: "Guamo Y Ladera",
        15185002: "Santo Domingo",
      },
      15187: {
        15187000: "Chivatá",
      },
      15189: {
        15189000: "Ciénega",
      },
      15204: {
        15204000: "Cómbita",
        15204001: "El Barne",
        15204003: "El Carmen",
        15204004: "La Concepcion",
        15204005: "San Isidro",
        15204006: "San Onofre",
        15204007: "San Rafael",
        15204002: "Santa Barbara",
      },
      15212: {
        15212000: "Coper",
      },
      15215: {
        15215000: "Corrales",
      },
      15218: {
        15218000: "Covarachía",
      },
      15223: {
        15223000: "Cubará",
        15223005: "El Guamo",
        15223011: "Gibraltar",
      },
      15224: {
        15224000: "Cucaita",
      },
      15226: {
        15226000: "Cuítiva",
        15226001: "Llano De Alarcón",
      },
      15232: {
        15232000: "Chíquiza",
        15232001: "San Pedro De Iguaque",
      },
      15236: {
        15236000: "Chivor",
      },
      15238: {
        15238012: "Ciudadela Industrial",
        15238000: "Duitama",
        15238015: "Pueblito Boyacence",
        15238009: "San Antonio Norte",
        15238008: "San Lorenzo Abajo",
        15238013: "Santa Clara",
        15238014: "Tocogua",
        15238011: "Trinidad",
      },
      15244: {
        15244000: "El Cocuy",
      },
      15248: {
        15248000: "El Espino",
        15248003: "La Barrera",
        15248002: "La Laguna",
        15248004: "Santa Ana",
      },
      15272: {
        15272000: "Firavitoba",
      },
      15276: {
        15276000: "Floresta",
        15276001: "Tobasía",
      },
      15293: {
        15293000: "Gachantivá",
      },
      15296: {
        15296000: "Gámeza",
      },
      15299: {
        15299000: "Garagoa",
      },
      15317: {
        15317000: "Guacamayas",
      },
      15322: {
        15322004: "Chorro De Oro",
        15322002: "Gaunza Abajo",
        15322003: "Gaunza Arriba",
        15322000: "Guateque",
        15322007: "Llano Grande",
        15322009: "Piedra Parada",
        15322012: "Rosales",
      },
      15325: {
        15325000: "Guayatá",
      },
      15332: {
        15332007: "Bachira",
        15332001: "Bocota",
        15332000: "Guicán",
        15332004: "La Cueva",
        15332008: "Tunebia Arriba",
      },
      15362: {
        15362000: "Iza",
      },
      15367: {
        15367000: "Jenesano",
      },
      15368: {
        15368001: "Cheva",
        15368000: "Jericó",
      },
      15377: {
        15377000: "Labranzagrande",
      },
      15380: {
        15380000: "La Capilla",
      },
      15401: {
        15401001: "Chapon O Guadua",
        15401005: "Humbo",
        15401000: "La Victoria",
        15401004: "Santa Helena",
      },
      15403: {
        15403001: "Cusagüi",
        15403000: "La Uvita",
      },
      15407: {
        15407003: "El Roble",
        15407000: "Villa De Leyva",
      },
      15425: {
        15425000: "Macanal",
        15425004: "San Pedro De Muceño",
      },
      15442: {
        15442005: "Guayabal",
        15442006: "Guazo",
        15442007: "La Pita",
        15442000: "Maripí",
        15442003: "Narapay",
        15442004: "Portachuelo",
        15442001: "Santa Rosa",
        15442002: "Zulia",
      },
      15455: {
        15455000: "Miraflores",
      },
      15464: {
        15464000: "Mongua",
      },
      15466: {
        15466000: "Monguí",
      },
      15469: {
        15469007: "Los Cayenos",
        15469008: "Los Sauces",
        15469000: "Moniquirá",
      },
      15476: {
        15476000: "Motavita",
        15476001: "Panelas",
      },
      15480: {
        15480000: "Muzo",
      },
      15491: {
        15491001: "Belencito",
        15491008: "Bonza",
        15491006: "Caleras",
        15491002: "Chameza Mayor",
        15491009: "Chamez Men",
        15491003: "Dicho",
        15491010: "Guaquira",
        15491007: "Nazareth",
        15491000: "Nobsa",
        15491004: "Punta Larga",
        15491011: "San Marton",
        15491012: "Santana",
        15491005: "Ucuenga",
      },
      15494: {
        15494000: "Nuevo Colón",
      },
      15500: {
        15500000: "Oicatá",
      },
      15507: {
        15507001: "Betania",
        15507002: "Buenavista",
        15507010: "Buenos Aires",
        15507011: "Mirador",
        15507000: "Otanche",
        15507004: "Pizarra",
        15507009: "San José De Nazareth",
      },
      15511: {
        15511000: "Pachavita",
      },
      15514: {
        15514001: "La Ururia",
        15514000: "Páez",
        15514002: "Sirasí",
      },
      15516: {
        15516008: "Canocas",
        15516009: "Cruz De Bonza",
        15516010: "El Tejar",
        15516006: "El Venado",
        15516002: "El Volcan",
        15516000: "Paipa",
        15516001: "Palermo",
        15516005: "Pantano De Vargas",
        15516004: "Romita",
      },
      15518: {
        15518001: "Corinto",
        15518002: "Curiso",
        15518000: "Pajarito",
      },
      15522: {
        15522000: "Panqueba",
      },
      15531: {
        15531000: "Pauna",
        15531004: "Quebrada Seca",
      },
      15533: {
        15533001: "Morcote",
        15533000: "Paya",
      },
      15537: {
        15537002: "Concentracion",
        15537000: "Paz De Río",
        15537001: "Paz Vieja",
      },
      15542: {
        15542000: "Pesca",
      },
      15550: {
        15550000: "Pisba",
      },
      15572: {
        15572017: "Cruce El Chaparro",
        15572006: "Cruce Palagua",
        15572018: "El Ermitaño",
        15572011: "El Marfil",
        15572019: "El Okal",
        15572008: "El Pescado",
        15572020: "El Trique",
        15572001: "Guanegro",
        15572021: "Kilometro 11",
        15572010: "Kilometro 25",
        15572009: "Kilometro Dos Y Medio",
        15572026: "Kilometro Uno Y Medio",
        15572027: "La Ceiba",
        15572023: "Muelle Velasquez",
        15572000: "Puerto Boyacá",
        15572005: "Puerto Gutierrez",
        15572024: "Puerto Niño",
        15572012: "Puerto Pinzon",
        15572013: "Puerto Romero",
        15572007: "Puerto Serviez",
      },
      15580: {
        15580001: "Cormal",
        15580002: "El Parque",
        15580003: "Humbo",
        15580000: "Quípama",
      },
      15599: {
        15599004: "El Escobal",
        15599001: "Guayabal (Fátima)",
        15599000: "Ramiriquí",
      },
      15600: {
        15600002: "La Candelaria",
        15600000: "Ráquira",
        15600001: "San Cayetano",
      },
      15621: {
        15621001: "Ranchogrande",
        15621000: "Rondón",
      },
      15632: {
        15632004: "El Molino",
        15632001: "Garavito",
        15632002: "Merchan",
        15632003: "Pantanos",
        15632000: "Saboyá",
        15632005: "Velandia",
      },
      15638: {
        15638000: "Sáchica",
      },
      15646: {
        15646001: "La Cumbre",
        15646002: "La Fabrica",
        15646003: "Salamanaca",
        15646000: "Samacá",
        15646004: "Tibaquira",
      },
      15660: {
        15660000: "San Eduardo",
      },
      15664: {
        15664000: "San José De Pare",
      },
      15667: {
        15667002: "Guamal",
        15667003: "Horizontes",
        15667006: "La Frontera (Corredor Vial)",
        15667004: "La Mesa Del Guavio",
        15667005: "San Carlos Del Guavio",
        15667000: "San Luis De Gaceno",
        15667001: "Santa Teresa",
      },
      15673: {
        15673000: "San Mateo",
      },
      15676: {
        15676000: "San Miguel De Sema",
      },
      15681: {
        15681007: "Coscuez",
        15681006: "San Martín",
        15681000: "San Pablo De Borbur",
        15681005: "Santa Bárbara",
        15681004: "Sector Peña Blanca",
      },
      15686: {
        15686000: "Santana",
      },
      15690: {
        15690001: "Caño Negro",
        15690000: "Santa María",
      },
      15693: {
        15693002: "El Imperio",
        15693000: "Santa Rosa De Viterbo",
      },
      15696: {
        15696000: "Santa Sofía",
      },
      15720: {
        15720002: "La Jupa",
        15720000: "Sativanorte",
        15720001: "Sativa Viejo",
      },
      15723: {
        15723000: "Sativasur",
      },
      15740: {
        15740000: "Siachoque",
      },
      15753: {
        15753000: "Soatá",
      },
      15755: {
        15755004: "Los Pinos",
        15755000: "Socotá",
      },
      15757: {
        15757001: "Pueblo Nuevo",
        15757002: "Santa Teresa",
        15757000: "Socha",
      },
      15759: {
        15759006: "Alcaparral",
        15759005: "El Crucero",
        15759007: "Milagro Y Playita",
        15759001: "Morcá",
        15759002: "Mortiñal",
        15759004: "Siatame",
        15759000: "Sogamoso",
        15759003: "Vanegas",
      },
      15761: {
        15761000: "Somondoco",
      },
      15762: {
        15762000: "Sora",
      },
      15763: {
        15763002: "Bosigas",
        15763003: "Cerreño",
        15763001: "El Moral",
        15763000: "Sotaquirá",
      },
      15764: {
        15764000: "Soracá",
      },
      15774: {
        15774000: "Susacón",
      },
      15776: {
        15776000: "Sutamarchán",
      },
      15778: {
        15778000: "Sutatenza",
      },
      15790: {
        15790002: "La Chapa",
        15790001: "Libertadores",
        15790000: "Tasco",
      },
      15798: {
        15798000: "Tenza",
      },
      15804: {
        15804000: "Tibaná",
      },
      15806: {
        15806002: "El Paraíso",
        15806003: "Santa Teresa",
        15806000: "Tibasosa",
      },
      15808: {
        15808000: "Tinjacá",
      },
      15810: {
        15810000: "Tipacoque",
      },
      15814: {
        15814000: "Toca",
      },
      15816: {
        15816000: "Toguí",
      },
      15820: {
        15820001: "Bado Castro",
        15820000: "Tópaga",
      },
      15822: {
        15822001: "La Puerta",
        15822000: "Tota",
      },
      15832: {
        15832000: "Tununguá",
      },
      15835: {
        15835000: "Turmequé",
      },
      15837: {
        15837006: "Bosiga Sur",
        15837007: "El Cruce",
        15837008: "Siderurgica",
        15837000: "Tuta",
      },
      15839: {
        15839004: "La Capilla",
        15839001: "Páramo",
        15839000: "Tutazá",
      },
      15842: {
        15842000: "Úmbita",
      },
      15861: {
        15861009: "El Capri",
        15861010: "El Manzano",
        15861006: "Estancia Grande",
        15861007: "Puente Boyacá",
        15861008: "Tierra Negra",
        15861000: "Ventaquemada",
      },
      15879: {
        15879000: "Viracachá",
      },
      15897: {
        15897000: "Zetaquira",
      },
    },
    17: {
      17001: {
        17001029: "Agua Bonita",
        17001022: "Alto Bonito",
        17001026: "Alto Corinto",
        17001001: "Alto De Lisboa",
        17001011: "Alto Del Naranjo",
        17001010: "Alto Tablazo",
        17001025: "Bajo Corinto",
        17001003: "Bajo Tablazo",
        17001034: "El Aguila",
        17001012: "El Arenillo",
        17001002: "Kilometro 41-Colombia",
        17001015: "La Aurora",
        17001004: "La Cabaña",
        17001005: "La Cuchilla Del Salado",
        17001006: "La Enea",
        17001024: "La Garrucha",
        17001008: "Las Pavas",
        17001000: "Manizales",
        17001023: "Mina Rica",
        17001009: "San Peregrino",
      },
      17013: {
        17013000: "Aguadas",
        17013014: "Alto De La Montaña",
        17013012: "Alto De Pito",
        17013001: "Arma",
        17013010: "Eden",
        17013004: "La Mermita",
        17013017: "Pore",
        17013011: "San Nicolás",
        17013016: "Viboral",
      },
      17042: {
        17042012: "Alsacia",
        17042000: "Anserma",
        17042002: "Bellavista",
        17042023: "Chapata",
        17042008: "Concharo",
        17042024: "El Horro",
        17042004: "Marapra",
        17042021: "Miravalle",
        17042007: "San Pedro",
      },
      17050: {
        17050000: "Aranzazu",
        17050006: "San Rafael",
      },
      17088: {
        17088008: "Asentamiento Indígena",
        17088000: "Belalcázar",
        17088001: "El Madroño",
        17088002: "La Habana",
        17088003: "San Isidro",
      },
      17174: {
        17174006: "Alto De La Mina",
        17174000: "Chinchiná",
        17174012: "El Reposo",
        17174001: "El Trébol",
        17174013: "Guayabal",
        17174011: "La Estrella",
        17174002: "La Floresta",
        17174007: "La Quiebra Del Naranjo",
        17174015: "San Andres",
      },
      17272: {
        17272008: "Balmoral",
        17272001: "El Pintado",
        17272002: "El Verso",
        17272000: "Filadelfia",
        17272009: "La Marina",
        17272003: "La Paila",
        17272004: "Morritos",
        17272005: "Samaria",
        17272007: "San Luis",
      },
      17380: {
        17380016: "Brisas Bocas De Pontona",
        17380017: "Brisas Tablones",
        17380001: "Buenavista",
        17380007: "Camelias",
        17380008: "Doña Juana",
        17380005: "El Tigre",
        17380002: "Guarinocito",
        17380009: "Horizonte",
        17380018: "Japon",
        17380010: "La Agustina",
        17380004: "La Atarraya",
        17380019: "La Bocana",
        17380000: "La Dorada",
        17380012: "La Habana",
        17380015: "Prosocial La Humareda",
        17380003: "Purnio",
      },
      17388: {
        17388004: "El Palo",
        17388005: "La Chuspa",
        17388002: "La Felisa",
        17388000: "La Merced",
        17388007: "Llanadas",
        17388010: "Toscana-Vergel",
      },
      17433: {
        17433001: "Aguabonita",
        17433003: "La Ceiba",
        17433004: "Las Margaritas",
        17433005: "Los Planes",
        17433000: "Manzanares",
      },
      17442: {
        17442001: "Cabras",
        17442002: "El Llano",
        17442008: "Jimenez Alto",
        17442009: "Jimenez Bajo",
        17442005: "La Cuchilla",
        17442010: "Ladrillera",
        17442007: "La Garrucha",
        17442011: "Llano Grande",
        17442012: "Loaiza",
        17442000: "Marmato",
        17442003: "San Juan",
      },
      17444: {
        17444000: "Marquetalia",
        17444001: "Santa Elena",
      },
      17446: {
        17446000: "Marulanda",
        17446003: "Mollejones",
        17446001: "Montebonito",
        17446004: "Paramo Herveo",
      },
      17486: {
        17486011: "Agrovilla",
        17486009: "Barrio Medellín",
        17486010: "La Isla",
        17486000: "Neira",
        17486004: "Pueblo Rico",
        17486012: "San José",
        17486005: "Tapias",
      },
      17495: {
        17495002: "La Quiebra",
        17495000: "Norcasia",
      },
      17513: {
        17513012: "Buenos Aires",
        17513001: "Castilla",
        17513002: "Las Coles",
        17513013: "Los Morros",
        17513000: "Pácora",
        17513003: "San Bartolomé",
        17513014: "San Lorenzo",
      },
      17524: {
        17524001: "Arauca",
        17524005: "Cartagena",
        17524002: "El Jardín (Reposo)",
        17524003: "La Plata",
        17524000: "Palestina",
      },
      17541: {
        17541016: "Aguabonita",
        17541001: "Arboleda",
        17541002: "Bolivia",
        17541004: "El Higuerón",
        17541006: "La Linda",
        17541008: "La Rioja",
        17541015: "La Soledad Alta",
        17541013: "La Torre",
        17541000: "Pensilvania",
        17541009: "Pueblonuevo",
        17541010: "Samaria",
        17541011: "San Daniel",
      },
      17614: {
        17614001: "Bonafont",
        17614003: "El Salado",
        17614004: "Florencia",
        17614008: "Iberia",
        17614022: "La Playa - Imurra",
        17614021: "Las Estancias",
        17614017: "Pueblo Viejo",
        17614005: "Quiebralomo",
        17614000: "Riosucio",
        17614014: "San Jerónimo",
        17614006: "San Lorenzo",
        17614010: "Sipirra",
        17614023: "Tumbabarreto",
      },
      17616: {
        17616001: "Alto De Arauca",
        17616011: "Calle Larga",
        17616004: "Quiebra Santa Bárbara",
        17616008: "Quiebra Varillas",
        17616000: "Risaralda",
      },
      17653: {
        17653004: "La Unión",
        17653000: "Salamina",
        17653021: "San Diego",
        17653007: "San Félix",
      },
      17662: {
        17662001: "Berlín",
        17662012: "El Congal",
        17662004: "Encimadas",
        17662003: "Florencia",
        17662005: "Los Pomos",
        17662008: "Rancholargo",
        17662000: "Samaná",
        17662007: "San Diego",
      },
      17665: {
        17665004: "Condominios Valles De Acapulco Y Los Seis Y",
        17665001: "La Libertad",
        17665002: "Primavera Alta",
        17665000: "San José",
      },
      17777: {
        17777008: "Guamal",
        17777005: "Hojas Anchas",
        17777003: "La Quinta",
        17777010: "Puerto Nuevo",
        17777000: "Supía",
      },
      17867: {
        17867001: "Cañaveral",
        17867005: "El Llano",
        17867003: "Isaza",
        17867007: "La Fe",
        17867004: "La Pradera",
        17867000: "Victoria",
      },
      17873: {
        17873001: "Alto De La Cruz - Los Cuervos",
        17873008: "Alto Villarazo",
        17873015: "Bajo Arroyo",
        17873016: "Corozal",
        17873002: "El Pindo",
        17873010: "Gallinazo",
        17873018: "Granja Agrícola La Paz",
        17873014: "La Floresta",
        17873011: "La Nueva Primavera",
        17873003: "Llanitos",
        17873007: "Miraflores",
        17873019: "Nuevo Río Claro",
        17873017: "Partidas",
        17873004: "Rioclaro",
        17873006: "San Julián",
        17873000: "Villamaría",
      },
      17877: {
        17877006: "Altos De Jaen",
        17877013: "Bosques Del Palmar",
        17877007: "El Faro",
        17877008: "El Remanso",
        17877002: "El Socorro",
        17877009: "La Esmeralda",
        17877010: "Los Alcaravanes",
        17877011: "Los Andes",
        17877012: "Quintas De Catalina",
        17877014: "Villa Del Roo",
        17877000: "Viterbo",
      },
    },
    18: {
      18001: {
        18001009: "El Para",
        18001000: "Florencia",
        18001006: "La Esperanza",
        18001007: "Norcacia",
        18001024: "Puerto Arango",
        18001004: "San Antonio De Atenas",
        18001010: "San Guillermo",
        18001005: "Santana Las Hermosas",
      },
      18029: {
        18029000: "Albania",
        18029003: "Dorado",
        18029005: "El Paraoso",
        18029004: "Versalles",
      },
      18094: {
        18094008: "Aletones",
        18094000: "Belén De Los Andaquíes",
        18094001: "El Portal La Mono",
        18094005: "Pueblo Nuevo Los Angeles",
        18094003: "Puerto Torres",
        18094009: "San Antonio De Padua",
      },
      18150: {
        18150010: "Bolivia",
        18150000: "Cartagena Del Chairá",
        18150011: "Cumarales",
        18150012: "El Cafe",
        18150005: "El Guamo",
        18150008: "Los Cristales",
        18150003: "Monserrate",
        18150013: "Napoles",
        18150014: "Peñas Blancas",
        18150004: "Peñas Coloradas",
        18150006: "Puerto Camelia",
        18150001: "Remolino Del Caguán",
        18150009: "Risaralda",
        18150002: "Santa Fe Del Caguán",
        18150007: "Santo Domingo",
      },
      18205: {
        18205000: "Curillo",
        18205002: "Novia Puerto Valdivia",
        18205003: "Palizadas",
        18205001: "Salamina",
      },
      18247: {
        18247006: "El Berlon",
        18247000: "El Doncello",
        18247001: "Maguare",
        18247007: "Peñas Negras",
        18247004: "Puerto Hungroa",
        18247002: "Puerto Manrique",
        18247005: "Rionegro",
      },
      18256: {
        18256002: "Bolivia",
        18256000: "El Paujíl",
        18256001: "Versalles",
      },
      18410: {
        18410005: "El Triunfo",
        18410000: "La Montañita",
        18410002: "La Unión Peneya",
        18410006: "Mateguadua",
        18410008: "Miramar",
        18410009: "Puerto Brasilia",
        18410010: "Puerto Gaitan",
        18410012: "Reina Baja",
        18410007: "San Isidro",
        18410001: "Santuario",
      },
      18460: {
        18460008: "Agua Blanca",
        18460007: "La Ilusion",
        18460004: "La Rastra",
        18460003: "Maticuru - Granario",
        18460000: "Milán",
        18460009: "Remolino Bajo Orteguaza",
        18460005: "Remolinos De Aricuntí",
        18460001: "San Antonio De Getucha",
      },
      18479: {
        18479000: "Morelia",
      },
      18592: {
        18592006: "La Aguililla",
        18592005: "La Esmeralda",
        18592003: "Lusitania",
        18592000: "Puerto Rico",
        18592002: "Rionegro",
        18592004: "Santana Ramos",
      },
      18610: {
        18610004: "Cristal",
        18610001: "Fraguita",
        18610003: "Sabaleta",
        18610000: "San José Del Fragua",
        18610002: "Yurayaco",
      },
      18753: {
        18753018: "Alto Palermo",
        18753003: "Balsillas (San Luis Del Oso)",
        18753004: "Campo Hermoso",
        18753001: "Ciudad Yaro",
        18753019: "Cristo Rey",
        18753020: "Delicias",
        18753006: "El Recreo",
        18753021: "El Ruby",
        18753010: "Gibraltar",
        18753002: "Guacamayas",
        18753015: "Guayabal",
        18753022: "La Cristalina",
        18753016: "La Sombra",
        18753023: "La Tunia (Caquetania)",
        18753011: "Los Pozos",
        18753024: "Luz Grande Los Angeles",
        18753025: "Playa Rica",
        18753009: "Puerto Betania",
        18753026: "Puerto Lozada",
        18753027: "Rovira",
        18753017: "San Juan De Lozada",
        18753012: "Santa Rosa",
        18753000: "San Vicente Del Caguán",
        18753007: "Tres Esquinas",
        18753013: "Troncales",
        18753028: "Yaguara Ii",
      },
      18756: {
        18756001: "Araracuara",
        18756006: "Cuemani",
        18756004: "Danubio (Campo Alegre)",
        18756010: "La Mana",
        18756007: "Mononguete",
        18756009: "Peña Roja",
        18756005: "Peñas Blancas",
        18756008: "Puerto Tejada",
        18756000: "Solano",
      },
      18785: {
        18785001: "Kilómetro 28",
        18785002: "Kilometro 30",
        18785003: "Puerto Bolivia",
        18785004: "Sinao",
        18785000: "Solita",
        18785005: "Union Sincelejo",
      },
      18860: {
        18860002: "Kilómetro 18",
        18860005: "Playa Rica",
        18860001: "Santiago De La Selva",
        18860000: "Valparaíso",
      },
    },
    19: {
      19001: {
        19001001: "Cajete",
        19001002: "Calibío",
        19001027: "Crucero De Puelenje",
        19001036: "El Arenal",
        19001024: "El Canelo",
        19001003: "El Charco",
        19001028: "El Salvador",
        19001021: "El Sendero",
        19001029: "El Túnel",
        19001006: "Figueroa",
        19001037: "Jardines De Paz",
        19001007: "Julumito",
        19001030: "Julumito Alto",
        19001031: "La Cabuyera",
        19001038: "La Fortaleza",
        19001033: "Lame",
        19001023: "La Meseta",
        19001032: "La Playa",
        19001008: "La Rejoya",
        19001010: "Las Mercedes",
        19001011: "Las Piedras",
        19001009: "La Yunga",
        19001039: "Parcelacion Atardeceres De La Pradera",
        19001025: "Poblazón",
        19001000: "Popayán",
        19001013: "Pueblillo Alto",
        19001014: "Puelenje",
        19001034: "Río Blanco",
        19001016: "Samanga",
        19001026: "Samuel Silverio",
        19001017: "San Rafael",
        19001018: "Santa Barbara",
        19001019: "Santa Rosa",
        19001035: "Vereda De Torres",
      },
      19022: {
        19022000: "Almaguer",
        19022001: "Caquiona",
        19022014: "La Honda",
        19022004: "Llacuanas",
        19022013: "San Jorge Herradura",
        19022009: "Saujo",
        19022003: "Tablón",
      },
      19050: {
        19050000: "Argelia",
        19050003: "Betania",
        19050011: "Botafogo",
        19050012: "Cristales Alto",
        19050005: "El Diviso",
        19050001: "El Mango",
        19050010: "El Naranjal",
        19050013: "El Plan",
        19050006: "El Plateado",
        19050002: "La Belleza",
        19050014: "Las Perlas",
        19050015: "Puente Tierra",
        19050016: "Puerto Rico",
        19050017: "San Juan Guadua",
        19050007: "Sinaí",
      },
      19075: {
        19075000: "Balboa",
        19075010: "Bermeja Alta",
        19075009: "El Vijal",
        19075007: "Guadalito",
        19075005: "La Bermeja",
        19075008: "La Lomita",
        19075001: "La Planada",
        19075002: "Olaya",
        19075011: "Paraoso",
        19075006: "Pureto",
        19075003: "San Alfonso",
      },
      19100: {
        19100000: "Bolívar",
        19100001: "Capellanoas",
        19100003: "Chalguayaco",
        19100049: "Cimarronas",
        19100005: "El Carmen",
        19100042: "El Morro",
        19100006: "El Rodeo",
        19100007: "Guachicono",
        19100041: "La Carbonera",
        19100038: "La Esperanza",
        19100013: "Lerma",
        19100015: "Los Milagros",
        19100016: "Los Rastrojos",
        19100018: "Melchor",
        19100019: "San Jose Del Morro",
        19100020: "San Juan",
        19100021: "San Lorenzo",
        19100022: "San Miguel",
        19100044: "Villa Nueva",
      },
      19110: {
        19110026: "Bellavista",
        19110000: "Buenos Aires",
        19110013: "El Ceral",
        19110020: "El Llanito",
        19110006: "El Porvenir",
        19110007: "Honduras",
        19110008: "La Balsa",
        19110029: "La Esperanza",
        19110017: "La Ventura",
        19110030: "Munchique",
        19110025: "Paloblanco",
        19110016: "San Francisco",
        19110010: "San Ignacio",
        19110012: "Timba",
      },
      19130: {
        19130000: "Cajibío",
        19130001: "Campoalegre",
        19130015: "Casas Bajas",
        19130002: "Chaux",
        19130003: "Dinde",
        19130004: "El Carmelo",
        19130016: "El Recuerdo",
        19130005: "El Rosario",
        19130013: "El Tunel",
        19130006: "La Capilla",
        19130007: "La Pedregosa",
        19130008: "La Venta",
        19130010: "Ortega",
      },
      19137: {
        19137011: "Andalucoa",
        19137000: "Caldono",
        19137001: "Cerro Alto",
        19137002: "El Pital",
        19137012: "Granadillo",
        19137003: "La Aguada",
        19137004: "Pescador",
        19137005: "Pioya",
        19137006: "Plan De Zuñiga",
        19137007: "Pueblo Nuevo",
        19137008: "Siberia",
      },
      19142: {
        19142057: "Altamira",
        19142032: "Alto El Palo",
        19142034: "Bodega Arriba",
        19142046: "Caicedo",
        19142000: "Caloto",
        19142001: "Campo Alegre",
        19142012: "Carpintero Chivera",
        19142030: "Crucero De Gualí",
        19142058: "Dominga Alta",
        19142013: "El Alba (Paraiso, Los Mangos)",
        19142059: "El Arrayan",
        19142060: "El Carmelo",
        19142061: "El Chocho",
        19142047: "El Credo",
        19142039: "El Guasimo",
        19142038: "El Nilo",
        19142004: "El Palo",
        19142026: "El Pedregal",
        19142056: "El Pilamo",
        19142005: "El Placer",
        19142062: "El Poblado",
        19142063: "El Socorro",
        19142055: "El Vergel",
        19142048: "Guabito",
        19142064: "Guadualito",
        19142065: "Guataba",
        19142007: "Huasano",
        19142031: "Huellas",
        19142015: "La Arrobleda",
        19142066: "La Buitrera",
        19142067: "La Cuchilla",
        19142049: "La Estrella",
        19142068: "La Guinea",
        19142009: "La Palomera",
        19142010: "La Placa",
        19142069: "La Quebrada",
        19142072: "Las Aguas",
        19142070: "La Selva",
        19142071: "La Trampa",
        19142024: "Loma Pelada",
        19142050: "Lopez Adentro",
        19142073: "Los Chorros",
        19142074: "Marañon",
        19142051: "Morales",
        19142075: "Morales Arrozal",
        19142052: "Napoles",
        19142053: "Pajarito",
        19142076: "Porvenir",
        19142011: "Quintero",
        19142016: "San Nicolas",
        19142017: "Santa Rita",
        19142044: "Santa Rosa",
        19142054: "Tierrero",
        19142045: "Toez",
        19142077: "Venadillo",
        19142078: "Vista Hermosa",
      },
      19212: {
        19212000: "Corinto",
        19212009: "El Barranco",
        19212001: "El Jagual",
        19212011: "El Pedregal",
        19212012: "La Laguna",
        19212013: "La Maroa",
        19212004: "Media Naranja",
        19212010: "Quebraditas",
        19212005: "Rionegro",
        19212008: "San Rafael",
      },
      19256: {
        19256001: "Alto Del Rey",
        19256002: "Baraya",
        19256003: "Betania",
        19256031: "Buena Vista",
        19256033: "Cabuyal",
        19256041: "Calichales",
        19256005: "Chapa",
        19256006: "Chisquoo",
        19256004: "Cuatro Esquinas",
        19256034: "El Crucero Del Pueblo",
        19256042: "El Higueron",
        19256043: "El Ochentayuno (La Gallera)",
        19256007: "El Placer",
        19256040: "El Retiro",
        19256000: "El Tambo",
        19256009: "El Zarzal",
        19256010: "Gamboa",
        19256044: "Guazaravita",
        19256012: "Huisito",
        19256053: "Juana Castaña",
        19256013: "La Alianza",
        19256045: "La Banda",
        19256046: "La Cuchilla",
        19256030: "La Laguna",
        19256056: "La Paloma",
        19256014: "La Paz",
        19256035: "La Romelia",
        19256032: "Las Botas",
        19256048: "Las Huertas",
        19256047: "Las Piedras",
        19256054: "La Ventana",
        19256015: "Los Anayes",
        19256016: "Los Andes",
        19256017: "Los Angeles",
        19256049: "Los Llanos",
        19256055: "Monterredondo",
        19256018: "Mosquera",
        19256051: "Murgueitio",
        19256011: "Nueva Granada",
        19256019: "Pandiguando",
        19256052: "Pepital",
        19256020: "Piagua",
        19256036: "Playa Rica",
        19256037: "Puerta Llave",
        19256021: "Puerto Rico",
        19256022: "Quilcace",
        19256023: "Rondon",
        19256038: "Roo Hondo",
        19256050: "Roo Sucio",
        19256024: "Sabanetas",
        19256057: "San Antonio",
        19256025: "San Joaquon",
        19256026: "San Juan De Mechenque",
        19256027: "Seguengue",
        19256028: "Uribe",
        19256039: "Veinte De Julio",
        19256029: "Villa Al Mar",
      },
      19290: {
        19290001: "El Rosario",
        19290000: "Florencia",
        19290002: "Marsella",
      },
      19300: {
        19300001: "El Centro",
        19300000: "Guachené",
        19300002: "Veredas Nororientales",
        19300003: "Veredas Unidas",
      },
      19318: {
        19318001: "Alfonso Lopez (Balsitas)",
        19318027: "Belen",
        19318002: "Benjamon Herrera (San Vicente)",
        19318016: "Boca De Napo",
        19318003: "Callelarga",
        19318020: "Cascajero",
        19318021: "Chamon",
        19318022: "Chanzara",
        19318025: "Chuare",
        19318004: "Concepcion De Guajuo",
        19318017: "El Atajo",
        19318005: "El Carmelo",
        19318000: "Guapí",
        19318023: "Las Junta",
        19318007: "La Soledad",
        19318008: "Limones",
        19318018: "Llanton",
        19318010: "Olaya Herrera (Guare)",
        19318024: "Quiroga",
        19318011: "Rosario",
        19318012: "San Agustín",
        19318013: "San Antonio De Guajuo",
        19318014: "San Francisco",
        19318026: "San José De Guapi",
        19318019: "Santa Clara",
        19318015: "Uribe Uribe (El Naranjo)",
      },
      19355: {
        19355001: "Calderas",
        19355012: "Carmen De Viboral",
        19355011: "Guanacas",
        19355000: "Inzá",
        19355013: "Los Alpes",
        19355002: "Pedregal",
        19355003: "Puerto Valencia",
        19355004: "San Andrés",
        19355005: "San Jose",
        19355014: "San Miguel",
        19355006: "Santa Rosa",
        19355010: "Santa Teresa",
        19355015: "Segovia",
        19355007: "Topa",
        19355008: "Tumbichucue",
        19355009: "Turminá",
      },
      19364: {
        19364000: "Jambaló",
        19364005: "La Esperanza",
        19364001: "La Maroa - El Trapiche",
        19364002: "La Mina",
        19364006: "Loma Gruesa",
        19364003: "Loma Redonda",
        19364004: "Valles Hondos",
      },
      19392: {
        19392005: "La Cuchilla",
        19392000: "La Sierra",
      },
      19397: {
        19397012: "Albania",
        19397001: "Altamira",
        19397002: "Arbela",
        19397018: "Barbillas",
        19397013: "Chaupiloma",
        19397014: "Dominical",
        19397003: "El Diviso",
        19397004: "El Palmar",
        19397005: "Guachicono",
        19397015: "La Carrera",
        19397000: "La Vega",
        19397016: "Los Ciruelos",
        19397006: "Los Uvos",
        19397007: "Pancitará",
        19397008: "Placer",
        19397009: "San Miguel",
        19397010: "Santa Bárbara",
        19397011: "Santa Juana",
        19397019: "Santa Rita",
        19397017: "Villa Maroa",
      },
      19418: {
        19418001: "Agua Clara (Golondro)",
        19418002: "Alto De Chuare",
        19418028: "Alto Naya",
        19418021: "Bajo Siguo",
        19418024: "Betania",
        19418033: "Boca Grande",
        19418034: "Brazo De La Rotura",
        19418025: "Cacao",
        19418026: "Calle Larga - Naya",
        19418020: "Candelaria",
        19418027: "Chiguero",
        19418022: "El Coco",
        19418003: "El Playon (Siguo)",
        19418004: "El Trapiche",
        19418029: "Eltrueno",
        19418005: "Guayabal",
        19418006: "Jolo",
        19418007: "La Concepcion",
        19418008: "La Sagrada Familia",
        19418030: "Las Cruces",
        19418031: "Las Pavas",
        19418000: "López",
        19418009: "Noanamito",
        19418010: "Playa Grande",
        19418011: "Roo Maya (Dos Quebradas)",
        19418012: "San Antonio De Chuare",
        19418032: "San Antonio De Gurumendy",
        19418013: "San Bartolo",
        19418014: "San Fernando",
        19418023: "San Francisco Adentro",
        19418015: "San Isidro",
        19418016: "San Pedro De Naya",
        19418017: "Santa Ana",
        19418035: "Santa Cruz De Siguín",
        19418018: "Taparal",
        19418019: "Zaragoza",
      },
      19450: {
        19450001: "Alto De Mayo",
        19450002: "Arboledas",
        19450009: "Cajamarca",
        19450015: "El Bado",
        19450017: "El Carbonero",
        19450003: "El Pilon",
        19450004: "Esmeraldas",
        19450020: "Los Llanos",
        19450021: "Los Medios",
        19450000: "Mercaderes",
        19450019: "Mojarras",
        19450006: "San Joaquín",
        19450007: "San Juanito",
        19450016: "Tabloncito",
        19450008: "Tablones Altos",
      },
      19455: {
        19455010: "Guatemala",
        19455003: "La Munda",
        19455000: "Miranda",
        19455004: "Monterredondo",
        19455005: "Ortigal",
        19455011: "San Andres",
        19455007: "Santa Ana",
        19455008: "Tierradura",
        19455009: "Tulipan",
      },
      19473: {
        19473002: "Carpintero",
        19473003: "Chimborazo",
        19473017: "El Rosario",
        19473004: "Honduras",
        19473014: "La Estacion",
        19473000: "Morales",
        19473008: "Pan De Azucar",
        19473009: "San Isidro",
        19473012: "Santa Rosa",
      },
      19513: {
        19513008: "Cuernavaca",
        19513004: "El Chamizo",
        19513003: "La Paila",
        19513000: "Padilla",
        19513001: "Yarumales",
      },
      19517: {
        19517023: "Aranzazu",
        19517001: "Araujo",
        19517002: "Avirama",
        19517000: "Belalcázar Paez",
        19517031: "Chachucue",
        19517004: "Chinas",
        19517003: "Cohetando",
        19517030: "Coquiyo",
        19517028: "Cuetando",
        19517024: "El Cabuyo",
        19517005: "El Colorado",
        19517032: "El Rodeo",
        19517033: "Guadualejo",
        19517034: "Guapio",
        19517035: "Guaquiyo",
        19517006: "Huila",
        19517007: "Itaibe",
        19517025: "La Ceja",
        19517036: "La Esmeralda",
        19517037: "La Maroa",
        19517009: "Lame",
        19517017: "La Mesa De Togoima",
        19517010: "La Palma",
        19517022: "La Troja",
        19517038: "Laureles",
        19517039: "Mesa De Caloto",
        19517040: "Mesa De Talaga",
        19517011: "Mosoco",
        19517012: "Ricaurte",
        19517013: "Riochiquito",
        19517026: "San Jose",
        19517014: "San Luis (Potrerillo)",
        19517041: "San Miguel",
        19517015: "Talaga",
        19517042: "Talaga Viejo",
        19517027: "Taravira",
        19517016: "Tóez",
        19517043: "Vicanenga",
        19517018: "Villa Rodroguez",
        19517019: "Vitonco",
      },
      19532: {
        19532016: "Angulo",
        19532026: "Betania",
        19532001: "Brisas",
        19532003: "Don Alonso",
        19532000: "El Bordo",
        19532013: "El Estrecho",
        19532014: "El Hoyo",
        19532018: "El Placer",
        19532019: "El Puro",
        19532004: "Galíndez",
        19532031: "Guayabal",
        19532005: "La Fonda",
        19532006: "La Mesa",
        19532007: "Las Tallas",
        19532028: "Mendez",
        19532010: "Pan De Azúcar",
        19532008: "El Patía",
        19532009: "Piedrasentada",
        19532029: "Quebrada Oscura",
        19532022: "Sachamates",
        19532012: "Sajando",
        19532025: "Santa Cruz",
        19532023: "Santa Rosa Baja",
        19532024: "Versalles",
      },
      19533: {
        19533001: "Campo Alegre",
        19533002: "El Remanso",
        19533003: "Miraflor",
        19533000: "Piamonte",
        19533004: "Yapura",
      },
      19548: {
        19548000: "Piendamó",
        19548001: "Tunía",
      },
      19573: {
        19573001: "Bocas Del Palo",
        19573002: "Las Brisas",
        19573005: "Perico Negro",
        19573000: "Puerto Tejada",
        19573003: "San Carlos",
        19573004: "Zanjon Rico",
      },
      19585: {
        19585000: "Coconuco",
        19585009: "Paletara",
        19585004: "Puracé",
        19585007: "Santa Leticia",
      },
      19622: {
        19622007: "Cefiro",
        19622008: "Diviso",
        19622001: "Gualoto",
        19622005: "Lomabajo",
        19622009: "Lomagrande",
        19622006: "Lombajo",
        19622010: "Marques",
        19622003: "Marquez",
        19622002: "Párraga",
        19622000: "Rosas",
        19622011: "Sauce",
        19622004: "Ufugu",
      },
      19693: {
        19693001: "El Rosal",
        19693002: "Marmato",
        19693003: "Paramillos",
        19693000: "San Sebastián",
        19693004: "Santiago",
        19693005: "Valencia",
        19693006: "Venecia",
      },
      19698: {
        19698015: "Agua Azul",
        19698043: "Bellavista",
        19698024: "Cambalache",
        19698017: "Dominguillo",
        19698025: "El Broche",
        19698018: "El Crucero",
        19698026: "El Llanito",
        19698001: "El Palmar",
        19698027: "El Tajo",
        19698002: "El Turco",
        19698028: "La Agustina",
        19698004: "La Arrobleda",
        19698029: "La Capilla",
        19698030: "La Chapa",
        19698031: "La Palomera",
        19698032: "La Quebrada",
        19698022: "Llano De Alegrías",
        19698033: "Lomitas Abajo",
        19698034: "Lomitas Arriba",
        19698035: "Lourdes",
        19698007: "Mondomo",
        19698008: "Paramillo",
        19698020: "Quinamayo",
        19698013: "San Antonio",
        19698037: "San José",
        19698014: "San Pedro",
        19698009: "San Rafael",
        19698000: "Santander De Quilichao",
        19698010: "Tres Quebradas",
      },
      19701: {
        19701014: "Curiaco",
        19701001: "Descanse",
        19701002: "El Carmelo",
        19701012: "San Juan",
        19701006: "San Juan De Villalobos",
        19701004: "Santa Clara",
        19701005: "Santa Martha",
        19701000: "Santa Rosa",
        19701015: "Sector Mandiyaco",
        19701013: "Villa Mosquera",
      },
      19743: {
        19743015: "Alto Grande",
        19743009: "El Cacique",
        19743022: "El Salado",
        19743001: "Guambia",
        19743016: "La Aguada",
        19743008: "La Campana",
        19743007: "La Estrella",
        19743017: "Laguna Seca",
        19743021: "La Ovejera",
        19743018: "Loma Del Carmen",
        19743011: "Mendez",
        19743020: "Michambe",
        19743012: "Miraflores",
        19743002: "Pitayo",
        19743004: "Pueblecito",
        19743010: "Puente Real",
        19743003: "Quichayá",
        19743019: "San Antonio",
        19743013: "San Pedro Del Bosque",
        19743000: "Silvia",
        19743014: "Tumburao",
        19743005: "Usenda",
        19743006: "Valle Nuevo",
      },
      19760: {
        19760008: "Buenavista",
        19760001: "Chapa",
        19760002: "Chiriboo",
        19760010: "El Carmen",
        19760003: "El Crucero",
        19760004: "Hatofroo",
        19760005: "La Paz",
        19760000: "Paispamba Sotara",
        19760006: "Piedra De Leon",
        19760011: "Puebloquemado",
        19760007: "Río Blanco",
        19760009: "Sachacoco",
      },
      19780: {
        19780010: "Agua Clara Canelar",
        19780003: "Asnazu",
        19780006: "Bellavista",
        19780001: "El Hato",
        19780004: "Gelima La Toma",
        19780008: "La Betulia",
        19780002: "La Meseta",
        19780007: "La Roma",
        19780005: "Matecana",
        19780009: "Mindala",
        19780000: "Suárez",
      },
      19785: {
        19785002: "Cascadas",
        19785003: "Crucero Bello",
        19785004: "El Carmelo",
        19785005: "El Fresno",
        19785001: "El Paraoso",
        19785006: "El Retiro",
        19785007: "El Tesoro",
        19785008: "La Ceja",
        19785009: "La Cumbre",
        19785010: "Mazamorras",
        19785000: "Sucre",
        19785011: "Tequendama",
      },
      19807: {
        19807013: "Antomoreno",
        19807001: "Camposano",
        19807002: "Cincodias",
        19807007: "Cruces",
        19807014: "La Martica",
        19807015: "Santa Maroa",
        19807000: "Timbío",
      },
      19809: {
        19809024: "Angostura",
        19809011: "Boca De Patía",
        19809015: "Brazo Corto",
        19809001: "Bubuey",
        19809022: "Cabecital",
        19809002: "Camarones",
        19809014: "Chacon",
        19809010: "Chete",
        19809021: "Corozal",
        19809003: "Coteje",
        19809018: "Cupi",
        19809012: "El Charco",
        19809004: "El Cuerval",
        19809013: "El Realito",
        19809025: "Guangui",
        19809016: "Info",
        19809017: "Llano Grande",
        19809005: "Pete",
        19809023: "Puerto Saija",
        19809006: "San Bernardo",
        19809007: "San José",
        19809020: "San Miguel",
        19809008: "Santa María",
        19809009: "Santa Rosa De Saija",
        19809019: "Soledad De Yanton",
        19809000: "Timbiquí",
      },
      19821: {
        19821004: "Natala",
        19821005: "San Francisco",
        19821006: "Santo Domingo",
        19821007: "Tacueyo",
        19821000: "Toribío",
      },
      19824: {
        19824002: "Gabriel López",
        19824004: "Paniquitá",
        19824005: "Polindara",
        19824000: "Totoró",
      },
      19845: {
        19845001: "Agua Azul",
        19845002: "Cantarito",
        19845003: "Chalo",
        19845004: "Gallinaza",
        19845005: "Juan Ignacio",
        19845006: "Primavera",
        19845000: "Villa Rica",
      },
    },
    20: {
      20001: {
        20001001: "Aguas Blancas",
        20001037: "Alto De La Vuelta",
        20001002: "Atanquez",
        20001027: "Azúcar Buena (La Mesa)",
        20001003: "Badillo",
        20001019: "Camperucho",
        20001005: "Caracolí",
        20001006: "Chemesquemena",
        20001036: "El Jabo",
        20001046: "El Mangon",
        20001047: "El Mojao",
        20001009: "Guacoche",
        20001022: "Guacochito",
        20001010: "Guaimaral",
        20001007: "Guatapurí",
        20001011: "La Mina",
        20001042: "Las Casitas",
        20001040: "Las Mercedes",
        20001028: "Las Raíces",
        20001024: "Los Calabazos",
        20001025: "Los Corazones",
        20001026: "Los Haticos",
        20001012: "Los Venados",
        20001013: "Mariangola",
        20001044: "Maruamaque",
        20001014: "Patillal",
        20001045: "Ponton",
        20001048: "Ramalito",
        20001030: "Rancho De Goya",
        20001031: "Río Seco",
        20001041: "Sabana Crespo",
        20001049: "Sabanitas",
        20001039: "San Martín (El Perro)",
        20001018: "Valencia De Jesus",
        20001000: "Valledupar",
        20001032: "Vega Arriba",
        20001034: "Villa Germania",
        20001050: "Villa Ramorez",
        20001051: "Villa Rueda",
      },
      20011: {
        20011000: "Aguachica",
        20011001: "Barrancalebrija",
        20011010: "Buturama",
        20011018: "Campo Amalia",
        20011015: "Cerro Bravo",
        20011025: "El Juncal",
        20011026: "La Campana",
        20011006: "Loma De Corredor",
        20011011: "Norean",
        20011009: "Puerto Patiño",
        20011012: "Santa Lucía",
        20011014: "Villa De San Andrés",
      },
      20013: {
        20013000: "Agustín Codazzi",
        20013002: "Casacaza",
        20013003: "Llerasca",
        20013006: "Punta Arrecha",
        20013001: "San Jacinto",
        20013007: "San Ramón",
        20013004: "Sicarare",
      },
      20032: {
        20032001: "Arjona",
        20032000: "Astrea",
        20032006: "El Hebrón",
        20032007: "El Jobo",
        20032003: "El Yucal",
        20032005: "Santa Cecilia",
      },
      20045: {
        20045000: "Becerril",
        20045003: "Campo-Alegre-Arzario",
        20045001: "Estados Unidos",
        20045004: "La Guajirita",
        20045002: "Tamaquito",
      },
      20060: {
        20060005: "Alto De Minas",
        20060002: "Bocas De Tigre",
        20060000: "Bosconia",
        20060006: "El Eden",
        20060007: "El Tropezon",
        20060001: "Las Pavas",
        20060004: "Loma Colorada",
        20060008: "Loma Linda",
        20060009: "Nueva Idea",
        20060010: "Puerto Lajas",
      },
      20175: {
        20175023: "Betel",
        20175024: "Buenos Aires",
        20175004: "Candelaria",
        20175000: "Chimichagua",
        20175025: "Cuatro Esquinas",
        20175026: "Dardanelos Dos",
        20175017: "El Canal",
        20175005: "El Guamo",
        20175027: "El Trebol De Pajonal",
        20175022: "Higo Amarillo",
        20175028: "La Brillantina",
        20175016: "La Mata",
        20175008: "Las Flores",
        20175009: "Las Vegas",
        20175010: "Mandinguilla",
        20175029: "Piedras Blancas",
        20175020: "Plata Perdida",
        20175030: "Pueblito",
        20175011: "Saloa",
        20175018: "Santo Domingo",
        20175013: "Sempegua",
        20175014: "Soledad",
        20175019: "Tronconal",
        20175031: "Ultimo Caso",
        20175032: "Zapati",
      },
      20178: {
        20178017: "Agua Fría",
        20178019: "Arenas Blancas",
        20178000: "Chiriguaná",
        20178018: "El Cruce De La Sierra",
        20178015: "Estación Chiriguaná",
        20178014: "La Aurora",
        20178020: "La Estacion",
        20178016: "La Sierra",
        20178021: "Los Cerrajones",
        20178022: "Ojo De Agua",
        20178006: "Poponte",
        20178008: "Rincón Hondo",
      },
      20228: {
        20228005: "Champán",
        20228012: "Chinela",
        20228000: "Curumaní",
        20228011: "El Mamey",
        20228007: "Guaimaral",
        20228009: "Hojancha",
        20228013: "Los Serenos",
        20228001: "Sabanagrande",
        20228002: "San Roque",
        20228003: "San Sebastián",
        20228004: "Santa Isabel",
      },
      20238: {
        20238002: "Caracolicito",
        20238003: "Chimila",
        20238000: "El Copey",
        20238004: "San Francisco De Asís",
      },
      20250: {
        20250004: "Cuatro Vientos",
        20250006: "El Carmen",
        20250000: "El Paso",
        20250001: "El Vallito",
        20250002: "La Loma",
        20250003: "Potrerillo",
        20250005: "Pueblo Regado",
      },
      20295: {
        20295007: "Cascajal",
        20295002: "El Contento",
        20295000: "Gamarra",
        20295001: "La Estación",
        20295008: "Mahoma",
        20295004: "Palenquillo",
        20295009: "Puerto Capulco",
        20295005: "Puerto Mosquito",
        20295006: "Puerto Viejo",
      },
      20310: {
        20310008: "Bijagual",
        20310001: "Bujaravita",
        20310002: "Búrbura",
        20310003: "Culebrita",
        20310004: "El Potrero",
        20310000: "González",
        20310009: "Mata De Fique",
        20310006: "Montera",
        20310007: "San Isidro",
      },
      20383: {
        20383001: "Ayacucho",
        20383006: "Besote",
        20383007: "Bubeta",
        20383002: "Carolina",
        20383011: "Estación Ferrocarril",
        20383000: "La Gloria",
        20383010: "La Mata",
        20383003: "Molina",
        20383004: "San Pablo",
        20383005: "Simaña",
      },
      20400: {
        20400003: "Boquerón",
        20400000: "La Jagua De Ibirico",
        20400001: "Las Palmitas",
        20400002: "La Victoria De San Isidro",
      },
      20443: {
        20443000: "Manaure Balcón Del Cesar",
      },
      20517: {
        20517006: "El Burro",
        20517001: "La Floresta",
        20517005: "Los Llanos",
        20517000: "Pailitas",
        20517004: "Palestina",
        20517002: "Rivera",
      },
      20550: {
        20550001: "Costilla",
        20550000: "Pelaya",
        20550012: "San Bernardo",
      },
      20570: {
        20570001: "La Caja",
        20570002: "Las Minas De Iracal",
        20570004: "Nabusimake (San Sebastian)",
        20570003: "Palmarito (Nuevo Colón)",
        20570000: "Pueblo Bello",
      },
      20614: {
        20614001: "El M Arquéz",
        20614004: "Los Angeles",
        20614006: "Montecitos",
        20614012: "Morrison",
        20614010: "Puerto Nuevo",
        20614000: "Río De Oro",
      },
      20621: {
        20621001: "Los Encantos",
        20621000: "La Paz",
        20621006: "San José Del Oriente",
        20621012: "San José De Oriente - Betania",
        20621011: "Varas Blancas",
      },
      20710: {
        20710009: "La Carolina",
        20710001: "La Llana",
        20710002: "La Palma",
        20710010: "La Trinidad",
        20710005: "Líbano",
        20710011: "Los Ortegas",
        20710008: "Puerto Carreño",
        20710000: "San Alberto",
      },
      20750: {
        20750005: "El Juncal",
        20750006: "El Rincón",
        20750007: "Las Pitillas",
        20750009: "Los Brasiles",
        20750001: "Los Tupes",
        20750002: "Media Luna",
        20750012: "Nuevas Flores",
        20750000: "San Diego",
        20750011: "Tocaimo",
      },
      20770: {
        20770001: "Aguas Blancas",
        20770013: "Buenos Aires",
        20770006: "Candelia",
        20770010: "Cuatro Bocas",
        20770002: "El Barro",
        20770014: "El Diviso",
        20770008: "La Curva",
        20770015: "Las Salinas",
        20770011: "Los Bagres",
        20770003: "Minas",
        20770012: "Pita Limón",
        20770004: "Puerto Oculto",
        20770005: "San José De Las Américas",
        20770000: "San Martín",
        20770007: "Terraplen",
        20770009: "Torcoroma",
      },
      20787: {
        20787007: "Antequera",
        20787002: "La Boca",
        20787011: "Las Brisas",
        20787009: "Las Palmas",
        20787014: "Mundo Alrevez",
        20787001: "Palestina La Nueva",
        20787012: "Pasacorriendo",
        20787013: "Pueblonuevo",
        20787000: "Tamalameque",
        20787005: "Zapatosa",
      },
    },
    23: {
      23001: {
        23001054: "Agua Vivas",
        23001036: "Boca De La Ceiba",
        23001037: "Broquelito",
        23001001: "Buenos Aires",
        23001028: "El Barsal",
        23001003: "El Cerrito",
        23001030: "El Cocuelo",
        23001068: "El Doce",
        23001038: "El Limón",
        23001039: "El Quince",
        23001004: "El Sabanal",
        23001040: "El Vidrial",
        23001041: "Ensenada De La Hamaca",
        23001042: "Galilea",
        23001005: "Guasimal",
        23001032: "Guateque",
        23001006: "Jaraquiel",
        23001043: "La Esperanza",
        23001044: "La Florida",
        23001007: "La Manta",
        23001064: "La Poza",
        23001008: "Las Palomas",
        23001034: "La Victoria",
        23001009: "Leticia",
        23001010: "Loma Verde",
        23001011: "Los Garzones",
        23001045: "Maquencal",
        23001046: "Maracayo",
        23001031: "Martinica",
        23001047: "Matamoros",
        23001048: "Mochilas",
        23001000: "Montería",
        23001035: "Morindo Central",
        23001029: "Nueva Esperanza",
        23001013: "Nueva Lucía",
        23001012: "Nuevo Paraíso",
        23001049: "Nuevos Horizontes",
        23001050: "Palmito Picao",
        23001002: "Palotal",
        23001014: "Patio Bonito",
        23001051: "Pereira",
        23001067: "Piñalito",
        23001016: "Pueblo Buho",
        23001069: "Pueblo Seco",
        23001017: "San Anterito",
        23001070: "San Francisco",
        23001015: "San Isidro",
        23001018: "Santa Clara",
        23001019: "Santa Isabel",
        23001020: "Santa Lucía",
        23001033: "Tenerife",
        23001021: "Tres Palmas",
        23001022: "Tres Piedras",
        23001071: "Villa Cielo",
        23001052: "Villavicencio",
        23001053: "Ya La Lleva",
      },
      23068: {
        23068001: "Alfonso Lopez",
        23068000: "Ayapel",
        23068003: "Cecilia",
        23068004: "El Cedro",
        23068015: "Las Delicias",
        23068012: "Marralú",
        23068006: "Nariño",
        23068007: "Palotal",
        23068014: "Pueblo Nuevo - Popales",
        23068009: "Sincelejito",
      },
      23079: {
        23079003: "Belén",
        23079000: "Buenavista",
        23079008: "Cienaga De Sambo",
        23079009: "Costa Rica",
        23079007: "Cotorra",
        23079017: "Coyongo",
        23079006: "El Paraoso",
        23079012: "El Viajano",
        23079018: "Las Cruces",
        23079019: "Las Maroas",
        23079010: "Mejor Esquina",
        23079004: "Nueva Estación",
        23079005: "Puerto Córdoba",
        23079015: "Santa Clara",
        23079016: "Santa Fe Del Arcial",
        23079021: "Tequendama",
        23079001: "Tierra Santa",
        23079014: "Veracruz",
        23079002: "Villa Fátima",
      },
      23090: {
        23090022: "Buenos Aires - Las Pavas",
        23090007: "Cadillo",
        23090000: "Canalete Urbano",
        23090008: "Cordobita Central",
        23090009: "Cordobita Frontera",
        23090013: "El Guineo",
        23090001: "El Limón",
        23090023: "El Tomate",
        23090014: "La Alcancia",
        23090016: "La Lorenza",
        23090019: "Paso Del Mono",
        23090002: "Popayán",
        23090021: "Quebrada De Urango",
        23090024: "Si Se Van",
        23090020: "Tierradentro",
      },
      23162: {
        23162025: "Buenavista Del Retiro",
        23162026: "Buenavista El Quemado",
        23162000: "Cereté",
        23162005: "Cuero Curtido",
        23162027: "El Carmen",
        23162018: "El Quemado",
        23162020: "Los Venados",
        23162001: "Martínez",
        23162002: "Mateo Gómez",
        23162028: "Oriente",
        23162029: "Playarica",
        23162003: "Rabolargo",
        23162006: "Retiro De Los Indios",
        23162012: "San Antonio",
        23162004: "Severa",
        23162024: "Tres Maria",
        23162030: "Venado-Campanito",
      },
      23168: {
        23168001: "Arache",
        23168002: "Campo Bello",
        23168003: "Carolina",
        23168000: "Chimá",
        23168004: "Corozalito",
        23168011: "El Brillante",
        23168009: "La Laguna",
        23168014: "Pimental",
        23168005: "Punta Verde",
        23168008: "Sabana Costa",
        23168006: "Sitio Viejo",
      },
      23182: {
        23182001: "Aguas Vivas",
        23182020: "Andalucía",
        23182028: "Arrimadero",
        23182043: "Bajo De Piedra",
        23182044: "Bleo",
        23182029: "Boca De Monte",
        23182002: "Cacaotal",
        23182046: "Canoa",
        23182045: "Cañahuate",
        23182003: "Carbonero",
        23182030: "Ceja Grande",
        23182000: "Chinú",
        23182026: "Chorrillo",
        23182038: "El Brillante",
        23182027: "El Deseo",
        23182039: "El Orozco",
        23182004: "El Pital",
        23182022: "El Tigre",
        23182047: "El Trebol",
        23182015: "Flechas Sabanas",
        23182013: "Flechas Sevilla",
        23182040: "Floral",
        23182016: "Garbado",
        23182005: "Heredia",
        23182048: "Jaraba",
        23182049: "Jerico",
        23182017: "La Panamá",
        23182018: "La Pilona",
        23182033: "Las Jarabas",
        23182032: "Las Lomas",
        23182041: "Lomas De Piedra",
        23182021: "Los Algarrobos",
        23182006: "Los Angeles",
        23182034: "Nova",
        23182007: "Nuevo Oriente",
        23182035: "Pajonal",
        23182023: "Palmital",
        23182031: "Paraoso",
        23182050: "Piñuelas",
        23182036: "Pisa Bonito",
        23182051: "Raicero",
        23182052: "Raizal",
        23182019: "Retiro De Los Pérez",
        23182008: "San Mateo",
        23182037: "San Quirre",
        23182009: "San Rafael",
        23182010: "Santa Cecilia",
        23182011: "Santa Fe",
        23182012: "Santa Rosa",
        23182042: "Termoelectrica",
        23182014: "Tierra Grata",
        23182024: "Villa Fatima",
      },
      23189: {
        23189030: "Barro Prieto",
        23189001: "Berástegui",
        23189010: "Charcon",
        23189000: "Ciénaga De Oro",
        23189029: "Egipto",
        23189011: "El Bobo",
        23189031: "El Guaimaro",
        23189027: "El Higal",
        23189016: "El Salado",
        23189002: "El Siglo",
        23189032: "El Templo",
        23189033: "La Arena",
        23189020: "La Barra",
        23189034: "La Draga",
        23189035: "La Gloria",
        23189003: "Laguneta",
        23189036: "La Saca",
        23189028: "Las Balsas",
        23189026: "Las Palmas",
        23189025: "Las Palmitas",
        23189037: "Las Palomas",
        23189018: "Las Piedras",
        23189004: "Los Mimbres",
        23189015: "Los Venados",
        23189008: "Malagana",
        23189038: "Mayoroa",
        23189039: "Noche Azul",
        23189019: "Pijiguayal",
        23189040: "Playa Blanca",
        23189041: "Poza Redonda",
        23189007: "Puerto De La Cruz",
        23189005: "Punta De Yáñez",
        23189024: "Rosa Vieja",
        23189014: "Salguero",
        23189023: "San Antonio Del Táchira",
        23189042: "Santiago Arriba",
        23189006: "Santiago Del Sur",
        23189021: "Santiago Pobre",
        23189043: "Venado Central",
      },
      23300: {
        23300022: "Chamarra",
        23300000: "Cotorra",
        23300010: "El Binde",
        23300001: "El Bongo",
        23300023: "El Vivero",
        23300009: "Guayabal",
        23300002: "Los Gómez",
        23300024: "Monton",
        23300006: "Paso De Las Flores",
        23300025: "Playas Del Campano",
        23300026: "Pueblo Nuevo",
        23300017: "Sabanal",
        23300021: "San Jose",
        23300027: "San Pablo",
        23300016: "Trementino",
        23300007: "Villa Nueva",
      },
      23350: {
        23350015: "Campo Alegre",
        23350000: "La Apartada Y La Frontera",
        23350003: "La Balsa",
        23350011: "La Escobilla",
        23350012: "La Magdalena",
        23350009: "Las Margaritas",
        23350010: "La Virgen",
        23350016: "Los Novillos",
        23350014: "Manzanares",
        23350006: "Margen Derecha De Campo Alegre",
        23350005: "Margen Derecha De Puerto Cordoba",
        23350002: "Margen Izquierda De Campo Alegre",
        23350001: "Margen Izquierda De Puerto Cordoba",
        23350004: "Nuevo Pueblo",
        23350017: "Puerto Cordoba",
        23350013: "San Jeronimo",
        23350007: "Sitio Nuevo",
      },
      23417: {
        23417014: "Campo Alegre",
        23417038: "Candelaria",
        23417024: "Castilleral",
        23417025: "Cotoca Abajo",
        23417018: "Cotoca Arriba",
        23417017: "El Campano De Los Indios",
        23417002: "El Carito",
        23417039: "El Guanabano",
        23417012: "El Lazo",
        23417030: "El Playón",
        23417019: "El Rodeo",
        23417042: "Juan De Dios Garis",
        23417003: "La Doctrina",
        23417036: "La Palma",
        23417031: "La Peinada",
        23417026: "Las Camorras",
        23417004: "Las Flores",
        23417029: "La Subida",
        23417020: "Los Higales",
        23417006: "Los Monos",
        23417037: "Los Morales",
        23417028: "Manantial",
        23417015: "Maracayo",
        23417023: "Mata De Caña",
        23417007: "Nariño",
        23417008: "Palo De Agua",
        23417021: "Remolino",
        23417011: "San Anterito",
        23417027: "San Nicolás De Barí",
        23417009: "San Sebastián",
        23417000: "Santa Cruz De Lorica",
        23417034: "Santa Lucía",
        23417010: "Tierralta",
        23417022: "Villa Concepcion",
      },
      23419: {
        23419014: "Barrio Mamey",
        23419021: "Brisas Del Caribe",
        23419010: "Buenavista",
        23419002: "El Ebano",
        23419019: "El Guaimaro",
        23419022: "El Mamby",
        23419023: "El Oasis",
        23419013: "Jalisco",
        23419020: "La Ponderancia",
        23419011: "La Salada",
        23419024: "Las Tinas",
        23419000: "Los Córdobas",
        23419025: "Los Esquimales",
        23419026: "Minuto De Dios",
        23419027: "Morindo Central",
        23419028: "Morindo La Union",
        23419029: "Morindo Santa Cruz",
        23419012: "Morindó Santana",
        23419030: "Nuevo Nariño",
        23419004: "Puerto Rey",
        23419009: "San Rafael",
        23419005: "Santa Rosa La Caña",
      },
      23464: {
        23464007: "Betulia",
        23464004: "Cedro De Piedra",
        23464000: "Momil",
        23464008: "Pereira",
        23464005: "Pueblecito (San Miguel)",
        23464001: "Sabaneta",
        23464002: "Sacana Abajo",
        23464003: "Trementino",
      },
      23466: {
        23466013: "Bocas De Perro",
        23466023: "Córdoba",
        23466001: "El Anclar",
        23466028: "El Palmar",
        23466031: "Las Margaritas",
        23466000: "Montelíbano",
        23466014: "Moralito",
        23466008: "Pica Pica",
        23466021: "Puerto Anchica",
        23466033: "Puerto Nuevo",
        23466005: "San Francisco Del Rayo",
        23466017: "Sitio Nuevo",
        23466006: "Tierradentro",
        23466037: "Villa Carminia",
      },
      23500: {
        23500006: "Broqueles",
        23500010: "La Rada",
        23500011: "Las Mujeres",
        23500013: "Mangle",
        23500000: "Moñitos",
        23500023: "Perpetuo Socorro",
        23500024: "Pueblito",
        23500001: "Río Cedro",
        23500026: "San Rafael",
        23500002: "Santander De La Cruz",
      },
      23555: {
        23555001: "Arenoso",
        23555002: "Campo Bello",
        23555003: "Carolina",
        23555005: "El Almendro",
        23555012: "El Reparo",
        23555015: "Las Pelonas",
        23555035: "Loma De Piedra",
        23555013: "Los Cerros",
        23555006: "Marañonal",
        23555010: "Medio Rancho",
        23555019: "Nuevo Paraíso",
        23555011: "Pamplona",
        23555000: "Planeta Rica",
        23555022: "Planetica",
        23555007: "Plaza Bonita",
        23555008: "Providencia",
        23555017: "Pueblo Rizo",
        23555044: "Punta Verde",
        23555029: "San Jeronimo(Golero)",
        23555004: "Santana(Centro Alegre)",
        23555020: "Santa Rosa",
      },
      23570: {
        23570001: "Arena Del Sur",
        23570014: "Arroyo De Arena",
        23570025: "Betulia",
        23570026: "Cafe Pisao",
        23570027: "Centro America",
        23570002: "Cintura",
        23570003: "Corcovado",
        23570023: "El Arcial",
        23570020: "El Campano",
        23570016: "El Contento",
        23570005: "El Poblado",
        23570004: "El Varal",
        23570006: "La Granjita",
        23570009: "La Magdalena",
        23570028: "Loma De Piedra",
        23570007: "Los Limones",
        23570013: "Neiva",
        23570024: "Nueva Esperanza",
        23570011: "Palmira",
        23570000: "Pueblo Nuevo",
        23570021: "Pueblo Regao",
        23570022: "Puerto Leticia",
        23570008: "Puerto Santo",
      },
      23574: {
        23574005: "Arizal",
        23574008: "Caña De Canañete",
        23574001: "Cristo Rey",
        23574010: "El Contento Arriba",
        23574002: "El Pantano",
        23574011: "El Silencio",
        23574013: "Jaramagal",
        23574009: "Las Mujeres",
        23574007: "Morindo",
        23574000: "Puerto Escondido",
        23574003: "San José De Canalete",
        23574006: "San Luis",
        23574012: "San Miguel",
        23574004: "Villa Ester",
      },
      23580: {
        23580006: "Buenos Aires Abajo",
        23580012: "El Brillante",
        23580004: "Juan José",
        23580001: "La Rica",
        23580002: "Pica Pica Viejo",
        23580011: "Puerto Belén",
        23580014: "Puerto Carepa",
        23580000: "Puerto Libertador",
        23580010: "San Juan",
        23580013: "San Pedro",
        23580009: "Santa Fé Las Claras",
        23580015: "Torno Rojo",
        23580003: "Villanueva",
      },
      23586: {
        23586001: "Aserradero",
        23586002: "El Hueso",
        23586003: "Los Corales",
        23586000: "Purísima De La Concepción",
        23586004: "San Pedro De Arroyo Hondo",
      },
      23660: {
        23660015: "Aguas Vivas",
        23660063: "Alpujarra Ii",
        23660001: "Arenas Del Norte",
        23660002: "Bajo Grande",
        23660041: "Brisas Del Mar",
        23660003: "Catalina",
        23660004: "Colomboy",
        23660037: "Dividivi",
        23660005: "El Crucero",
        23660022: "El Olivo",
        23660036: "El Orgullo",
        23660025: "El Remolino",
        23660020: "El Roble",
        23660006: "El Viajano",
        23660045: "Escobalito",
        23660028: "Guáimarito",
        23660030: "Guáimaro",
        23660046: "Guayabal",
        23660070: "Guayabal La Ye",
        23660047: "Kilometro 32",
        23660048: "Kilometro 34",
        23660031: "La Balsa",
        23660064: "La Quebrada",
        23660032: "Las Aguaditas",
        23660016: "Las Bocas",
        23660026: "Las Cruces",
        23660055: "Las Cumbres",
        23660056: "Las Huertas",
        23660038: "Las Manuelitas",
        23660008: "La Ye",
        23660007: "Llanadas",
        23660065: "Los Amarillos",
        23660066: "Los Chibolos",
        23660039: "Los Galanes",
        23660057: "Los Placeres",
        23660058: "Morrocoicito",
        23660009: "Morrocoy",
        23660067: "Nueva Esperanza",
        23660059: "Patio Bonito",
        23660017: "Pisa Flores",
        23660010: "Rodania (Rodáculo)",
        23660068: "Sabana De La Fuente",
        23660014: "Sabaneta",
        23660000: "Sahagún",
        23660040: "Salguerito",
        23660011: "Salitral",
        23660034: "San Andresito",
        23660012: "San Antonio",
        23660061: "San Francisco",
        23660062: "San Miguel",
        23660013: "Santiago Abajo",
        23660029: "Trementino",
        23660069: "Trementino Bulero",
      },
      23670: {
        23670024: "Alemania",
        23670002: "Calle Larga",
        23670025: "Cruz De Guayabo",
        23670003: "El Banco",
        23670021: "El Contento",
        23670026: "El Hoyal",
        23670023: "Jejen",
        23670014: "Las Casitas",
        23670005: "Los Carretos",
        23670015: "Los Castillos",
        23670016: "Los Guayacanes",
        23670013: "Plaza Bonita",
        23670009: "Pueblecito",
        23670017: "Sabanal",
        23670000: "San Andrés De Sotavento",
        23670011: "Tuchin",
        23670018: "Villanueva",
      },
      23672: {
        23672007: "Bajo Grande",
        23672008: "Bello",
        23672006: "Bijaito",
        23672009: "Calao",
        23672010: "Cerropetrona",
        23672011: "Cispata",
        23672012: "El Naranjo",
        23672001: "El Porvenir",
        23672013: "El Progreso",
        23672014: "El Tributo",
        23672015: "Grau",
        23672016: "La Bonguita",
        23672017: "La Parrilla",
        23672018: "Las Delicias",
        23672019: "Las Nubes",
        23672020: "Leticia",
        23672002: "Los Secos",
        23672003: "Nuevo Agrado",
        23672021: "Playa Blanca",
        23672022: "Punta Bolivar",
        23672000: "San Antero",
        23672023: "San Jose",
        23672024: "Santa Cruz",
        23672004: "Santa Rosa Del Balsamo",
        23672005: "Tijeretas",
      },
      23675: {
        23675016: "Barbascal De Asturias",
        23675017: "Barcelona",
        23675018: "Camino Real",
        23675004: "Caño Grande",
        23675012: "Chiquí",
        23675019: "El Castilo",
        23675001: "José Manuel De Altamira",
        23675015: "Miramar",
        23675020: "Nueva Estrella",
        23675013: "Pajonal",
        23675003: "Paso Nuevo",
        23675005: "Playas Del Viento",
        23675000: "San Bernardo Del Viento",
        23675009: "San Blas De Junín",
        23675014: "San Jose De Las Cañas",
        23675011: "Sicara",
        23675021: "Tinajones",
        23675007: "Trementino",
        23675022: "Villa Clara",
      },
      23678: {
        23678013: "Arriba",
        23678014: "Arroyo Grande",
        23678006: "Cabuya",
        23678008: "Callemar",
        23678002: "Carrizal",
        23678009: "Cienaguita",
        23678001: "El Campano",
        23678012: "El Charco",
        23678010: "El Hato",
        23678003: "Guacharacal",
        23678005: "Remedio Pobre",
        23678015: "San Carlos",
        23678000: "San Carlos",
        23678011: "San Miguel",
        23678004: "Santa Rosa",
      },
      23682: {
        23682001: "Batatalito",
        23682002: "Bocas De Ure",
        23682003: "Brazo Izquierdo",
        23682004: "Flechas",
        23682005: "La Dorada",
        23682000: "San José De Uré",
        23682006: "Versalles",
        23682007: "Viera Abajo",
        23682008: "Viera Arriba",
      },
      23686: {
        23686017: "Belen",
        23686014: "Boca De Lopez",
        23686022: "Bongas Mellas",
        23686001: "Buenos Aires",
        23686026: "Cañuelar",
        23686002: "Carrillo",
        23686025: "Corocito",
        23686027: "El Balsamo",
        23686028: "El Caño",
        23686018: "El Chiqui",
        23686029: "El Corozo",
        23686030: "El Napal",
        23686021: "El Obligado",
        23686013: "El Tapon",
        23686031: "La Chamarra",
        23686003: "La Madera",
        23686032: "La Majagua",
        23686004: "Las Guamas",
        23686011: "Las Lauras",
        23686033: "La Trampa",
        23686016: "Los Borrachos",
        23686034: "Morrocoy",
        23686035: "Pajonal",
        23686009: "Pelayito",
        23686024: "Pompeya",
        23686036: "Providencia",
        23686008: "Puerto Nuevo",
        23686020: "Retiro",
        23686005: "Sabananueva",
        23686006: "San Isidro",
        23686037: "San Jose",
        23686038: "San Miguel",
        23686000: "San Pelayo",
        23686039: "Terron",
        23686007: "Valparaíso",
      },
      23807: {
        23807031: "Águila Batata",
        23807034: "Bonito Viento",
        23807001: "Callejas",
        23807002: "Caramelo",
        23807029: "Crucito",
        23807039: "La Osa",
        23807026: "Los Morales",
        23807004: "Mantagordal",
        23807005: "Nueva Granada",
        23807017: "Nuevo Frasquillo",
        23807022: "Palmira",
        23807032: "Pueblo Cedro",
        23807006: "Saiza",
        23807035: "San Felipe Cadillo",
        23807007: "Santa Fe Ralito",
        23807027: "Santa Marta",
        23807008: "Severinera",
        23807000: "Tierralta",
        23807028: "Villa Providencia",
        23807010: "Volador",
      },
      23815: {
        23815011: "Algodoncillo",
        23815001: "Barbacoas",
        23815002: "Cruz Chiquita",
        23815004: "Flechas",
        23815005: "Guaymaral",
        23815006: "Molina",
        23815007: "Nueva Estrella",
        23815009: "San Juan De La Cruz",
        23815000: "Tuchín",
        23815010: "Vidales",
      },
      23855: {
        23855008: "Buenavista",
        23855018: "Cocuelo Arriba",
        23855009: "El Reposo",
        23855019: "Guadual Central",
        23855013: "Jaraguay",
        23855020: "Jerico",
        23855021: "La Libertad",
        23855007: "Las Palmas",
        23855012: "Las Piedras",
        23855004: "Loma Larga",
        23855016: "Manzanares",
        23855022: "Marsella",
        23855006: "Mata De Maoz",
        23855001: "Río Nuevo",
        23855017: "San Rafael",
        23855015: "Santo Domingo",
        23855000: "Valencia",
        23855002: "Vijagual",
        23855003: "Villanueva",
      },
    },
    25: {
      25001: {
        25001000: "Agua De Dios",
        25001003: "La Puna",
        25001002: "Leticia",
      },
      25019: {
        25019000: "Albán",
        25019001: "Chimbe (Danubio)",
        25019003: "La María",
        25019002: "Pantanillo",
      },
      25035: {
        25035000: "Anapoima",
        25035001: "La Paz",
        25035003: "Patio Bonito",
        25035002: "San Antonio De Anapoima",
      },
      25040: {
        25040000: "Anolaima",
        25040005: "Boquerón De Ilo",
        25040004: "Canazejas",
        25040006: "Corralejas",
        25040002: "La Florida",
        25040003: "Reventones",
      },
      25053: {
        25053000: "Arbeláez",
        25053003: "Tisince",
      },
      25086: {
        25086000: "Beltrán",
        25086005: "Chacara",
        25086006: "Honduras",
        25086002: "La Popa",
        25086001: "Paquiló",
        25086007: "Paraoso",
        25086003: "Puerto Gramalotal",
        25086004: "Puerto Teja",
        25086008: "Tabor",
      },
      25095: {
        25095000: "Bituima",
        25095002: "Boquerón De Iló",
        25095003: "La Sierra",
      },
      25099: {
        25099000: "Bojacá",
        25099002: "Santa Barbara",
      },
      25120: {
        25120000: "Cabrera",
        25120004: "Simón Bolívar",
        25120003: "Sumapaz La Playa",
      },
      25123: {
        25123000: "Cachipay",
        25123001: "Peña Negra",
      },
      25126: {
        25126010: "Aguanica",
        25126004: "Barro Blanco - Roo Grande",
        25126000: "Cajicá",
        25126009: "Calahorra",
        25126007: "Canelón",
        25126001: "Capellania",
        25126006: "Chuntame",
        25126015: "Chuntame Entrada A Vicente León",
        25126012: "El Misterio - Roo Grande",
        25126013: "La Esperanza - Roo Grande",
        25126008: "La Florida - Roo Froo",
        25126017: "Pablo Herrera - Chuntame",
        25126014: "Puente Vargas - Roo Grande",
        25126003: "Rincón Santo",
        25126011: "Río Frío - La Palma",
        25126018: "Santa Ines -Chuntame",
        25126016: "Sector Prado Entrada A Canelon",
      },
      25148: {
        25148008: "Azauncha",
        25148011: "Caceres",
        25148001: "Cambras",
        25148010: "Cambulo",
        25148002: "Canchimay",
        25148000: "Caparrapí",
        25148007: "Córdoba",
        25148003: "El Dindal",
        25148013: "Mata De Platano",
        25148014: "Puerto Cajuche",
        25148009: "San Carlos",
        25148012: "San Pablo",
        25148015: "San Pedro",
        25148005: "San Pedro",
        25148016: "San Ramón Alto",
        25148017: "San Ramon Bajo",
        25148006: "Tati",
      },
      25151: {
        25151000: "Cáqueza",
      },
      25154: {
        25154000: "Carmen De Carupa",
      },
      25168: {
        25168000: "Chaguaní",
        25168003: "Llanadas",
        25168004: "Melgas",
        25168005: "Nuquoa",
        25168006: "Rincon",
      },
      25175: {
        25175000: "Chía",
        25175010: "Chiquilinda",
        25175003: "Cuatro Esquinas",
        25175019: "El Espejo",
        25175013: "La Paz",
        25175020: "Pueblo Fuerte",
        25175021: "Puente Cacique",
        25175006: "Rincon De Fagua",
        25175016: "Rincon Santo",
        25175023: "Santa Barbara",
        25175002: "Sindamanoy I",
        25175025: "Villa Juliana",
      },
      25178: {
        25178013: "Abasticos",
        25178000: "Chipaque",
      },
      25181: {
        25181000: "Choachí",
      },
      25183: {
        25183000: "Chocontá",
        25183001: "El Sisga",
      },
      25200: {
        25200000: "Cogua",
        25200008: "El Cascajal",
        25200009: "El Durazno",
        25200004: "El Mortiño",
        25200010: "El Olivo",
        25200006: "La Chapa",
        25200005: "La Plazuela",
        25200015: "Ojo De Agua",
        25200002: "Rodamontal",
        25200012: "San Antonio",
        25200013: "Sector Alvarez",
        25200014: "Sector Zamora",
      },
      25214: {
        25214000: "Cota",
      },
      25224: {
        25224000: "Cucunubá",
      },
      25245: {
        25245000: "El Colegio Mesitas",
        25245001: "El Triunfo",
        25245002: "La Victoria",
        25245003: "Pradilla",
      },
      25258: {
        25258000: "El Peñon",
        25258001: "Guayabal De Toledo",
        25258002: "Talauta",
      },
      25260: {
        25260003: "Cruz Verde",
        25260000: "El Rosal",
        25260004: "Puente El Rosal",
        25260005: "San Antonio",
      },
      25269: {
        25269009: "Alto De Córdoba",
        25269031: "El Mirador",
        25269032: "El Paraiso",
        25269011: "El Pesebre",
        25269033: "El Progreso",
        25269000: "Facatativá",
        25269027: "La Loma",
        25269028: "La Recebera",
        25269029: "Las Palmas",
        25269008: "La Yerbabuena",
        25269007: "Los Andes",
        25269010: "Los Arrayanes",
        25269012: "Los Manzanos",
        25269021: "Los Robles",
        25269013: "Paso Ancho",
        25269014: "Pueblo Viejo",
        25269019: "Sagrado Corazón",
        25269022: "San Isidro",
        25269035: "San Jose",
        25269001: "San Rafael",
        25269017: "Santa Martha - La Esperanza",
        25269023: "Tierra Grata Alta",
        25269024: "Tierra Grata Sector El Cruce",
        25269030: "Toro Barroso",
        25269020: "Villa Myriam",
      },
      25279: {
        25279000: "Fómeque",
        25279001: "La Unión",
      },
      25281: {
        25281004: "El Ramal",
        25281000: "Fosca",
        25281002: "Sáname",
      },
      25286: {
        25286004: "Centro Agroindustrial",
        25286000: "Funza",
        25286005: "Terminal De Carga",
      },
      25288: {
        25288001: "Capellanía",
        25288000: "Fúquene",
        25288003: "Nuevo Fuquene",
      },
      25290: {
        25290016: "Chinauta",
        25290011: "Chinauta Los Panches",
        25290012: "El Triunfo Boquerón",
        25290000: "Fusagasugá",
        25290001: "La Aguadita",
        25290014: "La Cascada",
        25290015: "Usatama Bajo",
      },
      25293: {
        25293008: "Bocademonte",
        25293005: "El Guavio",
        25293000: "Gachalá",
        25293001: "Los Alpes",
        25293007: "Minas",
        25293006: "Palomas",
        25293003: "Santa Rita Del Roo Negro",
      },
      25295: {
        25295008: "El Roble",
        25295005: "El Roble Sur",
        25295000: "Gachancipá",
        25295006: "Roble Centro",
        25295009: "San Martín Poligono 1",
        25295010: "San Martín Poligono 2",
        25295011: "San Martín Poligono 3",
        25295002: "Santa Barbara",
      },
      25297: {
        25297000: "Gachetá",
        25297008: "Los Lopez",
      },
      25299: {
        25299000: "Gama",
        25299001: "San Roque",
      },
      25307: {
        25307002: "Acapulco",
        25307007: "Aguablanca",
        25307005: "Barzalosa",
        25307004: "Berlon",
        25307000: "Girardot",
        25307003: "Guabinal",
        25307008: "Guabinal Cerro",
        25307006: "Piamonte",
        25307010: "Presidente",
        25307001: "San Lorenzo",
      },
      25312: {
        25312000: "Granada",
        25312007: "La Veintidos",
        25312009: "San Jose",
        25312013: "San Raimundo",
      },
      25317: {
        25317000: "Guachetá",
      },
      25320: {
        25320008: "Alto Del Trigo",
        25320000: "Guaduas",
        25320001: "Guaduero",
        25320009: "La Cabaña",
        25320002: "La Paz De Calamoima",
        25320003: "Puerto Bogotá",
      },
      25322: {
        25322004: "Gamboa (El Placer)",
        25322000: "Guasca",
        25322003: "La Cabrerita",
      },
      25324: {
        25324001: "El Porvenir",
        25324000: "Guataquí",
        25324002: "Las Islas",
      },
      25326: {
        25326000: "Guatavita",
        25326002: "Montecillo",
      },
      25328: {
        25328001: "Alto Del Trigo",
        25328000: "Guayabal De Síquima",
      },
      25335: {
        25335000: "Guayabetal",
        25335003: "Las Mesas",
        25335004: "Limoncitos",
        25335002: "Monterredondo",
        25335005: "San Antonio",
        25335006: "San Miguel",
      },
      25339: {
        25339000: "Gutiérrez",
        25339001: "Pascote",
        25339002: "San Antonio",
      },
      25368: {
        25368002: "Alto Del Trigo",
        25368000: "Jerusalén",
        25368001: "La Libertad",
      },
      25372: {
        25372005: "Campo Alegre",
        25372002: "Chuscales",
        25372001: "Claraval",
        25372003: "El Salitrico",
        25372000: "Junín",
        25372006: "Puente Lisio",
        25372007: "Ramal",
        25372004: "Sueva",
      },
      25377: {
        25377010: "Altamar",
        25377011: "El Manzano",
        25377003: "El Salitre",
        25377012: "La Aurora Alta",
        25377000: "La Calera",
        25377013: "La Capilla",
        25377014: "Márquez",
        25377002: "Mundonuevo",
        25377015: "San Cayetano",
        25377016: "San José Del Triunfo",
        25377008: "Treinta Y Seis",
      },
      25386: {
        25386001: "La Esperanza",
        25386000: "La Mesa",
        25386002: "San Javier",
        25386003: "San Joaquín",
      },
      25394: {
        25394002: "El Hato",
        25394003: "La Hoya Tudela",
        25394000: "La Palma",
        25394005: "Minasal",
        25394001: "Murca",
      },
      25398: {
        25398001: "Cancuena",
        25398000: "La Peña",
      },
      25402: {
        25402000: "La Vega",
        25402005: "Patio Bonito",
        25402006: "San Juan",
      },
      25407: {
        25407000: "Lenguazaque",
      },
      25426: {
        25426000: "Machetá",
      },
      25430: {
        25430005: "Chauta",
        25430003: "El Corzo",
        25430001: "La Cuesta",
        25430000: "Madrid",
        25430006: "Moyano",
        25430007: "Pablo Vi",
        25430004: "Puente De Piedra",
      },
      25436: {
        25436000: "Manta",
      },
      25438: {
        25438013: "Gazabina",
        25438011: "Gazaduje",
        25438007: "Gazatavena",
        25438010: "La Esmeralda",
        25438014: "La Naguaya",
        25438006: "Los Alpes",
        25438000: "Medina",
        25438005: "Mesa De Los Reyes",
        25438003: "San Pedro De Guajaray",
        25438004: "Santa Teresita",
      },
      25473: {
        25473004: "Los Puentes",
        25473000: "Mosquera",
        25473007: "Parcelas",
        25473008: "Pencal",
      },
      25483: {
        25483001: "La Reforma Buscavida",
        25483000: "Nariño",
      },
      25486: {
        25486005: "Camacho",
        25486004: "Divino Niño",
        25486002: "El Oratorio",
        25486003: "La Puerta",
        25486000: "Nemocón",
        25486001: "Patio Bonito",
      },
      25488: {
        25488001: "La Esmeralda",
        25488000: "Nilo",
        25488002: "Pueblo Nuevo",
      },
      25489: {
        25489002: "El Cerro",
        25489000: "Nimaima",
        25489003: "Paso Del Rejo",
        25489001: "Tobia",
      },
      25491: {
        25491000: "Nocaima",
        25491001: "Tobia Chica",
      },
      25506: {
        25506001: "Aposentos",
        25506005: "El Trébol",
        25506000: "Venecia",
      },
      25513: {
        25513000: "Pacho",
        25513001: "Pasuncha",
      },
      25518: {
        25518001: "Cuatro Caminos",
        25518003: "El Plomo (El Paraoso)",
        25518000: "Paime",
        25518002: "Tudela",
        25518004: "Venecia",
      },
      25524: {
        25524000: "Pandi",
      },
      25530: {
        25530003: "El Engaño",
        25530006: "El Japon",
        25530005: "Guaicaramo",
        25530001: "Maya",
        25530000: "Paratebueno",
        25530002: "Santa Cecilia",
        25530004: "Villa Pacelly",
      },
      25535: {
        25535001: "Alto Del Molino",
        25535002: "Guchipas",
        25535000: "Pasca",
      },
      25572: {
        25572001: "Colorados",
        25572005: "Morro Colorado",
        25572003: "Puerto Libre",
        25572000: "Puerto Salgar",
        25572006: "Tres Y Medio",
      },
      25580: {
        25580003: "Gibraltar",
        25580002: "Palestina",
        25580004: "Paramon",
        25580000: "Pulí",
        25580005: "Talipa",
        25580001: "Valparaoso",
      },
      25592: {
        25592001: "La Magdalena",
        25592000: "Quebradanegra",
        25592003: "Tobia",
      },
      25594: {
        25594002: "Puente Quetame",
        25594000: "Quetame",
      },
      25596: {
        25596004: "La Botica",
        25596001: "La Sierra",
        25596002: "La Virgen",
        25596000: "Quipile",
        25596003: "Santa Marta",
      },
      25599: {
        25599000: "Apulo",
        25599002: "La Meseta",
        25599003: "La Vega",
        25599001: "San Ant De Tequen",
      },
      25612: {
        25612002: "El Paso",
        25612003: "El Portal",
        25612004: "Las Varas",
        25612001: "Manuel Sur",
        25612000: "Ricaurte",
        25612006: "San Marcos Poblado",
        25612007: "San Martín",
      },
      25645: {
        25645016: "Bellavista",
        25645018: "Los Naranjos",
        25645017: "Pradilla",
        25645011: "Pueblo Nuevo",
        25645000: "San Antonio Del Tequendama",
        25645001: "Santandercito",
        25645019: "Villa Pradilla",
        25645020: "Villa Shyn(Casa Moviles)",
      },
      25649: {
        25649003: "Portones",
        25649000: "San Bernardo",
      },
      25653: {
        25653005: "Albergue",
        25653001: "Camancha",
        25653002: "Cuibuco",
        25653006: "El Remanso",
        25653007: "Laguna Verde",
        25653003: "Las Mercedes",
        25653004: "Pinipay",
        25653000: "Pueblo Nuevo",
      },
      25658: {
        25658000: "San Francisco",
      },
      25662: {
        25662001: "Cambao",
        25662000: "San Juan De Rioseco",
        25662002: "San Nicolás",
      },
      25718: {
        25718002: "Santa Cruz",
        25718001: "Santa Ines",
        25718000: "Sasaima",
      },
      25736: {
        25736005: "Boitiva La Esperanza",
        25736003: "Boitiva San Roque",
        25736001: "El Hato",
        25736002: "La Playa",
        25736000: "Sesquilé",
        25736004: "Siatoya",
      },
      25740: {
        25740005: "Chacua",
        25740012: "La Union Sector La Union",
        25740013: "La Union Sector Pie De Alto",
        25740007: "Perico Sector La Honda",
        25740008: "Perico Sector La Macarena",
        25740004: "San Benito",
        25740014: "San Benito Sector Jazmon",
        25740010: "San Fortunato Sector Los Zorros",
        25740011: "San Miguel",
        25740000: "Sibaté",
      },
      25743: {
        25743006: "Chusaca",
        25743002: "Azafranal",
        25743000: "Silvania",
        25743005: "Subia",
      },
      25745: {
        25745004: "Centro Sector Juan Pacho",
        25745003: "El Retén",
        25745000: "Simijaca",
      },
      25754: {
        25754011: "Chacua Cabrera",
        25754001: "El Charquito",
        25754000: "Soacha",
      },
      25758: {
        25758006: "Briceño",
        25758009: "Gratamira",
        25758008: "Hatogrande",
        25758011: "La Diana",
        25758010: "Mercenario",
        25758004: "Meusa",
        25758007: "Pueblo Viejo",
        25758012: "Pueblo Viejo Sector Niño",
        25758000: "Sopó",
      },
      25769: {
        25769003: "Galdamez",
        25769002: "La Pradera",
        25769000: "Subachoque",
      },
      25772: {
        25772004: "Cacicazgo",
        25772001: "Hato Grande",
        25772002: "Santa Rosita",
        25772000: "Suesca",
      },
      25777: {
        25777001: "La Magola",
        25777002: "Santa Rosa",
        25777000: "Supatá",
      },
      25779: {
        25779000: "Susa",
      },
      25781: {
        25781002: "Las Peñas",
        25781000: "Sutatausa",
      },
      25785: {
        25785001: "Carrón",
        25785007: "El Bote",
        25785002: "El Pencil",
        25785008: "Los Chaparros",
        25785011: "Lourdes",
        25785005: "Palo Verde Chicú",
        25785003: "Parcelación Termales",
        25785009: "San Miguel",
        25785000: "Tabio",
        25785010: "Terpel",
      },
      25793: {
        25793003: "Boquerón",
        25793005: "Divino Niño",
        25793000: "Tausa",
      },
      25797: {
        25797001: "La Gran Vía",
        25797000: "Tena",
      },
      25799: {
        25799008: "El Palmar",
        25799009: "Gratamira",
        25799010: "Jacalito  (Sector El Barrio)",
        25799011: "La Cascajera",
        25799001: "La Punta",
        25799012: "Los Pinos",
        25799007: "Sector Pan De Azucar",
        25799000: "Tenjo",
        25799014: "Via La Peña-Juaica",
        25799015: "Zoque",
      },
      25805: {
        25805001: "Bateas",
        25805002: "Cumaca",
        25805000: "Tibacuy",
      },
      25807: {
        25807000: "Tibirita",
      },
      25815: {
        25815007: "La Colorada",
        25815003: "La Salada",
        25815001: "Pubenza",
        25815008: "San Carlos",
        25815000: "Tocaima",
      },
      25817: {
        25817007: "Cetina",
        25817010: "Chapiñones",
        25817001: "Chauta",
        25817012: "Chicala",
        25817002: "Dulcinea",
        25817008: "La Estación",
        25817006: "La Fuente",
        25817013: "Las Quintas",
        25817003: "Pelpak",
        25817004: "San Javier",
        25817000: "Tocancipá",
        25817005: "Tolima",
      },
      25823: {
        25823002: "El Naranjal",
        25823001: "San Antonio De Aguilera",
        25823000: "Topaipí",
      },
      25839: {
        25839002: "Laguna Azul",
        25839006: "La Playa",
        25839003: "Mámbita",
        25839004: "San Pedro De Jagua",
        25839005: "Santa Rosa",
        25839009: "Soya",
        25839000: "Ubalá",
      },
      25841: {
        25841002: "San Roque",
        25841000: "Ubaque",
      },
      25843: {
        25843010: "Centro Del Llano",
        25843001: "Guatancuy",
        25843009: "Palogordo",
        25843007: "San Luis",
        25843011: "Tausavita Bajo",
        25843012: "Viento Libre-Las Brisas",
        25843000: "Villa De San Diego De Ubaté",
        25843005: "Volcan Bajo",
      },
      25845: {
        25845000: "Une",
      },
      25851: {
        25851000: "Útica",
      },
      25862: {
        25862005: "Cerinza",
        25862006: "Corcega",
        25862002: "Guacamayas",
        25862000: "Vergara",
        25862004: "Villa Olarte",
      },
      25867: {
        25867001: "Alto El Rosario",
        25867000: "Vianí",
      },
      25871: {
        25871001: "Cerro Azul",
        25871000: "Villagómez",
      },
      25873: {
        25873000: "Villapinzón",
      },
      25875: {
        25875001: "Bagazal",
        25875004: "Puente De Bagazal",
        25875000: "Villeta",
      },
      25878: {
        25878003: "El Piñal",
        25878004: "Liberia",
        25878002: "San Gabriel",
        25878000: "Viotá",
      },
      25885: {
        25885001: "Alsacia",
        25885016: "Alto De Cañas",
        25885009: "Aposentos",
        25885020: "Cabo Verde",
        25885021: "El Castillo",
        25885022: "El Cauco",
        25885017: "El Chapon",
        25885002: "Guadualito",
        25885003: "Guayabales",
        25885004: "Ibama",
        25885019: "La Collareja",
        25885006: "Llano Mateo",
        25885010: "Montañas De Linares",
        25885018: "Patevaca",
        25885007: "Pueblo Nuevo",
        25885015: "San Luis",
        25885008: "Terán",
        25885000: "Yacopí",
        25885013: "Yasal",
      },
      25898: {
        25898006: "Cartagena",
        25898001: "El Ocaso",
        25898007: "La Cabaña",
        25898004: "La Capilla",
        25898005: "La Estación",
        25898002: "Pueblo Viejo",
        25898003: "Rincon Santo",
        25898000: "Zipacón",
      },
      25899: {
        25899009: "Alto Del Aguila",
        25899010: "Aposentos Altos",
        25899034: "Argelia",
        25899002: "Barandillas",
        25899033: "Barroblanco",
        25899011: "Bolovar 83",
        25899012: "Bosques De Silecia",
        25899029: "El Codito",
        25899030: "El Kiosko La Granja",
        25899015: "El Rudal",
        25899004: "El Tunal",
        25899031: "La Escuela",
        25899001: "La Granja",
        25899032: "La Granja Sector Los Jazminez",
        25899028: "La Mariela",
        25899016: "Loteo Banoy",
        25899017: "Loteo La Paz- Bomba Terpel- Loteo Susagua",
        25899019: "Loteo Santa Isabel",
        25899006: "Pasoancho",
        25899020: "Portachuelo",
        25899021: "Portal De Barandillas",
        25899005: "Roo Froo",
        25899018: "San Gabriel-Lote Pedroza",
        25899008: "San Jorge Palo Alto",
        25899007: "San Jorge Palo Bajo",
        25899025: "San Miguel",
        25899026: "Santiago Perez",
        25899000: "Zipaquirá",
      },
    },
    27: {
      27001: {
        27001001: "Altagracia",
        27001041: "Alto Munguido",
        27001063: "Barranco",
        27001005: "Bellaluz",
        27001044: "Boca De Naurita (Naurita)",
        27001052: "Boca De Nemota (Nemota)",
        27001064: "Boca De Tanando",
        27001008: "Boca De Tanando",
        27001011: "Calahorra",
        27001013: "Campobonito",
        27001047: "El Fuerte",
        27001065: "El Futuro",
        27001040: "El Tambo",
        27001036: "Gitrado",
        27001035: "Guadalupe",
        27001015: "Guarando",
        27001016: "Guayabal",
        27001018: "Las Mercedes",
        27001017: "La Troje",
        27001037: "Mojaudo",
        27001054: "Pacurita (Cabí)",
        27001056: "Puerto Murillo",
        27001048: "San Antonio De Icho",
        27001038: "Sanceno",
        27001024: "San Francisco De Icho",
        27001000: "San Francisco De Quibdo",
        27001020: "San Rafael De Negua",
        27001029: "Tagachí",
        27001032: "Tutunendó",
        27001060: "Villa Del Rosario",
        27001061: "Winando",
      },
      27006: {
        27006000: "Acandí",
        27006003: "Capurganá",
        27006009: "Chugando",
        27006005: "La Caleta",
        27006016: "Peñalosa",
        27006007: "San Francisco",
        27006010: "Sapzurro",
        27006018: "Titiza",
      },
      27025: {
        27025002: "Amparrado",
        27025030: "Amparraida(Santa Rita)",
        27025003: "Apartado",
        27025031: "Bagrera(Puerto Martonez)",
        27025011: "Batatal",
        27025012: "Bella Vista",
        27025004: "Chachajo",
        27025013: "Chigorodo",
        27025015: "Docacina",
        27025016: "Dominico",
        27025014: "El Salto(Bella Luz)",
        27025017: "Geando",
        27025032: "Guineo",
        27025018: "Iruto",
        27025019: "La Divisa",
        27025020: "La Felicia",
        27025021: "La Loma",
        27025023: "Las Delicias",
        27025033: "Miacora",
        27025024: "Mojaudo",
        27025006: "Nauca",
        27025025: "Nuncido",
        27025000: "Alto Baudo",
        27025034: "Playita",
        27025035: "Puerto Alegre",
        27025036: "Puerto Cordoba Urudo",
        27025037: "Puerto Echeverry",
        27025038: "Puerto Libia",
        27025027: "Puesto Indio",
        27025039: "Punto Caiminto",
        27025007: "San Francisco De Cugucho",
        27025008: "Santa Catalina De Catru",
        27025028: "Santa Maria De Condoto",
        27025010: "Yucal",
      },
      27050: {
        27050001: "Arenal",
        27050015: "Brazo De Los Naranjos",
        27050002: "Doña Josefa",
        27050011: "Isla De Los Pinilla",
        27050008: "La Molana",
        27050013: "La Toma",
        27050005: "Motoldo",
        27050012: "Plaa De Oro",
        27050010: "Puente De Paimado",
        27050009: "Puente De Tanando",
        27050004: "Real De Tanandó",
        27050003: "Samurindó",
        27050006: "San Jose De Purre",
        27050007: "San Marton De Purre",
        27050000: "Yuto",
      },
      27073: {
        27073001: "Aguasal",
        27073000: "Bagadó",
        27073002: "Chambare",
        27073003: "Dabaibe",
        27073009: "El Salto",
        27073004: "Engrivado",
        27073005: "La Sierra",
        27073012: "Pescadito",
        27073006: "Piedra Honda",
        27073010: "Playa Bonita",
        27073007: "San Marino",
        27073008: "Tapera",
        27073011: "Vivocora",
      },
      27075: {
        27075000: "Bahia Solano Mútis",
        27075001: "Cupica",
        27075002: "El Valle",
        27075003: "Huaca",
        27075004: "Huina",
        27075005: "Juna",
        27075006: "Mecana",
        27075007: "Nabugá",
      },
      27077: {
        27077002: "Belen De Docampodo",
        27077005: "Cuevita",
        27077021: "Curundo",
        27077006: "Dotenedo",
        27077026: "Guineal",
        27077007: "Hijua",
        27077008: "Orpúa",
        27077009: "Pavasa",
        27077011: "Piliza",
        27077000: "Bajo Baudo Pizarro",
        27077012: "Playita",
        27077032: "Puerto Abadoa",
        27077028: "Puerto Echeverri",
        27077022: "Punta De Igua",
        27077014: "Punta Purricha",
        27077015: "Purricha",
        27077031: "San Miguel De Baudo",
        27077016: "Sivirú",
        27077017: "Torreido De Abajo",
        27077018: "Torreido De Arriba",
        27077029: "Usaragá",
        27077033: "Villa Colombia",
        27077024: "Villa María",
        27077019: "Virudó",
      },
      27099: {
        27099001: "Alfonso Lopez",
        27099000: "Bojaya",
        27099006: "Boca De Opogado",
        27099015: "El Tigre",
        27099003: "Isla De Los Palacios",
        27099004: "La Boba",
        27099002: "La Loma De Bojayá",
        27099013: "Mesopotamia",
        27099005: "Napipi",
        27099030: "Piedra Candela",
        27099012: "Pogue",
        27099008: "Puerto Contó",
        27099009: "San José",
        27099011: "Veracruz",
      },
      27135: {
        27135001: "Boca De Raspadura",
        27135005: "Guapando",
        27135006: "Jorodo",
        27135007: "La Isla",
        27135002: "La Victoria",
        27135000: "Managrú",
        27135008: "Pavaza",
        27135003: "Puerto Pervel",
        27135009: "San Luis",
        27135004: "Tarido",
      },
      27150: {
        27150008: "Apartadó Buena Vista",
        27150001: "Brisas",
        27150010: "Brisas Del Cetino",
        27150011: "Buena Vista",
        27150012: "Chicao",
        27150000: "Curbaradó",
        27150002: "Domingodó",
        27150013: "Gengadodenio",
        27150003: "La Grande",
        27150014: "La Madre",
        27150015: "Nueva Esperanza",
        27150016: "Pueblo Nuevo",
        27150004: "Puerto Lleras",
        27150005: "Turriquitadó",
        27150006: "Vigia De Curbado",
        27150007: "Villa Nueva De Montaño",
      },
      27160: {
        27160000: "Cértegui",
        27160005: "Ibordo",
        27160001: "La Toma",
        27160002: "Parecito",
        27160003: "Paredes",
        27160004: "Variante De Certergui",
      },
      27205: {
        27205001: "Acoso",
        27205000: "Condoto",
        27205016: "Consuelo Andrapa",
        27205002: "Corodo",
        27205017: "El Paso",
        27205015: "Ilaria",
        27205018: "La Encharcazon",
        27205004: "La Florida",
        27205005: "La Muriña",
        27205014: "La Planta",
        27205006: "Mandinga",
        27205007: "Opogodo",
        27205008: "Santa Ana",
        27205011: "Tajuato",
        27205012: "Viroviro",
      },
      27245: {
        27245000: "El Carmen De Atrato",
        27245005: "El Porvenir",
        27245007: "El Siete 1",
        27245006: "El Siete 2",
        27245003: "Guaduas",
        27245004: "La Mansa",
        27245001: "La Playa",
      },
      27250: {
        27250021: "Burujon",
        27250025: "Carra",
        27250020: "Chappien",
        27250007: "Charambira",
        27250008: "Chontaduro",
        27250001: "Copomá",
        27250004: "Corriente Palo",
        27250002: "Cucurrupí",
        27250009: "Descolgadero",
        27250011: "El Choncho",
        27250010: "El Coco",
        27250013: "Garcoa Gomez",
        27250014: "Guachal",
        27250015: "Guagualito",
        27250016: "Jigualito",
        27250017: "La Peñitas",
        27250018: "Los Perea",
        27250019: "Munguido",
        27250006: "Palestina",
        27250029: "Pangalá",
        27250022: "Pangalita",
        27250023: "Papayo",
        27250005: "Pichimá",
        27250024: "Playita",
        27250026: "Puerto Murillo",
        27250028: "Quebrada De Pichima",
        27250003: "Quebrada De Togoromá",
        27250027: "Quebrada Giron",
        27250012: "Quocharo",
        27250030: "San Jose",
        27250000: "Santa Genoveva De Docordó",
        27250031: "Taparal",
        27250032: "Taparalito",
        27250033: "Too Cirilo",
        27250034: "Tordo",
        27250035: "Trojita",
        27250039: "Unión Valsalito",
        27250036: "Venado",
      },
      27361: {
        27361001: "Andagoya",
        27361004: "Basuru",
        27361036: "Boca De San Luis",
        27361044: "Chigorodo (Puerto Salazar)",
        27361006: "Cocove",
        27361040: "Guiniguini",
        27361000: "Istmina",
        27361025: "La Vobora",
        27361031: "Mungido",
        27361035: "Negria",
        27361019: "Olave Indio",
        27361042: "Paito",
        27361021: "Panamacito",
        27361043: "Playa Grande",
        27361023: "Potedo",
        27361009: "Primavera",
        27361049: "Primera Mojarra",
        27361029: "San Antonio",
        27361045: "San Cristobal",
        27361047: "Suruco San Jose",
        27361032: "Suruco Santa Monica",
      },
      27372: {
        27372001: "Aguacate",
        27372008: "Cabo Marzo",
        27372002: "Coredo",
        27372003: "Curiche",
        27372006: "Guarín",
        27372000: "Juradó",
        27372004: "Punta Ardita",
        27372005: "Punta De Cruces",
        27372007: "Punta Piña",
      },
      27413: {
        27413007: "Boraudo",
        27413012: "Calle Larga",
        27413001: "Chagratada",
        27413008: "El Llano",
        27413002: "Guaitadó",
        27413004: "Las Hamacas",
        27413003: "La Vuelta",
        27413000: "Lloró",
        27413005: "Mumbarado",
        27413009: "Nipordu",
        27413010: "Ogodó",
        27413006: "Tumutumbudo",
        27413011: "Villa Claret",
      },
      27425: {
        27425000: "Beté",
        27425001: "Boca De Amé",
        27425002: "Boca De Bebará",
        27425003: "Campoalegre",
        27425004: "El Llano De Bebará",
        27425005: "El Llano De Bebarama",
        27425006: "San Antonio Del Buey (Campo Santo)",
        27425007: "San Jose De Buey (Alto Buey)",
        27425008: "San Roque",
        27425009: "Tangüí",
      },
      27430: {
        27430001: "Almendro",
        27430002: "Arenal",
        27430004: "Bella Vista",
        27430005: "Beriguado",
        27430003: "Boca De Baudocito",
        27430024: "Boca De Pepe",
        27430006: "Cirundo La Banca",
        27430007: "Nuevo Platanares",
        27430009: "Pie De Pepe",
        27430010: "Puerto Adan",
        27430000: "Puerto Meluk",
        27430023: "Quera",
        27430012: "San Jose De Quera",
        27430025: "San Luis",
        27430013: "San Miguel Baudocito",
        27430026: "Union Misara",
        27430027: "Villa Nueva",
      },
      27450: {
        27450000: "Andagoya",
        27450001: "Bebedó",
        27450002: "Boca De Suruco",
        27450003: "Chiquichoqui",
        27450004: "Dipurdu El Guasimo",
        27450005: "El Guamo",
        27450013: "Fujiado",
        27450014: "Isla De Cruz",
        27450006: "Jigualito",
        27450007: "La Rancha",
        27450015: "La Union",
        27450016: "Macedonia",
        27450008: "Noanama",
        27450009: "Paimado",
        27450010: "Pringamo",
        27450017: "Puerto Murillo",
        27450018: "San Jeronimo",
        27450011: "San Marton",
        27450012: "San Miguel",
        27450019: "Union Waunaan",
      },
      27491: {
        27491013: "Carmen De Surama",
        27491009: "Curundo",
        27491011: "El Barrancón",
        27491001: "El Cajón",
        27491010: "El Tambito",
        27491002: "El Tigre",
        27491003: "Irabubu",
        27491004: "Juntas De Tamana",
        27491005: "La Playita",
        27491000: "Nóvita",
        27491006: "San Lorenzo",
        27491014: "Santa Rosa",
        27491007: "Sesego",
        27491008: "Urabara",
      },
      27495: {
        27495007: "Apartadó",
        27495001: "Arusí",
        27495002: "Coquí",
        27495008: "Jobí",
        27495003: "Jurubirá",
        27495000: "Nuquí",
        27495004: "Panguí",
        27495010: "Termales",
        27495005: "Tribugá",
      },
      27580: {
        27580001: "Alto Chato",
        27580002: "Calle Del Cedro",
        27580003: "Coroco",
        27580004: "Encharcazon",
        27580005: "San Jose De Viro Viro",
        27580006: "Santa Barbara",
        27580000: "Santa Rita",
      },
      27600: {
        27600001: "Boca De Apartado",
        27600005: "Boca De Paimado",
        27600006: "Chiguarando Alto",
        27600007: "Chiviguido",
        27600008: "Guayabalito",
        27600009: "La Punta",
        27600002: "La Punta Antado",
        27600010: "La Soledad",
        27600011: "Loma De Los Gamboa",
        27600000: "Paimadó",
        27600003: "San Isidro",
        27600004: "Villa Conto",
      },
      27615: {
        27615045: "7 De Agosto",
        27615016: "Alto Riosucio",
        27615023: "Belén De Bajirá",
        27615032: "Brasito",
        27615034: "Campo Alegre",
        27615017: "Chintado",
        27615004: "Jiguamiando",
        27615006: "La Honda",
        27615024: "La Isleta",
        27615007: "La Larga",
        27615019: "La Raya",
        27615018: "La Teresita",
        27615020: "La Travesoa",
        27615035: "Leoncito",
        27615036: "Llano Rico",
        27615038: "Nuevo Oriente",
        27615022: "Peranchito",
        27615008: "Peye",
        27615039: "Playa Roja",
        27615040: "Primavera",
        27615027: "Puente América - Cacarica",
        27615000: "Riosucio",
        27615009: "Salaquo",
        27615041: "San Andres",
        27615010: "Sautata (Perancho)",
        27615011: "Tamboral",
        27615042: "Tierra Adentro",
        27615012: "Truando",
        27615043: "Tumaradocito(Bella Vista)",
        27615026: "Venecia",
        27615015: "Villanueva",
      },
      27660: {
        27660008: "Corcovado",
        27660009: "Damasco",
        27660010: "Habita",
        27660011: "La Albania",
        27660015: "La Badea",
        27660006: "La Italia",
        27660012: "La Libertad-La Romita",
        27660013: "Los Patios",
        27660002: "Rioblanco",
        27660000: "San José Del Palmar",
        27660003: "San Pedro Ingara",
        27660014: "Zabaleta Surami",
      },
      27745: {
        27745010: "Buenas Brisas",
        27745001: "Cañaveral",
        27745011: "Chambacu",
        27745002: "Garrapatas",
        27745012: "Loma De Chupey",
        27745013: "Marqueza",
        27745003: "San Agustín",
        27745014: "Santa Rosa",
        27745000: "Sipí",
        27745009: "Tanando",
        27745004: "Taparal",
        27745015: "Teatino",
      },
      27787: {
        27787022: "Angostura",
        27787014: "Betania",
        27787023: "Bochoroma",
        27787024: "Burubata",
        27787029: "Cañaveral",
        27787002: "Carmelo",
        27787003: "Certegui",
        27787025: "Chacuante",
        27787015: "Corcobado",
        27787030: "Farallones",
        27787026: "Gingaraba",
        27787005: "Guarato",
        27787007: "La Esperanza",
        27787027: "La Playita",
        27787028: "La Union",
        27787016: "Manungara",
        27787031: "Mesetas",
        27787009: "Mumbu",
        27787032: "Peñas Del Olvido",
        27787010: "Playa De Oro",
        27787011: "Profundo",
        27787033: "Silencio",
        27787000: "Tadó",
        27787004: "Tapón",
      },
      27800: {
        27800001: "Balboa",
        27800007: "Betecito",
        27800010: "Cuque Peniel",
        27800011: "El Puerto",
        27800012: "El Roto",
        27800002: "Gilgal",
        27800008: "Marriaga",
        27800013: "Quebrada Bonita",
        27800003: "Santa María Del Darién",
        27800004: "Tanela",
        27800014: "Ticole Isla",
        27800005: "Titumate",
        27800009: "Tumarado",
        27800000: "Unguía",
      },
      27810: {
        27810000: "Ánimas",
        27810001: "El Plan De Raspadura",
        27810002: "La Ye",
        27810004: "San Pablo Adentro",
        27810003: "San Rafael Del Dos",
      },
      27086: {
        27086000: "Belen De Bajira",
      },
    },
    41: {
      41001: {
        41001018: "Aipecito",
        41001001: "Caguán",
        41001029: "Cedralito",
        41001002: "Chapinero",
        41001016: "El Colegio",
        41001013: "El Triunfo",
        41001022: "El Venado",
        41001003: "Fortalecillas",
        41001004: "Guacirco",
        41001030: "La Mata",
        41001000: "Neiva",
        41001006: "Organos",
        41001007: "Palacios",
        41001008: "Peñas Blancas",
        41001024: "Piedra Marcada",
        41001031: "Pradera",
        41001009: "San Antonio",
        41001017: "San Antonio De Anaconia",
        41001014: "San Francisco",
        41001011: "San Luis",
        41001012: "Vegalarga",
      },
      41006: {
        41006000: "Acevedo",
        41006010: "Delicias",
        41006011: "El Carmen",
        41006004: "La Tijiña",
        41006012: "La Victoria",
        41006003: "Pueblo Viejo",
        41006017: "Salado",
        41006001: "San Adolfo",
        41006015: "San Jose De Riecito",
        41006005: "San Marcos",
      },
      41013: {
        41013000: "Agrado",
        41013002: "Escalereta",
        41013001: "La Cañada",
        41013003: "San Jose De Belen",
      },
      41016: {
        41016000: "Aipe",
        41016004: "Cruce De Guacirco",
        41016003: "El Pata",
        41016008: "Mesitas",
        41016001: "Praga",
        41016007: "San Miguel",
        41016002: "Santa Rita",
        41016009: "Ventanas",
      },
      41020: {
        41020000: "Algeciras",
        41020006: "El Paraíso Nuevo",
        41020001: "El Paraíso Viejo",
        41020005: "El Puente",
        41020003: "El Toro",
        41020002: "La Arcadia",
      },
      41026: {
        41026000: "Altamira",
        41026003: "Grifo",
        41026004: "Llano De La Virgen",
        41026002: "Pajiji",
        41026005: "Puente",
      },
      41078: {
        41078000: "Baraya",
        41078010: "La Union",
        41078011: "Turquestan",
      },
      41132: {
        41132003: "Bajo Piravante",
        41132000: "Campoalegre",
        41132001: "La Vega",
        41132002: "Otas",
        41132004: "Roo Neiva",
      },
      41206: {
        41206000: "Colombia",
        41206004: "Las Lajas",
        41206006: "Los Rios",
        41206001: "Monguo",
        41206003: "Potrero Grande",
        41206007: "San Antonio Bajo",
        41206005: "San Marcos",
        41206002: "Santana",
      },
      41244: {
        41244000: "Elías",
        41244001: "El Viso",
        41244002: "Oritoguaz",
        41244003: "Potrerillos",
      },
      41298: {
        41298018: "Bajo Sartenejo",
        41298013: "Caguancito",
        41298014: "El Descanso",
        41298008: "El Meson",
        41298005: "El Paraoso",
        41298001: "El Recreo",
        41298000: "Garzón",
        41298019: "Jagualito",
        41298020: "La Cabaña",
        41298002: "La Jagua",
        41298015: "Majo",
        41298012: "Plazuela",
        41298007: "Providencia",
        41298003: "San Antonio Del Pescado",
        41298016: "San Gerardo",
        41298017: "Santa Marta",
        41298006: "Sartenjo",
        41298004: "Zuluaga",
      },
      41306: {
        41306012: "El Jardín",
        41306006: "El Mesón",
        41306014: "El Recreo",
        41306000: "Gigante",
        41306015: "La Bodega",
        41306001: "La Chiquita",
        41306002: "La Gran Vía",
        41306013: "La Gran Vía El Porvenir",
        41306003: "Potrerillos",
        41306007: "Pueblo Nuevo",
        41306004: "Rioloro",
        41306010: "Silvania",
        41306011: "Tres Esquinas",
        41306005: "Veracruz",
        41306009: "Vueltas Arriba",
      },
      41319: {
        41319000: "Guadalupe",
        41319003: "Los Cauchos",
        41319002: "Miraflores",
        41319004: "Potrerillos",
        41319001: "Resina",
      },
      41349: {
        41349000: "Hobo",
      },
      41357: {
        41357002: "Chaparro",
        41357000: "Íquira",
        41357003: "Río Negro",
        41357005: "San Luis",
        41357004: "Valencia La Paz",
      },
      41359: {
        41359003: "El Salto De Bordones",
        41359005: "Los Arrayanes",
        41359000: "San José De Isnos",
        41359004: "San Vicente",
      },
      41378: {
        41378001: "Buenos Aires",
        41378002: "El Pensil",
        41378000: "La Argentina",
      },
      41396: {
        41396001: "Belén",
        41396010: "El Salado",
        41396011: "Fatima",
        41396009: "Gallego",
        41396000: "La Plata",
        41396002: "Monserrate",
        41396003: "Moscopán",
        41396004: "San Andrés",
        41396006: "San Vicente",
        41396012: "Segovianas",
        41396005: "Villa Losada",
      },
      41483: {
        41483002: "Llano Buco",
        41483000: "Nátaga",
        41483001: "Patio Bonito",
        41483003: "Yarumal",
      },
      41503: {
        41503002: "El Carmen",
        41503000: "Oporapa",
        41503001: "San Roque",
      },
      41518: {
        41518001: "La Reforma",
        41518002: "Las Lajitas",
        41518003: "Las Orquideas",
        41518000: "Paicol",
      },
      41524: {
        41524009: "Amborco",
        41524001: "Betania",
        41524006: "El Juncal",
        41524004: "Ospina Pérez",
        41524000: "Palermo",
        41524005: "San Juan",
      },
      41530: {
        41530000: "Palestina",
      },
      41548: {
        41548001: "El Socorro",
        41548002: "Minas",
        41548000: "Pital",
      },
      41551: {
        41551001: "Bruselas",
        41551009: "Charguayaco",
        41551006: "Chillurco (Villas Del Norte)",
        41551008: "Criollo",
        41551002: "Guacacallo",
        41551003: "La Laguna",
        41551010: "Palmarito",
        41551000: "Pitalito",
        41551005: "Regueros",
      },
      41615: {
        41615007: "El Guadual",
        41615005: "La Primavera",
        41615001: "La Ulloa",
        41615006: "Río Frío",
        41615000: "Rivera",
        41615002: "Riverita",
      },
      41660: {
        41660001: "La Cabaña",
        41660007: "Morelia",
        41660000: "Saladoblanco",
      },
      41668: {
        41668006: "El Palmar",
        41668009: "El Rosario",
        41668002: "Obando",
        41668007: "Pradera",
        41668004: "Puerto Quinchana",
        41668000: "San Agustín",
        41668003: "Villa Fátima",
      },
      41676: {
        41676002: "El Socorro",
        41676003: "La Maroa",
        41676001: "San Joaquín",
        41676000: "Santa María",
      },
      41770: {
        41770008: "Avispero",
        41770007: "El Salado",
        41770001: "Gallardo",
        41770002: "Guayabal",
        41770004: "Hato Viejo",
        41770005: "Mantagua",
        41770006: "Picuna",
        41770010: "Safia",
        41770003: "San Calixto",
        41770000: "Suaza",
      },
      41791: {
        41791014: "Betania",
        41791010: "Buenos Aires",
        41791015: "Delicias",
        41791001: "El Vergel",
        41791005: "La Esmeralda",
        41791013: "Lagunilla",
        41791016: "La Mirada",
        41791007: "La Pampa",
        41791002: "Maito",
        41791017: "Pradera",
        41791018: "Quebraditas",
        41791003: "Quituro",
        41791009: "Ricabrisa",
        41791000: "Tarqui",
      },
      41797: {
        41797001: "Pacarní",
        41797000: "Tesalia",
      },
      41799: {
        41799001: "Anacleto García",
        41799006: "El Cedral",
        41799003: "San Andrés Tello",
        41799004: "Sierra De La Cañada",
        41799002: "Sierra Del Gramal",
        41799000: "Tello",
      },
      41801: {
        41801001: "San Pedro",
        41801000: "Teruel",
      },
      41807: {
        41807010: "Aguas Claras",
        41807011: "Alto Naranjal",
        41807012: "Cascajal",
        41807007: "Cosanza",
        41807002: "Mateo Rico",
        41807005: "Montañita",
        41807001: "Naranjal",
        41807013: "Pantanos",
        41807006: "Quinche",
        41807004: "San Antonio",
        41807009: "San Isidro",
        41807014: "Santa Barbara",
        41807000: "Timaná",
      },
      41872: {
        41872007: "Doche",
        41872006: "Golondrinas",
        41872003: "Hato Nuevo",
        41872005: "La Victoria",
        41872004: "Polonia",
        41872001: "Potosí",
        41872002: "San Alfonso",
        41872000: "Villavieja",
      },
      41885: {
        41885000: "Yaguará",
      },
    },
    44: {
      44001: {
        44001028: "Anaime",
        44001001: "Arroyo Arena",
        44001002: "Barbacoa",
        44001029: "Boca De Camarones",
        44001003: "Camarones",
        44001004: "Cascajalito",
        44001031: "Cerrillo",
        44001020: "Choles",
        44001021: "Comejenes",
        44001005: "Cotoprix",
        44001033: "Ebanal",
        44001022: "El Abra",
        44001008: "Galán",
        44001034: "Guadualito",
        44001035: "Juan Y Medio",
        44001036: "La Arena",
        44001018: "Las Palmas",
        44001024: "Los Moreneros",
        44001011: "Matitas",
        44001012: "Monguí",
        44001025: "Pelechua",
        44001026: "Perico",
        44001040: "Puente Bomba",
        44001000: "Riohacha",
        44001027: "Tigrera",
        44001016: "Tomarrazón",
        44001017: "Villa Martín",
      },
      44035: {
        44035000: "Albania",
        44035001: "Cuestecitas",
        44035002: "Huareuaren",
        44035003: "Los Remedios",
      },
      44078: {
        44078000: "Barrancas",
        44078001: "Carretalito",
        44078015: "Chancleta",
        44078010: "Guacamayal",
        44078009: "Guayacanal",
        44078002: "Hato Nuevo",
        44078003: "Lagunita",
        44078016: "Las Casitas",
        44078005: "Oreganal Viejo",
        44078006: "Papayal",
        44078014: "Patilla",
        44078011: "Pozo Hondo",
        44078007: "Roche",
        44078008: "San Pedro",
      },
      44090: {
        44090005: "Campana Nuevo",
        44090000: "Dibulla",
        44090001: "La Punta De Los Remedios",
        44090002: "Las Flores",
        44090003: "Mingueo",
        44090004: "Palomino",
        44090006: "Río Ancho",
      },
      44098: {
        44098001: "Buenavista",
        44098002: "Chorreras",
        44098000: "Distracción",
        44098005: "La Duda",
        44098008: "Los Hornitos",
        44098011: "Potrerito",
      },
      44110: {
        44110000: "El Molino",
      },
      44279: {
        44279008: "Bangañitas",
        44279018: "Cañaboba",
        44279007: "Cardonal",
        44279002: "Conejo",
        44279011: "El Confuso",
        44279005: "El Hatico",
        44279000: "Fonseca",
        44279016: "Guamachal",
        44279013: "Los Altos",
        44279019: "Pondores",
        44279015: "Potrerito",
        44279014: "Quebrachal",
        44279020: "Sabaneta",
        44279006: "Sitionuevo",
        44279021: "Trigo",
      },
      44378: {
        44378002: "Cerro Alto",
        44378003: "El Paraoso",
        44378004: "El Pozo",
        44378005: "Guaimarito",
        44378006: "Guamachito",
        44378000: "Hatonuevo",
        44378007: "La Cruz",
        44378008: "La Gloria",
        44378009: "La Lomita",
        44378001: "Tabaco",
      },
      44420: {
        44420001: "El Plan",
        44420000: "La Jagua Del Pilar",
      },
      44430: {
        44430001: "Albania",
        44430002: "Carraipía",
        44430012: "El Limoncito",
        44430014: "Garrapatero",
        44430004: "Ipapure",
        44430011: "La Arena",
        44430006: "La Majayura",
        44430005: "La Paz",
        44430000: "Maicao",
        44430015: "Maku",
        44430010: "Marañamana",
        44430007: "Paraguachón",
        44430016: "Santa Cruz",
        44430017: "Santa Rosa",
        44430013: "Yotojoroy",
      },
      44560: {
        44560001: "Arémasahin",
        44560003: "El Pájaro",
        44560009: "La Gloria",
        44560010: "La Paz",
        44560000: "Manaure",
        44560008: "Manzana",
        44560007: "Mayapo",
        44560002: "Musichi",
        44560005: "Santa Rosa",
        44560006: "Shirure",
      },
      44650: {
        44650022: "Boca Del Monte",
        44650001: "Cañaverales",
        44650002: "Caracolí",
        44650003: "Corral De Piedra",
        44650014: "Corralejas",
        44650021: "Curazao",
        44650004: "El Hatico De Los Indios",
        44650024: "El Placer",
        44650005: "El Tablazo",
        44650006: "El Totumo",
        44650007: "Guayacanal",
        44650018: "Lagunita",
        44650008: "La Junta",
        44650009: "La Peña",
        44650015: "La Peña De Los Indios",
        44650010: "La Sierrita",
        44650023: "Los Cardones",
        44650011: "Los Haticos",
        44650012: "Los Pondores",
        44650019: "Los Pozos",
        44650016: "Pondoritos",
        44650020: "Potrerito",
        44650000: "San Juan Del Cesar",
        44650017: "Villa Del Río",
        44650013: "Zambrano",
      },
      44847: {
        44847002: "Bahoa Honda",
        44847003: "Cabo De La Vela",
        44847004: "Carrizal",
        44847005: "Castilletes",
        44847006: "Casuso",
        44847007: "El Cardón",
        44847021: "Guarerpa",
        44847009: "Guimpesi",
        44847020: "Irraipa",
        44847010: "Jarara",
        44847025: "Jonjoncito",
        44847012: "Nazareth",
        44847023: "Porchina",
        44847013: "Puerto Estrella",
        44847014: "Puerto López",
        44847015: "Rancho Grande",
        44847024: "Taguaira",
        44847017: "Taparajon",
        44847018: "Taroa",
        44847000: "Uribia",
      },
      44855: {
        44855003: "Sierra Montaña",
        44855000: "Urumita",
      },
      44874: {
        44874000: "Villanueva",
      },
    },
    47: {
      47001: {
        47001001: "Bonda",
        47001011: "Buritaca",
        47001022: "Cabañas De Buritaca",
        47001002: "Calabazo",
        47001023: "Cañaveral (Agua Fría)",
        47001024: "Colinas De Calabazo",
        47001025: "Curvalito",
        47001003: "Don Diego",
        47001034: "El Trompito",
        47001005: "Gaira",
        47001006: "Guachaca",
        47001026: "Guacoche (La Llanta)",
        47001035: "La Aguacatera",
        47001031: "La Olla",
        47001032: "La Revuelta",
        47001036: "Machete Pelao",
        47001027: "Marketalia (Palominito)",
        47001009: "Minca",
        47001028: "Paz Del Caribe",
        47001029: "Perico Aguao",
        47001000: "Santa Marta",
        47001010: "Taganga",
        47001013: "Tigrera",
        47001038: "Valle De Gaira",
      },
      47030: {
        47030000: "Algarrobo",
        47030001: "Bella Vista",
        47030005: "Estacion Algarrobo",
        47030002: "Estación Del Ferrocarril",
        47030003: "Estación Lleras",
        47030004: "Loma Del Bálsamo",
      },
      47053: {
        47053000: "Aracataca",
        47053001: "Buenos Aires",
        47053011: "Cauca",
        47053004: "El Reten",
        47053013: "Sampués",
        47053008: "Santa Ana",
      },
      47058: {
        47058001: "Alejandría",
        47058009: "Carmen De Ariguaní",
        47058000: "El Dificil",
        47058003: "Pueblo Nuevo",
        47058005: "San José De Ariguaní",
        47058008: "Vadelco",
      },
      47161: {
        47161002: "Candelaria (Caimán)",
        47161000: "Cerro De San Antonio",
        47161003: "Concepción (Coco)",
        47161005: "Jesús Del Monte (Mico)",
        47161006: "Puerto Niño (Charanga)",
      },
      47170: {
        47170000: "Chivolo",
        47170001: "La China",
        47170003: "La Estrella",
        47170002: "Pueblo Nuevo",
      },
      47189: {
        47189000: "Ciénaga",
        47189022: "Cordobita",
        47189011: "La Gran Via",
        47189024: "La Isabel",
        47189025: "Maya",
        47189002: "Orihueca",
        47189018: "Palmor",
        47189003: "Riofrio",
        47189026: "San Javier",
        47189004: "San Pedro De La Sierra",
        47189006: "Sevillano",
        47189023: "Siberia",
        47189007: "Tucurinca",
      },
      47205: {
        47205001: "Bálsamo",
        47205002: "Bellavista",
        47205000: "Concordia",
        47205003: "Rosario Del Chengue",
      },
      47245: {
        47245001: "Aguaestrada",
        47245002: "Algarrobal",
        47245005: "Belén",
        47245025: "Botillero",
        47245021: "Caimanera",
        47245006: "Caño De Palma",
        47245000: "El Banco",
        47245003: "El Barranco De Chilloa",
        47245033: "El Cedro",
        47245007: "El Cerrito",
        47245008: "El Trébol",
        47245020: "Garzón",
        47245018: "Guacamayal",
        47245011: "Hatillo De La Sabana",
        47245022: "Islitas",
        47245026: "Los Mamones",
        47245004: "Los Negritos",
        47245019: "Malpica",
        47245032: "Mata De Caña",
        47245010: "Menchiquejo",
        47245028: "Pueblo Nuevo",
        47245016: "San Felipe Y San Eduardo",
        47245012: "San José",
        47245013: "San Roque",
        47245014: "Tamalamequito",
      },
      47258: {
        47258001: "Campo Alegre",
        47258002: "Cantagallar",
        47258003: "Carreto",
        47258000: "El Piñon",
        47258012: "Las Palmas",
        47258013: "Las Pavitas",
        47258010: "Los Patos",
        47258004: "Playón De Orozco",
        47258005: "Sabanas",
        47258006: "San Basilio",
        47258007: "Tio Gollo",
        47258011: "Vásquez",
        47258009: "Veranillo",
      },
      47268: {
        47268000: "El Retén",
        47268002: "La Colombia",
        47268006: "La Polvorita",
        47268003: "Las Flores",
        47268007: "Parate Bien",
        47268008: "San José De Honduras",
        47268001: "San Sebastian Del Bongo",
      },
      47288: {
        47288003: "Doña María",
        47288014: "El Cincuenta",
        47288000: "Fundación",
        47288013: "Santa Clara",
        47288004: "Santa Rosa",
      },
      47318: {
        47318014: "Bellavista",
        47318013: "Carretero",
        47318001: "Casa De Tabla",
        47318025: "El Veintiocho",
        47318015: "Guacamayal",
        47318002: "Guaimaral",
        47318000: "Guamal",
        47318003: "Hatoviejo",
        47318010: "Hurquijo",
        47318020: "La Ceiba",
        47318018: "Las Flores",
        47318005: "Los Andes",
        47318006: "Murillo",
        47318021: "Pajaral",
        47318007: "Pam Pan",
        47318022: "Paraco",
        47318004: "Pedregosa",
        47318011: "Playas Blancas",
        47318026: "Pueblo Nuevo",
        47318008: "Ricaurte",
        47318009: "Salvadora",
        47318019: "San Antonio",
        47318023: "San Isidro",
        47318017: "San Pedro",
        47318016: "Santa Teresita",
        47318012: "Sitio Nuevo",
        47318024: "Villa Nueva",
      },
      47460: {
        47460001: "El Bajo",
        47460000: "Nueva Granada",
        47460002: "La Gloria",
        47460003: "Las Tinas",
        47460004: "Los Andes",
        47460006: "San José De Ballestero",
      },
      47541: {
        47541001: "Bahía Honda",
        47541003: "Bomba",
        47541007: "Guaquirí",
        47541008: "Heredia",
        47541000: "Pedraza",
      },
      47545: {
        47545001: "Cabrera",
        47545004: "Casa Blanca",
        47545008: "El Brillante",
        47545007: "El Dividivi",
        47545002: "Filadelfia",
        47545005: "La Lucha",
        47545009: "Las Maroas",
        47545006: "Las Planadas",
        47545000: "Pijiño del Carmen",
        47545003: "San Jose De Prevencion",
      },
      47551: {
        47551002: "Caraballo",
        47551010: "Carmen Del Magdalena (Paraco)",
        47551003: "Chinoblas",
        47551001: "La Avianca",
        47551006: "Las Canoas",
        47551007: "Las Piedras",
        47551008: "Medialuna",
        47551011: "Paraíso",
        47551012: "Piñuelas",
        47551000: "Pivijay",
        47551013: "Placitas",
        47551015: "Salaminita",
        47551005: "San José De La Montaña(Garrapata)",
      },
      47555: {
        47555006: "Aguas Vivas",
        47555001: "Apure",
        47555018: "Buena Vista",
        47555002: "Carmen Del Magdalena",
        47555008: "Cerro Grande",
        47555007: "Ciénegueta",
        47555015: "Disciplina",
        47555021: "El Bajo",
        47555020: "Los Pozos",
        47555003: "Nueva Granada",
        47555000: "Plato",
        47555017: "San Antonio Del Río",
        47555011: "San José Del Purgatorio",
        47555005: "Zarate",
      },
      47570: {
        47570001: "Bocas De Aracataca",
        47570007: "El Triunfo",
        47570008: "Isla De Cataquita",
        47570002: "Isla Del Rosario",
        47570009: "Nueva Frontera",
        47570003: "Palmira",
        47570000: "Puebloviejo",
        47570010: "San Juan De Palos Prietos (La Montaña)",
        47570004: "Tasajera",
        47570005: "Tierra Nueva",
      },
      47605: {
        47605002: "Corral Viejo",
        47605003: "El Dividivi",
        47605006: "El Salao",
        47605008: "Las Casitas",
        47605007: "Martinete",
        47605000: "Remolino",
        47605004: "San Rafael De Buenavista",
        47605005: "Santa Rita",
      },
      47660: {
        47660002: "Casa De Tabla",
        47660003: "Cespedes",
        47660013: "Estacion Villa",
        47660005: "Flores De María",
        47660007: "La Horqueta",
        47660009: "Las Mulas (San Roque)",
        47660014: "Monterrubio",
        47660015: "Parapeto",
        47660011: "Pueblito De Los Barrios",
        47660000: "Sabanas de San Ángel",
      },
      47675: {
        47675005: "El Salao",
        47675001: "Guáimaro",
        47675006: "La Loma",
        47675000: "Salamina",
      },
      47692: {
        47692001: "Buenavista",
        47692002: "El Coco",
        47692013: "El Seis",
        47692017: "Jaime",
        47692003: "La Pacha",
        47692004: "Las Margaritas",
        47692005: "Los Galvis",
        47692006: "Maroa Antonia",
        47692019: "Pueblo Nuevo",
        47692014: "Sabanas De Peralejo",
        47692007: "San Rafael",
        47692000: "San Sebastián De Buenavista",
        47692008: "Santa Rosa",
        47692018: "San Valenton",
        47692009: "Troncosito",
        47692010: "Troncoso",
        47692011: "Venero",
      },
      47703: {
        47703001: "Angostura",
        47703002: "Bermejal",
        47703009: "El Horno",
        47703003: "El Palomar",
        47703008: "Guinea",
        47703004: "Janeiro",
        47703005: "La Montaña",
        47703013: "La Union",
        47703006: "Peñoncito",
        47703010: "Puerto Arturo",
        47703012: "San Jose De Chimila",
        47703007: "Santa Teresa",
        47703000: "San Zenón",
      },
      47707: {
        47707001: "Barro Blanco",
        47707009: "Jaraba",
        47707006: "San Fernando",
        47707000: "Santa Ana",
        47707011: "Santa Rosa",
      },
      47720: {
        47720001: "Cundinamarca",
        47720002: "San Pedro",
        47720000: "Santa Bárbara De Pinto",
        47720003: "Veladero",
      },
      47745: {
        47745001: "Buenavista",
        47745002: "Nueva Venecia",
        47745003: "Palermo",
        47745006: "San Antonio",
        47745000: "Sitionuevo",
      },
      47798: {
        47798010: "El Consuelo",
        47798007: "El Juncal",
        47798004: "Real Del Obispo",
        47798005: "San Luis",
        47798008: "Santa Inés",
        47798000: "Tenerife",
      },
      47960: {
        47960001: "Caño De Aguas",
        47960002: "Capucho",
        47960005: "Los Cerritos",
        47960003: "Piedras De Moler",
        47960004: "Piedras Pintadas",
        47960000: "Punta De Piedras",
      },
      47980: {
        47980016: "Caño Mocho",
        47980017: "El Mamon",
        47980013: "Estación Sevilla",
        47980001: "Guacamayal",
        47980002: "Guamachito",
        47980014: "La Candelaria",
        47980003: "La Gran Vía",
        47980004: "Orihueca",
        47980005: "Palomar",
        47980000: "Prado - Sevilla",
        47980006: "Riofrío",
        47980015: "San José De Kennedy (Campo Kennedy)",
        47980007: "Santa Rosalia",
        47980008: "Sevilla",
        47980009: "Soplador",
        47980010: "Tucurinca",
        47980011: "Varela",
        47980012: "Zawady",
      },
    },
    50: {
      50001: {
        50001015: "Alto Pompeya",
        50001019: "Apiay",
        50001021: "Argentina",
        50001022: "Balmoral",
        50001020: "Barcelona",
        50001023: "Bella Suiza",
        50001004: "Buenavista",
        50001016: "Cecilia",
        50001005: "Cocuy",
        50001001: "Concepción",
        50001024: "Condominio De Los Odontologos",
        50001025: "Condominio Santa Barbara",
        50001017: "La Nohora",
        50001026: "Llanerita",
        50001027: "Naturalia",
        50001028: "Parcelas Del Progreso",
        50001013: "Pipiral",
        50001030: "Quintas Del Diamante",
        50001029: "Quintas De San Fernando",
        50001002: "Rincon De Pompeya",
        50001031: "San Carlos",
        50001014: "San Luis De Ocoa",
        50001003: "Santa Rosa De Río Negro",
        50001032: "Sector Los Algarrobos-Naranjos",
        50001033: "Sector Vigoa Sebastopol",
        50001007: "Servita",
        50001000: "Villavicencio",
      },
      50006: {
        50006000: "Acacías",
        50006001: "Dinamarca",
        50006003: "San Isidro De Chichimene",
      },
      50110: {
        50110000: "Barranca De Upía",
        50110005: "El Hijoa",
        50110004: "Guaicaramo",
        50110001: "San Ignacio",
      },
      50124: {
        50124000: "Cabuyaro",
        50124002: "Guayabal De Upía",
        50124004: "Los Mangos",
        50124003: "Viso De Upía",
      },
      50150: {
        50150002: "Arenales",
        50150000: "Castilla La Nueva",
        50150003: "El Toro",
        50150001: "San Lorenzo",
      },
      50223: {
        50223000: "Cubarral San Luis",
        50223003: "Puerto Ariari",
        50223004: "Puerto Gomez",
      },
      50226: {
        50226007: "Caney Medio",
        50226000: "Cumaral",
        50226008: "El Caibe",
        50226002: "Guacavía",
        50226009: "Montebello",
        50226010: "Presentado",
        50226004: "San Nicolás",
        50226011: "Varsovia",
        50226005: "Veracruz",
      },
      50245: {
        50245000: "El Calvario",
        50245001: "Montfort",
        50245002: "San Francisco",
      },
      50251: {
        50251000: "El Castillo",
        50251006: "La Esmeralda",
        50251001: "Medellín Del Ariari",
        50251003: "Miravalles",
        50251004: "Puerto Esperanza",
        50251007: "Puerto Union",
      },
      50270: {
        50270000: "El Dorado",
        50270001: "Pueblo Sánchez",
        50270002: "San Isidro",
      },
      50287: {
        50287006: "Caño Blanco",
        50287000: "Fuente De Oro",
        50287005: "La Cooperativa",
        50287001: "Puerto Aljure",
        50287002: "Puerto Limón",
        50287007: "Puerto Nuevo",
        50287003: "Puerto Santander",
        50287004: "Union Del Ariari",
      },
      50313: {
        50313006: "Aguas Claras",
        50313001: "Canaguaro",
        50313002: "Dos Quebradas",
        50313000: "Granada",
        50313004: "La Playa",
        50313005: "Puerto Caldas",
      },
      50318: {
        50318000: "Guamal",
        50318001: "Humadea",
      },
      50325: {
        50325004: "Anzuelo",
        50325006: "La Cooperativa",
        50325000: "Mapiripán",
        50325002: "Mielón",
        50325001: "Puerto Alvira",
        50325007: "Puerto Siare",
        50325008: "Sardinata",
      },
      50330: {
        50330003: "Brisas Del Duda",
        50330002: "Jardín De Las Peñas",
        50330007: "La Argentina",
        50330008: "La Guajira",
        50330000: "Mesetas",
        50330004: "Mirador",
        50330005: "Oriente",
        50330009: "Puerto Nariño",
        50330010: "San Isidro",
      },
      50350: {
        50350005: "Bocas Del Perdido",
        50350006: "Catalina",
        50350007: "Cristalina",
        50350008: "El Rubo",
        50350000: "La Macarena",
        50350001: "La Sombra",
        50350002: "Los Pozos",
        50350003: "San Juan Del Losada",
        50350004: "Villa Del Río",
      },
      50370: {
        50370002: "El Diviso",
        50370001: "La Julia",
        50370000: "Uribe",
      },
      50400: {
        50400002: "Angosturas Del Guape",
        50400001: "Cacayal",
        50400000: "Lejanías",
      },
      50450: {
        50450001: "Porororio",
        50450000: "Puerto Concordia",
      },
      50568: {
        50568008: "Alto Tillava",
        50568005: "El Porvenir",
        50568010: "Las Cristalinas",
        50568001: "Planas",
        50568007: "Puente Arimena",
        50568000: "Puerto Gaitán",
        50568006: "Puerto Trujillo",
        50568004: "San Miguel",
        50568002: "San Pedro De Arimena",
      },
      50573: {
        50573004: "Altamira",
        50573010: "Bocas Del Guayuriba",
        50573012: "Chaviva",
        50573013: "El Tigre",
        50573011: "Guichiral",
        50573001: "La Balsa",
        50573014: "Melua",
        50573003: "Pachaquiaro",
        50573006: "Puerto Guadalupe",
        50573000: "Puerto López",
        50573007: "Puerto Porfía",
        50573008: "Remolino",
      },
      50577: {
        50577004: "Caño Rayado",
        50577003: "Casibare",
        50577000: "Puerto Lleras",
        50577006: "Tierra Grata",
        50577005: "Villa Lapaz",
      },
      50590: {
        50590004: "Barranco Colorado",
        50590006: "Charco Danto",
        50590003: "La Lindosa",
        50590007: "La Tigra",
        50590008: "Puerto Chispas",
        50590002: "Puerto Iris",
        50590000: "Puerto Rico",
        50590005: "Puerto Toledo",
      },
      50606: {
        50606000: "Restrepo",
      },
      50680: {
        50680003: "La Palmera",
        50680001: "Pajure",
        50680000: "San Carlos De Guaroa",
        50680004: "San Jose De Las Palomas",
        50680002: "Surimena",
      },
      50683: {
        50683011: "Cerrito",
        50683001: "El Vergel",
        50683005: "Mesa Fernández",
        50683012: "Miraflorez",
        50683013: "Peñas Blancas",
        50683000: "San Juan De Arama",
      },
      50686: {
        50686002: "La Candelaria",
        50686000: "San Juanito",
        50686001: "San Roque",
      },
      50689: {
        50689005: "Brisas Del Manacacoas",
        50689001: "El Merey",
        50689003: "Reforma",
        50689004: "Rincon Bolovar",
        50689000: "San Martín",
      },
      50711: {
        50711018: "Albania",
        50711019: "Buenos Aires",
        50711001: "Campo Alegre",
        50711004: "Caño Amarillo",
        50711012: "Costa Rica",
        50711020: "El Triunfo",
        50711011: "La Cooperativa",
        50711021: "La Reforma",
        50711003: "Maracaibo",
        50711014: "Palestina",
        50711022: "Palmeras",
        50711002: "Piñalito",
        50711007: "Pto Esperanza Margen Derecha",
        50711008: "Puerto Esperanza Margen Izquierdo",
        50711006: "Puerto Lucas Margen Derecho",
        50711005: "Puerto Lucas Margen Izquierdo",
        50711016: "Santo Domingo",
        50711000: "Vistahermosa",
      },
    },
    52: {
      52001: {
        52001039: "Anganoy",
        52001014: "Bajo Casanare",
        52001063: "Botanilla",
        52001030: "Buesaquillo",
        52001031: "Buesaquillo Alto",
        52001027: "Cabrera",
        52001055: "Canchala",
        52001060: "Castillo Loma",
        52001001: "Catambuco",
        52001019: "Cerotal",
        52001064: "Charguayaco",
        52001065: "Cruz De Amarillo",
        52001051: "Cubijan Bajo",
        52001033: "Cujacal",
        52001034: "Cujacal Bajo",
        52001090: "Cujacal Centro",
        52001042: "Daza-Morasurco Daza",
        52001029: "Dolores",
        52001066: "El Campanero",
        52001003: "El Encano",
        52001025: "El Puerto",
        52001058: "El Rosario",
        52001013: "El Socorro Cimarrón",
        52001004: "Genoy",
        52001010: "Gualmatán",
        52001086: "Gualmatan Alto",
        52001059: "Jamondino",
        52001009: "Jongovito",
        52001068: "Jurado",
        52001091: "La Alianza",
        52001087: "La Caldera",
        52001005: "La Laguna",
        52001071: "La Merced",
        52001072: "Las Encinas",
        52001092: "Las Iglesias",
        52001021: "La Victoria",
        52001056: "Los Angeles",
        52001038: "Mapachico",
        52001073: "Mapachico Alto",
        52001012: "Mapachico - Aticance",
        52001083: "Mapachico Bajo",
        52001074: "Mapachico San José",
        52001053: "Mocondino",
        52001016: "Motilon",
        52001006: "Nariño",
        52001007: "Obonuco",
        52001093: "Pendejino Reyes",
        52001089: "Puerres",
        52001094: "San Antonio De Aranda",
        52001088: "San Cayetano",
        52001052: "San Fernando",
        52001076: "San Francisco",
        52001024: "San José",
        52001078: "San Juan De Anganoy",
        52001000: "San Juan De Pasto",
        52001008: "Santa Barbara",
        52001079: "Santa Lucía",
        52001036: "Tescual",
        52001080: "Villa María",
      },
      52019: {
        52019008: "Buena Vista",
        52019004: "Campobello",
        52019009: "Carmelo Asentamiento 1",
        52019010: "Carmelo Asentamiento 2",
        52019011: "Cebadero Asentamiento 1",
        52019012: "Cebadero Asentamiento 2",
        52019013: "Fatima",
        52019002: "Guarangal",
        52019014: "San Bosco Asentamiento 1",
        52019015: "San Bosco Asentamiento 3",
        52019000: "San José de Alban",
        52019007: "Socorro Asentamiento 2",
        52019006: "Tambo Alto",
        52019016: "Tambo Bajo",
        52019017: "Viña",
      },
      52022: {
        52022000: "Aldana",
        52022003: "San Luis",
      },
      52036: {
        52036000: "Ancuyá",
        52036009: "Cocha Blanca",
        52036002: "Cruz De Mayo",
        52036001: "El Ingenio",
        52036015: "El Pedregal",
        52036013: "Indo",
        52036016: "La Aguada",
        52036003: "La Loma",
        52036014: "La Quinua",
        52036012: "Limonar",
        52036007: "Llano",
        52036005: "Macas Cruz",
        52036010: "San Luis",
        52036004: "Yanancha",
      },
      52051: {
        52051000: "Berruecos Arboleda",
        52051005: "El Empate",
        52051003: "Gomez",
        52051009: "La Cañada",
        52051006: "La Cocha",
        52051011: "Marton",
        52051001: "Rosaflorida (Cardenas)",
        52051012: "Rosaflorida Norte",
        52051007: "Rosa Florida Sur - Sector La Capilla",
        52051014: "Santa Teresa",
      },
      52079: {
        52079031: "Albo",
        52079001: "Altaquer",
        52079000: "Barbacoas",
        52079002: "Buenavista",
        52079034: "Carcuel",
        52079024: "Cargazol",
        52079025: "Cascajero",
        52079016: "Chalalbo",
        52079003: "Chalchal",
        52079004: "Chapira",
        52079033: "Coscorron Telembo",
        52079005: "Diaguillo",
        52079026: "El Diviso",
        52079017: "Guagaypo",
        52079006: "Junín",
        52079007: "Justo Ortoz",
        52079027: "La Playa",
        52079008: "Las Cruces",
        52079009: "Los Brazos",
        52079010: "Luis Avelino Perez",
        52079011: "Mongón",
        52079023: "Ñambí",
        52079012: "Olaya Herrera",
        52079013: "Pambana",
        52079028: "Paunde",
        52079032: "Pimbo",
        52079029: "Salí",
        52079022: "San Juan Palacio",
        52079018: "San Miguel Nambí",
        52079019: "Soledad",
        52079014: "Sucre Guinulte",
        52079015: "Telpo",
        52079020: "Teraimbe",
        52079021: "Yacula",
        52079030: "Yalare",
      },
      52083: {
        52083000: "Belén",
        52083001: "Santa Rosa",
      },
      52110: {
        52110009: "Altaclara",
        52110015: "Alto Higuerones",
        52110016: "Bermejal",
        52110017: "Bruselas",
        52110000: "Buesaco",
        52110018: "El Retiro",
        52110019: "El Salado",
        52110020: "Granadillo De Chavez",
        52110021: "Granadillo De Lunas",
        52110022: "Juanambu",
        52110011: "Juanamel",
        52110023: "La Inmaculada",
        52110024: "La Palma",
        52110025: "Meneses De Hurtado",
        52110026: "Naranjal",
        52110014: "Ortega",
        52110001: "Palasinoy",
        52110013: "Parapetos",
        52110002: "Rosal Del Monte",
        52110003: "San Antonio",
        52110027: "San Bosco",
        52110004: "San Ignacio",
        52110028: "San Jose De Coapitas",
        52110029: "San Miguel El Socorro",
        52110030: "San Miguel Santafe",
        52110005: "Santafé",
        52110006: "Santamaría",
        52110031: "Sumapaz",
        52110008: "Veracruz",
        52110007: "Villamoreno",
      },
      52203: {
        52203007: "El Bordo",
        52203005: "El Macal",
        52203000: "Génova",
        52203001: "Guaitarilla",
        52203002: "La Plata",
        52203006: "San Carlos",
        52203004: "Villanueva",
      },
      52207: {
        52207001: "Alfonso Lopez",
        52207004: "Alto Bomboná",
        52207014: "Alto Tinajilla",
        52207006: "Cajabamba",
        52207011: "Campamento",
        52207015: "Cariaco Bajo",
        52207016: "Churupamba",
        52207000: "Consacá",
        52207010: "El Guabo",
        52207013: "El Hatillo",
        52207017: "El Tejar",
        52207003: "Olaya Herrera",
        52207009: "Paltapamba",
        52207018: "San Antonio",
        52207005: "San Miguel De Cariaco",
        52207019: "San Rafael",
        52207020: "San Sebastian",
        52207021: "Santa Ines",
        52207007: "Veracruz",
        52207022: "Villa Ines",
      },
      52210: {
        52210001: "Aldea De Maroa",
        52210000: "Contadero",
        52210002: "La Josefina",
        52210003: "Ospina Perez",
        52210004: "Santo Domingo",
      },
      52215: {
        52215000: "Córdoba",
        52215002: "Llorente",
        52215001: "Los Arrayanes",
        52215003: "Payan",
        52215005: "Pueblo Bajo",
        52215007: "Santa Brogida",
        52215004: "Santander",
      },
      52224: {
        52224000: "Carlosama Cuaspud",
        52224003: "Chavisnan",
        52224002: "El Carchi",
        52224001: "Macas",
        52224004: "San Francisco",
      },
      52227: {
        52227001: "Chiles",
        52227000: "Cumbal",
        52227002: "Mayasquer",
        52227003: "Miraflores",
        52227006: "Nazate",
        52227004: "Panán",
        52227007: "Pueblo Viejo",
        52227005: "San Marton",
        52227008: "Talambi",
      },
      52233: {
        52233000: "Cumbitara",
        52233001: "Damasco",
        52233002: "El Desierto",
        52233010: "Guayabalito Nulpo",
        52233007: "La Esperanza",
        52233006: "Miguel Nulpi",
        52233009: "Pesqueroa",
        52233003: "Pisanda",
        52233011: "Santa Ana",
        52233008: "Santa Rosa",
        52233004: "Sidón",
      },
      52240: {
        52240001: "Arizona",
        52240002: "Caja Agraria",
        52240003: "Caño Alto",
        52240004: "Caño Bajo",
        52240000: "Chachaguí",
        52240005: "Chorrillo",
        52240006: "Guairabamba",
        52240007: "Itzamana",
        52240008: "La Loma",
        52240009: "La Venta",
        52240010: "Pasizara",
        52240011: "Santa Monica",
      },
      52250: {
        52250002: "Arenal",
        52250011: "Bahoa Mulatas",
        52250023: "Bazán",
        52250012: "Benjamon Herrera",
        52250003: "Bolívar (San Pedro)",
        52250024: "Capilla",
        52250025: "Corozo",
        52250026: "El Castigo",
        52250000: "El Charco",
        52250022: "El Cuil Pueblo Nuevo",
        52250027: "El Rosario",
        52250004: "Gaitan",
        52250028: "Guayabal",
        52250017: "Hormiguero",
        52250029: "Isupi",
        52250005: "Las Mercedes",
        52250030: "Pambilero",
        52250031: "Playa Grande",
        52250014: "Plinio Oliveros",
        52250018: "Pulbusa",
        52250032: "Pulbusa Pueblo Nuevo",
        52250006: "Roberto Payan",
        52250013: "Roo Tapaje",
        52250033: "San Antonio",
        52250019: "San Francisco De Taija",
        52250007: "San Jose Del Tapaje",
        52250034: "Santa Catalina",
        52250035: "Santa Rosa",
        52250021: "Secadero",
        52250036: "Taijita",
        52250009: "Turbay",
        52250016: "Uribe Uribe",
        52250037: "Yanzal",
      },
      52254: {
        52254000: "El Peñol",
        52254001: "Las Cochas",
        52254003: "Peñol Viejo",
        52254002: "San Francisco",
      },
      52256: {
        52256024: "El Rincon",
        52256000: "El Rosario",
        52256003: "La Esmeralda",
        52256002: "La Montaña",
        52256004: "La Sierra",
        52256012: "Marton Perez",
        52256014: "Santa Isabel",
      },
      52258: {
        52258001: "Aponte",
        52258007: "El Granadillo",
        52258000: "El Tablón De Gómez",
        52258006: "Fatima",
        52258002: "La Cueva",
        52258008: "La Isla",
        52258009: "Las Aradas",
        52258003: "Las Mesas",
        52258005: "La Victoria",
        52258010: "Los Yungas",
        52258011: "Marcella",
        52258012: "Pitalito Bajo",
        52258004: "Pompeya",
        52258013: "Porvenir",
        52258014: "San Francisco",
        52258015: "San Rafael",
        52258016: "Valencia",
      },
      52260: {
        52260011: "Azogue",
        52260000: "El Tambo",
        52260009: "Ricaurte",
        52260005: "San Pedro",
        52260010: "Tanguana",
      },
      52287: {
        52287001: "Chapal",
        52287008: "Chitarran",
        52287011: "El Terrero",
        52287000: "Funes",
        52287010: "Guapuscal Alto",
        52287002: "Guapuscal Bajo",
        52287005: "Sucumboos",
        52287012: "Tellez Alto",
      },
      52317: {
        52317002: "Chillanquer - El Consuelo",
        52317001: "Colimba",
        52317000: "Guachucal",
        52317005: "La Victoria",
        52317003: "Muellamues",
        52317004: "San Jose De Chillanquer",
      },
      52320: {
        52320001: "Ahumada",
        52320002: "Alex",
        52320007: "Buenos Aires",
        52320016: "El Rosal",
        52320005: "Esperanza",
        52320000: "Guaitarilla",
      },
      52323: {
        52323001: "Cuatis",
        52323000: "Gualmatán",
      },
      52352: {
        52352005: "Alto Del Rey",
        52352001: "Bolovar",
        52352006: "El Capuli",
        52352000: "Iles",
        52352007: "Iscuazan",
        52352008: "San Antonio",
        52352003: "San Francisco",
        52352004: "San Javier",
        52352002: "Tablon Bajo",
      },
      52354: {
        52354009: "Betania",
        52354011: "Camuestes",
        52354010: "Cuarchu",
        52354008: "El Alisal",
        52354000: "Imués",
        52354001: "Neira",
        52354012: "Neira Santa Ana",
        52354007: "Pedregal",
        52354002: "Pilcuán Recia",
        52354004: "Pilcuán Viejo (2)",
        52354005: "San Buenaventura",
        52354003: "Valencia",
      },
      52356: {
        52356000: "Ipiales",
        52356009: "Las Cruces",
        52356002: "Las Lajas",
        52356001: "La Victoria",
        52356005: "Lomas De Suras",
        52356003: "San Juan",
        52356004: "Yaramal",
      },
      52378: {
        52378013: "Cabuyales",
        52378016: "Escandoy",
        52378000: "La Cruz",
        52378017: "La Estancia",
        52378005: "San Gerardo",
        52378009: "Tajumbina",
      },
      52381: {
        52381009: "Bellavista",
        52381005: "El Barranco",
        52381007: "El Cacique",
        52381006: "El Maco",
        52381008: "El Rodeo",
        52381000: "La Florida",
        52381010: "Las Plazuelas",
        52381001: "Matituy",
        52381002: "Robles",
        52381004: "San Francisco",
        52381003: "Tunja Grande",
      },
      52385: {
        52385002: "Campanario",
        52385003: "El Palmar",
        52385004: "El Remate",
        52385005: "El Saspi",
        52385006: "Floresta",
        52385007: "Florida",
        52385008: "Guarango",
        52385000: "La Llanada",
        52385009: "Maco",
        52385010: "Murcielago",
        52385011: "Palma",
        52385012: "Prado",
        52385013: "San Francisco",
        52385014: "Santa Rosa",
        52385001: "Vergel",
      },
      52390: {
        52390005: "Aguacatal",
        52390010: "El Cedro",
        52390000: "La Tola",
        52390003: "La Vigoa",
        52390009: "Mulatos",
        52390012: "Nerete",
        52390008: "Pangamosa",
        52390007: "Poijal",
        52390004: "San Antonio De La Mar",
        52390006: "San Pablo De La Tola",
      },
      52399: {
        52399014: "Alpujarra",
        52399024: "Chaguarurco",
        52399033: "Cusillos",
        52399015: "El Sauce",
        52399034: "Juan Solarte Obando",
        52399003: "La Caldera",
        52399000: "La Unión",
        52399008: "Peña Blanca",
        52399004: "Quiroz",
        52399001: "Santander",
      },
      52405: {
        52405011: "Cañaduzal",
        52405012: "El Ofrio",
        52405001: "El Palmar",
        52405009: "El Tablon",
        52405010: "Esmeraldas",
        52405013: "Florida Baja",
        52405008: "La Florida",
        52405002: "Las Delicias",
        52405004: "La Villa",
        52405000: "Leiva",
        52405005: "Nariño",
        52405006: "Puerto Nuevo",
        52405007: "Santa Lucoa",
        52405014: "Villa Baja",
      },
      52411: {
        52411004: "Bellaflorida",
        52411000: "Linares",
        52411001: "San Francisco",
        52411002: "Tabiles",
        52411003: "Tambillo Bravos",
      },
      52418: {
        52418009: "El Carrizal",
        52418008: "El Palmar",
        52418002: "La Planada",
        52418003: "Pangus",
        52418011: "San Sebastian",
        52418000: "Sotomayor",
      },
      52427: {
        52427016: "Bella Vista",
        52427001: "Bolovar",
        52427017: "Campo Alegre",
        52427002: "Concordia",
        52427021: "El Carmelo",
        52427010: "El Trueno",
        52427013: "La Loma",
        52427015: "Las Brisas Hamburgo",
        52427018: "Las Mercedes",
        52427003: "Nansalbid",
        52427004: "Palacio",
        52427000: "Payán",
        52427005: "Piragua Gualpí",
        52427006: "Ricaurte",
        52427019: "San José",
        52427007: "San Luis",
        52427011: "San Luisito",
        52427020: "Santa Catalina",
        52427014: "Santander (Rosa)",
        52427009: "Tabujito",
      },
      52435: {
        52435002: "Chucunes",
        52435010: "El Arco",
        52435011: "El Arenal",
        52435009: "El Carmelo",
        52435003: "El Guabo",
        52435000: "Piedrahancha",
        52435008: "San Miguel",
      },
      52473: {
        52473019: "Alto Guandipa",
        52473020: "Barrera",
        52473007: "Bocas De Guandipa",
        52473021: "Caimitillal",
        52473022: "Campo Alegre",
        52473024: "Chapilar",
        52473001: "Cocalito Jiménez (Gabriel Turbay)",
        52473023: "Cocal Jimenez",
        52473004: "Cocal Payanes",
        52473025: "El Bajito",
        52473010: "El Bajito De Echandía",
        52473026: "El Cantil",
        52473009: "El Garcero",
        52473027: "El Naranjo",
        52473028: "El Playon",
        52473018: "El Tortugo",
        52473006: "Firme Cifuentes",
        52473029: "Gicrillal",
        52473030: "La Capilla",
        52473031: "La Contra",
        52473032: "Lagartera",
        52473013: "Miel De Abejas",
        52473000: "Mosquera",
        52473014: "Pampa Chapila",
        52473033: "Pampa Quiñones",
        52473034: "Piñal Relleno",
        52473015: "Playa Nueva",
        52473008: "Pueblo Nuevo",
        52473035: "Punta Piñal",
        52473016: "Salango",
        52473017: "San Francisco",
        52473036: "Tasquita",
        52473037: "Trejos",
      },
      52480: {
        52480001: "La Caldera",
        52480000: "Nariño",
      },
      52490: {
        52490001: "Alfonso Lopez Pumarejo (Florida)",
        52490002: "Bellavista",
        52490000: "Bocas De Satinga",
        52490003: "Cordoba (Carmen)",
        52490005: "Lerida (Las Maroas)",
        52490006: "Lozano Torrijos",
        52490007: "Merizalde Porvenir",
        52490008: "San José Calabazal",
        52490009: "Santander Soleda",
        52490010: "Uribe Uribe",
      },
      52506: {
        52506005: "Cunchilla Morena",
        52506003: "Gavilanes",
        52506004: "Mercedes",
        52506000: "Ospina",
        52506002: "San Isidro",
        52506006: "San Miguel",
      },
      52520: {
        52520007: "Boca De Curay",
        52520001: "Cordoba",
        52520008: "Hugo Belalcazar",
        52520009: "Jorge A.cuero",
        52520012: "La Playa",
        52520010: "Laureano Arella",
        52520011: "Luis Avelino Pérez",
        52520002: "Nicanor Valencia",
        52520003: "Patoa",
        52520004: "Pedro Veira Arias",
        52520000: "Salahonda",
        52520013: "San Pedro Del Vino",
        52520005: "Simon Bolovar",
        52520006: "Victor Calonge",
        52520014: "Vuelta Del Gallo",
      },
      52540: {
        52540001: "Altamira",
        52540005: "El Ejido",
        52540002: "Madrigal",
        52540000: "Policarpa",
        52540007: "Restrepo",
        52540004: "Sanchez",
        52540003: "San Roque (Buenavista)",
        52540006: "Santa Cruz",
      },
      52560: {
        52560001: "Cardenas",
        52560003: "Carrizal",
        52560011: "Cuaspud Nucleo",
        52560004: "La Cabaña",
        52560009: "Mueses",
        52560000: "Potosí",
        52560007: "San Antonio",
        52560002: "San Francisco Sinao",
        52560013: "San Marcos",
        52560006: "San Pedro",
        52560012: "Villa Nueva",
        52560010: "Yamuesquer",
      },
      52565: {
        52565001: "Guadrahuma",
        52565000: "Providencia",
      },
      52573: {
        52573001: "El Paramo",
        52573002: "Monopamba",
        52573000: "Puerres",
        52573003: "San Mateo",
      },
      52585: {
        52585001: "Casafroa",
        52585002: "Chires Centro",
        52585003: "El Espino",
        52585004: "José María Hernández",
        52585000: "Pupiales",
        52585007: "San Marcos",
      },
      52612: {
        52612008: "Chambu",
        52612009: "Cuaiquier Viejo",
        52612010: "Cuesbo Carretera",
        52612001: "Nulpe Alto",
        52612002: "Nulpe Medio",
        52612003: "Ospina Perez",
        52612007: "Pialapo",
        52612006: "Ramos",
        52612000: "Ricaurte",
        52612011: "San Francisco",
        52612004: "San Isidro",
        52612005: "Vegas",
        52612012: "Villa Nueva",
      },
      52621: {
        52621022: "Antonio Nariño",
        52621001: "Arteaga - Limonar",
        52621002: "Cacao",
        52621020: "Carlos Lleras Restrepo",
        52621021: "Chafalote",
        52621026: "Conquista",
        52621003: "El Guayabal",
        52621029: "El Sande Roo Ispi",
        52621004: "Fatima El Carme",
        52621005: "Gomez Jurado",
        52621030: "Guabal",
        52621006: "Gualpo",
        52621007: "Indu",
        52621025: "Inguanbi",
        52621008: "Jalaral",
        52621023: "Jorge Eliecer Gaitan",
        52621009: "Las Lajas Pumbi",
        52621028: "Las Mercedes - Chimbuza",
        52621024: "Limones Del Patoa",
        52621010: "Mialo",
        52621011: "Muñambi",
        52621012: "Negrito",
        52621013: "Paloseco",
        52621031: "Palsapi",
        52621032: "Panga",
        52621014: "Peñon G Martonez",
        52621015: "Piri (Paraoso)",
        52621016: "San Antonio - Boca Telembi",
        52621000: "San José",
        52621017: "Santa Elena",
        52621018: "Tasdan",
        52621019: "Trinidad La Merced",
        52621027: "Vuelta De Papi",
      },
      52678: {
        52678021: "Alto Canada",
        52678001: "Andalucoa",
        52678022: "Archiduque",
        52678023: "Betania",
        52678002: "Bolovar",
        52678020: "Canada",
        52678003: "Carrizal",
        52678017: "Cartagena",
        52678012: "Chuguldo",
        52678004: "Decio",
        52678016: "Doña Ana",
        52678018: "El Llano",
        52678009: "El Motilon",
        52678014: "El Salado",
        52678019: "El Salto",
        52678008: "La Aguada",
        52678024: "La Meza",
        52678005: "La Planada",
        52678006: "Maranguay",
        52678025: "Obando",
        52678026: "Piedrablanca",
        52678027: "Plan De San Marton",
        52678015: "Puerchag",
        52678011: "Sacampues",
        52678000: "Samaniego",
        52678028: "Santa Catalina",
        52678007: "Tanama",
        52678013: "Turupamba",
        52678010: "Yunguilla",
      },
      52683: {
        52683024: "20 De Julio",
        52683014: "Altamira Cruz De Arada",
        52683015: "Balcon Alto",
        52683008: "Bohorquez",
        52683001: "Bolovar",
        52683016: "Chavez",
        52683025: "Cruz Arada",
        52683002: "El Ingenio",
        52683010: "El Vergel",
        52683019: "La Regadera",
        52683006: "Loma",
        52683026: "Mundo Loma",
        52683009: "Paraguay",
        52683020: "Plan Ingenio",
        52683007: "Roma Chavez",
        52683021: "San Andres",
        52683003: "San Bernardo",
        52683000: "Sandoná",
        52683022: "San Fernando",
        52683023: "San Francisco Alto",
        52683011: "San Gabriel",
        52683012: "San Miguel",
        52683004: "Santa Bárbara",
        52683005: "Santa Rosa",
        52683017: "Tambillo",
      },
      52685: {
        52685000: "San Bernardo",
      },
      52687: {
        52687001: "El Carmen",
        52687004: "San Clemente",
        52687000: "San Lorenzo",
        52687002: "Santa Cecilia",
        52687003: "Santa Martha",
        52687005: "San Vicente",
      },
      52693: {
        52693002: "Briceño",
        52693000: "San Pablo",
      },
      52694: {
        52694001: "La Comunidad",
        52694002: "La Estancia",
        52694003: "Marton",
        52694000: "San Pedro De Cartago",
      },
      52696: {
        52696001: "Atanasio Girardot",
        52696002: "Chanzara",
        52696015: "Chico Pérez",
        52696012: "Cuerbal",
        52696006: "Francisco De Parada",
        52696000: "Iscuandé",
        52696013: "Juanchillo",
        52696014: "La Ensenada",
        52696016: "Las Marías",
        52696003: "Pacofico",
        52696009: "Palomino",
        52696004: "Sanabria",
        52696010: "San Jose",
        52696011: "San Sebastian De Belalcazar",
        52696005: "Santander",
        52696017: "Santa Rita",
        52696007: "Tomas C Mosquera",
      },
      52699: {
        52699001: "Balalaika",
        52699007: "Chipacuerdo",
        52699002: "El Eden",
        52699003: "El Sande",
        52699000: "Guachavés San Cruz",
        52699008: "Inga",
        52699004: "Manchag",
        52699005: "Piaramag",
        52699006: "Santa Rosa",
      },
      52720: {
        52720001: "El Espino",
        52720000: "Sapuyes",
      },
      52786: {
        52786006: "Alto De Diego",
        52786008: "Charguayaco",
        52786020: "Concordia",
        52786003: "Curiaco",
        52786007: "El Manzano",
        52786019: "El Remolino",
        52786002: "El Tablón",
        52786021: "Guayacanal",
        52786005: "Hueco",
        52786018: "La Granada",
        52786017: "Puerto Remolino",
        52786022: "San Isidro",
        52786000: "Taminango",
        52786011: "Taminanguito",
      },
      52788: {
        52788007: "Cebadal",
        52788003: "La Cocha",
        52788004: "San Francisco",
        52788006: "San Rafael",
        52788001: "Santander",
        52788005: "San Vicente",
        52788000: "Tangua",
        52788002: "Tapialquer Alto",
      },
      52835: {
        52835177: "Achotal",
        52835178: "Aguacate",
        52835104: "Albania",
        52835092: "Alto Agua Clara",
        52835179: "Alto Buenos Aires",
        52835180: "Alto Jagua(Rio Mira)",
        52835181: "Alto Santo Domingo",
        52835182: "Alto Villarica",
        52835183: "Bajo Buenos Aires(Tablon Salado)",
        52835154: "Bajo Guabal",
        52835107: "Bajo Jagua",
        52835020: "Barro Colorado",
        52835150: "Bellavista",
        52835167: "Boca De Tulmo",
        52835184: "Bocagrande",
        52835075: "Bocana Nueva",
        52835055: "Bocas De Curay",
        52835108: "Brisas Del Acueducto",
        52835156: "Buchely",
        52835185: "Buchely 2",
        52835109: "Cacagual",
        52835157: "Cajapí",
        52835186: "Cajapi Del Mira",
        52835068: "Caleta Viento Libre",
        52835160: "Candelillas",
        52835187: "Candelillas De La Mar",
        52835009: "Caunapí",
        52835069: "Ceibito",
        52835012: "Chajal",
        52835077: "Chilvicito",
        52835188: "Chimbuzal",
        52835162: "Chontal",
        52835010: "Colorado",
        52835153: "Congal",
        52835111: "Corriente Grande",
        52835011: "Descolgadero",
        52835159: "Dos Quebradas",
        52835063: "El Bajito",
        52835071: "El Carmen Km 36",
        52835073: "El Carmen Km 63",
        52835102: "El Coco",
        52835040: "El Progreso Santo Domingo",
        52835189: "El Progreso-Santo Domingo",
        52835118: "El Retorno",
        52835017: "Espriella",
        52835146: "Firme De Los Coimes",
        52835114: "Guabal",
        52835115: "Guachal",
        52835190: "Guachiri",
        52835191: "Guadual",
        52835116: "Gualtal",
        52835030: "Guayacana",
        52835163: "Imbilí",
        52835192: "Imbili El Guabo",
        52835037: "Imbili Miraspalmas",
        52835093: "Imbilpi Del Carmen",
        52835193: "Inda Zabaleta",
        52835099: "Inguapi Del Carmen",
        52835194: "Inguapi Del Carmen 2",
        52835195: "Inguapi Del Guayabo",
        52835152: "Inguapi El Guadual",
        52835196: "Inguapi La Chiricana",
        52835197: "Isla Grande",
        52835119: "Juan Domingo",
        52835120: "Kilómetro 28",
        52835121: "Kilómetro 35",
        52835123: "Kilómetro 58",
        52835198: "La Balsa",
        52835101: "La Barca",
        52835199: "La Brava",
        52835174: "La Brava Río Caunapí",
        52835015: "La Caleta",
        52835125: "La Chorrera",
        52835200: "La Concha(Tablon Salado)",
        52835201: "La Nupa",
        52835202: "Las Brisas",
        52835083: "La Sirena",
        52835149: "Las Mercedes",
        52835129: "La Vega",
        52835031: "Llorente",
        52835130: "Majagua",
        52835132: "Milagros",
        52835203: "Nerete(Rio Mira)",
        52835204: "Nueva Reforma",
        52835205: "Nuevo Puerto Nidia",
        52835206: "Olivo Curay",
        52835133: "Pácora",
        52835036: "Palambí",
        52835085: "Palay",
        52835064: "Papayal La Playa",
        52835207: "Paraiso",
        52835155: "Peña Colorada",
        52835134: "Pindales",
        52835208: "Piñal Dulce",
        52835161: "Piñal Salado",
        52835127: "Piñuela Rio Mira",
        52835016: "Pital",
        52835209: "Pital(Chimbuzal)",
        52835210: "Porvenir",
        52835136: "Pueblo Nuevo",
        52835211: "Pueblo Nuevo(Rio Mira)",
        52835212: "Pueblo Nuevo(Tablon Salado)",
        52835087: "Pulgande",
        52835213: "Restrepo",
        52835088: "Retoño",
        52835168: "Sagumbita",
        52835047: "Salisvo",
        52835214: "San Agustin",
        52835051: "San Antonio",
        52835220: "Sander Curay",
        52835215: "San Francisco",
        52835021: "San Jose Del Guayabo",
        52835216: "San Juan Pueblo Nuevo",
        52835217: "San Juan(Rio Mira)",
        52835042: "San Luis Robles",
        52835218: "San Pablo",
        52835164: "San Pedro Del Vino",
        52835166: "San Sebastián",
        52835100: "Santa María Rosario",
        52835091: "Santa Rosa",
        52835219: "San Vicente(Las Varas)",
        52835221: "Seis De Agosto",
        52835222: "Soledad Curay I",
        52835223: "Soledad Curay Ii",
        52835148: "Tablón Dulce La Pampa",
        52835140: "Tambillo",
        52835141: "Tangareal Carretera",
        52835224: "Tangareal Del Mira",
        52835058: "Teheran",
        52835225: "Trujillo",
        52835000: "Tumaco",
        52835059: "Uribe Uribe (Chilvi)",
        52835151: "Vaquería",
        52835158: "Variante",
        52835226: "Viguaral",
        52835050: "Villa San Juan",
        52835144: "Vuelta Candelilla",
        52835227: "Vuelta Candelillas 2",
        52835228: "Vuelta Del Carmen",
        52835165: "Vuelta Del Gallo",
        52835145: "Vuelta Larga",
        52835229: "Vuelta Larga(Rio Guanapi)",
        52835230: "Zapotal",
      },
      52838: {
        52838001: "Alban",
        52838002: "Cuatro Esquinas",
        52838018: "Guanama",
        52838016: "La Laguna",
        52838019: "Las Minas",
        52838012: "Los Arrayanes",
        52838003: "Olaya",
        52838004: "Pinzón",
        52838020: "Polachayan",
        52838010: "Rancho Grande",
        52838021: "San Carlos Quebrada Oscura",
        52838022: "San Roque",
        52838007: "Santander",
        52838000: "Túquerres",
        52838008: "Tutachag",
        52838009: "Yascual",
      },
      52885: {
        52885007: "Aguada",
        52885014: "Arguello Alto",
        52885004: "Chapacual",
        52885005: "El Placer",
        52885008: "El Rosario",
        52885010: "Inantas Alto",
        52885020: "Inantas Bajo",
        52885015: "La Cocha",
        52885016: "La Guaca",
        52885006: "Mejoa",
        52885001: "Minda",
        52885017: "Mohechiza Alto",
        52885018: "Mohechiza Bajo",
        52885011: "San Felipe",
        52885019: "San Jose De Cordoba",
        52885012: "Tacuaya",
        52885002: "Taindala",
        52885009: "Tasnaque",
        52885000: "Yacuanquer",
        52885013: "Zaragoza",
      },
    },
    54: {
      54001: {
        54001061: "Agua Blanca",
        54001001: "Agua Clara",
        54001028: "Alto Viento",
        54001025: "Arrayán",
        54001002: "Banco De Arenas",
        54001053: "Bella Vista",
        54001003: "Buena Esperanza",
        54001047: "Carmen De Tonchalá",
        54001000: "Cúcuta",
        54001054: "El Plomo",
        54001065: "El Porvenir",
        54001029: "El Prado",
        54001055: "El Suspiro",
        54001041: "Guaramito",
        54001066: "La China",
        54001062: "La Esperanza",
        54001042: "La Floresta",
        54001033: "La Jarra",
        54001043: "La Punta",
        54001056: "La Sabana",
        54001050: "Las Vacas",
        54001048: "Los Negros",
        54001063: "Minuto De Dios",
        54001064: "Nucleo Camilo Torres",
        54001057: "Nuevo Madrid",
        54001049: "Oripaya",
        54001036: "Palmarito",
        54001007: "Patillales",
        54001030: "Pórtico",
        54001038: "Puerto León",
        54001045: "Puerto Lleras",
        54001039: "Puerto Nuevo",
        54001010: "Puerto Santander",
        54001011: "Puerto Villamizar",
        54001015: "Ricaurte",
        54001058: "San Agustín De Los Pozos",
        54001017: "San Faustino",
        54001059: "San Jerónimo",
        54001018: "San Pedro",
        54001046: "Santa Cecilia",
        54001044: "Viigilancia",
      },
      54003: {
        54003000: "Ábrego",
        54003001: "Capitan Largo",
        54003002: "Casitas",
        54003015: "El Cairo",
        54003024: "El Campanario",
        54003016: "El Guamal",
        54003017: "El Higueron",
        54003004: "El Llanon",
        54003022: "El Loro",
        54003005: "El Tabaco",
        54003018: "El Tarra",
        54003009: "La Arenosa",
        54003025: "La Curva",
        54003026: "La Estancia",
        54003010: "La Maroa",
        54003021: "La Sierra",
        54003027: "La Teja",
        54003019: "Montecristo",
        54003028: "Roo Caliente",
        54003013: "San Jose",
        54003014: "San Vicente",
      },
      54051: {
        54051000: "Arboledas",
        54051002: "Castro",
        54051005: "Villa Sucre",
      },
      54099: {
        54099000: "Bochalema",
        54099001: "Espejuelos",
        54099002: "La Donjuana",
        54099003: "Monterredondo",
        54099004: "Portachuelo",
      },
      54109: {
        54109000: "Bucarasica",
        54109002: "La Curva",
        54109003: "La Sanjuana",
      },
      54125: {
        54125000: "Cácota",
        54125001: "Fenicia",
        54125002: "Icota",
        54125003: "Targuala",
      },
      54128: {
        54128000: "Cáchira",
        54128015: "El Llano",
        54128001: "La Carrera",
        54128003: "La Vega",
        54128011: "Los Mangos",
      },
      54172: {
        54172000: "Chinácota",
        54172005: "El Nuevo Diamante",
        54172001: "La Nueva Donjuana",
      },
      54174: {
        54174008: "Carrillo",
        54174000: "Chitagá",
        54174005: "Llano Grande",
        54174006: "Presidente",
        54174002: "San Luis De Chucarima",
      },
      54206: {
        54206001: "Balcones",
        54206002: "Cartagena",
        54206000: "Convención",
        54206004: "El Guamal",
        54206003: "El Hoyo",
        54206014: "Honduras",
        54206011: "La Libertad",
        54206005: "Las Mercedes",
        54206015: "La Trinidad",
        54206012: "La Vega",
        54206007: "Mesa Rica",
        54206016: "Miraflores",
        54206013: "Piedecuesta",
        54206008: "Soledad",
      },
      54223: {
        54223000: "Cucutilla",
        54223001: "Puente Julio Arboleda",
        54223004: "Roman",
        54223002: "San José De La Montaña",
      },
      54239: {
        54239000: "Durania",
      },
      54245: {
        54245001: "Astillero",
        54245002: "Bellaluz",
        54245022: "El Cajon",
        54245000: "El Carmen",
        54245004: "El Cobre",
        54245005: "El Zul",
        54245006: "Guamalito",
        54245020: "La Camorra",
        54245003: "La Culebrita",
        54245011: "La Estrella",
        54245008: "La Osa",
        54245009: "La Pelota",
        54245010: "La Quiebra",
        54245012: "Las Aguilas",
        54245013: "Las Vegas",
        54245015: "Pajitas",
        54245016: "Quebrada Arriba",
        54245021: "Quebrada Honda",
        54245017: "Santo Domingo",
      },
      54250: {
        54250005: "El Paso",
        54250000: "El Tarra",
        54250003: "Filo Gringo",
        54250006: "Las Torres",
        54250001: "Nueva Granada",
        54250002: "Oru",
        54250004: "Palmas De Vino",
      },
      54261: {
        54261007: "Astilleros",
        54261008: "Camilandia",
        54261000: "El Zulia",
        54261001: "Encerraderos",
        54261005: "La Alejandra",
        54261006: "Las Piedras",
        54261003: "La Ye - San Miguel",
      },
      54313: {
        54313000: "Gramalote",
      },
      54344: {
        54344001: "Astilleros",
        54344000: "Hacarí",
        54344009: "Las Juntas",
        54344010: "Los Laureles",
        54344003: "Maracaibo",
        54344011: "Mesitas",
        54344008: "San Jose Del Tarra",
      },
      54347: {
        54347000: "Herrán",
      },
      54377: {
        54377000: "Labateca",
        54377001: "La Cuchilla",
        54377002: "Santa Maroa",
      },
      54385: {
        54385009: "Campo Alegre",
        54385005: "El Tropezón",
        54385000: "La Esperanza",
        54385001: "La Pedregosa",
        54385006: "La Raya",
        54385002: "Leon Xiii",
        54385007: "Los Cedros",
        54385003: "Pueblo Nuevo",
        54385008: "Villamaría",
      },
      54398: {
        54398002: "Aspásica",
        54398000: "La Playa",
        54398006: "La Vega De San Antonio",
      },
      54405: {
        54405004: "Agua Linda",
        54405006: "El Trapiche",
        54405001: "La Garita",
        54405000: "Los Patios",
        54405003: "Los Vados",
      },
      54418: {
        54418000: "Lourdes",
      },
      54480: {
        54480001: "La Laguna",
        54480000: "Mutiscua",
      },
      54498: {
        54498002: "Aguasclaras",
        54498006: "Buenavista",
        54498008: "La Ermita",
        54498009: "La Floresta",
        54498000: "Ocaña",
        54498005: "Otaré",
        54498012: "Pueblo Nuevo",
      },
      54518: {
        54518000: "Pamplona",
      },
      54520: {
        54520001: "El Diamante",
        54520000: "Pamplonita",
      },
      54553: {
        54553000: "Puerto Santander",
      },
      54599: {
        54599000: "Ragonvalia",
      },
      54660: {
        54660001: "El Carmen De Nazareth",
        54660002: "La Laguna",
        54660000: "Salazar",
        54660005: "San José Del Avila",
      },
      54670: {
        54670002: "Banderas",
        54670003: "Casas Viejas",
        54670004: "El Espejo",
        54670006: "Guaduales",
        54670017: "Lagunitas",
        54670016: "La Quina",
        54670007: "Mesallana",
        54670010: "Palmarito",
        54670013: "Palmas De Vino",
        54670014: "Puente Real",
        54670011: "Quebrada Grande",
        54670018: "Quebradillas",
        54670000: "San Calixto",
        54670012: "San Jeronimo",
        54670015: "Santa Catalina",
      },
      54673: {
        54673002: "Cornejo",
        54673000: "San Cayetano",
        54673005: "Urimaco",
      },
      54680: {
        54680000: "Santiago",
      },
      54720: {
        54720002: "El Carmen",
        54720004: "Las Mercedes",
        54720003: "La Victoria",
        54720005: "Luis Vero",
        54720006: "San Marton De Loba",
        54720007: "San Roque",
        54720000: "Sardinata",
      },
      54743: {
        54743001: "Babega",
        54743002: "Belen",
        54743004: "La Laguna",
        54743003: "Los Rincón",
        54743005: "Ranchadero",
        54743000: "Silos",
      },
      54800: {
        54800001: "Alto Rosario",
        54800002: "Cerro La Flores",
        54800003: "Cuatro Esquinas",
        54800026: "El Aserrío",
        54800004: "El Guarico",
        54800010: "El Juncal",
        54800005: "El Oso",
        54800006: "El Tigre",
        54800007: "El Trigo",
        54800008: "Farache",
        54800009: "Guaranao",
        54800011: "La Cecilia",
        54800012: "La Jabonera",
        54800013: "La Teja",
        54800014: "La Trinidad",
        54800015: "Manzanares",
        54800016: "Miracotes",
        54800017: "Pilocoto",
        54800025: "Quince Letras",
        54800018: "Ramorez",
        54800019: "Roo De Oro",
        54800020: "San Jose",
        54800021: "San Juan De Dios",
        54800022: "San Pablo",
        54800000: "Teorama",
        54800023: "Travesoas",
        54800024: "Vijagual",
      },
      54810: {
        54810001: "Barco La Silla",
        54810017: "Campo Dos",
        54810011: "Campo Giles",
        54810007: "El Sesenta",
        54810002: "La Gabarra",
        54810003: "Paccelly",
        54810008: "Petrolea",
        54810000: "Tibú",
        54810006: "Tres Bocas",
        54810009: "Versalles",
      },
      54820: {
        54820004: "Gibraltar",
        54820015: "Samore",
        54820008: "San Bernardo De Bata",
        54820000: "Toledo",
      },
      54871: {
        54871000: "Villa Caro",
      },
      54874: {
        54874001: "Juan Frío",
        54874005: "La Parada",
        54874012: "Luis Carlos Galan Sarmiento",
        54874008: "Palogordo Norte",
        54874013: "Palogordo Sur",
        54874000: "Villa Del Rosario",
      },
    },
    63: {
      63001: {
        63001000: "Armenia",
        63001001: "El Caimo",
        63001002: "Murillo",
      },
      63111: {
        63111000: "Buenavista",
        63111001: "Roo Verde",
      },
      63130: {
        63130001: "Barcelona",
        63130000: "Calarcá",
        63130003: "La Bella",
        63130010: "La María",
        63130004: "La Virginia",
        63130005: "Quebradanegra",
      },
      63190: {
        63190000: "Circasia",
        63190025: "El Paraoso",
        63190010: "El Planazo",
        63190009: "El Triunfo",
        63190011: "La 18 Guayabal",
        63190001: "La Pola",
        63190026: "Los Almendros",
        63190027: "Los Pinos",
        63190017: "Los Robles",
        63190018: "Los Rosales",
        63190019: "Membrillal",
        63190028: "Monterrey",
        63190020: "Quintas Del Bosque",
        63190029: "San Antonio",
        63190013: "Urbanizacion Campestre Bosques De Toscana",
        63190012: "Urbanización El Caney",
        63190021: "Villa Jimena",
      },
      63212: {
        63212000: "Córdoba",
      },
      63272: {
        63272000: "Filandia",
        63272002: "La India",
      },
      63302: {
        63302000: "Génova",
      },
      63401: {
        63401003: "La Silvia",
        63401000: "La Tebaida",
      },
      63470: {
        63470007: "El Castillo",
        63470008: "El Gigante",
        63470010: "La Montaña",
        63470011: "Macho Negro",
        63470000: "Montenegro",
        63470004: "Once Casas",
        63470003: "Pueblo Tapado",
        63470012: "Puerto Samaria",
      },
      63548: {
        63548001: "Barragán",
        63548002: "La Mariela",
        63548000: "Pijao",
      },
      63594: {
        63594002: "El Laurel",
        63594007: "El Naranjal",
        63594005: "Pueblo Rico",
        63594006: "Puerto Alejandría",
        63594000: "Quimbaya",
      },
      63690: {
        63690001: "Boquía",
        63690008: "El Roble",
        63690005: "La Explanación",
        63690000: "Salento",
        63690009: "San Juan De Carolina",
      },
    },
    66: {
      66001: {
        66001044: "Alegrías La Gramínea (Brisas Del Condina)",
        66001001: "Altagracia",
        66001030: "Alto Alegrías",
        66001066: "Alto Erazo",
        66001002: "Arabia",
        66001039: "Barrio El Bosque",
        66001041: "Belmonte Bajo",
        66001043: "Betania",
        66001003: "Betulia",
        66001004: "Caimalito",
        66001045: "Calle Larga",
        66001067: "Canceles",
        66001046: "Caracol La Curva",
        66001047: "Cestillal",
        66001056: "Condina Guacary",
        66001068: "El Bosque",
        66001037: "El Chocho",
        66001069: "El Congolo",
        66001048: "El Contento",
        66001049: "El Crucero De Combia",
        66001070: "El Jardín",
        66001050: "El Jazmín",
        66001084: "El Jordán",
        66001051: "El Manzano",
        66001005: "El Placer (Combia)",
        66001052: "El Porvenir",
        66001007: "El Rocío",
        66001053: "Esperanza Galicia",
        66001054: "Estación Azufral",
        66001071: "Estación Villegas",
        66001055: "Estrella Morrón",
        66001072: "Galicia Alta",
        66001073: "Gilipinas",
        66001074: "Heriberto Herrera",
        66001035: "Huertas",
        66001029: "La Bananera",
        66001009: "La Bella",
        66001040: "La Cabañita",
        66001075: "La Carbonera",
        66001006: "La Convención",
        66001076: "La Coralia",
        66001058: "La Estrella",
        66001010: "La Florida",
        66001057: "Laguneta",
        66001077: "La Renta",
        66001013: "La Selva",
        66001088: "La Siria (Res Puertas)",
        66001078: "La Suiza",
        66001079: "La Ye",
        66001080: "Libare",
        66001015: "Morelia",
        66001016: "Mundo Nuevo",
        66001025: "Nueva Siria",
        66001059: "Nuevo Sol",
        66001065: "Pénjamo",
        66001000: "Pereira",
        66001032: "Pérez Alto",
        66001081: "Pérez Bajo",
        66001036: "Pital De Combia",
        66001060: "Plan De Vivienda La Unión",
        66001061: "Plan De Vivienda Yarumal",
        66001062: "Pueblo Nuevo",
        66001018: "Puerto Caldas",
        66001063: "San Carlos",
        66001020: "San José",
        66001082: "San Marino",
        66001085: "Santander",
        66001083: "Tribunas Consota",
        66001021: "Tribunas Corcega",
        66001028: "Yarumal",
        66001064: "Yarumito",
      },
      66045: {
        66045000: "Apía",
        66045004: "Jordania",
        66045009: "La María",
      },
      66075: {
        66075000: "Balboa",
        66075004: "Tambores",
        66075009: "Tres Esquinas",
      },
      66088: {
        66088006: "Andica",
        66088007: "Bajo Sirguia",
        66088001: "Baldelomar",
        66088000: "Belén De Umbría",
        66088002: "Columbia",
        66088010: "El Aguacate",
        66088011: "El Congo",
        66088003: "Mata De Guadua",
        66088008: "Providencia",
        66088004: "Puente Umbroa",
        66088009: "Santa Emilia",
        66088005: "Taparcal",
      },
      66170: {
        66170008: "Aguazul",
        66170009: "Buena Vista",
        66170010: "Comuneros",
        66170000: "Dosquebradas",
        66170011: "El Estanquillo",
        66170012: "Gaitán",
        66170013: "La Divisa",
        66170017: "La Playita",
        66170006: "La Unión",
        66170020: "Naranjales",
        66170021: "Santana Baja",
        66170022: "Villa Carola",
      },
      66318: {
        66318010: "Betania",
        66318011: "El Jordan",
        66318005: "El Paraoso",
        66318015: "El Silencio",
        66318000: "Guática",
        66318008: "Guatica Viejo",
        66318016: "Ospirma",
        66318002: "San Clemente",
        66318003: "Santa Ana",
        66318012: "Tarqui",
        66318006: "Travesías",
      },
      66383: {
        66383000: "La Celia",
        66383001: "Patio Bonito",
      },
      66400: {
        66400003: "El Aguacate",
        66400001: "La Palma",
        66400000: "La Virginia",
      },
      66440: {
        66440001: "Alto Cauca",
        66440009: "Estación Pereira",
        66440000: "Marsella",
        66440012: "Plan De Vivienda El Paraoso",
        66440008: "Plan De Vivienda El Rayo",
        66440013: "Plan De Vivienda Tacurrumbi",
        66440014: "Pueblo Tapado",
      },
      66456: {
        66456000: "Mistrató",
        66456010: "Pinar Del Roo",
        66456002: "Puerto De Oro",
        66456011: "Quebradas Arriba",
        66456003: "San Antonio Del Chami",
      },
      66572: {
        66572000: "Pueblo Rico",
        66572001: "Santa Cecilia",
        66572002: "Villa Claret",
      },
      66594: {
        66594001: "Batero",
        66594011: "Buenavista",
        66594016: "Ensenillal",
        66594002: "Irra",
        66594017: "La Esmeralda",
        66594018: "La Floresta",
        66594010: "Miracampos",
        66594012: "Moreta",
        66594005: "Murrapal",
        66594006: "Naranjal",
        66594000: "Quinchía",
        66594014: "San Jose",
        66594007: "Santa Elena",
        66594019: "Villa Rica",
      },
      66682: {
        66682019: "Bajo Samaria",
        66682021: "Cedralito",
        66682002: "Corregimiento Del Sur",
        66682003: "El Español",
        66682005: "El Guamal",
        66682022: "El Jazmon",
        66682017: "El Lembo",
        66682004: "El Manzanillo",
        66682001: "El Rodeo",
        66682015: "Fermon Lopez",
        66682016: "Guaimaral",
        66682008: "La Capilla",
        66682009: "La Estrella",
        66682020: "La Florida",
        66682018: "Los Nevados",
        66682023: "San Juan",
        66682012: "Santa Bárbara",
        66682000: "Santa Rosa De Cabal",
        66682014: "Tarapaca",
      },
      66687: {
        66687011: "Corinto",
        66687008: "El Tambo",
        66687012: "La Bamba",
        66687001: "La Bretaña",
        66687013: "La Esmeralda",
        66687002: "La Marina",
        66687003: "Peralonso",
        66687000: "Santuario",
        66687004: "Totuy",
      },
    },
    68: {
      68001: {
        68001000: "Bucaramanga",
      },
      68013: {
        68013000: "Aguada",
      },
      68020: {
        68020000: "Albania",
        68020001: "Carretero",
        68020002: "El Hatillo",
        68020003: "La Mesa",
      },
      68051: {
        68051000: "Aratoca",
        68051001: "Chiflas",
        68051012: "El Hoyo",
      },
      68077: {
        68077000: "Barbosa",
        68077001: "Cite",
      },
      68079: {
        68079000: "Barichara",
        68079002: "Butaregua",
        68079001: "Guane",
        68079003: "Paramito",
      },
      68081: {
        68081000: "Barrancabermeja",
        68081001: "El Centro",
        68081002: "El Llanito",
        68081010: "La Fortuna",
        68081009: "Los Laureles",
        68081004: "Meseta San Rafael",
        68081006: "San Rafael De Chucurí",
      },
      68092: {
        68092000: "Betulia",
        68092007: "Cerro De La Paz",
        68092001: "El Ramo",
        68092006: "La Dura",
        68092010: "La Playa",
        68092002: "Montebello",
        68092003: "Putana",
        68092004: "San Jorge",
        68092005: "Santa Barbara",
        68092011: "Sogamoso",
        68092009: "Sol De Oriente",
        68092008: "Tienda Nueva",
      },
      68101: {
        68101002: "Berbeo",
        68101000: "Bolívar",
        68101023: "Ermitaño",
        68101024: "Explanación-Cruce Nutrias",
        68101007: "Flórez",
        68101016: "Gallegos",
        68101014: "La Hermosura",
        68101015: "La Melona",
        68101026: "Puerto Guevara",
        68101025: "San Marcos",
        68101019: "San Roque",
        68101010: "Santa Rosa",
        68101012: "Trapal",
      },
      68121: {
        68121000: "Cabrera",
      },
      68132: {
        68132000: "California",
        68132002: "La Baja",
      },
      68147: {
        68147000: "Capitanejo",
      },
      68152: {
        68152000: "Carcasí",
        68152001: "El Tobal",
      },
      68160: {
        68160000: "Cepitá",
      },
      68162: {
        68162000: "Cerrito",
        68162007: "Servitá",
      },
      68167: {
        68167000: "Charalá",
        68167003: "Riachuelo",
      },
      68169: {
        68169000: "Charta",
        68169004: "Escuela De Perico",
        68169001: "La Aguada",
        68169003: "Ovejera",
      },
      68176: {
        68176000: "Chima",
        68176003: "La Chimera",
        68176004: "Palencia",
      },
      68179: {
        68179000: "Chipatá",
      },
      68190: {
        68190025: "Campo Padilla",
        68190012: "Campo Seco",
        68190028: "Cascajero",
        68190000: "Cimitarra",
        68190026: "Cruce De Santa Rosa",
        68190005: "Dos Hermanos",
        68190018: "El Aterrado",
        68190010: "Guayabito Bajo",
        68190027: "La Curva De La Nubia",
        68190020: "La Terraza",
        68190030: "La Traviata",
        68190009: "La Verde",
        68190032: "La Ye De La Torre",
        68190021: "Palmas Del Guayabito",
        68190022: "Primavera",
        68190002: "Puerto Araujo",
        68190003: "Puerto Olaya",
        68190014: "San Fernando",
        68190029: "San Juancito",
        68190023: "San Juan De La Carretera",
        68190024: "San Pedro De La Paz",
        68190006: "Santa Rosa",
        68190004: "Zambito",
      },
      68207: {
        68207000: "Concepción",
      },
      68209: {
        68209000: "Confines",
      },
      68211: {
        68211000: "Contratación",
        68211001: "San Pablo",
      },
      68217: {
        68217001: "Cincelada",
        68217000: "Coromoro",
      },
      68229: {
        68229000: "Curití",
      },
      68235: {
        68235001: "Angosturas De Los Andes",
        68235000: "El Carmen De Chucurí",
        68235004: "El Centenario",
        68235017: "La Explanacion",
        68235009: "Santo Domingo Del Ramo",
      },
      68245: {
        68245000: "El Guacamayo",
        68245003: "Santa Rita",
      },
      68250: {
        68250002: "Cruces",
        68250005: "El Danubio",
        68250006: "El Godo",
        68250000: "El Peñon",
        68250007: "Giron",
        68250008: "Junon",
        68250003: "Rio Blanco - Otoval",
        68250009: "San Francisco",
      },
      68255: {
        68255001: "Barrio Nuevo",
        68255002: "Betania",
        68255000: "El Playón",
        68255007: "Estacion Laguna",
        68255015: "La Bateca",
        68255011: "Planadas De La Arrumbazon",
        68255013: "San Bernardo",
        68255014: "San Pedro De Tigra",
      },
      68264: {
        68264001: "Canada",
        68264000: "Encino",
      },
      68266: {
        68266000: "Enciso",
        68266002: "Peña Colorada",
      },
      68271: {
        68271000: "Florián",
        68271001: "La Venta",
        68271007: "Otromundo",
        68271003: "San Antonio De Leones",
      },
      68276: {
        68276000: "Floridablanca",
        68276012: "Montearroyo Condominio",
        68276013: "Ruitoque Country Club Condominio",
        68276014: "Valle De Ruitoque",
        68276015: "Villa Edilia Condominio",
        68276016: "Villa Guadalquivir Condominio",
      },
      68296: {
        68296000: "Galán",
      },
      68298: {
        68298000: "Gámbita",
        68298002: "La Palma",
      },
      68307: {
        68307007: "Acapulco",
        68307002: "Bocas",
        68307000: "Girón",
        68307003: "Marta",
      },
      68318: {
        68318001: "Baraya",
        68318000: "Guaca",
      },
      68320: {
        68320005: "El Tirano",
        68320000: "Guadalupe",
        68320001: "La Sirena",
        68320003: "San Antonio",
      },
      68322: {
        68322000: "Guapotá",
        68322002: "La Siberia",
      },
      68324: {
        68324000: "Guavatá",
      },
      68327: {
        68327000: "Guepsa",
        68327004: "La Aurora",
        68327005: "La Portada",
        68327001: "La Virgen",
      },
      68344: {
        68344000: "Hato",
      },
      68368: {
        68368001: "Agua Fria",
        68368012: "Alto Cruces",
        68368015: "Cristales",
        68368000: "Jesús María",
        68368010: "Laderas",
      },
      68370: {
        68370000: "Jordán Sube",
      },
      68377: {
        68377003: "El Rubí",
        68377000: "La Belleza",
        68377001: "La Quitay",
        68377005: "Los Valles",
      },
      68385: {
        68385001: "Bajo Jordan",
        68385008: "Kilometro 15",
        68385009: "La India",
        68385000: "Landázuri",
        68385007: "Miralindo",
        68385005: "Plan De Armas",
        68385014: "Roo Blanco",
        68385006: "San Ignacio Del Opón",
        68385015: "San Pedro Del Opon",
      },
      68397: {
        68397002: "La Loma",
        68397000: "La Paz",
      },
      68406: {
        68406003: "El Conchal",
        68406000: "Lebrija",
        68406009: "Portugal",
        68406013: "Uribe Uribe",
        68406014: "Vanegas",
      },
      68418: {
        68418000: "Los Santos",
      },
      68425: {
        68425001: "Buenavista",
        68425002: "Buraga",
        68425005: "Ilarcuta - Tienda Nueva",
        68425003: "La Bricha",
        68425004: "La Huerta",
        68425000: "Macaravita",
        68425006: "Rasgon",
      },
      68432: {
        68432009: "Asodema",
        68432000: "Málaga",
      },
      68444: {
        68444000: "Matanza",
        68444001: "Patio Barrido",
        68444002: "Paujil",
        68444003: "San Francisco",
        68444006: "Santa Cruz De La Colina",
      },
      68464: {
        68464016: "Los Cauchos",
        68464000: "Mogotes",
        68464001: "Palmas",
        68464002: "Pitiguao",
      },
      68468: {
        68468002: "El Junco",
        68468000: "Molagavita",
      },
      68498: {
        68498000: "Ocamonte",
      },
      68500: {
        68500005: "Loma De Hoyos",
        68500003: "Monjas",
        68500000: "Oiba",
        68500004: "Pie De Alto",
        68500006: "Puente Llano",
        68500001: "San Pedro",
      },
      68502: {
        68502004: "Capilla Del Carmen",
        68502000: "Onzaga",
        68502001: "Padua",
        68502002: "Susa",
      },
      68522: {
        68522000: "Palmar",
      },
      68524: {
        68524000: "Palmas Del Socorro",
      },
      68533: {
        68533000: "Páramo",
      },
      68547: {
        68547018: "Buenos Aires Mesa Ruitoque",
        68547016: "Condominio Ruitoque Country Club",
        68547008: "Pescadero",
        68547000: "Piedecuesta",
        68547003: "Sevilla",
        68547006: "Tablanca",
        68547007: "Umpalá",
      },
      68549: {
        68549000: "Pinchote",
      },
      68572: {
        68572002: "Capilla",
        68572016: "Delicias",
        68572012: "Peña Blanca",
        68572003: "Providencia",
        68572000: "Puente Nacional",
        68572004: "Quebrada Negra",
        68572009: "Robles",
      },
      68573: {
        68573004: "Agua Linda",
        68573003: "Bocas Del Carare",
        68573001: "Campo Capote",
        68573002: "Las Montoyas",
        68573000: "Puerto Parra",
      },
      68575: {
        68575001: "Badillo",
        68575003: "Bocas Rosario",
        68575004: "Carpintero",
        68575019: "Cayumba",
        68575005: "Chingale",
        68575006: "El Guayabo",
        68575007: "El Pedral",
        68575025: "Garcoa Cadena",
        68575010: "Kilometro 16",
        68575011: "Kilometro 20",
        68575023: "Kilómetro Ocho",
        68575013: "Paturia",
        68575014: "Pradilla",
        68575015: "Puente Sogamoso",
        68575000: "Puerto Wilches",
        68575024: "San Claver",
        68575022: "Sitio Nuevo",
        68575018: "Vijagual",
      },
      68615: {
        68615002: "Cuesta Rica",
        68615036: "El Bambu",
        68615009: "La Ceiba",
        68615024: "La Musanda",
        68615026: "La Platanala",
        68615004: "La Salina",
        68615011: "Llano De Palmas",
        68615027: "Los Chorros (San José)",
        68615012: "Misijuay",
        68615013: "Papayal",
        68615008: "Popas",
        68615000: "Rionegro",
        68615033: "San Jose De Arevalo",
        68615031: "San Rafael",
        68615017: "Veinte De Julio",
      },
      68655: {
        68655001: "La Gomez",
        68655008: "Payoa Cinco",
        68655004: "Provincia",
        68655009: "Puerto Santos",
        68655000: "Sabana De Torres",
        68655002: "Sabaneta",
        68655006: "Veracruz Kilometro 80",
        68655012: "Villa Eva",
      },
      68669: {
        68669005: "Laguna De Ortices",
        68669007: "Pangote",
        68669000: "San Andrés",
      },
      68673: {
        68673002: "Alto San Roque",
        68673004: "La Carrera",
        68673005: "Las Casitas",
        68673000: "San Benito",
        68673001: "San Benito",
        68673003: "San Benito Nuevo",
      },
      68679: {
        68679000: "San Gil",
      },
      68682: {
        68682001: "Ricaurte",
        68682000: "San Joaquín",
      },
      68684: {
        68684008: "Cutaligua",
        68684000: "San José De Miranda",
        68684003: "Tequia",
      },
      68686: {
        68686000: "San Miguel",
      },
      68689: {
        68689001: "Albania",
        68689000: "San Vicente De Chucurí",
        68689012: "Yarima",
      },
      68705: {
        68705000: "Santa Bárbara",
      },
      68720: {
        68720002: "Cachipay",
        68720001: "La Aragua",
        68720004: "Plan De Alvarez",
        68720005: "San Juan Bosco De La Verde",
        68720000: "Santa Helena Del Opón",
      },
      68745: {
        68745001: "Agua Blanca",
        68745010: "Cienaga Del Opon",
        68745005: "Cinco Mil",
        68745003: "El Salto",
        68745004: "Guayabal",
        68745006: "La Llanita",
        68745009: "La Rochela",
        68745000: "Simacota",
        68745007: "Vizcaona Alta",
        68745008: "Vizcaona Baja",
      },
      68755: {
        68755000: "Socorro",
      },
      68770: {
        68770001: "Olival",
        68770002: "San José Suaita",
        68770000: "Suaita",
        68770008: "Tolotá",
        68770003: "Vado Real",
      },
      68773: {
        68773004: "Arales",
        68773001: "La Granja",
        68773002: "La Pradera",
        68773011: "Porvenir",
        68773003: "Sabana Grande",
        68773014: "San Isidro",
        68773000: "Sucre",
      },
      68780: {
        68780001: "Cachirí",
        68780007: "Cartagua",
        68780002: "El Mohan",
        68780000: "Suratá",
        68780008: "Turbay",
      },
      68820: {
        68820001: "Berlín",
        68820003: "Carrizal",
        68820005: "Gramal",
        68820002: "La Corcova",
        68820006: "Puerto Del Llano",
        68820000: "Tona",
        68820004: "Vegas",
      },
      68855: {
        68855004: "El Cerro",
        68855005: "El Guacal",
        68855006: "La Laguna",
        68855001: "Santa Teresa",
        68855000: "Valle De San José",
      },
      68861: {
        68861001: "Alto Jordán",
        68861002: "Gualilo",
        68861022: "Lomalta",
        68861024: "Los Guayabos",
        68861023: "Ropero",
        68861000: "Vélez",
      },
      68867: {
        68867001: "Borrero",
        68867002: "Tosca",
        68867000: "Vetas",
        68867003: "Volcan",
      },
      68872: {
        68872000: "Villanueva",
      },
      68895: {
        68895001: "La Fuente",
        68895002: "La Plazuela",
        68895000: "Zapatoca",
      },
    },
    70: {
      70001: {
        70001001: "Buenavista",
        70001017: "Buenavistica",
        70001019: "Castañeda",
        70001004: "Cerrito De La Palma",
        70001003: "Chocho",
        70001002: "Cruz Del Beque",
        70001005: "La Arena",
        70001006: "La Chivera",
        70001007: "La Gallera",
        70001009: "Laguna Flor",
        70001008: "La Peñata",
        70001010: "Las Huertas",
        70001011: "Las Majaguas",
        70001018: "Las Palmas",
        70001020: "Policarpa",
        70001013: "Sabanas Del Potrero",
        70001014: "San Antonio",
        70001021: "San Jacinto",
        70001015: "San Martin",
        70001022: "San Nicolas",
        70001016: "San Rafael",
        70001000: "Sincelejo",
        70001023: "Villa Rosita",
      },
      70110: {
        70110000: "Buenavista",
        70110004: "Las Chichas",
        70110005: "Los Anones",
        70110006: "Providencia",
      },
      70124: {
        70124003: "Alférez",
        70124000: "Caimito",
        70124005: "Candelaria",
        70124008: "Cedeño",
        70124001: "El Mamón",
        70124011: "La Mejoa",
        70124006: "La Solera",
        70124012: "Las Pavitas",
        70124004: "Los Cayitos",
        70124009: "Molinero",
        70124010: "Nueva Estación",
        70124013: "Nueva Estrella",
        70124014: "Nueva Fe",
        70124015: "Pampuma",
        70124002: "Siete Palmas",
        70124007: "Tofeme",
      },
      70204: {
        70204005: "Bajo Don Juan",
        70204002: "Chinulito",
        70204004: "El Cerro",
        70204006: "El Ojito",
        70204007: "Joney",
        70204000: "Ricaurte (Colosó)",
      },
      70215: {
        70215019: "Calle Nueva",
        70215001: "Cantagallo",
        70215004: "Chapinero",
        70215000: "Corozal",
        70215005: "Don Alonso",
        70215006: "El Mamón",
        70215016: "El Rincon De Las Flores",
        70215009: "Hato Nuevo",
        70215020: "La Panela",
        70215021: "Las Brujas",
        70215010: "Las Llanadas",
        70215018: "Las Peñas",
        70215011: "Las Tinas",
        70215014: "San Jose De Pileta",
        70215022: "Villa Nueva",
      },
      70221: {
        70221001: "Boca De La Ciénaga",
        70221000: "Coveñas",
        70221005: "El Mamey",
        70221002: "El Reparo",
        70221003: "Guayabal",
        70221004: "Punta Seca",
      },
      70230: {
        70230002: "Alemania",
        70230000: "Chalán",
        70230001: "La Ceiba",
        70230003: "Manzanares",
      },
      70233: {
        70233001: "Callejón",
        70233002: "Cayo De Palma",
        70233003: "Corneta",
        70233000: "El Roble",
        70233004: "El Sitio",
        70233005: "Las Tablitas",
        70233006: "Palmital",
        70233007: "Patillal",
        70233008: "Pueblo Nuevo O Grillo Alegre",
        70233009: "Rancho De La Cruz",
        70233010: "San Francisco",
        70233011: "Santa Rosa",
        70233012: "Tierra Santa",
        70233013: "Villavicencio",
      },
      70235: {
        70235001: "Baraya",
        70235000: "Galeras",
        70235007: "Pueblo Nuevo Ii",
        70235009: "Pueblo Nuevo I (Junín)",
        70235011: "Puerto Franco",
        70235002: "San Andrés De Palomo",
        70235003: "San José De Rivera",
      },
      70265: {
        70265002: "Diazgranados",
        70265004: "Gavalda",
        70265000: "Guaranda",
        70265006: "La Concordia",
        70265008: "Palmaritico",
        70265009: "Puerto Lopez",
      },
      70400: {
        70400006: "Boca Negra",
        70400003: "Cascarilla",
        70400001: "Cayo Delgado",
        70400007: "Conquitos",
        70400008: "La Gloria",
        70400004: "Las Palmitas",
        70400000: "La Unión",
        70400002: "Pajarito",
        70400005: "Sabaneta",
        70400009: "Villa Fatima",
      },
      70418: {
        70418008: "Birmania",
        70418001: "El Coley",
        70418002: "El Piñal",
        70418006: "Hatillo",
        70418000: "Los Palmitos",
        70418007: "Naranjal",
        70418003: "Palmas De Vino",
        70418004: "Sabanas De Beltrán",
        70418005: "Sabanas De Pedro",
      },
      70429: {
        70429002: "El Naranjo",
        70429017: "El Palmar",
        70429022: "Las Candelarias",
        70429005: "La Sierpita",
        70429006: "Las Palmitas",
        70429016: "Leon Blanco",
        70429023: "Los Patos",
        70429000: "Majagual",
        70429024: "Miraflores",
        70429025: "Palmarito",
        70429008: "Piza",
        70429009: "Pueblonuevo",
        70429021: "San Miguel",
        70429010: "San Roque",
        70429011: "Santander",
        70429014: "Sincelejito",
        70429012: "Tomala",
        70429013: "Zapata",
      },
      70473: {
        70473010: "Brisas Del Mar",
        70473001: "Cambimba",
        70473002: "El Rincón",
        70473003: "El Yeso",
        70473004: "Las Flores",
        70473008: "Medellon",
        70473000: "Morroa",
        70473009: "Sabanas De Cali",
        70473005: "Sabaneta",
        70473006: "Tumbatoro",
      },
      70508: {
        70508001: "Almagra",
        70508019: "Bajo Europa",
        70508020: "Bajo Grande",
        70508021: "Bajo La Palma",
        70508002: "Canutal",
        70508003: "Canutalito",
        70508022: "Centro Mula",
        70508004: "Chengue",
        70508005: "Damasco",
        70508006: "Don Gabriel",
        70508007: "El Floral",
        70508008: "El Palmar",
        70508009: "Flor Del Monte",
        70508011: "La Peña",
        70508016: "Loma Del Banco",
        70508023: "Los Andes",
        70508024: "Medellon",
        70508025: "Miramar",
        70508012: "Osos",
        70508000: "Ovejas",
        70508026: "Pedregal",
        70508013: "Pijiguay",
        70508015: "Salitral",
        70508027: "San Francisco",
        70508014: "San Rafael",
        70508018: "San Rafael Alto",
        70508029: "Santafe",
        70508028: "Santa Rosa",
        70508030: "Villa Colombia",
        70508031: "Zapato # 2 Pijiguay",
      },
      70523: {
        70523001: "Algodoncillo",
        70523005: "Centro Azul",
        70523006: "Chumpundun",
        70523007: "El Martillo",
        70523008: "El Palmar Brillante",
        70523009: "El Sabanal",
        70523002: "Guaimaral",
        70523003: "Guaimi",
        70523010: "Los Castillos",
        70523011: "Media Sombra",
        70523000: "Palmito",
        70523012: "Pueblecito",
        70523013: "Pueblo Nuevo",
        70523014: "San Miguel",
      },
      70670: {
        70670013: "Achiote",
        70670001: "Bossa Navarro",
        70670002: "Ceja Del Mango",
        70670016: "El Campo",
        70670003: "Escobar Abajo",
        70670004: "Escobar Arriba",
        70670005: "Huertas Chicas",
        70670006: "La Negra",
        70670017: "Loma De Piedra",
        70670020: "Los Perez",
        70670018: "Mata De Caña",
        70670007: "Mateo Pérez",
        70670008: "Palito",
        70670009: "Piedras Blancas",
        70670010: "Sabanalarga",
        70670000: "Sampués",
        70670011: "San Luis",
        70670012: "Segovia",
        70670019: "Siloe",
        70670022: "Villa Nueva",
      },
      70678: {
        70678022: "Cienaga Nueva",
        70678025: "Cispataca",
        70678028: "Corral Viejo",
        70678006: "Corral Viejo (Los Angeles)",
        70678002: "Cuiva",
        70678011: "Doña Ana",
        70678029: "El Cauchal",
        70678013: "El Limón",
        70678012: "Guayabal",
        70678003: "Jegua",
        70678004: "La Ceiba",
        70678032: "La Molina",
        70678033: "Las Chispas",
        70678023: "Las Delicias",
        70678015: "La Ventura",
        70678007: "Punta De Blanco",
        70678020: "Punta Nueva",
        70678034: "Rancho La Tía",
        70678000: "San Benito Abad",
        70678026: "San Isidro",
        70678009: "San Roque",
        70678010: "Santiago Apostol",
        70678027: "Villa Nueva",
      },
      70702: {
        70702001: "Albania",
        70702000: "Betulia",
        70702009: "El Socorro",
        70702002: "Hato Viejo",
        70702006: "Las Cruces",
        70702007: "Loma Alta",
        70702004: "Sabaneta",
        70702008: "Santo Tomás",
        70702005: "Villa López",
      },
      70708: {
        70708001: "Belén",
        70708002: "Buenavista",
        70708003: "Candelaria",
        70708004: "Caño Prieto",
        70708021: "Cayo De La Cruz",
        70708005: "Cuenca",
        70708006: "El Limón",
        70708015: "El Llano",
        70708024: "El Oasis",
        70708012: "El Pital",
        70708007: "El Tablón",
        70708008: "El Torno",
        70708014: "La Quebrada",
        70708009: "Las Flores",
        70708010: "Montegrande",
        70708017: "Neiva",
        70708011: "Palo Alto",
        70708028: "San Felipe",
        70708000: "San Marcos",
        70708013: "Santa Inés",
        70708029: "Sehebe",
      },
      70713: {
        70713001: "Aguacate",
        70713020: "Aguas Negras",
        70713024: "Altos De Julio",
        70713025: "Arroyo Seco",
        70713016: "Barrancas",
        70713002: "Berlín",
        70713003: "Berrugas",
        70713022: "Boca Cerrada",
        70713004: "Buenos Aires",
        70713017: "Cerro De Las Casas",
        70713015: "El Chicho",
        70713019: "El Pueblito",
        70713014: "Higuerón",
        70713005: "Labarcés",
        70713026: "Las Brisas",
        70713006: "Libertad",
        70713007: "Pajonal",
        70713018: "Pajonalito",
        70713021: "Palacios",
        70713023: "Palmira",
        70713008: "Palo Alto",
        70713027: "Pisisi",
        70713009: "Planparejo",
        70713010: "Rincón Del Mar",
        70713011: "Sabanas De Mucacal",
        70713028: "Sabanas De Rincón",
        70713029: "Sabanetica",
        70713012: "San Antonio",
        70713000: "San Onofre",
      },
      70717: {
        70717004: "El Bajo De La Alegría",
        70717003: "Numancia",
        70717005: "Perendengue",
        70717002: "Rovira",
        70717001: "San Mateo",
        70717000: "San Pedro",
      },
      70742: {
        70742002: "Bazán",
        70742004: "Cocorote",
        70742015: "Galapago",
        70742005: "Granada",
        70742013: "La Vivienda",
        70742008: "Los Limones",
        70742016: "Moralito",
        70742014: "Perendengue",
        70742000: "Sincé",
        70742010: "Valencia",
        70742011: "Vélez",
      },
      70771: {
        70771001: "Arboleda",
        70771023: "Bajo Pureza",
        70771028: "Buenavista",
        70771003: "Calzon Blanco",
        70771004: "Camajon",
        70771005: "Campo Alegre",
        70771031: "Cantarrana",
        70771007: "Chaparral",
        70771032: "Concepcion",
        70771006: "Córdoba",
        70771034: "El Cedro",
        70771008: "El Congreso",
        70771035: "El Garzal",
        70771036: "El Pajonal",
        70771037: "El Socorro",
        70771039: "Galindo",
        70771020: "Hato Nuevo",
        70771042: "Huerta Grande",
        70771009: "Isla Del Coco",
        70771010: "Isla Grande",
        70771011: "La Guaripa",
        70771024: "La Palma",
        70771044: "Las Caracuchas",
        70771045: "Las Cruces",
        70771022: "La Solera",
        70771012: "La Ventura",
        70771025: "Macheton",
        70771026: "Malambo",
        70771013: "Montería",
        70771015: "Naranjal",
        70771016: "Nariño",
        70771017: "Orejero",
        70771021: "Pampanilla",
        70771048: "Quitasueño",
        70771050: "San Jose",
        70771051: "San Jose Calastraba",
        70771018: "San Luis",
        70771027: "San Mateo",
        70771052: "San Rafael",
        70771000: "Sucre",
        70771019: "Travesoa",
      },
      70820: {
        70820001: "Coveñas",
        70820003: "Nueva Era",
        70820010: "Pita Abajo",
        70820007: "Pita En Medio",
        70820008: "Puerto Viejo",
        70820013: "Santa Lucía",
        70820000: "Santiago De Tolú",
      },
      70823: {
        70823011: "Cañito",
        70823001: "Caracol",
        70823007: "Cienaguita",
        70823016: "El Mamey",
        70823017: "El Reparo",
        70823010: "Gualón",
        70823018: "Guayabal",
        70823013: "La Floresta",
        70823006: "La Piche",
        70823012: "La Siria",
        70823002: "Las Piedras",
        70823008: "La Union",
        70823014: "Los Altos",
        70823003: "Macaján",
        70823019: "Monaco",
        70823015: "Nueva Esperanza",
        70823004: "Palmira",
        70823020: "Punta Seca-Santafe",
        70823000: "Tolú Viejo",
        70823021: "Torrente",
        70823005: "Varsovia",
      },
    },
    73: {
      73001: {
        73001032: "Alto De Gualanday",
        73001034: "Aparco",
        73001036: "Briceño",
        73001001: "Buenos Aires",
        73001060: "Calandaima",
        73001017: "Carmen De Bulira",
        73001061: "Cataima",
        73001030: "Cay",
        73001026: "Charco Rico",
        73001038: "Chembe",
        73001039: "Chucuní",
        73001020: "Coello - Cocora",
        73001043: "Curalito",
        73001004: "Dantas",
        73001062: "El Ingenio",
        73001018: "El Rodeo",
        73001015: "El Totumo",
        73001029: "Gamboa",
        73001064: "Granja Buenos Aires",
        73001000: "Ibagué",
        73001006: "Juntas",
        73001028: "La Flor",
        73001047: "La Helena",
        73001048: "La Maroa",
        73001049: "La Miel",
        73001050: "La Palmilla",
        73001007: "Laureles",
        73001014: "Llanitos",
        73001016: "Llano Del Combeima",
        73001057: "Los Túneles",
        73001027: "Pastales Nuevo",
        73001025: "Pastales Viejo",
        73001058: "Pico De Oro",
        73001009: "San Bernardo",
        73001063: "San Cayetano",
        73001010: "San Juan De La China",
        73001024: "Santa Teresa",
        73001011: "Tapias",
        73001012: "Toche",
        73001059: "Tres Esquinas",
        73001013: "Villarestrepo",
      },
      73024: {
        73024006: "Achiral",
        73024000: "Alpujarra",
        73024003: "Ameses",
        73024002: "El Carmen",
        73024001: "La Arada",
        73024005: "La Lindosa",
        73024008: "Los Medios",
        73024004: "San Luis",
        73024007: "Vega Grande",
      },
      73026: {
        73026000: "Alvarado",
        73026001: "Caldas Viejo",
        73026008: "La Tebaida",
        73026004: "Rincon Chipalo",
        73026011: "Totarito",
        73026005: "Veracruz",
      },
      73030: {
        73030000: "Ambalema",
        73030007: "Boqueron",
        73030002: "Chorrillo",
        73030006: "La Aldea El Danubio",
        73030004: "Pajonales",
      },
      73043: {
        73043000: "Anzoátegui",
        73043001: "Lisboa",
        73043002: "Palomar",
        73043003: "Santa Bárbara",
        73043004: "Santa Rita",
      },
      73055: {
        73055000: "Armero Guayabal",
        73055002: "Méndez",
        73055007: "Nuevo Horizonte",
        73055004: "San Felipe",
        73055006: "San Lorenzo",
        73055003: "San Pedro",
      },
      73067: {
        73067009: "Andes Estrellas",
        73067000: "Ataco",
        73067010: "Berlon",
        73067001: "Campohermoso",
        73067003: "Casa De Zinc",
        73067004: "Casa Verde",
        73067016: "Cóndor",
        73067014: "El Paujil",
        73067015: "La Ceja (Mesitas)",
        73067005: "Mesa De Pole",
        73067012: "Monteloro",
        73067006: "Polecito",
        73067018: "Pomarroso",
        73067019: "San Antonio Pole",
        73067007: "Santiago Pérez",
      },
      73124: {
        73124001: "Anaime",
        73124000: "Cajamarca",
      },
      73148: {
        73148000: "Carmen De Apicalá",
      },
      73152: {
        73152000: "Casabianca",
        73152002: "San Jerónimo",
      },
      73168: {
        73168000: "Chaparral",
        73168004: "El Limón",
        73168018: "Hato Viejo",
        73168005: "La Marina",
        73168006: "La Profunda",
        73168013: "La Virginia Baja",
        73168016: "Risaralda Calarma",
        73168015: "San Bartolome De Amoya",
        73168007: "San Jose De Las Hermosas",
      },
      73200: {
        73200006: "Caimito",
        73200017: "Calabozo",
        73200008: "Chaguala Afuera",
        73200000: "Coello",
        73200009: "Cunira",
        73200010: "Dosquebradas",
        73200001: "Gualanday",
        73200011: "La Arenosa",
        73200002: "La Barrialosa",
        73200012: "La Salina",
        73200003: "Llano De La Virgen",
        73200013: "Lucha Adentro",
        73200014: "Lucha Afuera",
        73200004: "Potrerillo",
        73200015: "Santa Barbara",
        73200005: "Vega Los Padres",
        73200016: "Vindi",
      },
      73217: {
        73217001: "Castilla",
        73217000: "Coyaima",
        73217011: "Guayaquil",
        73217003: "Mesas San Juan",
        73217004: "Santa Marta",
        73217005: "Totarco Dinde",
      },
      73226: {
        73226007: "Buenavista",
        73226000: "Cunday",
        73226008: "El Reves",
        73226001: "La Aurora",
        73226009: "La Victoria",
        73226002: "San Pablo",
        73226003: "Tres Esquinas",
        73226004: "Valencia",
        73226005: "Varsovia",
      },
      73236: {
        73236001: "Ambica",
        73236002: "Bermejo",
        73236000: "Dolores",
        73236003: "El Carmen",
        73236010: "La Soledad",
        73236007: "Los Llanitos",
        73236009: "Palos Altos",
        73236004: "Rionegro",
        73236005: "San Andrés",
        73236006: "San Jose",
        73236011: "San Pedro",
      },
      73268: {
        73268001: "Chicoral",
        73268000: "Espinal",
        73268003: "San Francisco",
      },
      73270: {
        73270000: "Falan",
        73270001: "Frías",
        73270004: "Piedecuesta",
      },
      73275: {
        73275003: "Alfonso Lopez",
        73275004: "Camala",
        73275001: "El Colegio",
        73275000: "Flandes",
        73275005: "Paradero I",
        73275006: "Paradero Ii (Tarqui)",
        73275008: "Paraoso",
        73275007: "Puerta Blanca",
        73275002: "Topacio",
      },
      73283: {
        73283009: "Agua Claras",
        73283001: "Betania",
        73283012: "Brisas Del Gualo",
        73283010: "Campeon",
        73283003: "El Tablazo",
        73283000: "Fresno",
        73283004: "La Aguadita",
        73283011: "Piedra Grande",
        73283008: "San Bernardo",
      },
      73319: {
        73319011: "Barroso",
        73319013: "Cañada Rodeo",
        73319016: "Cerezuela Garzas",
        73319001: "Cerrogordo",
        73319005: "Chipuelo Oriente",
        73319014: "Chontaduro",
        73319012: "El Jardon",
        73319008: "Guamal",
        73319000: "Guamo",
        73319002: "La Chamba",
        73319009: "La Troja",
        73319010: "Loma De Luisa",
        73319015: "Pueblo Nuevo",
        73319004: "Rincon Santo",
      },
      73347: {
        73347001: "Brasil",
        73347000: "Herbeo",
        73347003: "Letras",
        73347004: "Mesones",
        73347005: "Padua",
      },
      73349: {
        73349000: "Honda",
        73349001: "Perico",
      },
      73352: {
        73352001: "Balconcitos",
        73352002: "Boquerón",
        73352006: "Cafreroa",
        73352000: "Icononzo",
        73352003: "Mundo Nuevo",
        73352005: "Patecuinde",
      },
      73408: {
        73408001: "Delicias",
        73408005: "Iguasitos",
        73408002: "La Sierra",
        73408000: "Lérida",
        73408003: "Padilla",
      },
      73411: {
        73411009: "Campo Alegre",
        73411002: "Convenio",
        73411000: "Líbano",
        73411006: "San Fernando",
        73411010: "San Jorge",
        73411007: "Santa Teresa",
        73411008: "Tierradentro",
      },
      73443: {
        73443005: "Buenavista",
        73443006: "Camelias",
        73443001: "El Hatillo",
        73443007: "El Rodeo",
        73443004: "La Albania",
        73443002: "La Cabaña",
        73443008: "La Parroquia",
        73443009: "Las Maroas",
        73443003: "Pitalito",
        73443000: "San Sebastián De Mariquita",
        73443010: "Villa Janeth",
      },
      73449: {
        73449006: "Balcones Del Sumapaz",
        73449002: "Chimbo",
        73449001: "Cualamana",
        73449008: "El Ruby",
        73449009: "La Estancia",
        73449010: "La Estancia I  Y Ii",
        73449000: "Melgar",
        73449015: "Quebraditas",
        73449012: "Quebraditas 1",
        73449013: "Quebraditas 2",
        73449014: "San Jose De La Colorada",
      },
      73461: {
        73461001: "El Bosque",
        73461000: "Murillo",
      },
      73483: {
        73483009: "Balsillas",
        73483001: "La Palmita",
        73483010: "Montefroo",
        73483000: "Natagaima",
        73483007: "Palma Alta",
        73483005: "Pocharco",
        73483011: "Pueblo Nuevo",
        73483008: "Rincon Anchique",
        73483003: "Tamirco",
        73483002: "Velú",
      },
      73504: {
        73504009: "El Vergel",
        73504003: "Guaipa",
        73504004: "Hato De Iglesia",
        73504007: "La Mesa De Ortega",
        73504008: "Olaya Herrera",
        73504000: "Ortega",
        73504025: "Puente Cucuana",
        73504026: "Samaria",
      },
      73520: {
        73520000: "Palocabildo",
      },
      73547: {
        73547001: "Chicala",
        73547002: "Doima",
        73547003: "Guataquisito",
        73547005: "Paradero Chipalo",
        73547000: "Piedras",
      },
      73555: {
        73555001: "Bilbao",
        73555006: "Bruselas",
        73555002: "Gaitania",
        73555003: "La Estrella",
        73555000: "Planadas",
        73555005: "Río Claro",
        73555007: "San Miguel",
        73555004: "Sur De Ata",
      },
      73563: {
        73563001: "Aco",
        73563002: "Catalan",
        73563007: "Chenchito",
        73563008: "Corinto-Pioinia",
        73563009: "Corozales",
        73563010: "El Caiman",
        73563003: "El Conchal",
        73563011: "El Cruce",
        73563012: "El Fique",
        73563013: "El Puerto",
        73563014: "Isla Del Sol",
        73563015: "La Chica",
        73563016: "La Primavera",
        73563018: "Las Brisas",
        73563017: "La Virginia",
        73563006: "Malta",
        73563004: "Montoso",
        73563019: "Ojo De Agua",
        73563020: "Peñon Alto",
        73563000: "Prado",
        73563005: "Tafurito",
        73563021: "Tomogo",
        73563022: "Tortugas",
      },
      73585: {
        73585015: "Buenavista",
        73585016: "Cairo Socorro",
        73585001: "Chenche Asoleado",
        73585020: "Chenche Dos",
        73585019: "Chenche Tres",
        73585018: "Chenche Uno",
        73585005: "Consuel Y Tigre",
        73585009: "El Baura",
        73585013: "La Mata",
        73585002: "Lozania",
        73585000: "Purificación",
        73585007: "Villa Colombia",
        73585006: "Villa Esperanza",
      },
      73616: {
        73616005: "Gaitan",
        73616001: "Herrera",
        73616003: "La Lindosa",
        73616006: "Maracaibo",
        73616004: "Palonegro",
        73616002: "Puerto Saldaña",
        73616000: "Rioblanco",
      },
      73622: {
        73622002: "El Cedro",
        73622000: "Roncesvalles",
        73622001: "Santa Elena",
      },
      73624: {
        73624001: "El Corazón",
        73624007: "Guadualito",
        73624006: "Hato Viejo",
        73624008: "La Florida",
        73624012: "La Luisa",
        73624010: "La Selva",
        73624003: "Los Andes",
        73624011: "Martonez",
        73624013: "Pando Prado",
        73624004: "Riomanso",
        73624000: "Rovira",
        73624005: "San Pedro",
      },
      73671: {
        73671003: "Cerritos",
        73671004: "Concordia",
        73671005: "Cucharo",
        73671006: "El Progreso",
        73671007: "El Redil",
        73671001: "Jabalcon",
        73671008: "La Esperanza",
        73671009: "Normandoa",
        73671010: "Palmar Arenosa",
        73671011: "Palmar Trincadero",
        73671012: "Papagala",
        73671013: "Parcelacion San Carlos",
        73671014: "Pueblo Nuevo",
        73671000: "Saldaña",
        73671015: "San Aguston",
        73671002: "Santa Ines",
      },
      73675: {
        73675001: "La Florida",
        73675002: "Playarrica",
        73675000: "San Antonio",
        73675004: "Villa Hermosa",
      },
      73678: {
        73678001: "El Salitre",
        73678007: "Guasimito",
        73678008: "Luisa Garcoa",
        73678003: "Malnombre",
        73678004: "Payandé",
        73678000: "San Luis",
        73678009: "Santa Lucoa",
        73678006: "Tomogo",
      },
      73686: {
        73686001: "Colón",
        73686004: "La Congoja",
        73686002: "La Estrella",
        73686003: "San Rafael",
        73686000: "Santa Isabel",
      },
      73770: {
        73770005: "Agua Blanca",
        73770001: "Hato Viejo",
        73770000: "Suárez",
      },
      73854: {
        73854002: "Buenavista Baja",
        73854001: "La Manga",
        73854003: "Neme",
        73854004: "Vallecito",
        73854000: "Valle De San Juan",
      },
      73861: {
        73861001: "Junín",
        73861002: "La Sierrita",
        73861003: "Malabar",
        73861004: "Palmarosa",
        73861000: "Venadillo",
        73861005: "Vile",
      },
      73870: {
        73870004: "Platanillal",
        73870000: "Villahermosa",
      },
      73873: {
        73873002: "La Colonia",
        73873001: "La Mercadilla",
        73873003: "Los Alpes",
        73873004: "Puerto Lleras",
        73873000: "Villarrica",
      },
    },
    76: {
      76001: {
        76001040: "Altos De Normandia-La Ermita",
        76001039: "Altos De Normandia - Sector Tres Cruces Bajo",
        76001022: "Brisas De Montebello",
        76001023: "Campo Alegre",
        76001024: "Cascajal I",
        76001019: "Cascajal Ii",
        76001041: "Cascajal Iii",
        76001025: "Crucero Alto De Los Mangos",
        76001042: "El Estero",
        76001026: "El Filo",
        76001004: "El Hormiguero",
        76001027: "El Portento",
        76001001: "El Saladito",
        76001002: "Felidia",
        76001003: "Golondrinas",
        76001005: "La Buitrera",
        76001006: "La Castilla",
        76001007: "La Elvira",
        76001047: "La Fonda",
        76001028: "La Fragua",
        76001008: "La Leonera",
        76001043: "La Luisa",
        76001009: "La Paz",
        76001044: "La Sirena",
        76001030: "Las Palmas",
        76001045: "Las Palmas",
        76001029: "La Vorágine",
        76001010: "Los Andes",
        76001031: "Los Cerros",
        76001048: "Los Limones",
        76001032: "Montañitas (Alto Y Bajo)",
        76001016: "Montebello",
        76001049: "Morgan",
        76001012: "Navarro",
        76001013: "Pance",
        76001014: "Pichinde",
        76001034: "Pizamos",
        76001035: "Pueblo Nuevo",
        76001036: "San Francisco",
        76001037: "San Isidro",
        76001000: "Santiago De Cali",
        76001046: "Siloe",
        76001020: "Villacarmelo",
        76001038: "Villa Flamenco",
      },
      76020: {
        76020000: "Alcalá",
        76020006: "La Floresta",
      },
      76036: {
        76036001: "Altaflor",
        76036000: "Andalucía",
        76036002: "Campoalegre",
        76036003: "El Salto",
        76036010: "Madre Vieja",
        76036009: "Monte Hermoso",
        76036004: "Pardo",
        76036008: "Potrerillo",
        76036006: "Tamboral",
        76036005: "Zabaletas",
      },
      76041: {
        76041020: "Alto Bonito",
        76041001: "Anacaro",
        76041000: "Ansermanuevo",
        76041003: "El Billar",
        76041005: "El Roble",
        76041006: "El Vergel",
        76041013: "Gramalote",
        76041021: "Salazar",
      },
      76054: {
        76054000: "Argelia",
        76054004: "El Raizal",
        76054005: "La Aurora",
        76054003: "Maracaibo",
      },
      76100: {
        76100012: "Aguas Lindas",
        76100001: "Betania",
        76100000: "Bolívar",
        76100002: "Cerro Azul",
        76100018: "Cristales",
        76100004: "Dosquebradas",
        76100005: "Guare",
        76100006: "La Herradura",
        76100019: "La Plazuela",
        76100007: "La Tulia",
        76100008: "Naranjal",
        76100009: "Primavera",
        76100010: "Ricaurte",
        76100014: "San Fernando",
      },
      76109: {
        76109001: "Aguaclara",
        76109079: "Aguamansa",
        76109048: "Aragon",
        76109049: "Auca",
        76109006: "Bajo Calima",
        76109002: "Barco",
        76109064: "Bartola",
        76109083: "Bendiciones",
        76109024: "Bocas De Mayorquin",
        76109000: "Buenaventura",
        76109052: "Calle Larga",
        76109085: "Calle Larga-Aeropuerto",
        76109114: "Calle Larga-Rio Mayorquin",
        76109086: "Camino Viejo-Km 40",
        76109087: "Campo Hermoso",
        76109080: "Cascajita",
        76109053: "Chamuscado",
        76109008: "Cisneros",
        76109054: "Citronela",
        76109018: "Concepción",
        76109009: "Córdoba",
        76109058: "El Barranco",
        76109084: "El Cacao",
        76109010: "El Carmen",
        76109088: "El Crucero",
        76109089: "El Encanto",
        76109102: "El Limones",
        76109090: "El Llano",
        76109091: "El Salto",
        76109092: "Guadualito",
        76109061: "Guaimia",
        76109082: "Horizonte",
        76109093: "Joaquincito",
        76109046: "Juanchaco",
        76109062: "Juntas",
        76109094: "Katanga",
        76109095: "La Balastrera",
        76109045: "La Barra",
        76109003: "La Bocana",
        76109096: "La Comba",
        76109097: "La Contra",
        76109066: "La Delfina",
        76109021: "Ladrilleros",
        76109098: "La Fragua",
        76109019: "La Plata",
        76109101: "Las Palmas",
        76109100: "La Vuelta",
        76109022: "Llano Bajo",
        76109069: "Papayal",
        76109104: "Papayal 2",
        76109011: "Pastico",
        76109047: "Pianguita",
        76109105: "Pital",
        76109012: "Pital",
        76109099: "Primavera",
        76109028: "Puerto Merizalde",
        76109081: "Punta Bonita",
        76109030: "Punta Soldado",
        76109042: "Sabaletas",
        76109106: "Sagrada Familia",
        76109107: "San Antonio",
        76109031: "San Antonio(Yurumanguí)",
        76109108: "San Antoñito(Yurumangui)",
        76109071: "San Cipriano",
        76109032: "San Francisco De Naya",
        76109033: "San Francisco Javier",
        76109034: "San Isidro",
        76109109: "San Isidro(Cajambre)",
        76109072: "San Joaquín",
        76109041: "San Jose",
        76109074: "San José De Naya",
        76109036: "San Lorenzo",
        76109076: "San Marcos",
        76109037: "San Pedro",
        76109077: "Santa Cruz",
        76109110: "Santa María",
        76109111: "Secadero",
        76109038: "Silva",
        76109039: "Taparal",
        76109017: "Triana",
        76109112: "Umane",
        76109040: "Veneral",
        76109113: "Villa Estela",
        76109043: "Zacarías",
        76109078: "Zaragoza",
      },
      76111: {
        76111022: "Alaska",
        76111023: "Callejón Academia Militar",
        76111015: "Crucero - Nogales",
        76111021: "El Manantial",
        76111002: "El Placer",
        76111016: "El Porvenir",
        76111005: "El Vinculo",
        76111000: "Guadalajara De Buga",
        76111029: "Guadalejo",
        76111001: "La Campiña",
        76111030: "La Granjita",
        76111006: "La Habana",
        76111020: "La Magdalena",
        76111007: "La María",
        76111024: "La Palomera",
        76111025: "La Unidad",
        76111008: "Los Bancos",
        76111010: "Monterrey",
        76111018: "Pueblo Nuevo",
        76111026: "Puerto Bertin",
        76111012: "Quebradaseca",
        76111013: "Roo Loro/la Mesa",
        76111027: "San Antonio",
        76111014: "Zanjón Hondo",
      },
      76113: {
        76113017: "Almendronal",
        76113000: "Bugalagrande",
        76113001: "Ceilán",
        76113015: "Chicoral",
        76113002: "Chorreras",
        76113003: "El Guayabo",
        76113016: "El Overo (Sector La María)",
        76113004: "El Overo (Sector Poblado)",
        76113008: "Galicia",
        76113018: "La Esmeralda",
        76113009: "La Morena",
        76113010: "Mestizal",
        76113011: "Paila Arriba",
        76113012: "San Antonio",
        76113019: "Tetillal",
        76113013: "La Uríbe",
      },
      76122: {
        76122001: "Aures",
        76122009: "Barragán",
        76122000: "Caicedonia",
        76122006: "Samaria",
      },
      76126: {
        76126000: "Darién",
        76126001: "El Boleo",
        76126002: "El Diamante",
        76126017: "El Mirador",
        76126019: "El Vergel",
        76126007: "La Gaviota",
        76126018: "La Playa",
        76126016: "La Primavera",
        76126013: "Puente Tierra",
        76126014: "Riobravo",
        76126015: "San Jose",
      },
      76130: {
        76130017: "Brisas Del Fraile",
        76130001: "Buchitolo",
        76130000: "Candelaria",
        76130018: "Cantalomota",
        76130019: "Caucaseco",
        76130013: "Cavasa",
        76130025: "Chondular",
        76130020: "Domingo Largo",
        76130002: "El Arenal",
        76130003: "El Cabuyal",
        76130004: "El Carmelo",
        76130026: "El Dinamo",
        76130015: "El Gualí",
        76130005: "El Lauro",
        76130014: "El Otoño",
        76130027: "El Silencio",
        76130006: "El Tiple",
        76130028: "El Triunfo",
        76130029: "El Tunal",
        76130030: "El Tuno",
        76130031: "Guasimal",
        76130007: "Juanchito",
        76130021: "La Albania",
        76130032: "La Capilla",
        76130022: "La Gloria",
        76130033: "La Oliva",
        76130009: "La Regina",
        76130035: "Las Cuarenta",
        76130034: "La Solorza",
        76130036: "Los Alpes",
        76130037: "Los Potes",
        76130011: "Madrevieja",
        76130038: "Patio Bonito",
        76130016: "Poblado Campestre",
        76130024: "Pueblito Viejo",
        76130039: "San Andresito",
        76130012: "San Joaquín",
        76130040: "San Jorge",
        76130041: "Tiple Abajo",
        76130042: "Tiple Arriba",
        76130023: "Tres Tusas",
        76130008: "Villa Gorgona",
        76130043: "Vuelta De San Diego",
        76130044: "Zainera",
      },
      76147: {
        76147000: "Cartago",
        76147002: "Cauca",
        76147003: "Coloradas",
        76147012: "Guanabano",
        76147013: "Guayabito",
        76147004: "La Grecia",
        76147005: "Modín",
        76147006: "Piedra De Moler",
        76147014: "Zanjón Cauca",
        76147010: "Zaragoza",
      },
      76233: {
        76233001: "Atuncela",
        76233002: "Borrero Ayerbe",
        76233004: "Cisneros",
        76233000: "Dagua",
        76233005: "El Carmen",
        76233034: "El Cauchal",
        76233035: "El Chilcal",
        76233006: "El Danubio",
        76233007: "El Limonar",
        76233008: "El Naranjo",
        76233009: "El Palmar",
        76233010: "El Piñal",
        76233011: "El Queremal",
        76233030: "El Rucio",
        76233012: "El Salado",
        76233031: "Jiguales",
        76233027: "Juntas",
        76233029: "Kilometro 18",
        76233036: "Kilometro 26",
        76233037: "Kilometro 30",
        76233014: "La Cascada",
        76233015: "La Elsa",
        76233038: "La Guinea",
        76233041: "Las Camelias",
        76233039: "La Virgen",
        76233040: "La Yolomba",
        76233017: "Lobo Guerrero",
        76233018: "Los Alpes",
        76233032: "Los Cristales",
        76233042: "Machado",
        76233016: "Providencia",
        76233033: "Pueblo Nuevo",
        76233043: "Puerta Dagua",
        76233019: "San Bernardo",
        76233021: "Santa Maroa",
        76233020: "San Vicente",
        76233044: "Vergel",
        76233024: "Villahermosa",
        76233025: "Zabaletas",
        76233026: "Zelandia",
      },
      76243: {
        76243008: "Cañaveral - Villanueva",
        76243001: "Catarina - El Embal",
        76243000: "El Águila",
        76243012: "El Aguila - Cajones",
        76243031: "El Guayabo",
        76243002: "La Esparta",
        76243003: "La Guayacana(La Esparta)",
        76243005: "La María - Quebradagrande",
        76243030: "La Quiebra De San Pablo",
        76243006: "San Jose",
        76243009: "Santa Helena",
      },
      76246: {
        76246001: "Albán",
        76246000: "El Cairo",
      },
      76248: {
        76248001: "Aujo",
        76248014: "Campoalegre",
        76248002: "Carrizal",
        76248003: "El Castillo",
        76248000: "El Cerrito",
        76248012: "El Moral",
        76248005: "El Placer",
        76248006: "El Pomo",
        76248018: "La Honda",
        76248013: "Los Andes",
        76248008: "San Antonio",
        76248009: "Santa Elena",
        76248010: "Santa Luisa",
        76248011: "Tenerife",
      },
      76250: {
        76250003: "Bitaco",
        76250000: "El Dovio",
        76250007: "Lituania",
      },
      76275: {
        76275004: "Chococito",
        76275005: "El Lobano",
        76275015: "El Pedregal",
        76275025: "El Tamboral",
        76275000: "Florida",
        76275006: "La Diana",
        76275019: "Las Guacas",
        76275008: "La Union",
        76275021: "Los Caleños",
        76275009: "Pueblo Nuevo",
        76275010: "Remolino",
        76275011: "San Antonio De Los Caballeros",
        76275012: "San Francisco (El Llanito)",
        76275013: "Santa Rosa",
        76275016: "Santo Domingo",
        76275028: "Simón Bolívar",
        76275014: "Tarragona",
        76275024: "Tarragona Parte Alta",
      },
      76306: {
        76306001: "Costa Rica",
        76306000: "Ginebra",
        76306003: "Juntas",
        76306002: "La Floresta",
        76306009: "La Selva",
        76306007: "Los Medios",
        76306005: "Sabaletas",
        76306008: "Villa Vanegas",
      },
      76318: {
        76318009: "Alto De Guacas",
        76318011: "Cananguá",
        76318012: "El Placer",
        76318013: "El Triunfo",
        76318003: "Guabas",
        76318004: "Guabitas",
        76318000: "Guacarí",
        76318006: "Pichichí",
        76318010: "Puente Rojo",
        76318007: "Santa Rosa De Tapias",
        76318008: "Sonso",
      },
      76364: {
        76364001: "Ampudia",
        76364002: "Bocas Del Palo",
        76364017: "Chagres",
        76364022: "El Guaval",
        76364023: "El Triunfo",
        76364003: "Guachinte",
        76364000: "Jamundí",
        76364004: "La Liberia",
        76364018: "La Meseta",
        76364019: "La Ventura",
        76364005: "Paso De La Bolsa",
        76364020: "Peon",
        76364006: "Potrerito",
        76364008: "Puente Vélez",
        76364009: "Quinamayo",
        76364010: "Robles",
        76364011: "San Antonio",
        76364021: "San Isidro",
        76364012: "San Vicente",
        76364013: "Timba",
        76364014: "Villa Colombia",
        76364015: "Villapaz",
      },
      76377: {
        76377008: "Arboledas",
        76377001: "Bitaco",
        76377009: "Jiguales",
        76377000: "La Cumbre",
        76377002: "La Maroa",
        76377003: "Lomitas",
        76377004: "Pavas",
        76377010: "Pavitas",
        76377005: "Puente Palo",
        76377011: "Tres Esquinas",
      },
      76400: {
        76400009: "Campo Alegre",
        76400001: "Corcega",
        76400010: "El Guasimo",
        76400002: "El Lindero",
        76400011: "El Lucero",
        76400003: "La Aguada",
        76400012: "La Campesina",
        76400004: "La Despensa",
        76400000: "La Unión",
        76400013: "Pajaro De Oro",
        76400005: "Quebradagrande",
        76400008: "Sabanazo",
        76400006: "San Luis",
        76400014: "San Miguel",
        76400007: "San Pedro",
      },
      76403: {
        76403003: "Holguín",
        76403000: "La Victoria",
        76403004: "Miravalles",
        76403005: "Riveralta",
        76403006: "San José",
        76403007: "San Pedro",
        76403009: "Taguales",
      },
      76497: {
        76497001: "Cruces",
        76497002: "El Chuzo",
        76497003: "Juan Díaz",
        76497000: "Obando",
        76497005: "Puerto Molina",
        76497006: "Puerto Samaria",
        76497007: "San Isidro",
        76497008: "Villa Rodas",
      },
      76520: {
        76520001: "Aguaclara",
        76520002: "Amaime",
        76520003: "Ayacucho",
        76520004: "Barrancas",
        76520005: "Bolo Alizal",
        76520041: "Bolo Barrio Nuevo",
        76520006: "Bolo La Italia",
        76520043: "Bolomadre Vieja",
        76520007: "Bolo San Isidro",
        76520008: "Boyacá",
        76520009: "Caluce-Plan De Vivienda Los Guayabos",
        76520010: "Caucaseco",
        76520013: "Chontaduro",
        76520011: "Combia",
        76520012: "Coronado",
        76520014: "Guanabanal",
        76520015: "Guayabal",
        76520016: "Juanchito",
        76520017: "La Acequia",
        76520035: "La Bolsa",
        76520032: "La Buitrera",
        76520039: "La Cascada",
        76520038: "La Dolores",
        76520018: "La Herradura",
        76520033: "La Pampa",
        76520019: "La Quisquina",
        76520036: "La Rita",
        76520020: "La Torre",
        76520044: "La Union",
        76520021: "La Zapata",
        76520022: "Matapalo",
        76520023: "Obando",
        76520024: "Palmaseca",
        76520000: "Palmira",
        76520045: "Piles",
        76520025: "Potrerillo",
        76520046: "Rosario",
        76520026: "Rozo",
        76520047: "San Antonio De Las Palmas",
        76520027: "Tablones",
        76520028: "Tenjo",
        76520029: "Tienda Nueva",
        76520030: "Toche",
        76520048: "Tres Tusas",
      },
      76563: {
        76563027: "Arenillo",
        76563002: "Bolo Azul",
        76563003: "Bolo Blanco",
        76563005: "Bolo Hartonal",
        76563001: "Bolovar",
        76563007: "El Lobano",
        76563008: "El Nogal",
        76563024: "El Recreo",
        76563009: "El Retiro",
        76563022: "La Carbonera",
        76563028: "La Cruz",
        76563025: "La Feria",
        76563021: "La Floresta",
        76563029: "La Froa",
        76563011: "La Granja",
        76563012: "La Ruiza",
        76563013: "La Tupia",
        76563014: "Lomitas",
        76563030: "Los Pinos",
        76563017: "Parraga",
        76563018: "Potrerito",
        76563000: "Pradera",
        76563031: "San Antonio",
        76563019: "San Isidro",
        76563020: "Vallecito",
      },
      76606: {
        76606002: "El Diamante",
        76606003: "Ilama - El Agrado",
        76606004: "La Palma",
        76606005: "Madroñal",
        76606000: "Restrepo",
        76606006: "Riobravo",
        76606007: "Roman",
        76606008: "San Salvador",
      },
      76616: {
        76616010: "El Jagual",
        76616002: "Fenicia",
        76616016: "Las Brisas",
        76616015: "La Sultana",
        76616005: "La Zulia",
        76616017: "Los Estrechos",
        76616006: "Madrigal",
        76616013: "Porto Fenicia",
        76616007: "Portugal De Piedras",
        76616000: "Riofrío",
        76616009: "Salónica la Marina",
      },
      76622: {
        76622014: "Belgica",
        76622015: "Buenavista",
        76622001: "Cajamarca",
        76622005: "Candelaria",
        76622010: "El Aguacate",
        76622019: "El Castillo",
        76622011: "El Hobo",
        76622024: "El Pie",
        76622002: "El Retiro",
        76622018: "El Silencio",
        76622003: "Higueroncito",
        76622004: "Isugu",
        76622012: "La Montañuela",
        76622013: "Mateguadua",
        76622007: "Morelia",
        76622020: "Palmar Guayabal",
        76622008: "Puerto Quintero",
        76622000: "Roldanillo",
        76622009: "Santa Rita",
        76622022: "Tierra Blanca",
      },
      76670: {
        76670001: "Angosturas",
        76670002: "Buenos Aires",
        76670018: "Guaqueros",
        76670010: "Guayabal",
        76670003: "La Esmeralda",
        76670024: "La Siria",
        76670004: "Los Chancos",
        76670011: "Monte Grande",
        76670005: "Naranjal",
        76670012: "Pavas",
        76670006: "Platanares",
        76670007: "Presidente",
        76670008: "San José",
        76670000: "San Pedro",
        76670009: "Todos Los Santos",
      },
      76736: {
        76736002: "Cebollal",
        76736004: "Corozal",
        76736005: "Cumbarco",
        76736006: "El Venado",
        76736019: "Estacion Caicedonia",
        76736008: "La Cuchilla",
        76736010: "La Melva",
        76736018: "Quebradanueva",
        76736014: "San Antonio",
        76736000: "Sevilla",
      },
      76823: {
        76823001: "Bohío",
        76823002: "El Cedro",
        76823003: "La Pradera",
        76823005: "La Quiebra",
        76823006: "San Antonio",
        76823007: "San Francisco",
        76823000: "Toro",
        76823008: "Ventaquemada",
      },
      76828: {
        76828002: "Andinápolis",
        76828016: "Bajo Caceres",
        76828018: "Cerro Azul",
        76828003: "Cristales",
        76828004: "Dos Quebradas",
        76828005: "El Tabor - Puente Blanco",
        76828006: "Huasanó",
        76828017: "La Marina",
        76828014: "La Soledad",
        76828013: "La Sonora",
        76828007: "Robledo",
        76828000: "Trujillo",
        76828010: "Venecia",
      },
      76834: {
        76834001: "Aguaclara",
        76834002: "Altaflor",
        76834003: "Barragán",
        76834004: "Bocas De Tuluá",
        76834025: "Campoalegre",
        76834029: "Cienegueta",
        76834005: "El Picacho",
        76834006: "El Retiro",
        76834030: "Gato Negro",
        76834008: "La Diadema",
        76834009: "La Iberia",
        76834010: "La Marina",
        76834011: "La Moralia",
        76834012: "La Palmera",
        76834026: "La Rivera",
        76834031: "Loma Redonda",
        76834013: "Los Caimos",
        76834014: "Mateguadua",
        76834015: "Monteloro",
        76834016: "Nariño",
        76834032: "Palomestizo",
        76834028: "Piedritas",
        76834007: "Puerto Frazadas",
        76834017: "Quebradagrande",
        76834019: "San Lorenzo",
        76834020: "San Rafael",
        76834021: "Santa Lucía",
        76834022: "Tochecito",
        76834023: "Tres Esquinas",
        76834000: "Tuluá",
        76834024: "Venus",
      },
      76845: {
        76845004: "Calamonte",
        76845001: "Chapinero",
        76845005: "Dinamarca",
        76845002: "Moctezuma",
        76845000: "Ulloa",
      },
      76863: {
        76863001: "Campoalegre",
        76863002: "El Balsal",
        76863005: "El Vergel",
        76863007: "La Florida",
        76863017: "La Playa",
        76863018: "La Pradera",
        76863014: "Murrapal",
        76863016: "Pinares",
        76863010: "Puentetierra",
        76863012: "Puerto Nuevo",
        76863000: "Versalles",
      },
      76869: {
        76869001: "Cachimbal",
        76869003: "El Porvenir",
        76869008: "El Tambor",
        76869004: "La Fresneda",
        76869007: "La Rivera",
        76869010: "Vidal",
        76869000: "Vijes",
      },
      76890: {
        76890011: "Campoalegre",
        76890012: "Dopo",
        76890010: "El Bosque",
        76890001: "El Caney",
        76890002: "El Dorado",
        76890003: "Jiguales",
        76890005: "Las Delicias",
        76890013: "Los Planes",
        76890006: "Mediacanoa",
        76890007: "Miravalle",
        76890008: "Puentetierra",
        76890014: "Punta Brava",
        76890004: "Rayito - La Negra",
        76890009: "San Antonio De Piedras",
        76890000: "Yotoco",
      },
      76892: {
        76892017: "Arroyohondo",
        76892002: "Dapa",
        76892018: "El Chocho",
        76892014: "El Pedregal",
        76892011: "La Buitrera",
        76892003: "La Olga",
        76892019: "Manga Vieja",
        76892020: "Miravalle Dapa",
        76892015: "Miravalle Norte",
        76892004: "Montañitas",
        76892005: "Mulaló",
        76892021: "Pilas De Dapa",
        76892008: "San Marcos",
        76892009: "Santa Inés",
        76892010: "Yumbillo",
        76892000: "Yumbo",
      },
      76895: {
        76895007: "El Alisal",
        76895006: "El Vergel",
        76895008: "Estación Caicedonia",
        76895001: "Guasimal",
        76895002: "La Paila",
        76895003: "Limones",
        76895004: "Quebradanueva",
        76895005: "Vallejuelo",
        76895000: "Zarzal",
      },
    },
    81: {
      81001: {
        81001000: "Arauca",
        81001018: "Cañas Bravas",
        81001017: "El Caracol",
        81001020: "Monserrate",
        81001019: "Santa Barbara",
      },
      81065: {
        81065009: "Aguachica",
        81065000: "Arauquita",
        81065019: "Brisas Del Caranal",
        81065001: "Carretero",
        81065023: "El Campamento",
        81065010: "El Caucho",
        81065020: "El Oasis",
        81065021: "El Triunfo",
        81065002: "El Troncal",
        81065011: "Guamalito",
        81065008: "La Esmeralda (Jujua)",
        81065012: "La Esperanza",
        81065005: "La Paz",
        81065022: "La Pesquera",
        81065003: "Los Angelitos",
        81065016: "Los Chorros",
        81065017: "Panamá De Arauca",
        81065013: "Potoso (Primavera)",
        81065024: "Raudales De Lipa",
        81065007: "Reinera (Gaviota)",
        81065004: "San Lorenzo",
      },
      81220: {
        81220000: "Cravo Norte",
        81220004: "Juriepe",
        81220001: "La Virgen",
        81220003: "Los Caballos",
        81220002: "San Rafael",
      },
      81300: {
        81300006: "El Mordisco",
        81300000: "Fortul",
        81300003: "La Veinte",
        81300002: "Nuevo Caranal",
        81300010: "Nuevo Horizonte",
        81300007: "Palmarito",
        81300001: "Puerto Nidia",
      },
      81591: {
        81591004: "El Loro",
        81591002: "La Correa",
        81591005: "Las Acacias",
        81591000: "Puerto Rondón",
        81591006: "San Ignacio",
        81591007: "San Ramon",
      },
      81736: {
        81736006: "Puerto Lleras",
        81736008: "Puerto Nariño",
        81736000: "Saravena",
      },
      81794: {
        81794021: "Alto Cauca",
        81794001: "Betoyes",
        81794019: "Botalón",
        81794011: "Cachama",
        81794013: "Carunal",
        81794004: "Corocito Macagua",
        81794012: "Cusay",
        81794002: "El Banco",
        81794022: "Flor Amarillo",
        81794017: "Gran Bucare",
        81794023: "La Arenosa",
        81794014: "La Holanda",
        81794003: "Las Gaviotas",
        81794024: "Malvinas",
        81794005: "Mapoy",
        81794025: "Pueblo Nuevo",
        81794026: "Pueblo Seco",
        81794015: "Puenta Tabla",
        81794006: "Puerto Gaitán",
        81794020: "Puerto Miranda",
        81794010: "Puerto Nariño",
        81794018: "Puerto Nydia",
        81794016: "San Igridero",
        81794008: "San Lope",
        81794009: "San Salvador",
        81794027: "Santo Domingo",
        81794000: "Tame",
      },
    },
    85: {
      85001: {
        85001008: "Araguaney",
        85001001: "El Morro",
        85001002: "La Chaparrera",
        85001010: "La Guafilla",
        85001011: "La Llanerita",
        85001012: "La Niata",
        85001006: "Morichal",
        85001013: "Punto Nuevo",
        85001003: "Tilodirán",
        85001000: "Yopal",
      },
      85010: {
        85010000: "Aguazul",
        85010007: "Bellavista",
        85010009: "Cunama",
        85010001: "Cupiagua",
        85010012: "El Porvenir",
        85010002: "Monterralo",
        85010010: "Puente Cusiana",
        85010003: "San Benito",
        85010005: "San José",
        85010013: "San Jose Bubuy",
        85010004: "San Miguel De Farallones",
        85010011: "Turua",
        85010006: "Unete",
      },
      85015: {
        85015000: "Chámeza",
      },
      85125: {
        85125013: "Berlon",
        85125002: "Chire",
        85125001: "Corralito",
        85125014: "El Guafal",
        85125000: "Hato Corozal",
        85125003: "La Frontera - La Chapa",
        85125015: "Las Camelias",
        85125007: "Las Tapias",
        85125004: "Manare",
        85125005: "Puerto Colombia",
        85125011: "San Jose De Ariporo",
        85125008: "San Nicolas",
        85125012: "Santa Barbara",
        85125010: "Santa Rita",
      },
      85136: {
        85136000: "La Salina",
      },
      85139: {
        85139007: "Chavinave",
        85139003: "Gaviotas",
        85139002: "La Poyata",
        85139008: "Limonal",
        85139000: "Maní",
        85139006: "San Joaquon De Garibay",
        85139005: "Santa Helena De Cúsiva",
      },
      85162: {
        85162002: "Brisas De Llano",
        85162004: "El Porvenir",
        85162007: "La Horqueta",
        85162000: "Monterrey",
        85162006: "Villa Carola",
      },
      85225: {
        85225016: "La Yopalosa",
        85225000: "Nunchía",
      },
      85230: {
        85230006: "Campo Alegre",
        85230007: "Corea",
        85230008: "Cumaco",
        85230003: "El Algarrobo",
        85230009: "La Libertad",
        85230000: "Orocué",
        85230010: "Palmarito",
      },
      85250: {
        85250001: "Bocas De La Hermosa",
        85250003: "Caño Chiquito",
        85250002: "Centro Gaitan",
        85250004: "La Aguada",
        85250006: "Montaña Del Totumo",
        85250000: "Paz De Ariporo",
      },
      85263: {
        85263001: "El Banco",
        85263003: "Mirolindo",
        85263000: "Pore",
      },
      85279: {
        85279000: "Recetor",
      },
      85300: {
        85300001: "Aguaclara",
        85300003: "El Secreto",
        85300000: "Sabanalarga",
      },
      85315: {
        85315000: "Sácama",
      },
      85325: {
        85325002: "Bocas De Guanapalo (Miramar)",
        85325003: "Gaviotas Quiteve",
        85325001: "La Venturosa",
        85325000: "San Luis De Palenque",
      },
      85400: {
        85400002: "Tablón De Tamara",
        85400000: "Támara",
        85400004: "Teislandia",
      },
      85410: {
        85410005: "Paso Cusiana",
        85410000: "Tauramena",
      },
      85430: {
        85430006: "Belgica",
        85430001: "Bocas Del Pauto",
        85430003: "Caiman",
        85430002: "Guamal",
        85430004: "La Lucha",
        85430005: "Los Chochos",
        85430000: "Trinidad",
      },
      85440: {
        85440001: "Caribayona",
        85440003: "San Agustín",
        85440002: "Santa Helena De Upía",
        85440000: "Villanueva",
      },
    },
    86: {
      86001: {
        86001001: "Condagua",
        86001002: "El Pepino",
        86001014: "La Tebaida",
        86001000: "Mocoa",
        86001003: "Pueblo Viejo",
        86001004: "Puerto Limón",
        86001006: "San Antonio",
        86001009: "Yunguillo",
      },
      86219: {
        86219000: "Colón",
        86219001: "San Pedro",
      },
      86320: {
        86320008: "Buenos Aires",
        86320012: "Empalme",
        86320001: "La Tesalia",
        86320004: "Lucitania (Churuyaco)",
        86320000: "Orito",
        86320002: "Portugal",
        86320003: "San Juan Vides",
        86320009: "San Vicente De Luzon",
        86320010: "Siberia",
        86320011: "Simon Bolovar",
      },
      86568: {
        86568022: "Alto Danubio",
        86568009: "Bajo Cuembo",
        86568029: "Campo Alegre",
        86568023: "Caña Brava",
        86568024: "Comandante",
        86568025: "El Cristal",
        86568026: "La Carmelita",
        86568013: "La Dorada San Miguel",
        86568027: "La Libertad",
        86568030: "La Rosa",
        86568006: "Piñuña Blanco",
        86568000: "Puerto Asís",
        86568028: "Puerto Bello",
        86568020: "Puerto Vega",
        86568016: "San Miguel",
        86568019: "Santana",
        86568031: "Sinao (Achapos)",
        86568021: "Teteye",
        86568005: "Villa Victoria",
      },
      86569: {
        86569003: "El Cedral",
        86569000: "Puerto Caicedo",
        86569001: "San Pedro",
        86569002: "Villa Flor",
      },
      86571: {
        86571001: "El Cedro",
        86571005: "El Gallinazo",
        86571007: "El Juano",
        86571010: "El Recreo",
        86571009: "Galilea",
        86571003: "José María",
        86571004: "Mayoyogue",
        86571000: "Puerto Guzmán",
        86571008: "Puerto Rosario",
        86571006: "San Roque",
        86571002: "Santa Lucía",
      },
      86573: {
        86573005: "El Mecaya",
        86573006: "La Paya",
        86573007: "Las Delicias",
        86573001: "La Tagua",
        86573008: "La Victoria",
        86573010: "Nueva Apaya",
        86573009: "Piñuña Negro",
        86573000: "Puerto Leguízamo",
        86573011: "Puerto Nariño",
        86573002: "Puerto Ospina",
        86573003: "Sensella",
      },
      86749: {
        86749001: "El Egido",
        86749005: "Porotoyoca",
        86749007: "San Andres",
        86749004: "San Antonio",
        86749003: "San Francisco",
        86749002: "San Pedro",
        86749006: "Santiago",
        86749000: "Sibundoy",
      },
      86755: {
        86755002: "Patuyaco",
        86755001: "San Antonio",
        86755000: "San Francisco",
      },
      86757: {
        86757000: "La Dorada",
        86757001: "Puerto Colón",
      },
      86760: {
        86760001: "San Andrés",
        86760000: "Santiago",
      },
      86865: {
        86865009: "Alto Palmira",
        86865010: "Brisas Del Palmar",
        86865011: "El Cairo",
        86865004: "El Placer",
        86865003: "El Tigre",
        86865012: "El Venado",
        86865013: "Guadualito",
        86865008: "Jordán De Guisia",
        86865014: "La Concordia",
        86865015: "La Esmeralda",
        86865000: "La Hormiga - Valle Guamuez",
        86865016: "Loro Uno",
        86865017: "Miravalle",
        86865018: "Nueva Palestina",
        86865019: "San Andres",
        86865005: "San Antonio",
        86865020: "San Isidro",
        86865007: "Santa Rosa Sucumbios",
        86865021: "Villaduarte",
      },
      86885: {
        86885004: "Albania",
        86885005: "Alto Sinao",
        86885006: "Bajo Corazon",
        86885007: "Kofania",
        86885002: "La Castellana",
        86885008: "Naranjito",
        86885009: "Porvenir",
        86885001: "Puerto Umbría",
        86885010: "San Miguel De La Castellana",
        86885011: "Santa Rosa De Juanambu",
        86885000: "Villagarzón",
        86885012: "Villarrica",
      },
    },
    88: {
      88001: {
        88001001: "La Loma",
        88001000: "San Andrés Isla",
        88001002: "San Luis",
      },
      88564: {
        88564003: "Botton House",
        88564001: "Fresh Water Bay",
        88564005: "Rocky Point",
        88564004: "San Felipe",
        88564006: "Santa Catalina",
        88564000: "Providencia",
        88564002: "South West Bay",
      },
    },
    91: {
      91001: {
        91001005: "Arara",
        91001010: "Asociacion Mujeres Indogenas Kilometro 6",
        91001011: "El Progreso",
        91001012: "El Vergel",
        91001013: "Kilometro 11",
        91001014: "Kilometro 6",
        91001015: "La Libertad",
        91001016: "La Milagrosa",
        91001017: "La Playa",
        91001018: "Las Yaguas",
        91001000: "Leticia",
        91001019: "Loma Linda",
        91001020: "Macedonia",
        91001021: "Mocagua",
        91001022: "Monilla Amena",
        91001023: "Multietnica",
        91001002: "Nazareth",
        91001024: "Nuevo Jardon",
        91001025: "Palmeras",
        91001026: "Puerto Triunfo",
        91001027: "Ronda",
        91001028: "San Antonio Lagos",
        91001008: "San José",
        91001007: "San Martín De Amacayacú",
        91001006: "San Sebastián",
        91001001: "Santa Sofía",
        91001009: "Zaragoza",
      },
      91263: {
        91263000: "El Encanto",
      },
      91405: {
        91405000: "La Chorrera",
      },
      91407: {
        91407000: "La Pedrera",
      },
      91430: {
        91430000: "Pacoa",
      },
      91460: {
        91460000: "Mirití",
      },
      91530: {
        91530000: "Puerto Alegría",
      },
      91536: {
        91536000: "Puerto Arica",
      },
      91540: {
        91540002: "Boyahuazu",
        91540003: "Doce De Octubre",
        91540004: "Naranjales",
        91540005: "Puerto Esperanza",
        91540000: "Puerto Nariño",
        91540006: "Puerto Rico",
        91540007: "San Francisco",
        91540001: "San Juan De Atacuaro",
        91540008: "San Juan Del Soco",
        91540009: "Siete De Agosto",
        91540010: "Tipisca",
        91540011: "Veinte De Julio",
      },
      91669: {
        91669000: "Puerto Santander",
      },
      91798: {
        91798000: "Tarapacá",
      },
    },
    94: {
      94001: {
        94001011: "Barranco Tigre",
        94001009: "Chaguita",
        94001012: "Coayare",
        94001010: "Coconuevo",
        94001003: "Coco Viejo",
        94001000: "Inírida",
        94001013: "Yurí",
      },
      94343: {
        94343002: "Arrecifal",
        94343000: "Barranco Minas",
        94343003: "Sapuara",
      },
      94663: {
        94663000: "Mapiripana",
        94663001: "Puerto Zancudo",
      },
      94883: {
        94883000: "San Felipe",
      },
      94884: {
        94884000: "Puerto Colombia",
        94884001: "Sejal (Mahimachi)",
      },
      94885: {
        94885000: "La Guadalupe",
      },
      94886: {
        94886000: "Cacahual",
      },
      94887: {
        94887001: "Bocas De Yari",
        94887000: "Campo Alegre",
        94887002: "Venado Isana",
      },
      94888: {
        94888000: "Morichal Nuevo",
      },
    },
    95: {
      95001: {
        95001024: "Boqueron",
        95001012: "Cachicamo",
        95001017: "Charras",
        95001016: "El Capricho",
        95001026: "El Limon",
        95001006: "Guacamayas",
        95001023: "La Carpa",
        95001027: "Las Acacias",
        95001020: "Mocuare",
        95001028: "Nuevo Tolima",
        95001009: "Puerto Nuevo",
        95001011: "Puerto Ospina",
        95001029: "Resbalon",
        95001000: "San José Del Guaviare",
        95001019: "Tomachipán",
      },
      95015: {
        95015000: "Calamar",
      },
      95025: {
        95025003: "Cerritos",
        95025000: "El Retorno",
        95025002: "El Unilla",
        95025006: "La Fortaleza",
        95025001: "La Libertad",
        95025007: "Mirolindo",
        95025005: "San Lucas",
      },
      95200: {
        95200001: "Barranquillita",
        95200004: "Buenos Aires",
        95200002: "Lagos Del Dorado",
        95200003: "Las Pavas Caño Tigre",
        95200000: "Miraflores",
        95200007: "Pueblo Nuevo",
        95200013: "Puerto Lagrimas",
        95200009: "Puerto Nare",
        95200010: "Puerto Santander",
      },
    },
    97: {
      97001: {
        97001005: "Acaricuara",
        97001001: "Bocas De Queraro",
        97001002: "Camanaos",
        97001007: "Mando",
        97001000: "Mitú",
        97001008: "Piramiro",
        97001004: "Trinidad Del Tiquie",
        97001006: "Villafátima",
        97001009: "Yapu",
        97001010: "Yuruparo",
      },
      97161: {
        97161000: "Carurú",
      },
      97511: {
        97511000: "Pacoa",
      },
      97666: {
        97666001: "Comunidad De Curupira",
        97666000: "Taraira",
      },
      97777: {
        97777000: "Morichal",
      },
      97889: {
        97889001: "Papuro",
        97889000: "Yavaraté",
      },
    },
    99: {
      99001: {
        99001002: "Casuarito",
        99001001: "La Venturosa",
        99001000: "Puerto Carreño",
      },
      99524: {
        99524000: "La Primavera",
        99524005: "Marandua",
        99524006: "Matiyure",
        99524001: "Nueva Antioquia",
        99524002: "Santa Bárbara",
        99524008: "Santa Cecilia",
        99524007: "San Teodoro (La Pascua)",
      },
      99624: {
        99624001: "Guacacías",
        99624000: "Santa Rosalía",
      },
      99773: {
        99773004: "Amanaven",
        99773017: "Chaparral",
        99773005: "Chupave",
        99773000: "Cumaribo",
        99773018: "El 15",
        99773019: "El Cañaveral",
        99773020: "El Progreso",
        99773021: "El Tuparro",
        99773002: "El Viento",
        99773008: "Guanape",
        99773022: "La 14",
        99773001: "Palmarito",
        99773013: "Puerto Nariño",
        99773010: "Puerto Príncipe",
        99773015: "Santa Rita",
        99773003: "Tres Matas",
        99773023: "Werima",
      },
    },
  };
  const localities = $data[$state_code][$city_code]
    ? $data[$state_code][$city_code]
    : null;

  Object.keys(localities).map((locality) => {
    temporalLocalities = [
      ...temporalLocalities,
      {
        name: localities[locality],
        code: locality,
      },
    ];
  });

  return temporalLocalities;
}
