import { Injectable } from "@angular/core";
import { from } from "rxjs";
import { tap } from "rxjs/operators";
//Services
import { RouterService } from "../router/router.service";
//Interfaces
import { IRemoteConfig } from "src/app/interfaces/IRemoteConfig";
import { RemoteConfigService } from "../remote-config/remote-config.service";
import { TagsService } from "../tags/tags.service";
@Injectable({
  providedIn: "root",
})
export class UniteObjectsService {
  constructor(
    private routerService: RouterService, // no delete line
    private remoteConfig: RemoteConfigService,
    private tagsService: TagsService //no delete line
  ) {}

  addParameters(response: IRemoteConfig) {
    this.addRoutes(response);
    this.addSettingsMenu(response);
    this.addSettingsMenuWeb(response);
    this.addDataOnboarding(response);
    this.addSettingsHome(response);
    this.addSettingsTabs(response);
    this.addDataLoginChat(response);
    this.addSettingsCategoryContent(response);
  }
  //use addParameter(x,y,z) when the parameter is mandatory
  addParameter(textsIU, settings, parameter) {
    for (let itemtextsIU of textsIU) {
      for (let itemSettings of settings) {
        if (itemSettings.id === itemtextsIU.id) {
          itemtextsIU[parameter] = itemSettings[parameter];
          continue;
        }
      }
    }
    return textsIU;
  }

  //  use addParameterOptional(x,y,z) when the parameter is optional
  addParameterOptional(textsIU, settings, parameter) {
    for (let itemtextsIU of textsIU) {
      for (let itemSettings of settings) {
        if (itemSettings[parameter] && itemSettings.id === itemtextsIU.id) {
          itemtextsIU[parameter] = itemSettings[parameter];
          continue;
        }
      }
    }
    return textsIU;
  }
  private addRoutes(response: IRemoteConfig) {
    //Add routes
    const parameter = "route";

    this.addParameter(
      response[0]["page_profile"]["items"],
      response[1]["page_profile"],
      parameter
    );
    this.addParameter(response[0]["menu"], response[1]["menu"], parameter);

    this.addParameter(
      response[0]["menu_web"],
      response[1]["menu_web"],
      parameter
    );
  }

  private addSettingsMenu(response: IRemoteConfig) {
    //Add parameters
    const parameters = ["order", "active", "icon", "icon_web"];

    from(parameters)
      .pipe(
        tap((parameter) =>
          this.addParameter(response[0]["menu"], response[2], parameter)
        )
      )
      .subscribe();
  }
  private addSettingsMenuWeb(response: IRemoteConfig) {
    //Add parameters
    const parameters = ["order", "active", "icon", "icon_web"];

    from(parameters)
      .pipe(
        tap((parameter) =>
          this.addParameter(response[0]["menu_web"], response[8], parameter)
        )
      )
      .subscribe();
  }

  private addDataOnboarding(response: IRemoteConfig) {
    //Add paramaters
    const paramaters = [
      "order",
      "image",
      "style",
      "actionButtonPlaceholder",
      "firstButtonPlaceholder",
    ];

    from(paramaters)
      .pipe(
        tap((parameter) =>
          this.addParameter(
            response[0]["onboarding"]["data"],
            response[3]["data"],
            parameter
          )
        )
      )
      .subscribe();

    //import methods since Remote Config
    this.importMethodsOnboarding(response);
    // response[0]["onboarding"]["background"] =
    //   "https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/onboarding%2Fbackground_page1_3.svg?alt=media&token=2859bc5a-27d8-4041-8ed3-b320bf59df1c";
  }
  importMethodsOnboarding(response) {
    response[0]["onboarding"]["data"][0].skipMethod = () =>
      eval(response[3]["data"][0]["skipMethod"]);

    response[0]["onboarding"]["data"][1].actionMethodfirstButton = () =>
      eval(response[3]["data"][1]["actionMethodfirstButton"]);

    response[0]["onboarding"]["data"][1].actionMethodsecondButton = () =>
      eval(response[3]["data"][1]["actionMethodsecondButton"]);

    response[0]["onboarding"]["data"][2].skipMethod = () =>
      eval(response[3]["data"][2]["skipMethod"]);
  }
  private addSettingsHome(response) {
    const parameters = ["order", "active"];

    //Adding paremeters mandatory
    from(parameters)
      .pipe(
        tap((parameter) =>
          this.addParameter(
            response[0]["page_home"]["data"],
            response[4],
            parameter
          )
        )
      )
      .subscribe();

    const parametersOptionals = ["options"];
    //Adding parameters optionals
    from(parametersOptionals)
      .pipe(
        tap((parameterOptional) =>
          this.addParameterOptional(
            response[0]["page_home"]["data"],
            response[4],
            parameterOptional
          )
        )
      )
      .subscribe();
  }
  private addSettingsTabs(response) {
    const parameters = ["order", "active", "icon", "tab"];

    //Adding paremeters mandatory
    from(parameters)
      .pipe(
        tap((parameter) =>
          this.addParameter(response[0]["tabs"], response[5], parameter)
        )
      )
      .subscribe();
  }
  private addDataLoginChat(response) {
    const parameters = [
      "type",
      "question_format",
      "order",
      "name",
      "edit",
      "min_length",
      "max_length",
      "in_profile_page",
      "in_profile_page_editable",
      "show_in_chat",
    ];

    //Adding paremeters mandatory
    from(parameters)
      .pipe(
        tap((parameter) =>
          this.addParameter(
            response[0]["page_login_chat"]["data"],
            response[6],
            parameter
          )
        )
      )
      .subscribe();
    this.validateMethodsForLoginChat(response);
  }

  private validateMethodsForLoginChat(response) {
    this.remoteConfig.getUnifiedQuestions().subscribe((unifiedQuestions) => {
      if (
        !this.remoteConfig.authEmailIsActivated() &&
        this.remoteConfig.authPhoneIsActivated()
      ) {
        //remove data chat login with auth email
        if (!unifiedQuestions) {
          response[0]["page_login_chat"]["data"].splice(2, 2);
        } else {
          response[0]["page_login_chat"]["data"].splice(1, 2);
          // response[0]["page_login_chat"]["data"].splice(1, 1);
        }
      } else if (
        this.remoteConfig.authEmailIsActivated() &&
        !this.remoteConfig.authPhoneIsActivated()
      ) {
        //remove data chat login with auth email
        if (!unifiedQuestions) {
          response[0]["page_login_chat"]["data"].splice(4, 2);
        } else {
          response[0]["page_login_chat"]["data"].splice(3, 2);
        }
      }
    });
  }

  private addSettingsCategoryContent(response: IRemoteConfig) {
    //Add parameters
    const parameters = [
      "order",
      "active",
      "route",
      "title",
      "description",
      "image",
    ];

    from(parameters)
      .pipe(
        tap((parameter) =>
          this.addParameter(
            response[0]["category_content"],
            response[7],
            parameter
          )
        )
      )
      .subscribe();
  }
}
