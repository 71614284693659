import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import firebase from 'firebase';
import { finalize } from 'rxjs/operators';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class InvoicesService {

  constructor(private firestore: AngularFirestore
  ) { }

  newId() {
    return firebase.firestore().collection('temp').doc().id;
  }

  saveInvoice(data, uid){
    const docRef = this.firestore.collection(`/ChallengeUsers/${uid}/Invoices/${uid}/Answers/`).doc();
    docRef.set(data);
  }


}
