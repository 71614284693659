import { EventEmitter, Injectable } from "@angular/core";
import { AngularFirestore } from "@angular/fire/firestore";
import { Observable, Subscription } from "rxjs";
import { map } from "rxjs/operators";
import { AuthService } from "../auth/auth.service";
import * as firebase from "firebase/app";
import { ModalController } from "@ionic/angular";
import { RemoteConfigService } from "../remote-config/remote-config.service";
import { ProfileModalComponent } from "src/app/components/profile-modal/profile-modal.component";
import { StorageService } from "../storage/storage.service";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    private modalController: ModalController,
    public remoteConfig: RemoteConfigService,
    public storageService: StorageService
  ) {}

  phone;
  public termCondition$ = new EventEmitter<any>();
  async saveUserExtraInfo(data, uid = "") {
    const docId = uid != "" ? uid : this.authService.userGnxId;

    const docExists = await this.verifyIfDocExist(docId);
    const ref = this.firestore.collection("user-info").doc(docId);
    if (docExists) {
      return ref.update(data);
    }
    return ref.set(data);
  }

  getUserInfo() {
    return this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .valueChanges();
  }

  getUserInfoWithId() {
    return this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .snapshotChanges()
      .pipe(
        map((action) => {
          const data: any = action.payload.data();
          data.id = action.payload.id;
          return data;
        })
      );
  }

  async checkIfProfileComplete() {
    // console.clear();
    let observableUser: Subscription;
    const chatInfo = await this.remoteConfig.getLoginChat().toPromise();
    await this.storageService
      .get("phone-to-register")
      .then((val) => (this.phone = val));
    await new Promise((resolve) => {
      observableUser = this.getUserInfo().subscribe(async (user: any) => {
        if (!user) {
          const data = {
            programId: this.authService.programId[0],
            notificationTerms: false,
            phone: this.authService.phone,
            gnxCredential1: this.authService.gnxCredential1,
            firebaseCredential1: this.phone,
          };
          await this.saveUserExtraInfo(data);
        }

        const editables = chatInfo.filter(
          (question) => question.in_profile_page_editable === true
        );

        if (
          user == undefined ||
          user.name == undefined ||
          user.lastnames == undefined
        ) {
          resolve({ showModal: true, values: { editables, user } });
          // const keysUser= Object.getOwnPropertyNames(user);
        } else {
          this.termCondition$.emit("false");
          const keysUser = Object.getOwnPropertyNames(user);
          editables.map((editable) => {
            if (!keysUser.includes(editable.name)) {
              resolve({ showModal: true, values: { editables, user } });
            }
          });
        }
      });
    }).then(async (state: any) => {
      if (state.showModal) {
        await this.presentModalToCompleteInfo(state.values);
      }
      observableUser.unsubscribe();
    });
  }

  async presentModalToCompleteInfo(values) {
    const modal = await this.modalController.create({
      component: ProfileModalComponent,
      backdropDismiss: false,
      componentProps: {
        values,
      },
      cssClass: "my-custom-class gnx-modal",
    });

    modal.onDidDismiss().then((valuesForm) => {
      this.saveUserExtraInfo(valuesForm.data, "");
    });

    return await modal.present();
  }

  exitsActiveAddress() {
    return new Observable((observer) => {
      const data = this.firestore
        .collection("user-info")
        .doc(this.authService.userGnxId)
        .valueChanges();

      data.subscribe((doc: any) => {
        if (doc.activeAddress && Object.keys(doc.activeAddress).length > 0) {
          observer.next(true);
        } else {
          observer.error(true);
        }
      });
    });
  }

  async savePictureOnfirestore(url) {
    const docExists = await this.verifyIfDocExist(this.authService.userGnxId);
    const ref = this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId);
    if (docExists) {
      return ref.update({ urlAvatar: url });
    }
    return ref.set({ urlAvatar: url });
  }

  async updateField(field, data) {
    await this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .update({ [field]: data });
  }

  async verifyIfDocExist(uid) {
    return new Promise((resolve) => {
      this.firestore
        .collection("user-info")
        .doc(uid)
        .get()
        .subscribe((res) => {
          resolve(res.exists);
        });
    });
  }

  async removeFields(field) {
    return new Promise((resolve) => {
      this.firestore
        .collection("user-info")
        .doc(this.authService.userGnxId)
        .update({
          [field]: firebase.default.firestore.FieldValue.delete(),
        });
    });
  }
  async acceptTermsConditions() {
    const fecha = new Date();
    const docExists = await this.verifyIfDocExist(this.authService.userGnxId);
    const ref = this.firestore
      .collection("user-info")
      .doc(this.authService.userGnxId);
    if (docExists) {
      return ref.update({ termsConditions: fecha });
    }
    return ref.set({ termsConditions: fecha });
  }
}
