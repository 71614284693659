import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-kpi-skeleton',
  templateUrl: './kpi-skeleton.component.html',
  styleUrls: ['./kpi-skeleton.component.scss'],
})
export class KpiSkeletonComponent implements OnInit {
  @Input() mode: "carousel" | "grid" | "detail" | "list" | "one";
  linesSkeletonText = Array.from({ length: 10 }, (_, idx) => `${++idx}`);
  slideOpts = {
    slidesPerView: 2.2,
    freeMode: true,
    coverflowEffect: {
      rotate: 50,
      stretch: 2,
      depth: 100,
      modifier: 0,
      slideShadows: true,
    },
  };
  constructor() { }

  ngOnInit() {
    switch (this.mode) {
      case "carousel":
        this.linesSkeletonText = Array.from({ length: 10 }, (_, idx) => `${++idx}`);
        break;
      case "grid":
        this.linesSkeletonText = Array.from({ length: 3}, (_, idx) => `${++idx}`);
        break;
      case "detail":
        this.linesSkeletonText = Array.from({ length: 10 }, (_, idx) => `${++idx}`);
        break;
      case "list":
        this.linesSkeletonText = Array.from({ length: 2 }, (_, idx) => `${++idx}`);
        break;
      case "one":
        this.linesSkeletonText = Array.from({ length: 1}, (_, idx) => `${++idx}`);
        break;
      default:
        this.linesSkeletonText = Array.from({ length: 10}, (_, idx) => `${++idx}`);
        break;
    }
  }

}
