import { Injectable } from "@angular/core";
import { StorageService } from "../storage/storage.service";
import { AngularFirestore } from "@angular/fire/firestore";
import { AuthService } from "../auth/auth.service";
import firebase from "firebase/app";

@Injectable({
  providedIn: "root",
})
export class AdressService {
  constructor(
    private storageService: StorageService,
    private firestoreA: AngularFirestore,
    private authService: AuthService
  ) {}

  getAdress() {}

  async setAdress(newAdresss) {
    this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .update({
        address: firebase.firestore.FieldValue.arrayUnion(newAdresss),
      });
  }
  getAllAdress() {
    //return await this.storageService.get("adress");
    return this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .valueChanges();
  }
  async setActiveAdresss(adress) {
    this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .update({
        activeAddress: adress,
      });

    await this.storageService.set("active-address", adress);
  }

  async getActiveAdress() {
    return await this.storageService.get("active-address");
  }

  removeAdress(address) {
    this.firestoreA
      .collection("user-info")
      .doc(this.authService.userGnxId)
      .update({
        address: firebase.firestore.FieldValue.arrayRemove(address),
      });
      this.unsetActiveAdress(address);
  }

  async unsetActiveAdress(address){
        this.firestoreA
        .collection("user-info")
        .doc(this.authService.userGnxId)
        .update({
          activeAddress: firebase.firestore.FieldValue.arrayRemove(address),
        })
      }
}
