// @TODO Guard 
import { Injectable } from "@angular/core";
import {
  CanActivate,
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
  Router,
} from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "../storage/storage.service";
import { AuthService } from "../auth/auth.service";
import { RemoteConfigService } from "../remote-config/remote-config.service";
import { finalize } from "rxjs/operators";

@Injectable({
  providedIn: "root",
})
export class GnxAuthGuard implements CanActivate {
  constructor(
    private remoteConfig: RemoteConfigService,
    private router: Router
  ) { }

  canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot) : Observable<boolean> | Promise<boolean> | boolean{
    // @TODO valida que el tipo de autenciación si corresponda  a la pantalla que está entrando
    let authMethod = next.data.authMethod as string;
    return new Observable<boolean>(obs => {
        this.remoteConfig.getSettingsAuthMethods().subscribe((data: any) => {
            if(authMethod == 'email' && data.email || authMethod == 'phone' && data.phone){
                obs.next(true);
            } else {
                if(data.email){
                    return this.router.navigateByUrl('/login');
                } else {
                    return this.router.navigateByUrl('/otp');
                }
                obs.next(false);
            }
    });
  });

 }

}
