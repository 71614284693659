import { UserService } from "src/app/services/user/user.service";
import { UtilsService } from "src/app/services/utils/utils.service";
import { NavParams } from "@ionic/angular";
import { CheckoutPage } from "src/app/pages/private/checkout/checkout.page";
import { AlertService } from "src/app/services/alert/alert.service";
import { MapPage } from "src/app/pages/private/map/map.page";
import { Component, OnInit } from "@angular/core";
import { CredentialsService } from "src/app/services/credentials/credentials.service";
import { LanguagesService } from "src/app/services/languagesService/languages.service";
import { RemoteConfigService } from "src/app/services/remote-config/remote-config.service";
import { RouterService } from "src/app/services/router/router.service";
import { StorageService } from "src/app/services/storage/storage.service";
import { TagsService } from "src/app/services/tags/tags.service";

@Component({
  selector: "app-catalogue-detail-component",
  templateUrl: "./catalogue-detail.component.html",
  styleUrls: ["./catalogue-detail.component.scss"],
})
export class CatalogueDetailComponent implements OnInit {
  enableReedem: boolean = false;
  id: string = "";
  constructor(
    private params: NavParams,
    private credentialsService: CredentialsService,
    private router: RouterService,
    private user: UserService,
    private storageServices: StorageService,
    public languageService: LanguagesService,
    public remoteConfig: RemoteConfigService,
    public utilsService: UtilsService,
    private tagsService: TagsService,
    public alertService: AlertService,
  ) {
    this.id = this.params.get("productId");
  }

  ngOnInit() {
    this.utilsService.getUserPoints().then((points: any) => {
      if (this.remoteConfig.enableReedem())
        if (points > 0) {
          this.enableReedem = true;
        } else {
          this.enableReedem = false;
        }
    });
    this.id = this.params.get("productId");
  }

  credentialsCatalogue = () => {
    return this.credentialsService.catalogueNew();
  };

  get getTextsIUPageCatalogueDetails() {
    return this.languageService.textsIU.page_catalogue_detail;
  }
  get getTextIUCurrency() {
    return this.languageService.textsIU.currency_data;
  }

  productSelected(product) {
    const productId = product;
    const data = {
      productId,
      desdeModal: true,
    };
    this.router.redirectWithdata("checkout", product, CheckoutPage, data, "gnx-modal");
  }

  openPopupCheckout(product){
    this.tagsService.setEventTag(
      "rewards_detail_page",
      "rewards_category_redeem_clicked",
      false,
      true
    );
    this.alertService.dismissPopup();
    this.productSelected(product);
  }

  async productDetailSelected(event: any) {
    const product = event.detail.results[0];
  
    this.alertService.dismissPopup();

    this.user.exitsActiveAddress().subscribe(
      (doc) => {
      
        this.openPopupCheckout(product);
      },
      async (err) => {
        // this.alertService.presentAlertWithMessage({header:"Problemas al realizar la solicitud",description:"No tienes registrada una dirección, comunícate con el administrador del programa para solucionar el inconveniente"},['Aceptar']);
        const claims = await this.storageServices.get("gnx-claims");
        this.router.redirectWithdata("map/no-address/" + claims.uid, product, MapPage, {
          desdeModal: true,
          productModal: product
        }, "gnx-modal" );
      }
    );
  }
}
