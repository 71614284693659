import { Injectable } from "@angular/core";
import { Platform } from "@ionic/angular";

@Injectable({
  providedIn: "root",
})
export class PlatformService {
  constructor(public platform: Platform) {}

  checkPlatform(
    platformName:
      | "ipad"
      | "iphone"
      | "ios"
      | "android"
      | "phablet"
      | "tablet"
      | "cordova"
      | "capacitor"
      | "electron"
      | "pwa"
      | "mobile"
      | "mobileweb"
      | "desktop"
      | "hybrid"
  ) {
    return this.platform.is(platformName);
  }

  getPlatform() {
    const platforms = [
      "ipad",
      "iphone",
      "ios",
      "android",
      "phablet",
      "tablet",
      "cordova",
      "capacitor",
      "electron",
      "pwa",
      "mobile",
      "mobileweb",
      "desktop",
      "hybrid",
    ];
    const platform = platforms.filter((platform: any) => {
      return this.checkPlatform(platform);
    });
    return platform;
  }

  checkIfPlatformIsIphone() {
    return (
      this.platform.is("iphone") &&
      !this.platform.is("pwa") &&
      !this.platform.is("desktop") &&
      !this.platform.is("mobileweb")
    );
  }
}
