import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-address',
  templateUrl: './icon-address.component.svg',
  styleUrls: ['./icon-address.component.scss'],
})
export class IconAddressComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
