// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: true,
  appName: "Gran Premio Peñafiel",
  gmApiKey: "AIzaSyAhKdTC6kvQG5hfOGeKheKbsWBo1ZtkTXc",
  firebaseConfig: {
    apiKey: "AIzaSyBc1WnnQFXJ1O0O77oWqF5mYw_Wh8Xk3FY",
    authDomain: "gnx-penafiel.firebaseapp.com",
    projectId: "gnx-penafiel",
    storageBucket: "gnx-penafiel.appspot.com",
    messagingSenderId: "243062572112",
    appId: "1:243062572112:web:32a5ea2bde8a2f13f97d55",
    measurementId: "G-YNQMSZNCC6",
    databaseURL: "https://gnx-penafiel.firebaseio.com",
  },
  gluky: {
    app_id: "gnx_penafiel",
    programId: "mvp_gnx",
    companyId: "penafiel_bebidas_sa_de_cv",
    apikey: "AIzaSyBc1WnnQFXJ1O0O77oWqF5mYw_Wh8Xk3FY",
    apikeyPwa: "AIzaSyAsp9gQ7Wxp-38OhTSvJTNeB7W5nGmWJ8U",
    apiKeyWeb: "AIzaSyBc1WnnQFXJ1O0O77oWqF5mYw_Wh8Xk3FY",
    apiKeyAndroid: "AIzaSyAEwr1uatBxJWjLKxMucNMVNurLK8_eN4w",
    usersApiBasePath: "https://users-api.glukynet.com/v2",
    ordersApiBasePatch: "https://orders-api.glukynet.com/v1",
    pointsApiBasePath: "https://bank-api.glukynet.com/v1",
    bffApiBasePath: "https://bff-api.cms.glukynet.com/v1",
    productsApiBasePath: "https://products-front-api.glukynet.com/v1",
    productsUrl: "https://products-front-api.glukynet.com/v1",
    challengesStrapiApiBasePath:
      "https://bff-api.cms.glukynet.com/v1/contents/challenges",
    catalogueApiBasePath:
      "https://fb07883f71224ce39a631c312f9e280b.ent-search.us-east1.gcp.elastic-cloud.com",
  },
  country: "mx",
  img_profile_default:
    "https://firebasestorage.googleapis.com/v0/b/gnx-mvp.appspot.com/o/defaults%2Fdefault-user.jpg?alt=media&token=15fbfa41-6ba2-4a88-8530-e5e5f41e45a4",
  phoneIndicator: "+52",
  clevertapAccountId: "4R6-87K-895Z",
  pathNotification: "https://notifications-api.glukynet.com/v1",
  dinamycLink: {
    url: "https://penafiel.page.link/C1mr",
    packageName: "com.gluky.penafiel",
  },
  geochallenges: {
    proximityRadiusInMeters: 800,
  },
  emblu: {
    endpoint:
      "https://us-central1-gnx-penafiel.cloudfunctions.net/getEmblueToken",
    associatePushToEmail:
      "https://us-central1-gnx-penafiel.cloudfunctions.net/setAssociatePushToEmail",
    newContact:
      "https://us-central1-gnx-penafiel.cloudfunctions.net/setNewContact",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
