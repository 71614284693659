import { Injectable } from "@angular/core";
import { RemoteConfigService } from "../remote-config/remote-config.service";
import {
  gluky_addressfield_co_cities_list,
  gluky_addressfield_co_localities_list,
  gluky_addressfield_co_states_list,
} from "../../../../localities/co";
@Injectable({
  providedIn: "root",
})
export class LocalitiesService {
  country: string;

  constructor(public remoteConfig: RemoteConfigService) {}

  getFileByCountry() {
    return new Promise((resolve) => {
      this.remoteConfig.getInfoApp().subscribe((res) => {
        this.country = res.country;
        resolve(this.loadFileByCountry());
      });
    });
  }
  loadFileByCountry() {
    let file = {};
    switch (this.country) {
      case "co":
        file = {
          gluky_addressfield_co_cities_list,
          gluky_addressfield_co_localities_list,
          gluky_addressfield_co_states_list,
        };
    }

    return file;
  }
}
