import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-icon-orders',
  templateUrl: './icon-orders.component.svg',
  styleUrls: ['./icon-orders.component.scss'],
})
export class IconOrdersComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
