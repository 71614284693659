import { Injectable } from "@angular/core";
//Services
import { AlertService } from "../services/alert/alert.service";
import { LanguagesService } from "../services/languagesService/languages.service";

@Injectable({
  providedIn: "root",
})
export class ExceptionsService {
  constructor(
    private alertService: AlertService,
    private languageService: LanguagesService
  ) {}

  getErrorMessage(error) {
    let message = "";

    switch (error.code) {
      case "auth/wrong-password":
        message = this.getTextsIUExceptions.wrong_password;
        break;
      case "auth/invalid-email":
        message = this.getTextsIUExceptions.invalid_email;
        break;
      case "auth/email-already-in-use":
        message = this.getTextsIUExceptions.email_already_in_use;
        break;
      case "auth/invalid-verification-code":
        message = this.getTextsIUExceptions.invalid_verification_code;
        break;
      case "auth/too-many-requests":
        message = this.getTextsIUExceptions.too_many_requests;
        break;
      case "auth/user-not-found":
        message = this.getTextsIUExceptions.wrong_password;
        break;
      case "Not found":
        message = this.getTextsIUExceptions.user_not_found;
        break;
      case "404":
        message = this.getTextsIUExceptions.wrong_password;
        break;
      case "403":
        message = this.getTextsIUExceptions.wrong_password;
        break;
      case "auth/duplicate_phone":
        message = this.getTextsIUExceptions.duplicate_phone;
        break;
      default:
        message = this.getTextsIUExceptions.default;
        break;
    }
    return message;
  }
  async renderError(err) {
    const messageError = await this.getErrorMessage(err);
    this.alertService.presentAlert(messageError);
  }
  get getTextsIUExceptions() {
    return this.languageService.textsIU["auth_error"];
  }
}
