import { KEY_THIS_TOKEN_FCM_DISPOSITIVE } from './../../services/fcm/fcm.service';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { StorageService } from './../../services/storage/storage.service';
import { environment } from './../../../environments/environment';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface UserNotification {
  title: string,
  body: string,
  image: {
    id: string,
    secure_url: string,
    url: string,
  },
  viewed: boolean,
  createdAt: Date,
  entityId: string,
  type: string,
  appUrl: string,
  icon?: string
  id?:string;
  date?:string;
}

@Injectable({
  providedIn: 'root'
})
export class PushNotificationsManagerService {
numberViewed$ : BehaviorSubject<number| string> = new BehaviorSubject(0);
dataNotifications$ : BehaviorSubject<string> = new BehaviorSubject('');
  constructor(
    private http: HttpClient,
    private storageServices: StorageService) { }

  async getNotifications(user?) {
    const claims = await this.storageServices.get("gnx-claims");
    const userUid = user? user : claims?.uid
    if (claims) {
      return new Promise((resolve, reject) =>
        this.http
          .get(
            `${environment.pathNotification}/frontend/user/${userUid}/notifications`
          )
          .subscribe(
            async (res:any) => {
              let numberViewed = res.data.filter((element)=>{return !element?.viewed}).length
              const numberViewedMoreNine = (numberViewed <= 9) ?  numberViewed : '9+'
              this.numberViewed$.next(numberViewedMoreNine);
              this.dataNotifications$.next(res.data)
              resolve(res.data);
            },
            (err) => {
              reject(err);
            }
          )
      );
    } else return false;
   
  }

  async viewNotifications(notification:UserNotification){
    const claims = await this.storageServices.get("gnx-claims");
    if (claims) {
      return new Promise((resolve, reject) =>
        this.http
          .put(
            `${environment.pathNotification}/frontend/user/${claims.uid}/notification/${notification.id}/viewed`,notification
          )
          .subscribe(
            async (res:any) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          )
      );
    } else return false;
  }

  async deleteToken(user?) {
    const claims = await this.storageServices.get("gnx-claims");
    const token = await this.storageServices.get(KEY_THIS_TOKEN_FCM_DISPOSITIVE);
    const userUid = user? user : claims?.uid;
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
      }),
      body: {
        "fcmToken": token?.token
      },
    };
    
    if (claims) {
      if (token?.token) {
        return new Promise((resolve, reject) =>
        this.http.delete(`${environment.pathNotification}/frontend/user/${userUid}/delete-token`, options)
          .subscribe(
            async (res:any) => {
              resolve(res);
            },
            (err) => {
              reject(err);
            }
          )
      );
      } else return false;
      
    } else return false;
   
  }

  deleteInfoCache(){
    this.numberViewed$.next(0);
    this.dataNotifications$.next('')
  }
}
